<template>
    <main class="group m-b-6" id="content" role="main">
        <slot v-if="hasModules" name="header-lessons"></slot>
        <slot v-else name="header-empty"></slot>

        <div class="page-content">
            <div class="row row-collapse">
                <div class="columns">
                    <section class="section p-t-6 p-b-6">
                        <div class="panel row">
                            <div class="panel-content columns">
                                <modules
                                    v-if="hasModules"
                                    v-bind:hub="hub"
                                    v-bind:modules="modules"
                                    v-on:modules:count="handleModulesCountChange"
                                ></modules>
                                <slot v-else name="body-empty"></slot>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { EventBus } from '../event-bus.js';

export default {
    props: {
        hub: {
            type: Object,
            required: true,
        },
        modules: {
            type: Array,
        },
    },
    data() {
        return {
            items: this.modules,
            hasModules: this.modules.length > 0,
        }
    },
    methods: {
        handleModulesCountChange(modulesCount) {
            this.hasModules = modulesCount > 0;
        },
    },
    mounted() {
        // Listen for lessons added in "bulk"
        EventBus.$on('lessons:added', (moduleWithLessons) => {
            // This handler is only needed when no modules exists. Otherwise
            // handler in lessons component will add lessons
            if (this.items.length > 0) {
                return;
            }

            this.items.push(moduleWithLessons);
            this.hasModules = true;
        });
    },
}
</script>