<template>
    <div class="uploads-group">
        <div class="card column uploads uploads-r-1_3 br c-lightest-bg text-center p-l-4 p-r-4">
            <div class="upload-content row row-middle full-width-dropzone">
                <dropzone
                    ref="dropzone"
                    v-bind:id="fieldName"
                    v-bind:url="uploadUrl"
                    v-bind:max-files="maxFilesNum"
                    v-bind:accepted-file-types="acceptedFileTypes"
                    v-bind:max-filesize="100"
                ></dropzone>
            </div>
        </div>
        <div class="slat m-t-2 m-b-2 row row-middle row-collapse br-bottom" v-for="(file, index) in items" v-bind:key="index">
            <div class="column">
                <div class="row row-collapse-indent row-middle p-b-2 p-t-2">
                    <div class="slat-content column">
                        <h4 v-if=" ! file.error" class="f-w-bold f-s-3 m-b-0">{{ file.uploadFileName }}</h4>
                        <p v-if=" ! file.uploaded && ! file.error" class="f-s-3 m-0 c-medium-tint">{{ file.uploadMbSent }}MB {{ trans('of') }} {{ file.uploadMbTotal }}MB</p>
                        <p v-if="file.uploaded" class="f-s-3 m-0 c-medium-tint">
                            <!-- /icons/check.svg -->
                            <svg class="icon m-b-1 i-size-small m-r-1" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"/><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"/></g></svg>
                            {{ trans('Uploaded') }}
                        </p>
                        <h4 v-if="file.error" class="f-w-bold f-s-3 m-b-0 c-error">{{ trans('Oops! Upload failed') }}</h4>
                        <p v-if="file.error" class="f-s-3 m-0 c-medium-tint">
                            {{ file.error }}
                            <a v-on:click.prevent="removeFile(index)" class="ul" href="#remove-file">{{ trans('Try Again.') }}</a>
                        </p>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <div v-if=" ! file.uploaded && ! file.error" class="fl-left m-r-2">
                            <upload-progress-donut
                                v-if="file.uploading"
                                size="small"
                                v-bind:percentage="file.uploadProgress"
                            ></upload-progress-donut>
                            <div v-if="file.processing" class="chartdonut chartdonut_animation--rotate chartdonut--small">
                                <svg width="33" height="33"><g><circle cx="50%" cy="50%" r="13" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                        <a class="i-container i-filled-dark cu-p" href="#remove-file" v-on:click.prevent="removeFile(index)">
                            <!-- /icons/remove.svg -->
                            <svg class="icon icon-remove i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Dropzone from './Dropzone.vue';
    import { EventBus } from '../../event-bus.js';

    export default {
        components: {
            Dropzone
        },
        props: {
            fieldName: {
                type: String,
                default: 'file'
            },
            files: {
                type: Array
            },
            acceptedFileTypes: {
                type: String,
                default: null
            },
            maxFilesNum: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                uploadUrl: Kourses.activeBaseUrl + '/upload/regular?_token=' + Laravel.csrfToken + '&context=download',
                items: []
            }
        },
        watch: {
            files(value) {
                if (value.length === 0) {
                    this.items = [];

                    // Clear all files so that in case maxFilesNum is 1 we can upload again
                    if (this.$refs['dropzone']) {
                        this.$refs['dropzone'].dropzone.removeAllFiles(false);
                    }
                }
            }
        },
        methods: {
            removeFile(index) {
                this.items.splice(index, 1);

                this.$emit('update:files', this.uploadedFiles());
                EventBus.$emit('removeAllFiles');
            },
            uploadedFiles() {
                return this.items.reduce((filtered, item) => {
                    if (item.uploaded) {
                        filtered.push({
                            id: null,
                            url: item.fileUrl,
                            name: item.fileName,
                            path: item.filePath,
                            size: item.uploadMbTotal,
                            title: item.fileName
                        });
                    }

                    return filtered;
                }, []);
            }
        },
        mounted() {
            let $vm = this;

            this.$on('dropzone:addedfile', (file) => {
                let item = {
                    uploadProgress: 0,
                    uploadFileName: file.name,
                    uploadMbSent: 0,
                    uploadMbTotal: (file.size / 1024 / 1024).toFixed(2),
                    uploading: true,
                    processing: false,
                    error: false,
                    uploaded: false
                };

                $vm.items.push(item);
            });
            this.$on('dropzone:uploadprogress', (file, progress, bytesSent) => {
                let item = _.find(this.items, ['uploadFileName', file.name]);

                item.uploadProgress = progress;
                item.uploadMbSent = (bytesSent / 1024 / 1024).toFixed(2);

                if (progress === 100) {
                    item.uploading = false;
                    item.processing = true;
                }
            });
            this.$on('dropzone:success', (file, response) => {
                let item = _.find(this.items, ['uploadFileName', file.name]);

                item.fileUrl = response.url;
                item.fileName = response.name;
                item.filePath = response.path;
                item.processing = false;
                item.uploaded = true;

                this.$emit('update:files', this.uploadedFiles());
            });
            this.$on('dropzone:error', (file, response, xhr) => {
                let item = _.find(this.items, ['uploadFileName', file.name]);

                if (xhr && xhr.status === 413) {
                    item.error = $vm.trans('File size too large. Max file size is 100MB.');
                } else if (typeof response === 'object') {
                    item.error = response.errors.file[0];
                } else if (typeof response === 'string') {
                    item.error = response;
                } else {
                    item.error = $vm.trans('Unknown error.');
                }

                item.uploading = false;
                item.processing = false;

                // KS-273 Clear "accepted files" after error
                if ($vm.$refs['dropzone']) {
                    $vm.$refs['dropzone'].dropzone.removeFile(file);
                }
            });
        }
    }
</script>