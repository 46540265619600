<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <figure class="m-0">
            <img v-if="image" v-bind:src="resizedImageUrl" v-bind:alt="alt" v-on:error="e => { resizedImageUrl = image.url; }">
            <figcaption v-if="caption" class="p-t-2 f-s-2 c-muted">{{ caption }}</figcaption>
        </figure>
    </div>
</template>
<script>
    import Image from '../page/Image.vue';

    export default {
        extends: Image
    }
</script>