<template>
    <div>
        <component
            v-if="pageHeaderBlock"
            v-bind:block="pageHeaderBlock.block"
            v-bind:index="pageHeaderBlock.index"
            v-bind:is="pageHeaderBlock.type"
            v-bind:key="pageHeaderBlock.index"
        ></component>
        <component
            v-for="block in items"
            v-bind:block="block.block"
            v-bind:index="block.index"
            v-bind:is="block.type"
            v-bind:key="block.index"
        ></component>
    </div>
</template>
<script>
    import { EventBus } from '../../event-bus.js';

    import Faqs from './blocks/renders/page/Faqs.vue';
    import Text from './blocks/renders/page/Text.vue';
    import Image from './blocks/renders/page/Image.vue';
    import Video from './blocks/renders/page/Video.vue';
    import Bullets from './blocks/renders/page/Bullets.vue';
    import FeaturedProducts from './blocks/renders/page/FeaturedProducts.vue';
    import Products from './blocks/renders/page/Products.vue';
    import PageHeader from './blocks/renders/page/PageHeader.vue';
    import Testimonials from './blocks/renders/page/Testimonials.vue';

    export default {
        components: {
            'faq': Faqs,
            'textb': Text,
            'imageb': Image,
            'videob': Video,
            'bullet': Bullets,
            'products': Products,
            'page_header': PageHeader,
            'testimonial': Testimonials,
            'featured_product': FeaturedProducts
        },
        props: {
            blocks: {
                type: [Array, Object],
                default: function() {
                    return [];
                }
            },
            data: {
                type: Object
            }
        },
        data() {
            return {
                items: this.blocks.filter((block) => {
                        return block.type !== 'page_header';
                }),
                pageHeaderBlock: _.find(this.blocks, {'type': 'page_header'})
            }
        },
        mounted() {
            var $vm = this;

            EventBus.$on('block:add', (block) => {
                if (block.type === 'page_header') {
                    $vm.pageHeaderBlock = block;
                } else {
                    $vm.items.push(block);
                }
            });

            EventBus.$on('block:remove', (index) => {
                if (_.get($vm.pageHeaderBlock, 'index') === index) {
                    $vm.pageHeaderBlock = null;
                } else {
                    $vm.items.splice($vm.items.findIndex((item) => {
                        return item.index === index;
                    }), 1);
                }
            });

            EventBus.$on('block:update:order', (newIndex, oldIndex) => {
                $vm.items.splice(newIndex, 0, $vm.items.splice(oldIndex, 1)[0]);
            });
        }
    }
</script>