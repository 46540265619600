<template>
    <modal v-bind:show.sync="show" ref="modal" class="text-left">
        <div slot="header">
            <div class="nav nav-tab">
                <a v-if="hubUrl" class="nav-item f-s-4 m-r-3 m-l-3" v-bind:class="{'nav-item--active': active === 'hub'}" href="#hub" v-on:click.prevent="active = 'hub'">{{ trans('Hub') }}</a>
            </div>
        </div>
        <div v-if="active === 'hub'">
            <h4 class="f-s-8 m-b-2">{{ trans('Your hub sharing URL') }}</h4>
            <p class="c-muted f-s-4">{{ trans('Copy and paste the links below into emails, chats or browsers.') }}</p>
            <div class="form-group form-group-addon">
                <label>
                    <span class="sc-reader">{{ trans('URL') }}</span>
                    <input class="input__field" type="text" disabled v-bind:value="hubUrl">
                </label>
            </div>
        </div>
        <div slot="footer">
            <button v-clipboard="copyUrl" v-on:success="showSuccessMessage" class="btn btn-primary m-r-3" type="button">{{ copyButtonLabel }}</button>
        </div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Modal from '../modals/Modal.vue';
import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

export default {
    components: {
        Modal
    },
    props: {
        hubUrl: {
            type: String
        },
        activeTab: {
            type: String,
            default: 'hub'
        }
    },
    data() {
        return {
            show: false,
            active: this.activeTab
        }
    },
    computed: {
        copyButtonLabel() {
            switch (this.active) {
                default:
                    return this.trans('Copy Hub URL');
            }
        }
    },
    methods: {
        copyUrl() {
            switch (this.active) {
                default:
                    return this.hubUrl;
            }
        },
        showSuccessMessage() {
            this.$toast.success(this.trans('URL copied!'));
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:share', () => {
            $vm.show = true;
        });
    }
}
</script>