<template>
    <div>
        <nav class="nav-tab nav-tab--compressed p-l-5">
            <a
                v-for="plan in plans"
                v-bind:href="'#plan-' + plan.id"
                v-bind:class="{'nav-item--active': selected === plan.id}"
                v-bind:key="plan.id"
                v-on:click.prevent="selected = plan.id"
                class="nav-item f-s-4 m-r-2 m-l-2 p-r-1 p-l-0 f-w-bold custom-heading-font"
            >{{ plan.name }}</a>
        </nav>
        <div class="card p-5">
            <div
                v-for="plan in plans"
                v-show="selected === plan.id"
                v-bind:class="{'tab-content--active': selected === plan.id}"
                v-bind:id="'plan-' + plan.id"
                v-bind:key="plan.id"
                class="tab-content"
            >
                <div class="card-content">
                    <p v-if="plan.description && plan.description.length > 0" class="f-s-4 c-primary">{{ plan.description }}</p>
                    <div class="course-membership-aside row row-collapse-indent row-middle">
                        <div class="course-membership-aside-content column xsmall-12 small-12 medium-12 large-12">
                            <span class="f-w-bold custom-heading-font c-primary f-s-9 m-r-2 inline price price--f-half price--f-w-regular" v-html="plan.value"></span>
                            <span v-if="plan.type !== 'free'" class="f-s-2 c-muted inline" v-html="plan.formatted_trial"></span>
                        </div>
                        <div class="column xsmall-12 small-12 medium-12 large-12 m-t-4">
                            <a class="btn btn-primary btn-theme btn-block" v-bind:href="plan.url">{{ buttonLabel }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            plans: {
                type: Array
            },
            buttonLabel: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                selected: this.plans[0].id
            }
        }
    }
</script>