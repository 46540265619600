<template>
    <div>
        <div v-bind:class="className">
            <label v-if="label" class="m-b-2">{{ label }}</label>
            <input class="toggle m-toggle" v-bind:id="name" type="checkbox" v-bind:class="{'checked': checked}">
            <label v-bind:for="name" v-on:click="toggle"></label>
            <input type="hidden" v-bind:name="name" v-bind:value="checked ? 1 : 0" />
        </div>
        <slot v-if="checked"></slot>
        <slot name="unchecked" v-if=" ! checked"></slot>
    </div>
</template>
<script>
    import Toggle from './Toggle.vue';

    export default {
        extends: Toggle,
        props: {
            hasUncheckedState: {
                type: Boolean,
                default: false
            }
        }
    }
</script>