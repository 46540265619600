<script>
    import { EventBus } from '../../../../../event-bus.js';

    export default {
        props: {
            block: {
                type: Object
            },
            index: {
                type: Number
            }
        },
        mounted() {
            var $vm = this;

            EventBus.$on('block:update:value', (index, property, value) => {
                if (index !== this.index) {
                    return;
                }

                this.$data[property] = value;
            });
        }
    }
</script>