<template>
    <transition name="save-bar-slide">
        <footer v-if="dirty" class="footer footer-fixed p-t-2 p-b-2 c-bright-bg text-right br-top dropshadow-small">
            <div class="row">
                <div class="columns">
                    <span v-if="disabled" class="c-muted f-s-1 m-r-4">{{ trans('Saving disabled while your upload is not finished') }}</span>
                    <button v-on:click="submitForm" v-bind:class="{'btn-loading': loading, 'btn-disabled': disabled}" v-bind:disabled="disabled" type="submit" class="btn btn-primary">
                        {{ label }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </footer>
    </transition>
</template>
<script>
    import { EventBus } from '../../event-bus.js';

    export default {
        props: {
            label: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dirty: false,
                loading: false,
                disabled: false,
            }
        },
        methods: {
            handleBeforeUnload(event) {
                var confirmationMessage = this.trans("There is some unsaved data");

                if (this.disabled === true) {
                    this.trans("There is still some data being processed");
                }

                event.returnValue = confirmationMessage;
                return confirmationMessage;
            },
            submitForm() {
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
                this.loading = true;
                return true;
            }
        },
        mounted() {
            let $vm = this;

            EventBus.$on('form:dirty', () => {
                $vm.dirty = true;
                window.addEventListener('beforeunload', this.handleBeforeUnload);
            });

            EventBus.$on('form:clean', () => {
                $vm.dirty = false;
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
            });

            EventBus.$on('form:save:unsafe', () => {
                $vm.disabled = true;
                window.addEventListener('beforeunload', this.handleBeforeUnload);
            });

            EventBus.$on('form:save:safe', () => {
                $vm.disabled = false;
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
            });
        }
    }
</script>