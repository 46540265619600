<template>
    <div class="activity-widget">
        <div class="br-bottom m-b-5">
            <div class="row row-bottom">
                <nav class="nav-tab">
                    <a class="nav-item f-s-4 m-r-2 m-l-2 p-r-1 p-l-0 nav-item--active" href="#activity-recent" data-toggle="tab">{{ trans('Recent Activity') }}</a>
                </nav>
            </div>
        </div>
        <div class="tab-content tab-content--active">
            <div class="dashboard-aside-alert">
                <activity-item v-for="item in activityItems" v-bind:icon="item.icon" v-bind:key="item.id" v-bind:message="item.message"></activity-item>
            </div>
            <div v-if="hasMoreActivities" class="text-center m-t-5">
                <button v-on:click="loadMoreActivities" v-bind:class="{'btn-loading': loadingActivities}" type="button" class="btn btn-outline btn-outline--bright">
                    {{ trans('Load More') }}
                    <div v-if="loadingActivities" class="loader">
                        <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                            <svg class="i-stroke-accent" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import ActivityItem from './ActivityItem.vue';

    export default {
        components: {
            ActivityItem
        },
        props: {
            activities: {
                type: Array
            }
        },
        data() {
            return {
                activeTab: 'activity',

                activityItems: _.get(this, 'activities.data', []),
                activitiesCurrentPage: parseInt(_.get(this, 'activities.current_page', 1), 10),
                activitiesLastPage: parseInt(_.get(this, 'activities.last_page', 1), 10),
                loadingActivities: false
            }
        },
        computed: {
            hasMoreActivities() {
                return this.activitiesCurrentPage < this.activitiesLastPage;
            }
        },
        methods: {
            loadMoreActivities() {
                this.loadingActivities = true;
                window.axios.get(Kourses.apiBaseUrl + '/dashboard/recent-activities?page=' + parseInt(this.activitiesCurrentPage + 1, 10))
                    .then(response => {
                        response.data.data.forEach(item => {
                            this.activityItems.push(item);
                        });
                        this.activitiesCurrentPage = parseInt(response.data.meta.current_page, 10);
                        this.loadingActivities = false;
                    });
            }
        }
    }
</script>