<template>
    <modal v-bind:show.sync="show" ref="modal" size="medium">
        <div slot="header"></div>

        <form-wizard
            v-cloak
            v-bind:validation="validators"
            v-bind:action="actionUrl"
        >
            <wizard-step
                v-bind:next-label="trans('Create Membership')"
                v-on:enter="elementFocus('name')"
            >
                <div class="row">
                    <div class="column">
                        <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('Give your membership a name') }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="small-12 columns">
                        <div v-bind:class="{'has-error': errors.has('name')}" class="form-group">
                            <input v-model="name" class="input__field" type="text" ref="name" name="name" v-bind:placeholder="trans('Membership name')" maxlength="255" required>
                        </div>
                        <div v-if="errors.has('name')" class="form-help">{{ errors.get('name') }}</div>
                    </div>
                </div>
            </wizard-step>
        </form-wizard>

        <div slot="footer"></div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Errors from '../../errors.js';
import Modal from '../modals/Modal.vue';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            show: false,
            errors: new Errors(),
            validators: {
                1: this.validateName,
            },
            name: null,
            actionUrl: window.Kourses.activeBaseUrl + '/memberships',
        }
    },
    methods: {
        validateName() {
            this.errors.clear();

            if ( ! this.name || this.name.trim().length === 0) {
                this.errors.set('name', this.trans('Please enter a name for your membership'));

                return false;
            }

            return true;
        },
        elementFocus(element) {
            this.$nextTick(() => this.$refs[element].focus());
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-membership', () => {
            $vm.show = true;
        });
    },
    mounted() {
        // Auto-open (show) modal window if hash is what we want
        if (window.location.hash && window.location.hash.substring(1) === 'new-membership') {
            this.show = true;
        }
    },
}
</script>