<template>
    <div class="row row-center">
        <div class="columns p-0">
            <image-upload
                field-name="image"
                ratio="3:1"
                v-bind:overlay-color="overlayColor"
                v-bind:existing-file-url="dataUrl"
                v-bind:existing-file-path="dataPath"
                v-on:image-selected="addImage"
                v-on:image-removed="removeImage"
            ></image-upload>
            <input type="hidden" v-bind:name="fieldNamePrefix + '[image][url]'" v-model="dataUrl">
            <input type="hidden" v-bind:name="fieldNamePrefix + '[image][path]'" v-model="dataPath">

            <div class="c-muted f-s-2 m-t-2">{{ trans('Make this file: 640 -1280px wide; Smaller than 1.5GB') }}</div>
            <color-picker
                v-if="useColorOverlay"
                v-bind:color-label="trans('Colour Overlay')"
                v-bind:opacity-label="trans('Opacity')"
                v-bind:value="{ color, opacity }"
                v-bind:use-opacity="true"
                v-bind:field-name-prefix="fieldNamePrefix"
                v-on:update:color="color = $event"
                v-on:update:opacity="opacity = $event"
            ></color-picker>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            fieldNamePrefix: {
                type: String,
                default: true
            },
            values: {
                type: [Object, Array]
            },
            useColorOverlay: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            overlayColor() {
                if (this.useColorOverlay) {
                    return `background-color: ${this.color}; opacity: ${this.opacity / 100}`;
                }

                return null;
            }
        },

        data: function() {
            return {
                dataUrl: _.get(this.values, 'image.url', ''),
                dataPath: _.get(this.values, 'image.path', ''),
                color: _.get(this.values, 'color', '#000000'),
                opacity: _.get(this.values, 'opacity', 50)
            };
        },

        methods: {
            addImage(image) {
                this.dataUrl = image.url;
                this.dataPath = image.path;
            },
            removeImage() {
                this.dataUrl = this.dataPath = '';
            }
        },
    }
</script>