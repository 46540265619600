<template>
    <div class="upload-group avatar-group m-b-5 row row-middle row-expand">
        <dropzone
            v-if=" ! imageUploaded"
            ref="dropzone"
            v-bind:id="fieldName"
            v-bind:url="uploadUrl"
            v-bind:accepted-file-types="acceptedFileTypes"
            v-bind:markup="dropzoneMarkup"
        ></dropzone>
        <div v-else class="avatar-super">
            <div class="avatar row-middle row">
                <img v-bind:class="{'avatar-image avatar-super': type === 'avatar', 'avatar-super': type === 'favicon'}" v-bind:src="resizedImageUrl" v-original-fallback />
            </div>
        </div>
        <div v-if=" ! uploading && ! processing && ! error" class="column">
            <button v-on:click="triggerUpload" type="button" class="btn btn-outline btn-outline--bright btn-icon">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/upload.svg -->
                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>icons/colour/upload</title><path d="M11 7.4V16h2V7.4l4 4 1.4-1.4-5.7-5.7c-.4-.4-1-.4-1.4 0L5.6 10 7 11.4l4-4zM5 18h14v2H5z" class="icon"/></svg>
                </span>
                {{ uploadLabel }}
            </button>
            <button v-if="imageUploaded" v-on:click="removeFile" type="button" class="btn btn-text btn-text--muted m-l-3">{{ deleteLabel }}</button>
        </div>
        <div v-if="uploading" class="column">
            <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading') }} {{ uploadFileName }}</h4>
            <p class="f-s-3 m-0 c-medium-tint">{{ uploadMbSent }}MB {{ trans('of') }} {{ uploadMbTotal }}MB</p>
        </div>
        <div v-if="processing" class="column">
            <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Processing') }} {{ uploadFileName }}</h4>
            <p class="f-s-3 m-0 c-medium-tint">
                <!-- /icons/check.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon i-size-small m-r-1"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"></path><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"></path></g></svg>
                {{ trans('Uploaded') }}
            </p>
        </div>
        <div v-if="error" class="column">
            <h4 class="f-w-bold f-s-3 m-b-0 c-error">{{ trans('Oops! Upload failed') }}</h4>
            <p class="f-s-3 m-0 c-medium-tint">
                {{ error }}
                <a v-on:click.prevent="removeFile" class="ul" href="#remove-file">{{ trans('Try Again.') }}</a>
            </p>
        </div>
        <div v-if="uploading" class="fl-left m-r-2">
            <upload-progress-donut
                size="small"
                v-bind:percentage="uploadProgress"
            ></upload-progress-donut>
        </div>
        <div v-if="processing" class="fl-left m-r-2">
            <div class="chartdonut chartdonut_animation--rotate chartdonut--small">
                <svg width="33" height="33"><g><circle cx="50%" cy="50%" r="13" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
            </div>
        </div>
        <div v-if="error" class="fl-left m-r-2"></div>
        <button v-if="uploading || processing || error" v-on:click="removeFile" type="button" class="i-container i-filled-dark cu-p">
            <!-- /icons/remove.svg -->
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="icon i-size-medium"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
        </button>
        <input type="hidden" v-bind:name="fieldName" v-model="filePath" />
        <input type="hidden" v-bind:name="fieldUrlName" v-model="fileUrl" />

        <image-crop-modal
            ref="image-crop-modal"
            v-on:crop:success="cropSuccessful"
            v-on:crop:cancelled="cropCancelled"
            v-bind:resize="type"
            v-bind:aspect-ratio="cropAspectRatio"
        ></image-crop-modal>
    </div>
</template>
<script>
import Dropzone from '../Dropzone.vue';
import { EventBus } from '../../../event-bus.js';
import { resizedImageUrl } from '../../../helpers.js';
import Croppable from '../../../mixins/Croppable';
import ImageCropModal from '../../modals/ImageCropModal.vue';

export default {
    components: {
        Dropzone,
        ImageCropModal,
    },
    mixins: [
        Croppable,
    ],
    props: {
        fieldName: {
            type: String,
            default: 'file'
        },
        fieldUrlName: {
            type: String,
            default() {
                return 'temp_' + this.fieldName + '_url';
            }
        },
        existingFilePath: {
            type: String
        },
        existingFileUrl: {
            type: String
        },
        existingFileName: {
            type: String
        },
        // Needed when used in a list
        index: {
            type: Number
        },
        type: {
            type: String,
            default: 'avatar'
        },
        size: {
            type: String,
            default: 'small'
        },
        uploadLabel: {
            type: String,
            required: true
        },
        deleteLabel: {
            type: String,
            required: true
        },
        crop: {
            type: Boolean,
            default: false,
        },
        cropAspectRatio: {
            type: Number,
            default: 1
        },
        maxFilesize: {
            type: Number,
            default: 50
        },
    },
    data() {
        return {
            uploadUrl: this.crop
                ? Kourses.activeBaseUrl + '/upload/regular?_token=' + Laravel.csrfToken + '&size=no_resize'
                : Kourses.activeBaseUrl + '/upload/regular?_token=' + Laravel.csrfToken + '&size=' + this.type,
            acceptedFileTypes: 'image/*',
            uploadProgress: 0,
            uploadFileName: '',
            uploadMbTotal: 0,
            uploadMbSent: 0,
            uploading: false,
            processing: false,
            error: false,
            fileUrl: _.get(this, 'existingFileUrl', ''),
            resizedImageUrl: resizedImageUrl(_.get(this, 'existingFileUrl', ''), this.size),
            fileName: _.get(this, 'existingFileName', ''),
            filePath: _.get(this, 'existingFilePath', ''),
            dropzoneMarkup: this.type === 'avatar'
                ? '<div class="br rounded-xlarge avatar-super c-lightest-bg i-filled-dark cu-p"><div class="avatar row-middle row row-center"><span class="i-container"><!-- /icons/add-29.svg --><svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" class="icon i-size-large"><g fill="#94A0B0" class="icon"><path d="M31 32c7.18 0 13-5.82 13-13v-4c0-7.18-5.82-13-13-13S18 7.82 18 15v4c0 7.18 5.82 13 13 13zm0 2c-8.284 0-15-6.716-15-15v-4c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15v4c0 8.284-6.716 15-15 15zM19 44h17v-2H19C8.507 42 0 50.507 0 61v1h60v-2H2.029C2.547 51.077 9.947 44 19 44zm33-10v18h2V34z"></path><path d="M44 44h18v-2H44z"></path></g></svg></span></div></div>'
                : '<div class="br avatar-super c-lightest-bg i-filled-dark cu-p"><div class="avatar row-middle row row-center"><span class="i-container"><!-- /icons/i-upload.svg --><svg class="icon i-size-large" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g fill="#94A0B0" class="icon"><path d="M34 47V15h-2v32z"/><path d="M47 30.414L48.414 29 33 13.586 17.586 29 19 30.414l14-14zM4 2h8V0H2v10h2zm22 0h14V0H26zm36 0v8h2V0H54v2zm0 58h-8v2h10V52h-2zm-22 0H26v2h14zM4 60v-8H2v10h10v-2zM2 24v14h2V24zm60 0v14h2V24z"/></g></svg></span></div></div>'
        }
    },
    computed: {
        imageUploaded() {
            if (this.fileUrl && this.fileUrl.length > 0) {
                return true;
            }

            return false;
        }
    },
    watch: {
        filePath(newValue) {
            EventBus.$emit('form:element:changed', this.fieldName, newValue);
        }
    },
    methods: {
        removeFile() {
            this.fileUrl    = '';
            this.resizedImageUrl = '';
            this.filePath   = '';
            this.fileName   = '';
            this.uploading = false;
            this.processing = false;
            this.error = null;

            // Cancel current uploads
            EventBus.$emit('removeAllFiles');

            // Used in blocks
            this.$emit('image-removed', this.index);
        },
        triggerUpload() {
            this.removeFile();

            this.$nextTick(() => {
                if (this.$refs['dropzone']) {
                    this.$refs['dropzone'].dropzone.hiddenFileInput.click();
                }
            });
        },
    },
    mounted() {
        let $vm = this;

        this.$on('dropzone:addedfile', (file) => {
            $vm.uploading =  true;
            $vm.processing = false;
            $vm.uploadProgress = 0;
            $vm.uploadFileName = file.name;
            $vm.uploadMbSent = 0;
            $vm.uploadMbTotal = (file.size / 1024 / 1024).toFixed(2);
            $vm.error = false;
        });
        this.$on('dropzone:uploadprogress', (file, progress, bytesSent) => {
            $vm.uploadProgress = progress;
            $vm.uploadMbSent = (bytesSent / 1024 / 1024).toFixed(2);

            if (progress === 100) {
                $vm.uploading = false;
                $vm.processing = true;
            }
        });
        this.$on('dropzone:success', (file, response) => {
            $vm.fileUrl = response.url;
            $vm.resizedImageUrl = response.url;
            $vm.fileName = response.name;
            $vm.filePath = response.path;
            $vm.processing = false;

            // For JS processing
            $vm.$emit('image-selected', response, $vm.index);
        });
        this.$on('dropzone:error', (file, error) => {
            $vm.error = $vm.trans('Something went wrong.');
            if (typeof error === 'string') {
                $vm.error = error;
            } else if (_.has(error, 'file.0')) {
                $vm.error = error.file[0];
            } else if (_.has(error, 'errors.file.0')) {
                $vm.error = error.errors.file[0];
            }

            // By changing these values in the "next tick" we are allowing
            // VueJS to swap out dropzone and force its render after there is
            // an error.
            $vm.$nextTick(() => {
                $vm.uploading = false;
                $vm.processing = false;
            });

            // KS-273 Clear "accepted files" after error
            if ($vm.$refs['dropzone']) {
                $vm.$refs['dropzone'].dropzone.removeFile(file);
            }
        });

        if (this.crop) {
            this.initCropper('image-crop-modal');
        }
    }
}
</script>