<script>
import Comment from './Comment.vue';
import Paginator from '../Paginator.vue';
import { EventBus } from '../../event-bus.js';
import { lessonEditLink, contentPageEditLink, postEditLink } from '../../routes';
import { COMMENT_STATUS_APPROVED, COMMENT_STATUS_PENDING } from '../../constants';

export default {
    components: {
        Comment,
        Paginator
    },
    props: {
        comments: {
            type: Array,
            required: true,
        },
        total: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 20
        },
        lastPage: {
            type: Number,
            default: 1
        },
    },
    computed: {
        commentableId() {
            if ( ! this.feature) {
                return null;
            }

            if (this.feature.type === 'course' || this.feature.type === 'community') {
                return this.feature.encoded_hub_id;
            }

            if (this.feature.type === 'content-page') {
                return this.feature.encoded_id;
            }

            return null;
        },
        commentableType() {
            if ( ! this.feature) {
                return null;
            }

            return this.feature.type;
        },
    },
    data() {
        return {
            items: this.comments,
            author: {
                'encoded_id': null,
                'is_admin': true,
                'full_name': this.trans('Course Author'),
                'image_url': null,
            },
            loading: false,
            paginator: {
                total: this.total,
                to: this.perPage,
                from: 1,
                lastPage: this.lastPage,
                currentPage: 1,
            },
        }
    },
    methods: {
        lessonEditLink(lesson) {
            return lessonEditLink(lesson);
        },
        contentPageEditLink(contentPageFeature) {
            return contentPageEditLink(contentPageFeature);
        },
        postEditLink(post) {
            return postEditLink(post);
        },
        changePage(page, showLoadingIndicator = true) {
            // We may don't want to show loading indicator while we fetch new comments due to deleting one
            if (showLoadingIndicator === true) {
                this.loading = true;
            }

            window.axios.get(Kourses.apiBaseUrl + '/comments',{
                params: {
                    'page': page,
                    'per_page': this.perPage,
                    'commentable_id': this.commentableId,
                    'commentable_type': this.commentableType,
                    'status': this.status || null,
                    'query': this.query || null,
                }
            }).then(this.showComments);
        },
        showComments(response) {
            this.paginator = {
                to: response.data.meta.to,
                from: response.data.meta.from,
                total: response.data.meta.total,
                lastPage: response.data.meta.last_page,
                currentPage: response.data.meta.current_page
            };
            this.items = response.data.data;
            this.loading = false;
        },
        deleteComment(comment, index) {
            this.$dialog.confirm(this.trans("If you delete it, it won't be there any more."), {loader: true})
                .then((dialog) => {
                    // We'll delete the comment "optimistically". If it fails then we'll return it back
                    this.items.splice(index, 1);

                    window.axios
                        .delete(window.Kourses.apiBaseUrl + '/comments/' + comment.encoded_id)
                        .then(() => {
                            // Fetch the current page to add one more comment (to replace the one deleted)
                            this.changePage(this.paginator.currentPage, false);

                            // Remove one comment from the total
                            this.paginator.total--;

                            dialog.close();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.danger(this.trans('Comment could not be deleted.'));

                            // Return the comment back on
                            this.items.splice(index, 0, comment);

                            dialog.close();
                        });
                });
        },
        approveComment(comment) {
            // Let's optimistically approve it immediately
            comment.status = COMMENT_STATUS_APPROVED;

            window.axios
                .post(window.Kourses.apiBaseUrl + '/comments/' + comment.encoded_id + '/approve')
                .catch((error) => {
                    console.log(error);
                    this.$toast.danger(this.trans('Comment could not be approved.'));
                    comment.status = COMMENT_STATUS_PENDING;
                })
        },
        replyTo(comment) {
            EventBus.$emit('modal:reply-to-comment', comment);
        }
    },
}
</script>