<template>
    <div>
        <div class="br-bottom m-b-5">
            <div class="row row-middle">
                <nav class="nav-tab column">
                    <a v-on:click.prevent="switchTimePeriod('today')" v-bind:class="{'nav-item--active': timePeriod === 'today'}" class="nav-item f-s-4 m-r-3 m-l-3 p-l-0" href="#time-period">{{ trans('Today') }}</a>
                    <a v-on:click.prevent="switchTimePeriod('month')" v-bind:class="{'nav-item--active': timePeriod === 'month'}" class="nav-item f-s-4 m-r-3 m-l-3" href="#time-period">{{ trans('This Month') }}</a>
                    <a v-on:click.prevent="switchTimePeriod('year')" v-bind:class="{'nav-item--active': timePeriod === 'year'}" class="nav-item f-s-4 m-r-3 m-l-3" href="#time-period">{{ trans('This Year') }}</a>
                    <a v-on:click.prevent="switchTimePeriod('all-time')" v-bind:class="{'nav-item--active': timePeriod === 'all-time'}" class="nav-item f-s-4 m-r-3 m-l-3" href="#time-period">{{ trans('All Time') }}</a>
                </nav>
                <div v-if="usedCurrencies.length > 1" class="text-right">
                    <reporting-currency-dropdown-menu
                        v-bind:selected-currency="reportingCurrency"
                        v-bind:available-currencies="usedCurrencies"
                    ></reporting-currency-dropdown-menu>
                </div>
            </div>
        </div>
        <div class="dashboard-chart-meta m-b-4">
            <div class="row row-collapse">
                <div class="column f-s-5">{{ headline }}</div>
                <div class="text-right">
                    <p class="f-s-3 m-b-2 f-w-regular c-muted">
                        <a v-bind:href="setupGuideUrl" class="ul h-anim h-c-muted">{{ trans('Show Set up Guide') }}</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="dashboard-chart-content chart-grid section p-b-5">
            <div class="row row-collapse-indent">
                <div class="medium-6 small-6 column">
                    <div class="chart br dropshadow-small">
                        <div class="chart-content p-4 p-t-5 p-b-0">
                            <div class="chart-meta row row-collapse">
                                <div class="column">
                                    <p class="f-s-5 c-medium-tint">{{ trans('Sales') }}</p>
                                </div>
                                <div class="column text-right">
                                    <div class="i-container i-tone-accent">
                                        <!-- /icons/lg-7-sales.svg -->
                                        <svg class="icon i-size-medium rounded-ittybitty" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#6F6FFF" class="icon" d="M7 25.384a1 1 0 0 1-.694-1.721l19-18.27a1 1 0 0 1 1.387 1.442l-19 18.27a.995.995 0 0 1-.693.279z"/><path d="M12.35 7.678c.092.258.15.532.15.821 0 1.379-1.122 2.5-2.501 2.5a2.501 2.501 0 0 1-2.499-2.5c0-.289.059-.562.149-.82a4.484 4.484 0 0 0-2.148 3.819c0 2.483 2.021 4.5 4.498 4.5 2.486 0 4.5-2.017 4.5-4.5a4.48 4.48 0 0 0-2.149-3.82zm13 13.501c.092.258.15.532.15.821 0 1.379-1.122 2.5-2.501 2.5A2.503 2.503 0 0 1 20.5 22c0-.289.059-.562.149-.82a4.484 4.484 0 0 0-2.148 3.819c0 2.483 2.021 4.5 4.498 4.5 2.486 0 4.5-2.017 4.5-4.5a4.48 4.48 0 0 0-2.149-3.82z" fill="#CCE6FF" class="icon-2"/><path d="M26.721 5.422a1 1 0 0 0-1.414-.028l-19 18.27a1 1 0 0 0 1.386 1.441l19-18.27a.999.999 0 0 0 .028-1.413zM9.999 13A4.506 4.506 0 0 0 14.5 8.5c0-2.481-2.02-4.5-4.501-4.5A4.504 4.504 0 0 0 5.5 8.499 4.506 4.506 0 0 0 9.999 13zm0-7A2.503 2.503 0 0 1 12.5 8.5c0 1.379-1.122 2.5-2.501 2.5A2.503 2.503 0 0 1 7.5 8.499 2.502 2.502 0 0 1 9.999 6zm13 11.5A4.505 4.505 0 0 0 18.5 22c0 2.481 2.019 4.5 4.499 4.5A4.506 4.506 0 0 0 27.5 22c0-2.481-2.02-4.5-4.501-4.5zm0 7A2.503 2.503 0 0 1 20.5 22c0-1.378 1.121-2.5 2.499-2.5A2.503 2.503 0 0 1 25.5 22c0 1.379-1.122 2.5-2.501 2.5z" fill="#6F6FFF"/></svg>
                                    </div>
                                </div>
                            </div>
                            <div class="chart-header">
                                <h4 class="chart-title c-primary f-s-10 f-w-regular m-b-1">{{ salesData.currentTotal }}</h4>
                                <div class="chart-description c-primary f-s-5">{{ salesData.newTotal }} {{ trans('New') }} / {{ salesData.rebillTotal }} {{ trans('Rebills') }}</div>
                            </div>
                        </div>
                        <div class="chart-figure">
                            <area-chart
                                v-bind:dataset="salesData.dataset"
                                class="rounded-ittybitty block"
                                fill-color="#f6f7fd"
                                stroke-color="#4867e1"
                            ></area-chart>
                        </div>
                        <div v-if="loading" class="chart-loading">
                            <div class="chartdonut chartdonut_animation--rotate chartdonut--small">
                                <svg width="33" height="33"><g><circle cx="50%" cy="50%" r="13" stroke-width="3" stroke="#4867e1" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                        <div v-if="timePeriod !== 'all-time' && ! loading" class="chart-caption chart-stat p-l-4 p-r-4">
                            <span v-if="salesData.trend === '+'" class="c-theme-bg c-bright chart-stat-group inline rounded-xlarge m-r-2">
                                <span class="i-container i-filled-bright">
                                    <!-- /icons/caret-up.svg -->
                                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"/><path class="icon" d="M17 14l-5-6-5 6z"/></svg>
                                </span>
                                <span class="chart-stat-value c-bright f-s-2 inline m-t-micro m-n-l-micro m-r-micro">{{ salesData.difference }}%</span>
                            </span>
                            <span v-else class="c-error-bg c-bright chart-stat-group inline rounded-xlarge m-r-2">
                                <span class="i-container i-filled-bright">
                                    <!-- /icons/caret-down.svg -->
                                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z"/><path fill="#000" class="icon" d="M7 10l5 6 5-6z"/></g></svg>
                                </span>
                                <span class="chart-stat-value c-bright f-s-2 inline m-t-micro m-n-l-micro m-r-micro">{{ salesData.difference }}%</span>
                            </span>
                            <span class="chart-help-text c-muted f-s-2">{{ trans('from') }} {{ previousPeriodLabel }}</span>
                        </div>
                    </div>
                </div>
                <div class="medium-6 small-6 column">
                    <div class="chart br dropshadow-small">
                        <div class="chart-content p-4 p-t-5 p-b-0">
                            <div class="chart-meta row row-collapse">
                                <div class="column">
                                    <p class="f-s-5 c-medium-tint">{{ trans('Members') }}</p>
                                </div>
                                <div class="column text-right">
                                    <div class="i-container i-tone-theme">
                                        <!-- /icons/lg-8-members.svg -->
                                        <svg class="icon i-size-medium rounded-ittybitty" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#CCE6FF" class="icon-2" d="M25 23.5a7.983 7.983 0 0 0-2.934-6.19 7.955 7.955 0 0 0-3.192 1.082c-1.749 1.202-1.749 2.293-1.749 2.293L18 27.5h5.921a7.948 7.948 0 0 0 1.079-4z"/><path d="M14.29 15.216a9.223 9.223 0 0 0-1.263-.61 5.419 5.419 0 0 0 1.907-4.126c0-2.997-2.438-5.435-5.435-5.435S4.064 7.483 4.064 10.48c0 1.65.742 3.128 1.907 4.126a9.509 9.509 0 0 0-1.262.61C1.85 16.894 0 20.173 0 23.572v2.882a1 1 0 1 0 2 0v-2.882c0-2.703 1.461-5.306 3.723-6.631a7.471 7.471 0 0 1 7.556 0c2.26 1.325 3.721 3.927 3.722 6.631v2.882a1 1 0 1 0 2 0v-2.883c-.002-3.399-1.851-6.678-4.711-8.355zM9.5 13.914c-1.894 0-3.435-1.541-3.435-3.435S7.606 7.044 9.5 7.044s3.435 1.541 3.435 3.435-1.541 3.435-3.435 3.435zm18.007 3.615c-.368-.216-.75-.4-1.142-.561 1.081-.955 1.778-2.335 1.778-3.888 0-2.871-2.335-5.207-5.206-5.207s-5.207 2.336-5.207 5.207a5.213 5.213 0 0 0 5.199 5.206l.004.001h.003c1.249 0 2.479.334 3.559.968 2.128 1.247 3.504 3.699 3.505 6.246v.955a1 1 0 1 0 2 0V25.5c-.001-3.244-1.765-6.373-4.493-7.971zm-4.569-1.243a3.21 3.21 0 0 1-3.207-3.207 3.21 3.21 0 0 1 3.207-3.207 3.21 3.21 0 0 1 3.206 3.207 3.21 3.21 0 0 1-3.206 3.207z" fill="#6F6FFF" class="icon"/></svg>
                                    </div>
                                </div>
                            </div>
                            <div class="chart-header">
                                <h4 class="chart-title c-primary f-s-10 f-w-regular m-b-1">{{ membersData.current }}</h4>
                                <div class="chart-description c-primary f-s-5">{{ trans('In :products products', {'products': membersData.products}) }}</div>
                            </div>
                        </div>
                        <div class="chart-figure">
                            <area-chart
                                v-bind:dataset="membersData.dataset"
                                class="rounded-ittybitty block"
                            ></area-chart>
                        </div>
                        <div v-if="loading" class="chart-loading">
                            <div class="chartdonut chartdonut_animation--rotate chartdonut--small">
                                <svg width="33" height="33"><g><circle cx="50%" cy="50%" r="13" stroke-width="3" stroke="#28d6b1" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                        <div v-if="timePeriod !== 'all-time' && ! loading" class="chart-caption chart-stat p-l-4 p-r-4">
                            <span v-if="membersData.trend === '+'" class="c-theme-bg c-bright chart-stat-group inline rounded-xlarge m-r-2">
                                <span class="i-container i-filled-bright">
                                    <!-- /icons/caret-up.svg -->
                                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"/><path class="icon" d="M17 14l-5-6-5 6z"/></svg>
                                </span>
                                <span class="chart-stat-value c-bright f-s-2 inline m-t-micro m-n-l-micro m-r-micro">{{ membersData.difference }}%</span>
                            </span>
                            <span v-else class="c-error-bg c-bright chart-stat-group inline rounded-xlarge m-r-2">
                                <span class="i-container i-filled-bright">
                                    <!-- /icons/caret-down.svg -->
                                    <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z"/><path fill="#000" class="icon" d="M7 10l5 6 5-6z"/></g></svg>
                                </span>
                                <span class="chart-stat-value c-bright f-s-2 inline m-t-micro m-n-l-micro m-r-micro">{{ membersData.difference }}%</span>
                            </span>
                            <span class="chart-help-text c-muted f-s-2">{{ trans('from') }} {{ previousPeriodLabel }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import AreaChart from './AreaChart.vue';
    import ReportingCurrencyDropdownMenu from './ReportingCurrencyDropdownMenu.vue';

    export default {
        components: {
            AreaChart,
            ReportingCurrencyDropdownMenu
        },
        props: {
            setupGuideUrl: {
                type: String
            },
            stats: {
                type: Object
            },
            reportingCurrency: {
                type: String
            },
            usedCurrencies: {
                type: Array
            }
        },
        data() {
            return {
                loading: false,
                timePeriod: 'today',
                salesStats: _.get(this.stats, 'sales'),
                memberStats: _.get(this.stats, 'member'),
            }
        },
        computed: {
            headline() {
                var today = new Date();
                switch (this.timePeriod) {
                    case 'today':
                        return this.trans('Today’s Overview ') + today.toLocaleDateString(window.Kourses.locale, {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                        });
                    case 'month':
                        return this.trans('Month’s Overview ') + today.toLocaleDateString(window.Kourses.locale, {
                            month: 'short',
                            year: 'numeric'
                        });
                    case 'year':
                        return this.trans('Year’s Overview ') + today.toLocaleDateString(window.Kourses.locale, {
                            year: 'numeric'
                        });
                    case 'all-time':
                        return this.trans('All Time overview');
                }
            },
            previousPeriodLabel() {
                switch (this.timePeriod) {
                    case 'today':
                        return this.trans('yesterday');
                    case 'month':
                        return this.trans('last month');
                    case 'year':
                        return this.trans('last year');
                }
            },
            membersData() {
                return _.get(this.memberStats, this.timePeriod);
            },
            salesData() {
                return _.get(this.salesStats, this.timePeriod);
            }
        },
        methods: {
            switchTimePeriod(period) {
                if (false === _.has(this.memberStats, period) || false === _.has(this.salesStats, period)) {
                    // Copy original period data sets into variable
                    var salesDatasetTempHolder = this.salesStats[this.timePeriod].dataset;
                    var memberDatasetTempHolder = this.memberStats[this.timePeriod].dataset;

                    // Reset the chart values while loading
                    this.salesStats[this.timePeriod].dataset = [0, 0];
                    this.memberStats[this.timePeriod].dataset = [0, 0];

                    this.loading = true;
                    window.axios.get(window.Kourses.apiBaseUrl + '/dashboard/stats/' + period)
                        .then(response => {
                            this.salesStats[period] = response.data.sales;
                            this.memberStats[period] = response.data.member;
                            this.loading = false;

                            // Revert back the original period data
                            this.salesStats[this.timePeriod].dataset = salesDatasetTempHolder;
                            this.memberStats[this.timePeriod].dataset = memberDatasetTempHolder;

                            this.timePeriod = period;
                        });
                } else {
                    this.timePeriod = period;
                }
            }
        }
    }
</script>