<template>
    <div class="upload-content row row-collapse row-middle">
        <div class="column p-4 overflow-y--auto">
            <h3>{{ trans('Enter your video URL') }}</h3>
            <div class="row row-bottom">
                <div class="column p-l-0">
                    <div v-bind:class="{'has-error': errors.has('url')}" class="form-group m-b-0">
                        <label>
                            {{ trans('Video URL') }}
                            <input type="text" v-model="url" maxlength="100" class="input__field p-l-2">
                        </label>
                    </div>
                </div>
                <button v-bind:class="{'btn-loading': processing}" v-on:click="processVideoUrl" type="button" class="btn btn-primary">
                    {{ trans('Add Video') }}
                    <div v-if="processing" class="loader">
                        <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                            <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                </button>
            </div>
            <div v-if="errors.has('url')" class="f-s-2 m-t-1 c-muted-dim">{{ errors.get('url') }}</div>
            <p v-else class="f-s-2 m-t-1 c-muted-dim">{{ trans('Enter the URL of your Video. We support YouTube, Wistia & Vimeo PRO videos') }}</p>
        </div>
    </div>
</template>
<script>
import Errors from '../../../errors.js';

export default {
    data() {
        return {
            url:  '',
            errors: new Errors(),
            processing: false,
        }
    },
    methods: {
        processVideoUrl() {
            this.processing = true;

            window.axios
                .post(window.Kourses.apiBaseUrl + '/video/process/url', {
                    url: this.url
                })
                .then((response) => {
                    this.errors.clear();
                    this.processing = false;
                    this.$emit('url:changed', response.data.url, response.data.type, response.data.html);
                })
                .catch((error) => {
                    console.log(error);
                    this.processing = false;
                    this.errors.record(error.response.data);
                });
        }
    },
}
</script>