<script>
    export default {
        props: {
            name: {
                type: String
            },
            block: {
                type: Object
            },
            index: {
                type: Number
            },
            expanded: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isExpanded: _.get(this, 'expanded', true)
            }
        },
        watch: {
            expanded(expanded) {
                this.isExpanded = expanded;
            }
        },
        methods: {
            removeBlock() {
                this.$emit('remove-block', this.block, this.index);
            },
            toggleBlock() {
                this.isExpanded = ! this.isExpanded;

                this.$emit('toggle-block', this.index, this.isExpanded);
            }
        }
    }
</script>