<template>
    <span v-show="show">
        <slot></slot>
    </span>
</template>
<script>
    import { EventBus } from '../event-bus.js';

    export default {
        props: {
            toggled: {
                type: Boolean,
                default: true
            },
            name: {
                type: String,
                required: true
            },
            mode: {
                type: String,
                default: 'field'
            },
            reverse: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                show: this.toggled
            }
        },
        mounted() {
            let $vm = this;

            if (this.mode === 'field') {
                EventBus.$on('form:element:changed', (name, value) => {
                    if (name !== this.name) {
                        return;
                    }

                    if (this.reverse) {
                        this.show = value === '1';
                    } else {
                        this.show = value !== '1';
                    }
                });
            } else if (this.mode === 'event') {
                EventBus.$on(this.name, (state) => {
                    this.show = state;
                });
            }
        }
    }
</script>