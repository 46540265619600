<template>
    <div class="row m-t-5 row-center">
        <div class="column large-8 medium-10 small-11 xsmall-12">
            <h3 v-if="heading" class="f-s-7 c-primary f-w-bold m-b-5">{{ heading }}</h3>
            <ul v-if="items.length > 0">
                <li v-if="item.text && item.text.length > 0" v-for="(item, index) in items" v-bind:key="index">{{ item.text }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                heading: _.get(this.block.data, 'heading'),
                items: _.get(this.block.data, 'items', [])
            }
        }
    }
</script>