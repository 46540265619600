<template>
    <div class="editor__container m-2">
        <h3>{{ trans('Page Elements') }}</h3>

        <component
            v-if="pageHeaderBlock"
            v-bind:name="pageHeaderBlock.name"
            v-bind:index="pageHeaderBlock.index"
            v-bind:block="pageHeaderBlock.block"
            v-bind:is="pageHeaderBlock.type"
            v-bind:key="pageHeaderBlock.index"
            v-on:remove-pageHeaderBlock="removeBlock"
            v-on:toggle-pageHeaderBlock="toggleBlock"
            v-bind:expanded="expandedBlock === pageHeaderBlock.index"
        ></component>

        <div v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
            <component
                v-for="block in items"
                v-bind:name="block.name"
                v-bind:index="block.index"
                v-bind:block="block.block"
                v-bind:is="block.type"
                v-bind:key="block.index"
                v-on:remove-block="removeBlock"
                v-on:toggle-block="toggleBlock"
                v-bind:expanded="expandedBlock === block.index"
                v-bind:uses-navbar-title="navbarTitle"
            ></component>
        </div>
        <div class="expandable expandable-icon-bar m-t-1 p-t-4">
            <div class="row row-center br-top p-t-1">
                <a class="icon-toggle" href="#expand" v-on:click.prevent="expanded = ! expanded">
                    <div class="i-container dropshadow-medium inline insert-buttons-show p-2 rounded-xlarge c-accent-bg i-filled-bright">
                        <!-- /icons/add.svg -->
                        <svg v-if=" ! expanded" class="icon icon-expand-toggle i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                        <!-- /icons/s-close.svg -->
                        <svg v-else class="icon icon-expand-toggle i-size-small" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#6F6FFF" class="icon" d="M9.414 8l2.793-2.793a.999.999 0 1 0-1.414-1.414L8 6.586 5.207 3.793a.999.999 0 1 0-1.414 1.414L6.586 8l-2.793 2.793a.999.999 0 1 0 1.414 1.414L8 9.414l2.793 2.793a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L9.414 8z"/></svg>
                    </div>
                </a>
            </div>
            <transition name="drip-email-slide">
                <div v-if="expanded" class="expand-content">
                    <div class="row row-center p-b-1 row-collapse">
                        <div class="columns p-l-5 p-r-5">
                            <div class="row row-collapse" v-for="(rowBlocks, index) in availableBlocks" v-bind:key="index">
                                <div class="column text-center" v-for="(block, type) in rowBlocks" v-bind:key="type">
                                    <div class="card column p-2">
                                        <a class="card-item card-link" v-bind:href="'#add-' + type" v-on:click.prevent="addBlock(type)">
                                            <div class="card-figure">
                                                <div class="i-container btn btn-text width-auto-min i-tone-accent" v-html="block.icon"></div>
                                            </div>
                                            <div class="card-content">
                                                <div class="c-muted f-s-2 m-b-1">{{ block.name }}</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:key="index" v-bind:value="id" />
    </div>
</template>
<script>
    import { EventBus } from '../../event-bus.js';

    import FaqBlock from './blocks/FaqBlock.vue';
    import TextBlock from './blocks/TextBlock.vue';
    import ImageBlock from './blocks/ImageBlock.vue';
    import VideoBlock from './blocks/VideoBlock.vue';
    import BulletBlock from './blocks/BulletBlock.vue';
    import PageHeaderBlock from './blocks/PageHeaderBlock.vue';
    import MembershipsBlock from './blocks/MembershipsBlock.vue';
    import TestimonialBlock from './blocks/TestimonialBlock.vue';
    import FeaturedMembershipBlock from './blocks/FeaturedMembershipBlock.vue';
    import PrimaryButtonBlock from './blocks/PrimaryButtonBlock.vue';
    import AuthorBlock from './blocks/AuthorBlock.vue';
    import PricingBlock from './blocks/PricingBlock.vue';
    import CourseContentBlock from './blocks/CourseContentBlock.vue';

    export default {
        components: {
            'faq': FaqBlock,
            'textb': TextBlock,
            'imageb': ImageBlock,
            'videob': VideoBlock,
            'bullet': BulletBlock,
            'page_header': PageHeaderBlock,
            'testimonial': TestimonialBlock,
            'memberships': MembershipsBlock,
            'featured_membership': FeaturedMembershipBlock,
            'primary_button': PrimaryButtonBlock,
            'author': AuthorBlock,
            'pricing': PricingBlock,
            'course_content': CourseContentBlock
        },
        props: {
            blocks: {
                type: [Array, Object],
                default: function() {
                    return [];
                }
            },
            enabledBlocks: {
                type: Object,
                required: true
            },
            data: {
                type: Object
            },
            navbarTitle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                index: this.blocks.length,

                expanded: this.blocks.length > 0 ? false : true,
                availableBlocks: _.chain(this.enabledBlocks).toPairs().chunk(5).map(_.fromPairs).value(),
                forRemoval: [],
                expandedBlock: 0,

                items: this.blocks.filter((block) => {
                        return block.type !== 'page_header';
                }),
                pageHeaderBlock: _.find(this.blocks, {'type': 'page_header'})
            };
        },
        methods: {
            addBlock(type) {
                let block = {
                    type: type,
                    name: 'blocks[' + this.index + ']',
                    index: this.index,
                    block: {
                        data: {}
                    },
                    autostart: true
                };

                if (type === 'page_header') {
                    this.pageHeaderBlock = block;
                } else {
                    this.items.push(block);
                }

                this.expandedBlock = this.index;

                EventBus.$emit('block:add', block);

                this.expanded = false;

                this.index += 1;
            },
            onUpdate(event) {
                // Update items order
                this.items.splice(event.newIndex, 0, this.items.splice(event.oldIndex, 1)[0]);

                EventBus.$emit('block:update:order', event.newIndex, event.oldIndex);
            },
            removeBlock(block, index) {
                if (_.get(this.pageHeaderBlock, 'index') === index) {
                    this.pageHeaderBlock = null;
                } else {
                    this.items.splice(this.items.findIndex((item) => {
                        return item.index === index;
                    }), 1);
                }

                EventBus.$emit('block:remove', index);

                if (_.get(block, 'id')) {
                    this.forRemoval.push(block.id);
                }
            },
            toggleBlock(index, toggled) {
                if (toggled) {
                    this.expandedBlock = index;
                } else {
                    this.expandedBlock = null;
                }
            }
        }
    }
</script>