<template>
    <div class="columns">
        <div v-if="showToolbar" class="row row-collapse-indent row-middle m-b-2">
            <div class="column">
                <div class="dropdown-container btn btn-text header-nav--link p-l-0 p-r-0 f-w-regular" v-toggle="'ddCategories'">
                    <span class="p-r-1 f-s-4">{{ trans('Categories') }}</span>
                    <span class="i-container i-filled-dark">
                        <!-- /icons/caret-down.svg -->
                        <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z"/><path fill="#000" class="icon" d="M7 10l5 6 5-6z"/></g></svg>
                    </span>
                    <div class="dropdown dropdown--width-default dropdown--bd dropdown-p--left" id="ddCategories">
                        <div class="dropdown-group text-left p-4 p-t-3">
                            <div>
                                <a
                                    v-on:click.prevent="selectedCategory = null"
                                    href="#all-categories"
                                    class="c-primary p-t-2 p-b-2 f-s-4 block"
                                >{{ trans('All categories') }}</a>
                            </div>
                            <div v-for="category in categories" v-bind:key="category.id">
                                <a
                                    v-on:click.prevent="selectedCategory = category.id"
                                    v-bind:href="'#category-' + category.id"
                                    class="c-primary p-t-2 p-b-2 f-s-4 block"
                                >{{ category.name }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-container btn btn-text header-nav--link p-l-0 p-r-0 f-w-regular" v-toggle="'ddTags'">
                    <span class="p-r-1 f-s-4">{{ trans('Tags') }}</span>
                    <span class="i-container i-filled-dark">
                <!-- /icons/caret-down.svg -->
                        <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z"/><path fill="#000" class="icon" d="M7 10l5 6 5-6z"/></g></svg>
                    </span>
                    <div class="dropdown dropdown--width-default dropdown--bd dropdown-p--left" id="ddTags">
                        <div class="dropdown-group text-left p-4 p-t-3">
                            <div>
                                <a
                                    v-on:click.prevent="selectedTag = null"
                                    href="#all-tags"
                                    class="c-primary p-t-2 p-b-2 f-s-4 block"
                                >{{ trans('All tags') }}</a>
                            </div>
                            <div v-for="tag in tags" v-bind:key="tag.tag">
                                <a
                                    v-on:click.prevent="selectedTag = tag.tag"
                                    v-bind:href="'#tag-' + tag.tag"
                                    class="c-primary p-t-2 p-b-2 f-s-4 block"
                                >{{ tag.tag }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right m-r-4">
                <div class="form-group form-group-addon form-group-simple m-b-0 search-bar search-bar-expand fl-right text-right" v-bind:class="{'search-bar-open': searchVisible}">
                    <label>
                        <input class="input__field" type="text" v-bind:placeholder="trans('Type name, or keyword')" v-model="query" ref="search-field">
                    </label>
                    <div class="form-append m-b-1 p-b-micro">
                        <button v-on:click="toggleSearch" type="button" class="i-container i-filled-dark c-bright-bg btn btn-text width-auto-min p-0 m-r-0 p-b-2">
                            <!-- /icons/search.svg -->
                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showToolbar" class="br-top m-b-5"></div>
        <div class="card-grid row row-collapse-indent row-center">
            <div v-for="membership in filteredMemberships" v-bind:class="{'large-4': ! narrow, 'large-6': narrow}" v-bind:key="membership.id" class="card column medium-6 small-6 xsmall-12 m-b-5">
                <a class="card-item card-item--stretch card-link c-bright-bg br" href="#sales-page">
                    <div class="card-figure card-figure-animate animate rounded-ittybitty">
                        <img v-if="membership.image" v-bind:src="membership.image_url.small">
                        <img v-else v-bind:src="membership.image_type_url">
                    </div>
                    <div class="card-content p-4 p-b-5">
                        <h2 class="card-title f-s-5 m-b-0">{{ membership.name }}</h2>
                        <div v-if="membership.author" class="c-medium-tint f-s-1 m-t-1">{{ membership.author.full_name }}</div>
                        <p v-if="membership.description" class="c-medium-tint f-s-3 m-t-4 m-b-0" v-html="clipHtml(membership.stripped_description, 150)"></p>
                    </div>
                    <div class="card-footer card-footer--bottom p-b-4">
                        <div v-if="useWidePrice(membership)" class="row row-middle row-compress">
                            <div class="column large-6">
                                <div class="btn btn-theme btn--narrow btn--variable-height block">{{ trans('Learn More') }}</div>
                            </div>
                            <div class="column card-price f-s-3 c-dark f-w-bold p-l-0 price price--f-small price--interval-inline-block price--installments-inline-block price--vat-block price--vat-c-muted" v-html="membership.value"></div>
                        </div>
                        <div v-else class="row row-middle">
                            <div class="column large-8">
                                <div class="btn btn-theme block">{{ trans('Learn More') }}</div>
                            </div>
                            <div class="column card-price f-s-3 c-dark f-w-bold p-l-0 price price--f-small price--interval-inline-block price--installments-inline-block price--vat-block price--vat-c-muted" v-html="membership.value"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div v-if="showLoadMore" class="row row-center m-t-4">
            <button v-on:click="showAll = true" type="button" class="btn btn-theme">{{ trans('Load More') }}</button>
        </div>
    </div>
</template>
<script>
import clip from 'text-clipper';

export default {
    props: {
        items: {
            type: Array
        },
        categories: {
            type: Array
        },
        tags: {
            type: Array
        },
        category: {
            type: Number
        },
        tag: {
            type: String,
            default: null,
            required: false
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
        visibleItemsNum: {
            type: Number,
            default: 6
        },
        narrow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            memberships: _.get(this, 'items',[]),
            selectedCategory: _.get(this, 'category', null),
            selectedTag: _.get(this, 'tag', null),
            query: null,
            showAll: false,
            searchVisible: false
        }
    },
    computed: {
        filteredMemberships() {
            let filteredMemberships = this.memberships;

            if (this.selectedCategory) {
                filteredMemberships = filteredMemberships.filter(item => item.category_id === this.selectedCategory);
            }

            if (this.selectedTag && this.selectedTag.length > 0) {
                filteredMemberships = filteredMemberships.filter(item => {
                    return typeof _.find(item.tags, {'tag': this.selectedTag}) !== 'undefined';
                });
            }

            if (this.query !== null && this.query.length > 0) {
                filteredMemberships = filteredMemberships.filter(item => _.startsWith(item.title.toLowerCase(), this.query));
            }

            if ( ! this.showAll) {
                filteredMemberships = filteredMemberships.slice(0, this.visibleItemsNum);
            }

            return filteredMemberships;
        },
        showLoadMore() {
            return this.filteredMemberships.length !== this.memberships.length;
        }
    },
    methods: {
        toggleSearch() {
            this.searchVisible = ! this.searchVisible;

            if (this.searchVisible) {
                this.$refs['search-field'].focus();
            }
        },
        clipHtml(string, length) {
            return clip(string, length, {
                html: true
            });
        },
        planType(membership) {
            if (membership.pricing_plans && membership.pricing_plans.length > 0) {
                return membership.pricing_plans[0].type;
            }

            return null;
        },
        useWidePrice(membership) {
            return true;

            // const planType = this.planType(membership);

            // if (planType === 'installment-plan' || planType === 'subscription') {
            //     return true;
            // }

            // return false;
        },
    }
}
</script>