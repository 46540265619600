<template>
    <div>
        <div v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
            <div v-for="(category, index) in items" v-bind:key="'hub-category-' + category.encoded_id" class="slat-group p-t-3 br p-b-2 m-b-5" v-bind:data-encoded-id="category.encoded_id">
                <div class="slat-group-header row row-full row-middle">
                    <div class="slat-controls m-vertical-none p-r-1 m-l-5">
                        <span class="m-r-2 i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                    </div>
                    <div class="slat-content column">
                        <h2 class="f-w-bold f-s-4 m-b-0">
                            {{ category.title }}
                            <a class="i-container i-filled-dark" v-on:click.prevent="showModalEdit(category, index)" href="#edit-category">
                                <!-- /icons/edit.svg -->
                                <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                            </a>
                        </h2>
                    </div>
                    <div class="slat-aside text-right m-4">
                        <span class="tooltip animate">
                            <a v-on:click.prevent="deleteCategory(category, index)" class="i-container i-filled-dark" href="#delete-category">
                                <!-- /icons/s-trash.svg -->
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                            </a>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                        </span>
                    </div>
                </div>
                <div class="slat-group-content p-l-4 p-r-4 p-t-4 m-t-1 p-t-0">
                    <hubs
                        v-bind:hubs="category.hubs"
                        v-bind:category-id="category.id"
                        v-bind:key="'hubs-' + category.encoded_id"
                    ></hubs>
                </div>
            </div>
        </div>
        <div>
            <div class="text-center p-t-1">
                <button v-on:click="showModalNew" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4 cu-p">
                    <span class="icon i-container i-filled-accent">
                        <!-- /icons/add.svg -->
                        <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                    </span>
                    {{ trans('Add Category') }}
                </button>
            </div>
        </div>
        <new-hub-category-modal
            v-on:close="modalNew.show = false"
            v-on:add-category="addCategory"
            ref="new-category-modal"
        ></new-hub-category-modal>
        <edit-hub-category-modal
            v-on:close="modalEdit.show = false"
            v-on:save-category="editCategory"
            v-on:delete-category="deleteCategory"
            ref="edit-category-modal"
        ></edit-hub-category-modal>
    </div>
</template>
<script>
import Orderable from '../../mixins/Orderable.js';
import Hubs from './Hubs.vue';
import NewHubCategoryModal from './NewHubCategoryModal.vue';
import EditHubCategoryModal from './EditHubCategoryModal.vue';

export default {
    components: {
        Hubs,
        NewHubCategoryModal,
        EditHubCategoryModal
    },
    mixins: [Orderable],
    props: {
        categories: {
            type: Array,
        },
    },
    data() {
        return {
            items: _.get(this, 'categories', []),
            model: 'Kourses\\Models\\HubCategory',
        }
    },
    methods: {
        showModalNew() {
            this.modalNew.show = true;
        },
        addCategory(category) {
            window.axios
                .post(window.Kourses.apiBaseUrl + '/hub-categories', category)
                .then((response) => {
                    this.items.push(response.data);
                    this.modalNew.show = false;
                    this.modalNew.reset();
                })
                .catch((error) => {
                    this.modalNew.setErrors(error.response.data);
                });
        },
        showModalEdit(category, index) {
            this.modalEdit.category = category;
            this.modalEdit.index = index;
            this.modalEdit.show = true;
        },
        editCategory(category, index) {
            window.axios
                .put(window.Kourses.apiBaseUrl + '/hub-categories/' + category.encoded_id, category)
                .then(() => {
                    this.items.splice(index, 1, category);
                    this.modalEdit.show = false;
                    this.modalEdit.reset();
                })
                .catch((error) => {
                    this.modalEdit.setErrors(error.response.data);
                });
        },
        deleteCategory(category, index) {
            // We need to check for existence of HTML elements as we are not
            // syncing category items due to draggable issues
            if (this.$el.querySelectorAll(`[data-hub-category-id="${category.id}"] .slat`).length > 0) {
                this.$dialog
                    .alert(this.trans("You can't delete a category that still contains hubs. Please delete or move hubs before deleting this category."), {
                        okText: this.trans('OK'),
                        headline: this.trans('Hub Category Is Not Empty')
                    });
            } else {
                this.$dialog
                    .confirm(this.trans("Are you sure you wish to delete this hub category?"), {
                        loader: true,
                        headline: this.trans("Delete Hub Category?")
                    })
                    .then((dialog) => {
                        window.axios
                            .delete(window.Kourses.apiBaseUrl + '/hub-categories/' + category.encoded_id)
                            .then(() => {
                                this.items.splice(index, 1);
                                this.modalEdit.show = false;
                                this.modalEdit.reset();
                                dialog.close();
                            });
                    });
            }
        },
    },
    mounted() {
        this.modalNew = this.$refs['new-category-modal'];
        this.modalEdit = this.$refs['edit-category-modal'];
    },
}
</script>