<template>
    <div v-sortable="{ onEnd: onUpdate, handle: '.handle', group: 'lessons' }" v-bind:data-module-id="moduleId" style="min-height:10px;">
        <template v-if="items && items.length > 0">
            <div  class="slat m-t-4 m-b-3 slat-draggable slat-product-lesson" v-for="lesson in items" v-bind:key="'lesson-' + lesson.id" v-bind:data-encoded-id="lesson.encoded_id">
                <div class="row row-compress row-middle br p-t-3 p-b-3" v-bind:class="{'slat-draft': lesson.status === 'draft'}">
                    <div class="slat-controls m-vertical-none m-l-2">
                        <span class="m-r-2 i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                        <span class="control-group">
                            <div class="inline">
                                <label class="control control-checkbox block c-muted f-s-3 m-b-0 p-l-1">
                                    <input v-bind:checked="isSelected(lesson.id)" v-on:change="toggleSelected(lesson.id)" type="checkbox" />
                                    <div class="control-indicator control-checkbox-indicator"></div>
                                </label>
                            </div>
                        </span>
                    </div>
                    <div class="slat-figure column">
                        <a v-bind:href="lessonEditLink(lesson)" v-bind:alt="lesson.name" class="block">
                            <img v-bind:src="getLessonThumbnail(lesson)">
                        </a>
                    </div>
                    <div class="slat-content column">
                        <a v-bind:href="lessonEditLink(lesson)" class="f-s-3 m-b-0 f-w-bold block">
                            <span class="c-primary">{{ lesson.name }}</span>
                            <span class="i-container i-filled-dark m-r-2">
                                <!-- /icons/edit.svg -->
                                <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                            </span>
                            <span v-if="lesson.status === 'draft'" class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-r-4">{{ trans('Draft') }}</span>
                        </a>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <!--<a v-on:click.prevent="changePreviewStatus(lesson, lesson.preview_enabled ? 0 : 1)" href="#lesson-preview">
                            <span class="tooltip animate m-3">
                                <span v-bind:class="{'i-filled-dark': ! lesson.preview_enabled, 'i-filled-theme': lesson.preview_enabled}" class="i-container">
                                    <svg class="icon m-b-1 m-l-2 i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M15.99 10.042c.002-.073-.012-.142-.025-.212-.009-.044-.002-.088-.017-.132C14.713 5.991 11.519 3.5 8 3.5S1.287 5.991.052 9.698a1 1 0 1 0 1.897.633C2.911 7.441 5.344 5.5 8 5.5c2.541 0 4.863 1.788 5.904 4.473a11.21 11.21 0 0 1-.661.94 1 1 0 1 0 1.598 1.203c.417-.555.807-1.081 1.049-1.648l.007-.015c.011-.024.024-.048.034-.072.018-.045.014-.092.024-.139.016-.066.033-.13.035-.2z"/><path d="M8 5.515c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"/></g></svg>
                                </span>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Make Free Preview') }}</span>
                            </span>
                        </a>-->
                        <a href="#lesson-publish" v-on:click.prevent="changePublishStatus(lesson, lesson.status === 'draft' ? 'published' : 'draft')">
                            <span class="tooltip animate">
                                <span class="i-container" v-bind:class="{'i-filled-dark': lesson.status === 'draft', 'i-filled-theme': lesson.status === 'published'}">
                                    <!-- /icons/s-checked.svg -->
                                    <svg class="icon m-b-1 m-l-2 i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#6F6FFF" class="icon" d="M7.5 11.5l-.056-.002a1.004 1.004 0 0 1-.726-.373l-2-2.5a1.002 1.002 0 0 1 1.563-1.25l1.302 1.628 5.59-5.591a.999.999 0 1 1 1.414 1.414l-6.38 6.381a.993.993 0 0 1-.707.293z"/><path fill="#6F6FFF" class="icon" d="M7.999 15.058a7.057 7.057 0 0 1-7.05-7.05c0-3.887 3.162-7.049 7.05-7.049 1.391 0 2.737.405 3.895 1.171a1 1 0 1 1-1.104 1.668 5.056 5.056 0 0 0-7.841 4.21c0 2.784 2.266 5.05 5.05 5.05s5.05-2.266 5.05-5.05a1 1 0 1 1 2 0 7.057 7.057 0 0 1-7.05 7.05z"/></svg>
                                </span>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Publish') }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Orderable from '../mixins/Orderable.js';
import { EventBus } from '../event-bus.js';
import { lessonEditLink, lessonDeleteLink, lessonPublishLink, lessonPreviewLink } from '../routes.js';

export default {
    mixins: [Orderable],
    props: {
        lessons: {
            type: Array
        },
        checked: {
            type: Array,
            default: function() {
                return [];
            }
        },
        moduleId: {
            type: Number
        }
    },
    data() {
        return {
            items: this.lessons,
            model: 'Kourses\\Lesson',
            orderableParentKey: 'module_id',
        }
    },
    methods: {
        lessonEditLink(lesson) {
            return lessonEditLink(lesson);
        },
        isSelected(lessonId) {
            return _.indexOf(this.checked, lessonId) >= 0;
        },
        toggleSelected(lessonId) {
            let checked = this.checked;
            let index = _.indexOf(checked, lessonId);

            if (index < 0) {
                checked.push(lessonId);
            } else {
                checked.splice(index, 1);
            }

            this.$emit('update:checked', checked);
        },
        changePublishStatus(lesson, status) {
            lesson.status = status;
            if (status === 'published') {
                window.axios
                    .put(lessonPublishLink(lesson))
                    .then((response) => {
                        lesson.status = response.data.status;
                    });
            } else {
                window.axios
                    .delete(lessonPublishLink(lesson))
                    .then((response) => {
                        lesson.status = response.data.status;
                    });
            }
        },
        changePreviewStatus(lesson, status) {
            lesson.preview_enabled = status;
            if (status) {
                window.axios
                    .put(lessonPreviewLink(lesson))
                    .then((response) => {
                        lesson.preview_enabled = response.data.preview_enabled;
                    });
            } else {
                window.axios
                    .delete(lessonPreviewLink(lesson))
                    .then((response) => {
                        lesson.preview_enabled = response.data.preview_enabled;
                    });
            }
        },
        deleteLesson(lesson) {
            window.axios
                .delete(lessonDeleteLink(lesson))
                .then(() => {
                    this.lessons.splice(_.findIndex(this.items, (item) => {
                        return lesson.id === item.id;
                    }), 1);
                });
        },
        getLessonThumbnail(lesson) {
            if (lesson.image) {
                return lesson.image_url.thumbnail;
            } else if (lesson.video_image_url) {
                return lesson.video_image_url;
            }

            return '/img/placeholder-image.png';
        },
    },
    mounted() {
        const $vm = this;

        // Listen for lessons added in "bulk"
        EventBus.$on('lessons:added', (moduleWithLessons) => {
            if ($vm.moduleId !== parseInt(moduleWithLessons.id)) {
                return;
            }

            for (let lesson of moduleWithLessons.lessons) {
                $vm.items.push(lesson);
            }
        });
    }
}
</script>