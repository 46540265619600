<template>
    <div class="row row-center">
        <div class="columns p-0">
            <div class="form-group-addon m-b-2">
                <label class="control control-checkbox block c-muted m-b-0">
                    {{ trans('Use Default Header Image') }}
                    <input v-bind:name="fieldNamePrefix + '[default_image]'" v-model="useDefaultImage" value="1" type="checkbox">
                    <div class="control-indicator control-checkbox-indicator"></div>
                </label>
            </div>
            <div class="cover-container">
                <image-upload
                    field-name="image"
                    ratio="3:1"
                    v-bind:overlay-color="overlayColor"
                    v-bind:existing-file-url="dataUrl"
                    v-bind:existing-file-path="dataPath"
                    v-on:image-selected="addImage"
                    v-on:image-removed="removeImage"
                ></image-upload>
                <input type="hidden" v-bind:name="fieldNamePrefix + '[image][url]'" v-model="dataUrl" >
                <input type="hidden" v-bind:name="fieldNamePrefix + '[image][path]'" v-model="dataPath" >

                <color-picker
                    v-bind:color-label="trans('Colour Overlay')"
                    v-bind:opacity-label="trans('Opacity')"
                    v-bind:value="{ color, opacity }"
                    v-bind:use-opacity="true"
                    v-bind:field-name-prefix="fieldNamePrefix"
                    v-on:update:color="color = $event"
                    v-on:update:opacity="opacity = $event"
                ></color-picker>
                <div v-if="useDefaultImage" class="cover-overlay cover-overlay--bright"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            fieldNamePrefix: {
                type: String,
                default: true
            },
            defaults: {
                type: [Object, Array]
            },
            data: {
                type: [Object, Array]
            }
        },

        computed: {
            overlayColor() {
                return `background-color: ${this.color}; opacity: ${this.opacity / 100}`;
            }
        },

        data: function() {
            let useDefaultImage = _.get(this.data, 'default_image', '0') === '1' ? true : false;

            return {
                dataUrl: useDefaultImage ? _.get(this.defaults, 'image.url', '')
                                         : _.get(this.data, 'image.url', ''),
                dataPath: useDefaultImage ? _.get(this.defaults, 'image.path', '')
                                          : _.get(this.data, 'image.path', ''),
                color: useDefaultImage ? _.get(this.defaults, 'color', '#000000')
                                       : _.get(this.data, 'color', '#000000'),
                opacity: useDefaultImage ? _.get(this.defaults, 'opacity', 50)
                                         : _.get(this.data, 'opacity', 50),

                useDefaultImage: useDefaultImage,
            };
        },

        watch: {
            useDefaultImage(newValue) {
                if (newValue) {
                    this.dataUrl = _.get(this.defaults, 'image.url', '');
                    this.dataPath = _.get(this.defaults, 'image.path', '');
                    this.color = _.get(this.defaults, 'color', '#000000');
                    this.opacity = _.get(this.defaults, 'opacity', 50);
                } else {
                    this.dataUrl = _.get(this.data, 'image.url', '');
                    this.dataPath = _.get(this.data, 'image.path', '');
                    this.color = _.get(this.data, 'color', '#000000');
                    this.opacity = _.get(this.data, 'opacity', 50);
                }
            }
        },

        methods: {
            addImage(image) {
                this.dataUrl = image.url;
                this.dataPath = image.path;
            },
            removeImage() {
                this.dataUrl = this.dataPath = '';
            }
        },
    }
</script>