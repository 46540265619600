let focusOutHandler = (event) => {
    if (event.target && event.target.classList.contains('input__field')) {
        event.target.closest('.form-group').classList.remove('input--filled');
    }
}
let focusInHandler = (event) => {
    if (event.target && event.target.classList.contains('input__field')) {
        event.target.closest('.form-group').classList.add('input--filled');
    }
}
let mouseOverHandler = (event) => {
    if (event.target && event.target.classList.contains('input__field')) {
        event.target.closest('.form-group').classList.add('input--hover');
    }
}
let mouseOutHandler = (event) => {
    if (event.target && event.target.classList.contains('input__field')) {
        event.target.closest('.form-group').classList.remove('input--hover');
    }
}

document.documentElement.addEventListener('focusout', focusOutHandler);
document.documentElement.addEventListener('focusin', focusInHandler);
document.documentElement.addEventListener('mouseover', mouseOverHandler);
document.documentElement.addEventListener('mouseout', mouseOutHandler);