<template>
    <div class="form-group">
        <label>
            <span>{{ label }}</span>
            <input class="input__field" v-bind:type="type" v-bind:maxlength="maxLength" v-bind:name="name" v-model="value" style="border: 0">
        </label>
        <span class="f-s-1 fr c-medium-tint">{{ textLength }}/{{ maxLength }}</span>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String
        },
        name: {
            type: String
        },
        rows: {
            type: Number
        },
        maxLength: {
            type: Number
        },
        value: {
            type: String
        }
    },
    computed: {
        textLength() {
            return this.value.length;
        }
    }
}
</script>
