<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="slat-group-content">
            <h2 v-if="heading" class="f-s-7 c-primary m-b-4">{{ heading }}</h2>
            <course-content
                v-bind:modules="modules"
            ></course-content>
        </div>
    </div>
</template>
<script>
    import Block from '../page/Block.vue';
    import CourseContent from '../../../CourseContent.vue';

    export default {
        extends: Block,
        components: {
            CourseContent
        },
        data() {
            return {
                modules: _.get(this.$parent, 'data.product.modules', []),
                heading: _.get(this.block.data, 'heading', ''),
            }
        }
    }
</script>