<template>
    <div v-cloak>
         <div v-if="items.length > 0" class="page-content">
            <div class="row row-collapse">
                <div class="columns">
                    <section class="section p-t-6 p-b-6">
                        <div class="panel row">
                            <div class="panel-aside columns small-12 medium-3 large-4 p-r-6">
                                <div class="f-s-5 m-b-2 p-b-1">{{ trans('Download Files') }}</div>
                                <p class="c-muted f-s-3 m-b-1">{{ trans("Add files to your lesson. Click 'Add Files' to upload files to this lesson. You can edit the name of files after upload.") }}</p>
                            </div>
                            <div class="panel-content columns small-12 medium-9 large-8">
                                <div class="slat-group m-b-5">
                                    <div class="slat-group-content" v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
                                        <div class="slat m-b-3 slat-draggable" v-for="(file, index) in items" v-bind:key="file.path" v-bind:class="{'m-t-4': index > 0}" v-bind:data-encoded-id="file.encoded_id">
                                            <div class="row row-compress row-middle br p-t-3 p-b-3">
                                                <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                                                    <span class="m-r-2 i-container i-filled-dark">
                                                        <span class="handle">
                                                            <!-- /icons/drag.svg -->
                                                            <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="slat-figure column">
                                                    <div v-if="file.image && file.image.length > 0" class="block">
                                                        <img v-bind:src="resizedImageUrl(file.image, 'thumb')" v-bind:alt="file.title" v-original-fallback />
                                                    </div>
                                                    <div v-else class="block">
                                                        <span v-html="getFileTypeIcon(file.path)"></span>
                                                    </div>
                                                </div>
                                                <div class="slat-content column">
                                                    <h4 class="f-w-bold f-s-3 m-b-0">
                                                        {{ file.title }}
                                                        <a v-if="file.id" class="i-container i-filled-dark" v-bind:href="baseUrl + '/' + file.encoded_id + '/edit'">
                                                            <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                                                        </a>
                                                    </h4>
                                                    <p class="f-s-3 m-0 c-medium-tint">
                                                        <span>{{ file.name }}</span>
                                                        <span>&nbsp;&bull;&nbsp;</span>
                                                        <span>{{ file.size }}MB</span>
                                                    </p>
                                                </div>
                                                <div class="slat-aside text-right m-r-4">
                                                    <span class="tooltip animate">
                                                        <a class="i-container i-filled-dark" href="#delete-file" v-on:click.prevent="removeFile(index)">
                                                            <!-- /icons/s-trash.svg -->
                                                            <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                                        </a>
                                                        <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <input type="hidden" v-bind:name="'files[' + index + '][id]'" v-bind:value="file.id" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][url]'" v-bind:value="file.url" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][name]'" v-bind:value="file.name" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][path]'" v-bind:value="file.path" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][size]'" v-bind:value="file.size" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][image]'" v-bind:value="file.image" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][title]'" v-bind:value="file.title" />
                                            <input type="hidden" v-bind:name="'files[' + index + '][description]'" v-bind:value="file.description" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div v-else>
            <slot name="empty"></slot>
        </div>

        <files-upload-modal
            v-on:close="modal.show = false"
            v-on:add-files="addFiles"
            ref="files-upload-modal"
        ></files-upload-modal>
    </div>
</template>
<script>
import Files from './Files.vue';

export default {
    extends: Files
}
</script>