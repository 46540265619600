<template>
    <div class="row">
        <aside class="column small-12 medium-3">
            <div class="card m-b-4 p-4 br">
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Search') }}</label>
                    <div class="form-group-addon">
                        <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search Posts')" v-model="query" v-on:keydown.enter="filterPosts">
                        <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                            <!-- /icons/search.svg -->
                            <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                        </span>
                    </div>
                </div>
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Hub') }}</label>
                    <select class="input-small input__field" v-model="hub">
                        <option v-bind:value="null">{{ trans('All hubs') }}</option>
                        <option v-if="hubs.length > 0" disabled>--</option>
                        <option v-for="(value, key) in hubs" v-bind:value="key" v-bind:key="key">{{ value }}</option>
                    </select>
                </div>
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Filter') }}</label>
                    <select class="input-small input__field" v-model="filter">
                        <option v-for="(value, key) in postFilters" v-bind:value="key" v-bind:key="key">{{ value }}</option>
                    </select>
                </div>
                <div class="form-group form-group-compact">
                    <label class="sc-reader">{{ trans('Order') }}</label>
                    <select class="input-small input__field" v-model="order">
                        <option v-for="(value, key) in postOrdering" v-bind:value="key" v-bind:key="key">{{ value }}</option>
                    </select>
                </div>
                <button v-on:click="filterPosts" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                    {{ trans('Search') }}
                    <div v-if="searching" class="loader">
                        <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                            <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                </button>
                <div class="text-center">
                    <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                </div>
            </div>
        </aside>
        <div class="column small-12 medium-9">
            <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
            </div>
            <template v-else>
                <div v-if="items.length > 0">
                    <post
                        v-for="(post, index) in items"
                        v-bind:key="post.encoded_id"
                        v-bind:post="post"
                        v-bind:liked="post.is_liked"
                        v-bind:author="post.member || author"
                        v-on:post:reply="replyTo(post)"
                        v-on:post:delete="deletePost(post, index)"
                        v-on:post:like="likePost(post)"
                        v-on:post:dislike="dislikePost(post)"
                    ></post>
                    <paginator
                        v-if=" ! loading"
                        v-bind:to="paginator.to"
                        v-bind:from="paginator.from"
                        v-bind:total="paginator.total"
                        v-bind:last-page="paginator.lastPage"
                        v-bind:current-page="paginator.currentPage"
                        v-on:paginator:switch-page="changePage"
                        v-bind:full-width="true"
                    ></paginator>
                </div>
                <div v-else>
                    <p class="c-mutedf-s-4">
                        {{ trans("There are no posts at the moment!") }}
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Posts from './Posts.vue';

export default {
    extends: Posts,
    props: {
        memberId: {
            type: String,
            required: true,
        },
    },
    methods: {
        changePage(page, showLoadingIndicator = true) {
            // We may don't want to show loading indicator while we fetch new comments due to deleting one
            if (showLoadingIndicator === true) {
                this.loading = true;
            }

            window.axios.get(Kourses.apiBaseUrl + '/posts/' + this.type, {
                params: {
                    'page': page,
                    'per_page': this.perPage,
                    'hub': this.hub,
                    'query': this.query || null,
                    'filter': this.filter,
                    'order': this.order,
                    'member': this.memberId,
                }
            }).then(this.showPosts);
        },
    }
}
</script>