<template>
    <div class="uploads-group">
        <div v-if="tab !== 'display'">
            <div v-if="allowUpload && tab === 'upload'" class="card column uploads uploads--min-height-250 uploads-r-16_9 br c-lightest-bg m-b-4">
                <div class="upload-content row row-middle row-collapse">
                    <div
                        v-if=" ! uploading && ! processing"
                        style="cursor: pointer; width: 100%; height: 100%;"
                        v-on:drop="handleFileSelect"
                        v-on:dragover="handleDragOver"
                        v-on:click="handleDropzoneClick"
                    >
                        <div class="row row-middle row-tall">
                            <div v-if=" ! error" class="column text-center">
                                <div class="card-figure m-b-4 p-l-4 p-r-4">
                                    <span class="i-container i-tone-accent">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon i-size-xlarge" viewBox="0 0 512 512"><path d="M160 160c-17.7 0-32 14.3-32 32V320c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H160zM352 288l44.9 29.9c2 1.3 4.4 2.1 6.8 2.1c6.8 0 12.3-5.5 12.3-12.3V204.3c0-6.8-5.5-12.3-12.3-12.3c-2.4 0-4.8 .7-6.8 2.1L352 224v64z"></path><path fill-opacity="0.4" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM128 192c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V192zM396.9 317.9L352 288V224l44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3V307.7c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1z"></path></svg>
                                    </span>
                                </div>
                                <div class="card-content">
                                    <h2 class="card-title f-s-5 f-w-bold m-b-1 f-w-regular">{{ trans('Drag & Drop') }}</h2>
                                    <p class="f-s-4 c-muted">{{ trans('or browse your computer for a file') }}</p>
                                </div>
                                <input id="browse" type="file" style="display: none;" v-on:change="handleFileSelect" ref="browse">
                            </div>
                            <div v-if="error" class="column text-center">
                                <div class="card-figure m-b-4 p-l-4 p-r-4">
                                    <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                                        <span class="i-container i-filled-dark cu-p">
                                            <!-- /icons/remove.svg -->
                                            <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                                        </span>
                                    </button>
                                    <div class="i-container i-filled-error">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" class="icon i-size-large"><g fill="#FF4D4D" class="icon"><path d="M32 63C14.88 63 1 49.12 1 32 1 14.88 14.88 1 32 1c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31zm0-2c16.016 0 29-12.984 29-29S48.016 3 32 3 3 15.984 3 32s12.984 29 29 29z"></path><path d="M32 52a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-1-39v26h2V11z"></path></g></svg>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <h4 class="f-w-bold f-s-3 m-b-0 c-error">{{ trans('Oops! Upload failed') }}</h4>
                                    <p class="f-s-3 m-0 c-medium-tint">
                                        {{ error }}
                                        <a v-on:click.prevent="removeFile" class="ul" href="#remove-file">{{ trans('Try Again.') }}</a>
                                    </p>
                                </div>
                                <input id="browse" type="file" style="display: none;" v-on:change="handleFileSelect" ref="browse">
                            </div>
                        </div>
                    </div>
                    <div v-if="uploading || processing" class="column text-center">
                        <div class="card-figure m-b-4">
                            <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                                <span class="i-container i-filled-dark cu-p">
                                    <!-- /icons/remove.svg -->
                                    <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                                </span>
                            </button>
                            <upload-progress-donut
                                v-if="uploading"
                                size="large"
                                v-bind:percentage="uploadProgress"
                            ></upload-progress-donut>
                            <div v-if="processing" class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                                <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                        <div v-if="uploading" class="card-content">
                            <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading :fileName', {'fileName': uploadFileName}) }}</h4>
                            <p class="f-s-3 m-0 c-medium-tint">{{ trans(':uploadedMB of :totalMB', {'uploaded': uploadMbSent, 'total': uploadMbTotal}) }}</p>
                        </div>
                        <div v-if="processing" class="card-content">
                            <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Processing :fileName', {'fileName': uploadFileName}) }}</h4>
                            <p class="f-s-3 m-0 c-medium-tint">
                                <!-- /icons/check.svg -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon i-size-small m-r-1"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"></path><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"></path></g></svg>
                                {{ trans('Uploaded') }}
                            </p>
                            <p v-if="safeToSave" class="f-s-3 m-0 c-medium-tint">{{ trans('It is now safe to save your content. Processing will continue in the background.') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="tab === 'url'" class="card column uploads uploads--min-height-250 uploads-r-16_9 br c-lightest-bg m-b-4">
                <external-video-url
                    v-on:url:changed="changeExternalVideoUrl"
                ></external-video-url>
            </div>
            <div v-if="tab === 'wistia'" class="card column uploads uploads--min-height-250 uploads-r-16_9 br c-lightest-bg m-b-4">
                <wistia-upload
                    v-bind:api-key="wistiaKey"
                    v-on:video:uploaded="movedFromWistia"
                ></wistia-upload>
            </div>
            <nav v-if="allowUpload" class="nav">
                <a v-bind:class="{'header-nav--active' : tab === 'upload'}" v-on:click.prevent="tab = 'upload'" class="header-nav--link f-s-3 m-r-3" href="#upload">{{ trans('Upload File') }}</a>
                <a v-bind:class="{'header-nav--active' : tab === 'url'}" v-on:click.prevent="tab = 'url'" class="header-nav--link f-s-3 m-l-3 m-r-3" href="#url">{{ trans('External Video URL') }}</a>
                <!-- <a v-bind:class="{'header-nav--active' : tab === 'wistia'}" v-on:click.prevent="tab = 'wistia'" class="header-nav--link f-s-3 m-l-3 m-r-3" href="#wistia">{{ trans('Move from Wistia') }}</a> -->
            </nav>
        </div>
        <div v-if="tab === 'display'" class="br ps--r">
            <div class="row row-center">
                <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                    <span class="i-container i-filled-dark cu-p">
                        <!-- /icons/remove.svg -->
                        <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                    </span>
                </button>
                <div class="column text-center p-l-0 p-r-0">
                    <div class="video">
                        <div ref="player" v-bind:id="fieldName + '_player'"></div>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" v-bind:name="fieldName" v-model="fileUrl" />
        <input type="hidden" name="video_type" v-bind:value="videoType" />
    </div>
</template>
<script>
import Player from '@vimeo/player';
import { EventBus } from '../../event-bus.js';
import WistiaUpload from './video/WistiaUpload.vue';
import ExternalVideoUrl from './video/ExternalVideoUrl.vue';
import { VimeoUploader, BunnyUploader } from '../../uploader.js';

export default {
    components: {
        WistiaUpload,
        ExternalVideoUrl
    },
    props: {
        fieldName: {
            type: String,
            default: 'file'
        },
        existingFileUrl: {
            type: String
        },
        existingFileName: {
            type: String
        },
        existingVideoType: {
            type: String,
            default: 'vimeo'
        },
        existingVideoEmbedCode: {
            type: String,
            default: null
        },
        // Needed when used in a list
        index: {
            type: Number
        },
        integrations: {
            type: [Array, Object]
        },
        allowUpload: {
            type: Boolean,
            default: true
        },
        videoHostingProvider: {
            type: String,
            default: 'bunny'
        }
    },
    data() {
        return {
            uploadProgress: 0,
            uploadFileName: '',
            uploadMbTotal: 0,
            uploadMbSent: 0,
            uploading: false,
            processing: false,
            error: false,
            fileUrl: _.get(this, 'existingFileUrl', ''),
            fileName: _.get(this, 'existingFileName', ''),
            metadata: null,
            safeToSave: false,

            tab: _.get(this, 'existingFileUrl', false)
                ? 'display'
                : this.allowUpload
                    ? 'upload'
                    : 'url',
            // wistiaKey: _.get(this.$parent.$parent, 'data.integrations.wistia.key', _.get(this, 'integrations.wistia.key', null)),

            videoType: this.existingVideoType,
        }
    },
    watch: {
        fileUrl(newValue) {
            EventBus.$emit('form:element:changed', this.fieldName, newValue);
        },
        uploading(newValue) {
            if (newValue === true) {
                EventBus.$emit('form:save:unsafe');
            } else {
                EventBus.$emit('form:save:safe');
            }
        },
    },
    methods: {
        removeFile() {
            // Remove player
            if (typeof this.$refs['player'] !== 'undefined') {
                this.$refs['player'].firstChild.remove();
            }

            this.tab = this.allowUpload ? 'upload' : 'url';
            this.fileUrl    = '';
            this.fileName   = '';
            this.metadata   = null;
            this.uploading = false;
            this.processing = false;
            this.error = null;
            this.safeToSave = false;
            this.uploadProgress = 0;
            this.uploadFileName = null;
            this.uploadMbSent = 0;
            this.uploadMbTotal = 0;
            this.videoType = 'none';

            this.terminateExistingUpload();

            EventBus.$emit(this.fieldName + ':video:exists', false);
        },
        handleFileSelect(event) {
            event.stopPropagation();
            event.preventDefault();

            let $vm = this;
            let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

            // Check number of files
            if (files.length !== 1) {
                $vm.error = $vm.trans('Single file only');
                $vm.uploading = $vm.processing = false;
                return;
            }

            // Reset data
            $vm.upload = null;
            $vm.uploading = true;
            $vm.processing = false;
            $vm.safeToSave = false;
            $vm.uploadProgress = 0;
            $vm.uploadFileName = files[0].name;
            $vm.uploadMbSent = 0;
            $vm.uploadMbTotal = (files[0].size / 1024 / 1024).toFixed(2);
            $vm.error = false;
            $vm.metadata = null;

            // Check file type
            if (0 > files[0].type.indexOf('video/')) {
                $vm.error = $vm.trans('Unsupported file format');
                $vm.uploading = $vm.processing = false;
                return;
            }

            this.uploader.upload(files[0], {
                onError: this.onUploadError,
                onProgress: this.onUploadProgress,
                onSuccess: this.onUploadSuccess,
                onTranscoded: this.onUploadTranscoded,
            });
        },
        handleDragOver(event) {
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
        },
        handleDropzoneClick() {
            this.$refs['browse'].click();
        },
        movedFromWistia(video) {
            this.tab = 'display';
            this.fileUrl = video.url;
            this.uploadFileName = video.name;
            this.processing = true;
            this.safeToSave = true;
            this.loopUntilTranscoded();
        },
        createVimeoPlayer(videoUrl, dummy = false) {
            // There were cases where the player was duplicated on error.
            // So now we remove it first.
            this.removeVideoPlayerNode();

            // We need to create a node which we can remove later on due to Vimeo Player using WeakMap() and saving element for reuse.
            var node = document.createElement('div');

            this.$refs['player'].appendChild(node);

            return new Player(node,{
                url: videoUrl,
                responsive: 1,
                dummy: dummy
            });
        },
        createBunnyIframe(videoId) {
            window.axios.post(window.Kourses.apiBaseUrl + '/bunny/generate-embed', {
                video: videoId
            }).then((response) => {
                this.addEmbedCode(response.data.embed);
            });
        },
        removeVideoPlayerNode() {
            if (this.$refs['player'].firstChild) {
                this.$refs['player'].firstChild.remove();
            }
        },
        addEmbedCode(embedCode) {
            var node = document.createElement('div');

            this.$refs['player'].appendChild(node);

            node.className = 'embed-responsive-video';
            node.insertAdjacentHTML('beforeend', embedCode);
        },
        changeExternalVideoUrl(url, type, embedCode) {
            this.tab = 'display';
            this.fileUrl = url;
            this.videoType = type;

            Vue.nextTick(() => {
                switch (type) {
                    case 'vimeo_url':
                        this.createVimeoPlayer(url);
                        break;
                    case 'wistia_url':
                    case 'youtube_url':
                    default:
                        this.addEmbedCode(embedCode);
                }
            });
        },
        terminateExistingUpload() {
            this.uploader.terminate();
        },
        onUploadProgress(bytesUploaded, bytesTotal) {
            this.uploadMbSent = (bytesUploaded / 1024 / 1024).toFixed(2);
            this.uploadProgress = parseInt((bytesUploaded / bytesTotal) * 100);
        },
        onUploadSuccess(fileUrl) {
            this.fileUrl = fileUrl;
            this.videoType = this.videoHostingProvider;
            this.uploading = false;
            this.processing = true;
            this.safeToSave = true;

            EventBus.$emit(this.fieldName + ':video:exists', true);
        },
        onUploadTranscoded() {
            this.tab = 'display';
            this.processing = false;

            Vue.nextTick(() => {
                if (this.videoType === 'vimeo') {
                    this.createVimeoPlayer(this.fileUrl, true).ready().then(() => {
                        this.$emit('video-selected', this.fileUrl);
                    })
                    .catch(() => {
                        this.processing = true;
                        this.tab = 'upload';
                    });
                } else if (this.videoType === 'bunny') {
                    this.createBunnyIframe(this.fileUrl);
                }
            });
        },
        onUploadError(error) {
            this.error = error;
            this.uploading = false;
            this.processing = false;
        },
        loopUntilTranscoded() {
            this.uploader.loopUntilTranscoded(this.fileUrl, this.onUploadTranscoded);
        },
        createVideoUploader() {
            if (this.videoHostingProvider === 'vimeo') {
                return new VimeoUploader({
                    vimeoToken: window.Vimeo.token,
                    vimeoApiUrl: window.Vimeo.apiBaseUrl,
                    vimeoFolder: window.Vimeo.folder,
                    koursesApiUrl: window.Kourses.apiBaseUrl,
                    koursesDomains: [
                        window.Kourses.baseUrl,
                        window.Kourses.websiteUrl,
                    ],
                    koursesWebsiteId: window.Kourses.websiteId,
                });
            } else if (this.videoHostingProvider === 'bunny') {
                return new BunnyUploader({
                    bunnyApiKey: window.Bunny.apiKey,
                    bunnyApiUrl: window.Bunny.apiBaseUrl,
                    bunnyLibraryId: window.Bunny.libraryId,
                    bunnyPullZoneUrl: window.Bunny.pullZoneUrl,
                    bunnyCollectionId: window.Bunny.collection,
                    koursesApiUrl: window.Kourses.apiBaseUrl,
                    koursesWebsiteId: window.Kourses.websiteId,
                });
            }
        }
    },
    mounted() {
        let $vm = this;

        $vm.uploader = $vm.createVideoUploader();

        if ( ! $vm.fileUrl) {
            return;
        }

        if ($vm.videoType === 'vimeo') {
            $vm.createVimeoPlayer($vm.fileUrl).ready().catch(function() {
                $vm.processing = true;

                $vm.uploader.getVideoStatus($vm.fileUrl)
                    .then(({status}) => {
                        $vm.tab = 'upload';
                        $vm.removeVideoPlayerNode();

                        if (status === 'processing') {
                            $vm.loopUntilTranscoded();
                        } else if (status === 'missing') {
                            $vm.error = $vm.trans('Video not found.');
                            $vm.processing = false;
                        }
                    });
            });
        } else if ($vm.videoType === 'vimeo_url') {
            $vm.createVimeoPlayer($vm.fileUrl);
        } else if ($vm.videoType === 'bunny') {
            // Show embed
            $vm.addEmbedCode($vm.existingVideoEmbedCode);

            // But check if it isn't finished and show processing screen
            $vm.uploader.getVideoStatus($vm.fileUrl)
                .then(({status}) => {
                    if (status === 'processing') {
                        $vm.tab = 'upload';
                        $vm.processing = true;
                        $vm.loopUntilTranscoded();
                    }
                })
        } else {
            $vm.addEmbedCode($vm.existingVideoEmbedCode);
        }
    }
}
</script>