<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="text-center">
            <a class="btn btn-primary btn-theme btn-large" href="#sales-page">{{ label }}</a>
        </div>
    </div>
</template>
<script>
    import Block from '../page/Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                label: _.get(this.block.data, 'label'),
            }
        }
    }
</script>