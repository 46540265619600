<template>
    <div class="uploads-group">
        <div v-bind:class="ratioClass" class="card column uploads uploads--min-height-250 br c-lightest-bg text-center p-l-4 p-r-4">
            <div v-if=" ! imageUploaded" class="upload-content row row-middle full-width-dropzone">
                <dropzone
                    v-if=" ! uploading && ! processing"
                    ref="dropzone"
                    v-bind:id="fieldName"
                    v-bind:url="uploadUrl"
                    v-bind:markup="dropzoneMarkup"
                    v-bind:accepted-file-types="acceptedFileTypes"
                    v-bind:max-filesize="maxFilesize"
                ></dropzone>
                <div v-if="uploading || processing" class="column text-center">
                    <div class="card-figure m-b-4">
                        <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                            <span class="i-container i-filled-dark cu-p">
                                <!-- /icons/remove.svg -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="icon i-size-medium"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                            </span>
                        </button>
                        <upload-progress-donut
                            v-if="uploading"
                            size="large"
                            v-bind:percentage="uploadProgress"
                        ></upload-progress-donut>
                        <div v-if="processing" class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                            <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                    <div v-if="uploading" class="card-content">
                        <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading') }} {{ uploadFileName }}</h4>
                        <p class="f-s-3 m-0 c-medium-tint">{{ uploadMbSent }}MB {{ trans('of') }} {{ uploadMbTotal }}MB</p>
                    </div>
                    <div v-if="processing" class="card-content">
                        <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Processing') }} {{ uploadFileName }}</h4>
                        <p class="f-s-3 m-0 c-medium-tint">
                            <!-- /icons/check.svg -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon i-size-small m-r-1"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"></path><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"></path></g></svg>
                            {{ trans('Uploaded') }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="imageUploaded" class="upload-content row row-middle row-collapse cover">
                <div class="column text-center">
                    <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                        <span class="i-container i-filled-dark cu-p">
                            <!-- /icons/remove.svg -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="icon i-size-medium"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                        </span>
                    </button>
                    <div class="cover-image cover-image-overlay">
                        <div v-if="overlayColor" class="cover-overlay" v-bind:style="overlayColor"></div>
                        <img v-bind:src="resizedImageUrl" v-on:error="e => { resizedImageUrl = fileUrl; }" class="image-fit">
                    </div>
                </div>
            </div>
        </div>
        <input v-if="showFormFields" type="hidden" v-bind:name="fieldName" v-model="filePath" />
        <input v-if="showFormFields" type="hidden" v-bind:name="fieldUrlName" v-model="fileUrl" />
    </div>
</template>
<script>
import Dropzone from '../Dropzone';
import { EventBus } from '../../../event-bus.js';
import { resizedImageUrl } from '../../../helpers.js';

export default {
    components: {
        Dropzone
    },
    props: {
        fieldName: {
            type: String,
            default: 'file'
        },
        fieldUrlName: {
            type: String,
            default() {
                return 'temp_' + this.fieldName + '_url';
            }
        },
        existingFilePath: {
            type: String
        },
        existingFileUrl: {
            type: String
        },
        existingFileName: {
            type: String
        },
        // Needed when used in a list
        index: {
            type: Number
        },
        ratio: {
            type: String,
            default: '16:9'
        },
        overlayColor: {
            type: String,
            default: null
        },
        showFormFields: {
            type: Boolean,
            default: true
        },
        maxFilesize: {
            type: Number,
            default: 50
        },
        resize: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            uploadUrl: Kourses.activeBaseUrl + '/upload/regular?_token=' + Laravel.csrfToken + '&size=' + (this.resize ? this.ratio : 'no_resize'),
            acceptedFileTypes: 'image/*',
            uploadProgress: 0,
            uploadFileName: '',
            uploadMbTotal: 0,
            uploadMbSent: 0,
            uploading: false,
            processing: false,
            error: false,
            fileUrl: _.get(this, 'existingFileUrl', ''),
            resizedImageUrl: resizedImageUrl(_.get(this, 'existingFileUrl', ''), 'large'),
            fileName: _.get(this, 'existingFileName', ''),
            filePath: _.get(this, 'existingFilePath', '')
        }
    },
    computed: {
        imageUploaded() {
            if (this.fileUrl && this.fileUrl.length > 0) {
                return true;
            }

            return false;
        },
        ratioClass() {
            if (this.ratio === '16:9') {
                return 'uploads-r-16_9';
            } else if (this.ratio === '5:1') {
                return 'uploads-r-1_5';
            } else if (this.ratio === '3:2') {
                return 'uploads-r-3_2';
            }

            return 'uploads-r-1_3';
        },
        dropzoneMarkup() {
            if (this.error) {
                return '<div class="row row-middle row-tall"><div class="column text-center"><div class="card-figure m-b-4"><div class="i-container i-filled-error"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" class="icon i-size-large"><g fill="#FF4D4D" class="icon"><path d="M32 63C14.88 63 1 49.12 1 32 1 14.88 14.88 1 32 1c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31zm0-2c16.016 0 29-12.984 29-29S48.016 3 32 3 3 15.984 3 32s12.984 29 29 29z"></path><path d="M32 52a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-1-39v26h2V11z"></path></g></svg></div></div><div class="card-content"><h4 class="f-w-bold f-s-3 m-b-0 c-error">Oops! Upload failed</h4><p class="f-s-3 m-0 c-medium-tint">' + this.error + '&nbsp;<span class="ul c-accent">Try again.</span></p></div></div></div>';
            } else {
                return '<div class="row row-middle row-tall"><div class="column text-center"><div class="card-figure m-b-2"><span class="i-container i-tone-accent"><svg xmlns="http://www.w3.org/2000/svg" class="icon i-size-xlarge" viewBox="0 0 512 512"><path fill="#6c6bf6" d="M323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176z"/><path fill="#6c6bf6" fill-opacity="0.4" d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg></span></div><div class="card-content"><h2 class="card-title f-s-5 f-w-bold m-b-1 f-w-regular">Drag & Drop</h2><p class="f-s-4 c-muted">or browse your computer for a file (max upload limit ' + this.maxFilesize + 'MB per file)</p></div></div></div>';
            }
        }
    },
    watch: {
        filePath(newValue) {
            EventBus.$emit('form:element:changed', this.fieldName, newValue);
        },
        existingFileUrl(newValue) {
            this.fileUrl = newValue;
            this.resizedImageUrl = resizedImageUrl(newValue, 'large');
        },
        existingFilePath(newValue) {
            this.filePath = newValue;
        },
        uploading(newValue) {
            if (newValue === true) {
                EventBus.$emit('form:save:unsafe');
            } else {
                EventBus.$emit('form:save:safe');
            }
        },
    },
    methods: {
        /**
         * Delete uploaded and cancelled image from storage.
         *
         * @param   {String}  filePath
         *
         * @return  {Void}
         */
         deleteFileFromStorage(filePath) {
            window.axios.post(window.Kourses.activeBaseUrl + '/upload/delete', {
                'files': [
                    filePath,
                ],
            });
        },
        removeFile: function() {
            // If user immediately discards image that he uploaded - delete it from storage
            if (( ! this.existingFilePath || this.existingFilePath !== this.filePath)
            && this.filePath !== '') {
                this.deleteFileFromStorage(this.filePath);
            }

            this.fileUrl    = '';
            this.resizedImageUrl = '';
            this.filePath   = '';
            this.fileName   = '';
            this.uploading = false;
            this.processing = false;
            this.error = null;

            // Cancel current uploads
            EventBus.$emit('removeAllFiles');

            // Used in blocks
            this.$emit('image-removed', this.index);
        }
    },
    mounted() {
        let $vm = this;

        this.$on('dropzone:addedfile', (file) => {
            $vm.uploading = true;
            $vm.processing = false;
            $vm.uploadProgress = 0;
            $vm.uploadFileName = file.name;
            $vm.uploadMbSent = 0;
            $vm.uploadMbTotal = (file.size / 1024 / 1024).toFixed(2);
            $vm.error = false;

            $vm.$emit('upload:image:started', $vm.fieldName, $vm.index);
        });
        this.$on('dropzone:uploadprogress', (file, progress, bytesSent) => {
            $vm.uploadProgress = progress;
            $vm.uploadMbSent = (bytesSent / 1024 / 1024).toFixed(2);

            if (progress === 100) {
                $vm.uploading = false;
                $vm.processing = true;
            }
        });
        this.$on('dropzone:success', (file, response) => {
            $vm.fileUrl = response.url;
            $vm.resizedImageUrl = response.url;
            $vm.fileName = response.name;
            $vm.filePath = response.path;
            $vm.processing = false;

            // For JS processing
            $vm.$emit('image-selected', response, $vm.index);
            EventBus.$emit('image-selected', response, $vm.index, this.fieldName);
        });
        this.$on('dropzone:error', (file, error, xhr) => {
            $vm.error = $vm.trans('Something went wrong.');

            if (xhr && xhr.status === 413) {
                $vm.error = $vm.trans(`File size too large. Max file size is ${$vm.maxFilesize}MB.`);
            } else if (typeof error === 'string') {
                $vm.error = error;
            } else if (_.has(error, 'file.0')) {
                $vm.error = error.file[0];
            } else if (_.has(error, 'errors.file.0')) {
                $vm.error = error.errors.file[0];
            }

            // By changing these values in the "next tick" we are allowing
            // VueJS to swap out dropzone and force its render after there is
            // an error.
            $vm.$nextTick(() => {
                $vm.uploading = false;
                $vm.processing = false;
            });

            // KS-273 Clear "accepted files" after error
            if ($vm.$refs['dropzone']) {
                $vm.$refs['dropzone'].dropzone.removeFile(file);
            }
        });
    }
}
</script>