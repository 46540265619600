<template>
    <div>
        <div v-bind:class="{'table-group': paginator.lastPage > 1}">
            <div class="table-overflow-set">
                <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                    <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
                <table v-if=" ! loading && items.length > 0" class="table">
                    <thead>
                        <tr>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 185px;">{{ trans('Name') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Code') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Discount') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Membership') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Member') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Start') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Expires') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 80px;">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" v-bind:key="item.id">
                            <td v-bind:data-heading="trans('Name')">
                                <a v-if="item.orders_count === 0" v-on:click.prevent="editCoupon(index)" href="#edit-coupon" class="f-w-bold block">
                                    <span class="c-primary">{{ item.name }}</span>
                                    <span class="i-container i-filled-dark">
                                        <!-- /icons/edit.svg -->
                                        <svg class="icon i-size-small inline m-b-1 m-l-2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                                    </span>
                                </a>
                                <a v-else v-on:click.prevent="showDisabledEditAlert" href="#edit-coupon" class="f-w-bold block">
                                    <span class="c-primary">{{ item.name }}</span>
                                    <span class="i-container i-filled-dark">
                                        <!-- /icons/edit.svg -->
                                        <svg class="icon i-size-small inline m-b-1 m-l-2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                                    </span>
                                </a>
                            </td>
                            <td v-bind:data-heading="trans('Code')">{{ item.code }}</td>
                            <td v-bind:data-heading="trans('Discount')">{{ trans(':discount off', {'discount': item.discount}) }}</td>
                            <td v-bind:data-heading="trans('Membership')">{{ item.membership_id ? membership.name : trans('all memberships') }}</td>
                            <td v-bind:data-heading="trans('Member')">{{ item.member_id ? item.member.full_name : trans('all members') }}</td>
                            <td v-bind:data-heading="trans('Start')">{{ formatDate(item.starts_at) }}</td>
                            <td v-bind:data-heading="trans('Expires')">{{ formatDateTime(item.expires_at) }}</td>
                            <td class="text-right">
                                <span v-if="item.orders_count > 0" class="tooltip animate">
                                    <a class="i-container i-filled-dark m-r-1" v-bind:href="couponShowLink(item)">
                                         <!-- icons/m-info.svg -->
                                         <svg class="icon i-size-small inline" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h32v32H0z"/><g transform="translate(5 5)" class="icon"><path d="M11 23C4.373 23-1 17.627-1 11S4.373-1 11-1s12 5.373 12 12-5.373 12-12 12zm0-2c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z"/><path d="M10 17h2V9H8v2h2z"/><path d="M8 17h6v-2H8z"/><circle cx="11" cy="6" r="1"/></g></svg>
                                    </a>
                                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('View used coupons') }}</span>
                                </span>
                                <span class="tooltip animate">
                                    <a class="i-container i-filled-dark" href="#delete-coupon" v-on:click.prevent="deleteCoupon(index)">
                                        <!-- /icons/s-trash.svg -->
                                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                    </a>
                                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove coupon') }}</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-if=" ! loading && items.length === 0" class="c-muted m-t-6 text-center">{{ trans("You haven't created any coupons yet!") }} <a v-on:click.prevent="addCoupon" href="#add-coupon">{{ trans('Add one now') }}</a></p>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:full-width="true"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>

        <coupon-modal
            ref="coupon-modal"
            v-bind:members="members"
            v-bind:membership="membership"
            v-on:coupon:added="couponAdded"
            v-on:coupon:updated="couponUpdated"
            v-on:coupon:delete="deleteCoupon"
        ></coupon-modal>
    </div>
</template>
<script>
import Paginator from '../Paginator.vue';
import CouponModal from '../modals/CouponModal.vue';
import { couponShowLink } from '../../routes';

export default {
    components: {
        Paginator,
        CouponModal
    },

    props: {
        coupons: {
            type: Object
        },
        members: {
            type: Object
        },
        membership: {
            type: Object
        },
    },
    data() {
        return {
            items: _.get(this.coupons, 'data', []),
            paginator: {
                to: _.get(this.coupons, 'to', 1),
                from: _.get(this.coupons, 'from', 1),
                total: _.get(this.coupons, 'total', 1),
                lastPage: _.get(this.coupons, 'last_page', 1),
                currentPage: _.get(this.coupons, 'current_page', 1),
            },
            loading: false,
            modal: null
        }
    },
    methods: {
        couponShowLink(coupon) {
            return couponShowLink(coupon);
        },
        changePage(page) {
            this.loading = true;

            this.loadCoupons(page);
        },
        loadCoupons(page) {
            return window.axios.get(Kourses.apiBaseUrl + '/coupons?page=' + page + '&membership_id=' + this.membership.id).then(this.showCoupons);
        },
        showCoupons(response) {
            this.paginator = {
                to: response.data.to,
                from: response.data.from,
                total: response.data.total,
                lastPage: response.data.last_page,
                currentPage: response.data.current_page
            };
            this.items = response.data.data;
            this.loading = false;
        },
        resetModal() {
            this.modal.show = false;
            this.modal.index = null;
            this.modal.reset();
        },
        addCoupon() {
            this.resetModal();
            this.modal.show = true;
        },
        editCoupon(index) {
            this.resetModal();
            this.modal.show = true;
            this.modal.index = index;

            let coupon = _.clone(this.items[index], true);
            coupon.value = coupon.value / 100;
            coupon.membership_id = coupon.membership_id || null;
            coupon.plan_id = coupon.plan_id || '';
            coupon.starts_at = this.convertToDate(coupon.starts_at);
            coupon.expires_at = this.convertToDateTime(coupon.expires_at);

            this.modal.item = coupon;
            this.modal.starts = coupon.starts_at ? 'date' : 'immediately';
            this.modal.ends = coupon.expires_at ? 'date' : 'indefinitely';
        },
        couponAdded(coupon) {
            this.items.push(coupon);
        },
        couponUpdated(coupon, index) {
            this.items.splice(index, 1, coupon);
        },
        deleteCoupon(index) {
            this.$dialog
                .confirm(this.trans("If you delete this coupon, you cannot recover it. Are you sure you want to delete it?"), {loader: true})
                .then((dialog) => {
                    window.axios.delete(Kourses.apiBaseUrl + '/coupons/' + this.items[index].encoded_id).then((response) => {
                        this.items.splice(index, 1);
                        dialog.close();
                    });
                });
        },
        formatDate(date) {
            if (date && date.length > 0) {
                return (new Date(date)).toLocaleDateString(window.Kourses.locale, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                });
            }

            return this.trans('immediately');
        },
        formatDateTime(dateTime) {
            if (dateTime && dateTime.length > 0) {
                return (new Date(dateTime)).toLocaleDateString(window.Kourses.locale, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                });
            }

            return this.trans('indefinitely');
        },
        convertToDate(date) {
            if (date === null) {
                return null;
            }

            return this.convertToDateTime(date).split(' ')[0];
        },
        convertToDateTime(dateTimeString) {
            if (dateTimeString === null) {
                return null;
            }

            const date = new Date(dateTimeString);

            return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;
        },
        showDisabledEditAlert() {
            this.$dialog.alert(
                this.trans("Coupon was already used so it can't be changed as it could affect transaction data."),
                {
                    'headline': this.trans("Coupon can't be modified"),
                    'okText': this.trans('OK'),
                }
            );
        },
    },
    mounted() {
        this.modal = this.$refs['coupon-modal'];
    }
}
</script>