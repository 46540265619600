<template>
    <div>
        <div v-for="(hub, index) in selectedHubs" v-bind:key="hub.id" class="slat br m-b-4">
            <div class="row row-collapse-indent row-middle p-3">
                <div class="slat-figure--large column small-12">
                    <a class="c-primary" v-bind:href="hubShowLink(hub)">
                        <img v-bind:src="hub.image ? hub.image_url.small : hub.placeholder_image_url" v-bind:alt="hub.title" />
                    </a>
                </div>
                <div class="slat-content column p-l-0">
                    <a class="c-primary" v-bind:href="hubShowLink(hub)">
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ hub.title }}</h4>
                    </a>
                    <div class="card-type f-s-2 c-muted m-t-1">{{ hubFeatures(hub) }}</div>
                </div>
                <div class="slat-aside text-right m-r-4">
                    <span class="tooltip animate">
                        <a class="i-container i-filled-dark" href="#delete-hub" v-on:click.prevent="deleteItem(index)">
                            <!-- /icons/s-trash.svg -->
                            <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                        </a>
                        <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="text-center p-t-1">
            <button type="button" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4" v-on:click="addItem">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/add.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                </span>
                {{ trans('Add Hub') }}
            </button>
        </div>

        <input v-for="(hub, index) in selectedHubs" v-bind:name="'hub_ids[' + index + ']'" v-bind:value="hub.id" v-bind:key="hub.id" type="hidden" />
        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:key="id" v-bind:value="id" />

        <hub-picker-modal
            v-bind:hubs="filteredHubs"
            v-on:close="modal.show = false"
            v-on:select-hub="selectHub"
            ref="hub-picker-modal"
        ></hub-picker-modal>
    </div>
</template>
<script>
import { hubShowLink } from '../../routes';
import HubPickerModal from '../memberships/HubPickerModal.vue';

export default {
    components: {
        HubPickerModal,
    },
    props: {
        hubs: {
            type: Array
        },
        items: {
            type: Array
        }
    },
    data() {
        return {
            selectedHubs: [],
            selectedHubIds: _.get(this, 'items', []),
            forRemoval: []
        }
    },
    computed: {
        filteredHubs() {
            return this.hubs.filter((hub) => {
                return _.indexOf(this.selectedHubIds, hub.id) < 0 && _.indexOf(this.selectedHubIds, hub.id + '') < 0;
            });
        }
    },
    methods: {
        hubShowLink(hub) {
            return hubShowLink(hub);
        },
        addItem() {
            this.modal.show = true;
        },
        selectHub(hub) {
            this.modal.show = false;
            this.selectedHubs.push(hub);
            this.selectedHubIds.push(hub.id);
        },
        deleteItem(index) {
            let hubId = this.selectedHubs[index].id;

            if (this.items.indexOf(hubId) >= 0) {
                this.forRemoval.push(hubId);
            }

            this.selectedHubs.splice(index, 1);
            this.selectedHubIds.splice(index, 1);
        },
        hubFeatures(hub) {
            if (typeof hub.enabled_hub_features == 'undefined') {
                return '';
            }

            return hub.enabled_hub_features.reduce((featuresList, feature) => {
                if (featuresList.length > 0) {
                    featuresList += ', ';
                }

                featuresList += feature.label;

                return featuresList;
            }, '');
        },
    },
    mounted() {
        this.modal = this.$refs['hub-picker-modal'];
    },
    created() {
        if (this.selectedHubIds.length && this.hubs.length) {
            this.selectedHubIds.forEach((id) => {
                this.selectedHubs.push(this.hubs.find((item) => {
                    return id == item.id;
                }));
            });
        }
    }
}
</script>