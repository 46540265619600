// Load dependencies
require('./bootstrap');
require('./form-effects');
require('./init-vue');
require('./init-lang');

// Init Vue app
const app = new Vue({
    el: '#app',
    methods: {
        deleteUsingForm(formId) {
            return () => {
                document.getElementById(formId).submit();
            }
        }
    }
});
