<template>
    <div>
        <div v-bind:class="{'m-b-5': error !== null}">
            <h5 class="c-primary f-s-2 m-t-2 m-b-0">{{ lesson.name }}</h5>
            <span class="c-medium-tint f-s-2 m-r-3 inline">{{ trans('will be released') }}</span>
            <div class="form-group inline m-b-0 number-spinner m-r-2" v-bind:class="{'has-error': error !== null}">
                <number-spinner
                    v-bind:field-name="`${fieldName}[days]`"
                    v-bind:value="days"
                    v-on:update:length="updateValue"
                    v-bind:size="4"
                    v-bind:max-length="4"
                ></number-spinner>
            </div>
            <span class="c-muted f-s-2 inline">{{ trans('days after a student enrolls') }}</span>
        </div>
        <div v-if="error !== null" class="form-help">{{ error }}</div>
    </div>
</template>
<script>
export default {
    props: {
        lesson: {
            type: Object,
            required: true,
        },
        fieldName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            error: null,
            days: parseInt(_.get(this.lesson.schedule, 'days', 0), 10),
        }
    },
    watch: {
        days(newValue, oldValue) {
            if (this.error !== null) {
                return;
            }

            if (isNaN(oldValue)) {
                oldValue = 0;
            }

            this.$emit('update:days', newValue - oldValue);
        },
    },
    methods: {
        validateLength(number) {
            this.error = null;

            if ( ! number && number !== 0) {
                this.error = this.trans('Number of days field is required');

                return false;
            } else if (isNaN(number)) {
                this.error = this.trans('Number of days must be a number');

                return false;
            }

            return true;
        },
        updateValue(number) {
            if (this.validateLength(number) === false) {
                return;
            }

            this.days = parseInt(number, 10);
        },
    },
}
</script>
