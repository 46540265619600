<template>
    <div>
        <div class="row row-middle row-collapse m-b-0">
            <div class="column"></div>
            <div class="column text-right">
                <select class="input-small input__field" v-model="location">
                    <option v-bind:value="null">{{ trans('All Locations') }}</option>
                    <option value="homepage">{{ trans('Homepage') }}</option>
                    <optgroup v-bind:label="trans('Course')">
                        <option v-for="hub in hubs" v-bind:value="hub.id" v-bind:key="hub.encoded_id">{{ hub.title }}</option>
                    </optgroup>
                </select>
            </div>
        </div>
        <div v-if="filteredItems.length > 0" class="slat-group m-b-5">
            <div class="slat-group-content" v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
                <div class="slat m-b-3 slat-draggable" v-for="(offer, index) in filteredItems" v-bind:key="offer.encoded_id" v-bind:class="{'m-t-4': index > 0}" v-bind:data-encoded-id="offer.encoded_id">
                    <div class="row row-compress row-middle br p-t-3 p-b-3">
                        <div v-if="filteredItems.length > 1" class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                            <span class="m-r-2 i-container i-filled-dark">
                                <span class="handle">
                                    <!-- /icons/drag.svg -->
                                    <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                                </span>
                            </span>
                        </div>
                        <div v-if="offer.image && offer.image.length > 0" class="slat-figure column">
                            <div class="block">
                                <img v-bind:src="resizedImageUrl(offer.image, 'thumb')" v-bind:alt="offer.internal_title" />
                            </div>
                        </div>
                        <div class="slat-content column">
                            <h4 class="f-w-bold f-s-3 m-b-0">
                                {{ offer.internal_title }}
                            </h4>
                            <p class="f-s-3 m-0 c-medium-tint">
                                <span>{{ offer.membership.name }}</span>
                                <span>&nbsp;&bull;&nbsp;</span>
                                <span>{{ offerLocationLabel(offer) }}</span>
                            </p>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <span class="tooltip animate">
                                <a class="i-container i-filled-dark" v-bind:href="offerEditLink(offer)">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon m-r-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                                </a>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Edit') }}</span>
                            </span>
                            <span class="tooltip animate">
                                <a class="i-container i-filled-dark" href="#delete-file" v-on:click.prevent="removeOffer(index)">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                </a>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot v-else name="empty"></slot>
    </div>
</template>
<script>
import Orderable from '../../mixins/Orderable.js';
import { resizedImageUrl } from '../../helpers.js';
import { offerDeleteLink, offerEditLink } from '../../routes.js';


export default {
    mixins: [Orderable],
    props: {
        hubs: {
            type: Array,
            required: true,
        },
        offers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            items: this.offers,
            model: 'Kourses\\Models\\PromoOffer',
            location: null
        }
    },
    computed: {
        filteredItems() {
            if (this.location === null) {
                return this.items;
            }

            return this.items.filter((item) => {
                if (this.location === 'homepage' && item.location === 'homepage') {
                    return true;
                }

                if (this.location !== 'homepage' && item.location_id === this.location) {
                    return true;
                }

                return false;
            });
        },
    },
    methods: {
        resizedImageUrl(url, size) {
            return window.Kourses.assetBaseUrl + resizedImageUrl(url, size);
        },
        offerEditLink(offer) {
            return offerEditLink(offer);
        },
        removeOffer(index) {
            this.$dialog.confirm(this.trans("If you delete this promotional offer, you cannot recover it. Are you sure you want to delete it?"), {loader: true})
                .then((dialog) => {
                    window.axios.delete(offerDeleteLink(this.offers[index])).then(() => {
                        this.items.splice(index, 1);
                        dialog.close();
                    });
                });
        },
        offerLocationLabel(offer) {
            if (offer.location === 'homepage') {
                return this.trans('Homepage');
            }

            return this.trans('Course') + ' (' + offer.hub.title + ')';
        },
    }
}
</script>