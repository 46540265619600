<template>
    <dropdown-menu
        style="height: 25px;"
        v-bind:show-caret-icon="false">
        <span class="text-right f-s-2" slot="trigger">&bull;&bull;&bull;</span>
        <div>
            <a v-bind:href="membershipEditLink()" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Edit Membership Settings') }}</a>
            <a v-if="integrationTypes.includes('live')" v-bind:href="membershipCheckoutUrl()" target="_blank" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">
                {{ trans('Checkout Page') }}
            </a>
            <a v-if="integrationTypes.includes('test')" v-bind:href="membershipTestCheckoutUrl()" target="_blank" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">
                {{ trans('Test Checkout Page') }}
            </a>
        </div>
        <div v-if="canDeleteMemberships" class="br-top"></div>
        <div v-if="canDeleteMemberships" >
            <form method="POST" v-bind:action="membershipDeleteLink()">
                <input type="hidden" name="_token" v-bind:value="csrfToken" />
                <input type="hidden" name="_method" value="DELETE" />
                <button v-confirm="{'loader': true, 'message': trans('This will delete all membership content including its features. Memberships cannot be recovered once deleted.'), 'anotherMessage': trans('Confirm you would still like to delete this membership?')}" type="submit" class="c-error p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 btn-block btn-block--left h-anim h-bg-accent--light cu-p">{{ trans('Delete Membership') }}</button>
            </form>
        </div>
    </dropdown-menu>
</template>
<script>
import { membershipDeleteLink, membershipEditLink, membershipCheckoutUrl, membershipTestCheckoutUrl } from '../../routes';
import DropdownMenu from '../DropdownMenu.vue';

export default {
    components: {
        DropdownMenu
    },
    props: {
        membership: {
            type: Object
        },
        canDeleteMemberships: {
            type: Boolean,
            default: false
        },
        paymentsEnabled: {
            type: Boolean,
            default: false
        },
        integrationTypes: {
            type: Array,
        },
    },
    data() {
        return {
            csrfToken: window.Laravel.csrfToken,
        }
    },
    methods: {
        membershipEditLink() {
            return membershipEditLink(this.membership);
        },
        membershipDeleteLink() {
            return membershipDeleteLink(this.membership);
        },
        membershipCheckoutUrl() {
            return membershipCheckoutUrl(this.membership);
        },
        membershipTestCheckoutUrl() {
            return membershipTestCheckoutUrl(this.membership);
        }
    },
}
</script>