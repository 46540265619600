<template>
    <div class="uploads-group">
        <div v-if=" ! imageUploaded" class="card column uploads uploads--min-height-250 uploads-r-1_5 br c-lightest-bg text-center p-l-4 p-r-4">
            <div class="upload-content row row-middle full-width-dropzone">
                <dropzone
                    v-if=" ! uploading && ! processing"
                    v-bind:id="fieldName"
                    v-bind:url="uploadUrl"
                    v-bind:markup="dropzoneMarkup"
                    v-bind:accepted-file-types="acceptedFileTypes"
                ></dropzone>
                <div v-if="uploading || processing" class="column text-center">
                    <div class="card-figure m-b-4">
                        <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                            <span class="i-container i-filled-dark cu-p">
                                <!-- /icons/remove.svg -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="icon i-size-medium"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                            </span>
                        </button>
                        <upload-progress-donut
                            v-if="uploading"
                            size="large"
                            v-bind:percentage="uploadProgress"
                        ></upload-progress-donut>
                        <div v-if="processing" class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                            <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                    <div v-if="uploading" class="card-content">
                        <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading') }} {{ uploadFileName }}</h4>
                        <p class="f-s-3 m-0 c-medium-tint">{{ uploadMbSent }}MB {{ trans('of') }} {{ uploadMbTotal }}MB</p>
                    </div>
                    <div v-if="processing" class="card-content">
                        <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Processing') }} {{ uploadFileName }}</h4>
                        <p class="f-s-3 m-0 c-medium-tint">
                            <!-- /icons/check.svg -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon i-size-small m-r-1"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"></path><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"></path></g></svg>
                            {{ trans('Uploaded') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="imageUploaded" class="card column uploads uploads-r-1_5 br c-lightest-bg text-center p-l-4 p-r-4">
            <div class="upload-content row row-middle row-collapse cover">
                <div class="column text-center">
                    <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                        <span class="i-container i-filled-dark cu-p">
                            <!-- /icons/remove.svg -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="icon i-size-medium"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                        </span>
                    </button>
                    <img v-bind:src="fileUrl">
                </div>
            </div>
        </div>
        <input v-if="showFormFields" type="hidden" v-bind:name="fieldName" v-model="filePath" />
        <input v-if="showFormFields" type="hidden" v-bind:name="fieldUrlName" v-model="fileUrl" />

        <image-crop-modal
            ref="image-crop-modal"
            v-on:crop:success="cropSuccessful"
            v-on:crop:cancelled="cropCancelled"
            v-bind:aspect-ratio="5"
        ></image-crop-modal>
    </div>
</template>
<script>
import ImageUpload from './ImageUpload';
import ImageCropModal from '../../modals/ImageCropModal';
import Croppable from '../../../mixins/Croppable';
import { resizedImageUrl } from '../../../helpers.js';

export default {
    extends: ImageUpload,
    components: {
        ImageCropModal
    },
    mixins: [
        Croppable,
    ],
    data() {
        return {
            uploadUrl: Kourses.activeBaseUrl + '/upload/regular?_token=' + Laravel.csrfToken + '&size=no_resize',
            fileUrl: _.get(this, 'existingFileUrl', ''),
            resizedImageUrl: resizedImageUrl(_.get(this, 'existingFileUrl', ''), 'large'),
            fileName: _.get(this, 'existingFileName', ''),
            filePath: _.get(this, 'existingFilePath', '')
        }
    },
    mounted() {
        this.initCropper('image-crop-modal');
    },
}
</script>