<template>
    <form v-bind:action="url" class="dropzone" v-bind:id="id" ref="form"></form>
</template>
<script>
import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

export default {
    props: {
        id: {
            type: String
        },
        url: {
            type: String
        },
        acceptedFileTypes: {
            type: String
        },
        maxFiles: {
            type: Number,
            default: 1
        },
        maxFilesize: {
            type: Number,
            default: 50
        },
        markup: {
            type: String,
            default: function() {
                return '<div class="row row-middle row-tall"><div class="column text-center"><div class="card-figure m-b-2"><span class="i-container i-tone-accent"><svg xmlns="http://www.w3.org/2000/svg" class="icon i-size-xlarge" viewBox="0 0 640 512"><path d="M476 106.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S560.9 288 552 288H408 360 280c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C440.5 100 448 96 456 96s15.5 4 20 10.7zM64 128H192v64c-17.7 0-32 14.3-32 32V416c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V352H512v96c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z"></path><path fill-opacity="0.4" d="M192 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM456 96c-8 0-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144C471.5 100 464 96 456 96zM304 128a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"></path></svg></span></div><div class="card-content"><h2 class="card-title f-s-5 f-w-bold m-b-1 f-w-regular">Drag & Drop</h2><p class="f-s-4 c-muted">or browse your computer for a file (max upload limit ' + this.maxFilesize + 'MB per file)</p></div></div></div>';
            }
        }
    },
    mounted() {
        // Initializing dropzone
        this.dropzone = new Dropzone(this.$refs.form, {
            clickable: true,
            maxFiles: this.maxFiles,
            maxFilesize: this.maxFilesize,
            dictDefaultMessage: this.markup,
            addRemoveLinks: false,
            acceptedFiles: this.acceptedFileTypes,
            createImageThumbnails: false,
            previewTemplate: '<div></div>'
        });

        // Initializing events
        var $vm = this;
        this.dropzone.on('addedfile', function (file) {
            $vm.$parent.$emit('dropzone:addedfile', file)
        });
        this.dropzone.on('removedfile', function (file, error, xhr) {
            $vm.$parent.$emit('dropzone:removedfile', file, error, xhr)
        });
        this.dropzone.on('success', function (file, response) {
            $vm.$parent.$emit('dropzone:success', file, response)
        });
        this.dropzone.on('error', function (file, error, xhr) {
            $vm.$parent.$emit('dropzone:error', file, error, xhr)
        });
        this.dropzone.on('maxfilesreached', function() {
            $vm.$parent.$emit('dropzone:maxfilesreached');
        });
        this.dropzone.on('uploadprogress', function (file, progress, bytesSent) {
            $vm.$parent.$emit('dropzone:uploadprogress', file, progress, bytesSent)
        });
    }
}
</script>