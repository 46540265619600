<template>
    <span class="number-spinner-controls">
        <input class="number-spinner-input input__field" type="text" v-bind:name="fieldName" v-bind:size="size" v-bind:maxlength="maxLength" v-model="number" ref="element">
        <div class="inc number-spinner-button i-size-ittybitty" v-on:click="increment">+</div>
        <div class="dec number-spinner-button i-size-ittybitty" v-on:click="decrement">-</div>
    </span>
</template>
<script>
import { EventBus } from '../../event-bus';

export default {
    props: {
        fieldName: {
            type: String,
            required: true
        },
        value: {
            type: Number
        },
        size: {
            type: Number,
            default: 3
        },
        maxLength: {
            type: Number,
            default: 3
        },
        allowNegative: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            number: parseInt(this.value)
        }
    },
    watch: {
        number(value) {
            this.updateLength(value);
        }
    },
    methods: {
        increment() {
            this.number = parseInt(this.number) + 1;
        },
        decrement() {
            if (parseInt(this.number) > 0 || this.allowNegative) {
                this.number = parseInt(this.number) - 1;
            }
        },
        updateLength(value) {
            this.$emit('update:length', value);
            EventBus.$emit('form:element:changed', this.fieldName, value);
        },
    },
    mounted() {
        this.$nextTick(() => {
            EventBus.$emit('form:element:initialize', this.fieldName, this.value);
        })
    }
}
</script>