<template>
    <div v-bind:class="className">
        <label v-if="label" class="m-b-2">{{ label }}</label>
        <input class="toggle m-toggle" v-bind:id="name" type="checkbox" v-bind:class="{'checked': checked}">
        <label v-bind:for="name" v-on:click="toggle"></label>
        <input type="hidden" v-bind:name="name" v-bind:value="checked ? 1 : 0" />
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String
        },
        toggled: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: 'form-group form-group-simple'
        }
    },
    data() {
        return {
            checked: this.toggled
        }
    },
    watch: {
        toggled(newValue) {
            this.checked = newValue;
        },
    },
    methods: {
        toggle() {
            this.checked = ! this.checked;
            this.$emit('update:toggled', this.checked);
            EventBus.$emit('form:element:changed', this.name, this.checked ? '1' : '0');
        }
    }
}
</script>