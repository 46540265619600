<template>
    <dropdown-menu v-bind:show-caret-icon="true">
        <span slot="trigger" class="c-muted f-s-1">{{ selectedCurrency }}</span>
        <a v-for="currency in availableCurrencies" v-bind:href="selectCurrencyLink(currency)" v-bind:key="currency" v-bind:class="{'c-muted cu-d': currency === selectedCurrency, 'c-primary h-anim h-bg-accent--light': currency !== selectedCurrency}" class="p-t-2 p-b-2 p-l-4 p-r-4 f-s-4 block">{{ currency }}</a>
    </dropdown-menu>
</template>
<script>
    import DropdownMenu from '../DropdownMenu.vue';

    export default {
        components: {
            DropdownMenu
        },
        props: {
            selectedCurrency: {
                type: String,
                default: 'USD'
            },
            availableCurrencies: {
                type: Array
            }
        },
        methods: {
            selectCurrencyLink(currency) {
                if (currency === this.selectedCurrency) {
                    return 'javascript:void(0);'
                }
                return window.Kourses.activeBaseUrl + '/website/reporting-currency/' + currency
            }
        }
    }
</script>