<template>
    <div v-sortable="{ onEnd: onUpdate, handle: '.handle', group: 'hubs' }" v-bind:data-hub-category-id="categoryId" style="min-height:10px;">
        <template v-if="items && items.length > 0">
            <div  class="slat m-t-4 m-b-3 slat-draggable slat-product-lesson" v-for="hub in items" v-bind:key="'hub-' + hub.encoded_id" v-bind:data-encoded-id="hub.encoded_id">
                <div class="row row-compress row-middle br p-t-3 p-b-3">
                    <div class="slat-controls m-vertical-none m-l-2">
                        <span class="m-r-2 i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                    </div>
                    <div class="slat-content column">
                        <a v-bind:href="baseEditUrl + hub.encoded_id" class="f-s-3 m-b-0 f-w-bold block">
                            <span class="c-primary">{{ hub.title }}</span>
                            <span class="i-container i-filled-dark m-r-2">
                                <!-- /icons/edit.svg -->
                                <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                            </span>
                            <span v-if="hub.status === 'draft'" class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-r-4">{{ trans('Draft') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Orderable from '../../mixins/Orderable.js';
import { EventBus } from '../../event-bus.js';

export default {
    mixins: [Orderable],
    props: {
        hubs: {
            type: Array
        },
        categoryId: {
            type: Number
        },
    },
    data() {
        return {
            items: this.hubs,
            model: 'Kourses\\Models\\Hub',
            orderableParentKey: 'hub_category_id',
            baseEditUrl: window.Kourses.activeBaseUrl + '/hubs/',
        }
    },
}
</script>