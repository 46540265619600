<template>
    <div v-bind:class="className">
        <label v-if="label" class="m-b-2">{{ label }}</label>
        <input class="toggle m-toggle" v-bind:id="name" type="checkbox" v-bind:class="{'checked': checked}">
        <label v-bind:for="name" v-on:click="toggle"></label>
        <input type="hidden" v-bind:name="name" v-bind:value="checked ? 1 : 0" />
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Toggle from './Toggle.vue';


export default {
    extends: Toggle,
    mounted() {
        EventBus.$on('form:element:changed', (field, value) => {
            if (field !== 'images[logo]') {
                return;
            }

            if (value === '') { // Image removed
                // Already disabled - added to avoid unnecessary events
                // being triggered
                if (this.checked === true) {
                    return;
                }

                // In order to re-use Toggle's component "toggle" method which
                //triggers all sorts of events, first we unintuitevly change
                // checked to false which we then switch to true with "toggle"
                // method to be sure that proper events are triggered.
                this.checked = false;
                this.toggle();
            } else {
                // Already disabled - added to avoid unnecessary events
                // being triggered
                if (this.checked === false) {
                    return;
                }

                // In order to re-use Toggle's component "toggle" method which
                //triggers all sorts of events, first we unintuitevly change
                // checked to true which we then switch to false with "toggle"
                // method to be sure that proper events are triggered.
                this.checked = true;
                this.toggle();
            }
        });
    }
}
</script>
