<template>
    <div class="row row-collapse">
        <div class="form-group column">
            <label v-if="label">{{ label }}</label>
            <input type="text" v-bind:name="name" class="input__field cu-p" v-bind:value="emoji" readonly v-on:click="togglePicker" ref="icon" />
            <div class="form-group-addon">
                <dropdown-menu
                    v-bind:show-caret-icon="true"
                    v-on:open="addListener"
                    ref="dropdown"
                    class="form-append dropdown--z-above-redactor"
                    width="auto"
                    v-bind:close-on-click="false"
                >
                    <span slot="trigger"></span>
                    <emoji-picker
                        ref="picker"
                        class="light"
                    ></emoji-picker>
                </dropdown-menu>
            </div>
        </div>
        <button v-if="showClearButton" v-on:click="clearEmoji" type="button" class="btn btn-text btn-text--muted m-l-3">{{ trans('Clear') }}</button>
    </div>
</template>
<script>
import DropdownMenu from '../DropdownMenu.vue';
import { EventBus } from '../../event-bus.js';

export default {
    components: {
        DropdownMenu
    },
    props: {
        value: {
            type: String,
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false
        },
        showClearButton: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            emoji: this.value,
        }
    },
    watch: {
        emoji() {
            EventBus.$emit('form:element:changed', this.name, this.emoji);
        },
    },
    methods: {
        addListener() {
            this.$nextTick(() => {
                if (typeof this.$refs.picker == 'undefined') {
                    return;
                }

                this.$refs.picker.addEventListener('emoji-click', e => this.selectEmoji(e.detail), {once: true});
            });
        },
        selectEmoji(detail) {
            this.emoji = detail.unicode;
            this.$refs.dropdown.closeMenu();
        },
        togglePicker(event) {
            if (this.$refs.dropdown.show === false) {
                this.$refs.dropdown.openMenu();
            } else {
                this.$refs.dropdown.closeMenu();
            }

            // We need to stop event propagation in order for clickaway not to
            // be triggered in the dropdown component
            event.stopPropagation();
        },
        clearEmoji() {
            this.emoji = '';
        },
    },
}
</script>