<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Edit category') }}</h3>
            <p class="f-s-4 c-muted">{{ trans('Set a name for this category. This can be used to help organize your hubs on your website home page.') }}</p>
        </div>
        <div class="form-group" v-bind:class="{'has-error': errors.has('title')}">
            <label>
                {{ trans('Category Title') }}
                <input class="input__field" type="text" v-model="category.title">
            </label>
        </div>
        <div v-if="errors.has('title')" class="form-help">
            {{ errors.get('title') }}
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveCategory" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Save') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-outline btn-outline--alert" v-on:click="deleteCategory" v-bind:class="{'btn-loading': loadingDelete}">
                        {{ trans('Delete') }}
                        <div v-if="loadingDelete" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-alert" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from '../modals/Modal.vue';
    import Errors from '../../errors.js';

    export default {
        components: {
            Modal
        },
        data() {
            return {
                category: {
                    title: ''
                },
                show: false,
                loading: false,
                loadingDelete: false,
                errors: new Errors(),
                index: null
            }
        },
        methods: {
            saveCategory() {
                this.loading = true;
                this.$emit('save-category', this.category, this.index);
            },
            deleteCategory() {
                this.loadingDelete = true;
                this.$emit('delete-category', this.category, this.index);
            },
            reset() {
                this.category = {
                    title: ''
                };
                this.index = null;
                this.loading = false;
                this.loadingDelete = false;
                this.errors.clear();
            },
            setErrors(errors) {
                this.loading = false;
                this.loadingDelete = false;
                this.errors.record(errors);
            },
        }
    }
</script>