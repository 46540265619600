<template>
    <div class="row m-t-5 row-center">
        <div class="column large-8 medium-10 small-11 xsmall-12">
            <div class="m-t-4">
                <h2 v-if="heading" class="f-s-8 c-primary m-t-6">{{ heading }}</h2>
                 <div v-html="text"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                heading: _.get(this.block.data, 'heading'),
                text: _.get(this.block.data, 'text'),
            }
        }
    }
</script>