<template>
    <div class="fr btn-text">
        <button v-show="published" v-on:click="toggleDraft" v-bind:class="{'btn-loading': loading}" class="btn btn-text btn-text--accent width-auto-min f-s-3" type="button">
            {{ trans('Unpublish Lesson') }}
            <div v-if="loading" class="loader">
                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                    <svg class="i-stroke-accent" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
            </div>
        </button>
        <button v-show=" ! published" v-on:click="togglePublished" v-bind:class="{'btn-loading': loading}" class="btn btn-text btn-text--accent width-auto-min f-s-3" type="button">
            {{ trans('Publish Lesson') }}
            <div v-if="loading" class="loader">
                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                    <svg class="i-stroke-accent" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
            </div>
        </button>
    </div>
</template>
<script>
import { lessonPublishLink } from '../routes';

export default {
    props: {
        lesson: {
            type: Object
        }
    },
    data() {
        return {
            published: this.lesson.status === 'published' ? true : false,
            loading: false
        }
    },
    methods: {
        togglePublished() {
            this.loading = true;
            window.axios
                .put(lessonPublishLink(this.lesson))
                .then(() => {
                    this.loading = false;
                    this.published = true;
                });
        },
        toggleDraft() {
            this.loading = true;
            window.axios
                .delete(lessonPublishLink(this.lesson))
                .then(() => {
                    this.loading = false;
                    this.published = false;
                });
        },
    }
}
</script>