<template>
    <div class="slat-group m-t-5 p-t-3 br p-b-3 m-b-5">
        <div class="slat-group-header row row-middle" v-bind:class="{'br-bottom p-b-3': isExpanded}">
            <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                <span class="i-container i-filled-dark">
                    <span class="handle">
                        <!-- /icons/drag.svg -->
                        <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                    </span>
                </span>
            </div>
            <div class="slat-content column p-l-2">
                <h2 class="f-w-bold f-s-4 m-b-0">
                    <a v-on:click.prevent="toggleBlock" href="#toggle" class="c-primary">{{ trans('Bullets') }}</a>
                </h2>
            </div>
            <div class="slat-aside text-right m-r-4">
                <span class="tooltip animate">
                    <a class="i-container i-filled-dark" href="#delete-faq" v-on:click.prevent="removeBlock">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                </span>
            </div>
        </div>
        <div v-show="isExpanded" class="slat-group-content p-t-4 m-t-1">
            <div class="row row-center">
                <div class="columns p-l-3 p-r-3">
                    <bullets
                        v-bind:data="data"
                        v-on:update:items="updateItems"
                        v-on:update:heading="updateHeading"
                        v-bind:field-name="name + '[data]'"
                        v-bind:heading-label="trans('Section Heading')"
                        v-bind:items-label="trans('Bullets')"
                    ></bullets>

                    <div v-if="usesNavbarTitle" class="form-group">
                        <label>
                            {{ trans('Navbar Link Title') }}
                            <input class="input__field" type="text" v-bind:name="name + '[data][navbar_title]'" v-model="navbarTitle">
                        </label>
                    </div>

                    <input v-if="block.id" type="hidden" v-bind:name="name + '[id]'" v-bind:value="block.id" />
                    <input type="hidden" v-bind:name="name + '[type]'" value="bullet" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { EventBus } from '../../../event-bus.js';
    import Block from './Block.vue';
    import NavbarTitleMixin from './NavbarTitleMixin.vue';

    export default {
        extends: Block,
        mixins: [
            NavbarTitleMixin
        ],
        data: function() {
            return {
                data: _.get(this.block, 'data', {})
            }
        },
        methods: {
            updateItems(items) {
                Vue.nextTick(() => {
                    EventBus.$emit('block:update:value', this.index, 'items', items);
                });
            },
            updateHeading(heading) {
                EventBus.$emit('block:update:value', this.index, 'heading', heading);
            }
        }
    }
</script>