<template>
    <div v-if="show">
        <slot></slot>
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';

export default {
    props: {
        visible: {
            type: Boolean,
            default: true
        },
        eventName: {
            type: String
        },
        showValue: {
            type: [String, Number, Boolean, Array, Function],
            default: true
        },
        hideValue: {
            type: [String, Number, Boolean, Array, Function],
            default: false
        }
    },
    data() {
        return {
            show: this.visible
        }
    },
    created() {
        var $vm = this;

        EventBus.$on($vm.eventName, (value, value2) => {
            if (Array.isArray($vm.showValue)) {
                if ($vm.showValue.includes(value)) {
                    $vm.show = true;
                }
            } else if ($vm.showValue instanceof Function) {
                // @see resources/views/hubs/community/edit.blade.php how to use this
                $vm.show = $vm.showValue([value, value2]) !== null
                    ? $vm.showValue([value, value2])
                    : $vm.show;
            } else {
                if (value == $vm.showValue) {
                    $vm.show = true;
                }
            }

            if (Array.isArray($vm.hideValue)) {
                if ($vm.hideValue.includes(value)) {
                    $vm.show = false;
                }
            } else if ($vm.hideValue instanceof Function) {
                // Do nothing as showValue will do the work
            }  else {
                if (value == $vm.hideValue) {
                    $vm.show = false;
                }
            }
        });
    }
}
</script>