<template>
    <div class="row row-collapse m-b-5 m-t-4">
        <div class="column" v-bind:class="{'medium-12': inSidebar, 'medium-4': ! inSidebar}">
             <div class="colorpicker-group">
                <label v-if="colorLabel" class="m-b-2">{{ colorLabel }}</label>
                <div class="colorpicker-group colorpicker-group--with-hex">
                    <div class="colorpicker-select">
                        <a v-on:click.prevent="triggerColorPicker" class="btn btn-icon--right btn-outline btn-outline--slim btn--sq colorpicker-btn width-auto-min h-anim h-br-muted rounded-ittybitty" href="#pick-color">
                            <label class="colorpicker-status p-0 m-n-t-micro" v-bind:style="{'background-color': color}">
                                <input v-on:click.prevent="triggerColorPicker" class="colorpicker-status-input p-0 m-0" type="color" v-bind:name="useOpacity ? fieldNamePrefix + '[color]' : fieldNamePrefix" v-model="color" ref="color-picker" />
                            </label>I
                            <span class="i-container i-filled-dark" v-on:click.prevent="triggerColorPicker">
                                <!-- /icons/caret-s-down.svg -->
                                <svg class="icon  i-size-xsmall m-n-t-micro inline" width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><path d="M-7-9h24v24H-7z" fill="none"/><path class="icon" d="M10 0L5 6 0 0z"/></svg>
                            </span>
                        </a>
                        <picker v-if="showColorPicker" v-on-clickaway="hideColorPicker" v-bind:disable-alpha="true" v-bind:disable-fields="true" v-bind:value="color" v-on:input="updateColor"></picker>
                    </div>
                    <input type="text" maxlength="7" v-model="color" class="f-s-3 c-muted m-b-0 m-l-2 colorpicker__hex" v-bind:class="{'colorpicker__hex--sidebar': inSidebar}">
                </div>
            </div>
        </div>
        <div v-if="useOpacity" class="column" v-bind:class="{'medium-12 m-t-3': inSidebar, 'medium-5': ! inSidebar}">
            <div class="range-group">
                <label>
                    {{ opacityLabel }}
                    <div class="range-group-slider br br-c--lighter m-t-2 p-l-2 p-r-2 h-anim h-br-muted">
                        <input class="range-control block" type="range" min="0" max="100" step="1" v-bind:name="fieldNamePrefix + '[opacity]'" v-model="opacity">
                    </div>
                </label>
                <span class="range-group__value f-s-2 c-muted">{{ opacity }}%</span>
            </div>
        </div>
        <div class="column medium-3"></div>
    </div>
</template>
<script>
    import { Chrome } from 'vue-color';
    import { directive as onClickaway } from 'vue-clickaway';
    import { EventBus } from '../../event-bus';

    export default {
        components: {
            'picker': Chrome
        },
        directives: {
            onClickaway: onClickaway
        },
        props: {
            fieldNamePrefix: {
                type: String,
                required: true
            },
            value: {
                type: Object
            },
            useOpacity: {
                type: Boolean,
                default: false
            },
            colorLabel: {
                type: String,
                default: null
            },
            opacityLabel: {
                type: String,
                default: null
            },
            inSidebar: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                color: _.get(this.value, 'color', '#000000'),
                opacity: _.get(this.value, 'opacity', 50),
                showColorPicker: false
            }
        },
        watch: {
            color(newValue) {
                // Make sure color value HEX is prepended with #
                if (newValue.indexOf('#') < 0 && newValue && newValue.length > 0) {
                    newValue = '#' + newValue;
                    this.color = newValue;
                }

                this.$emit('update:color', newValue);
                EventBus.$emit('form:element:changed', this.useOpacity ? this.fieldNamePrefix + '[color]' : this.fieldNamePrefix, newValue);
            },
            opacity(newValue) {
                this.$emit('update:opacity', newValue);
                EventBus.$emit('form:element:changed', this.fieldNamePrefix + '[opacity]', newValue);
            },
            value(newValue) {
                this.color = newValue.color;
                this.opacity = newValue.opacity;
            }
        },
        methods: {
            triggerColorPicker() {
                this.showColorPicker = true;
            },
            updateColor(color) {
                this.color = color.hex;
            },
            hideColorPicker() {
                this.showColorPicker = false;
            }
        },
        created() {
            var $vm = this;
            window.addEventListener('keyup', event => {
                if (this.showColorPicker && event.keyCode === 27) {
                    this.hideColorPicker();
                }
            });
        }
    }
</script>