<template>
    <div class="panel-content columns small-12 medium-9 large-8">
        <div class="slat m-b-4">
            <div class="row row-compress-indent row-compress row-top row-integration-options">
                <div class="slat-figure--medium column small-12 cover-container">
                    <img class="br br-c--light integration-icon" v-bind:src="activeIntegration.icon">

                    <div v-if="$parent.isLoading" class="cover-overlay cover-overlay--bright">
                        <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny integration-preloader">
                            <svg class="i-stroke-bright" width="24" height="24"><g><circle cx="50%" cy="50%" r="10" stroke-width="2" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                    </div>
                </div>

                <div class="slat-content column">
                    <h2 class="card-title c-primary f-s-4 m-b-1">{{ activeIntegration.title }}</h2>
                    <p class="f-s-3 c-muted m-b-0">{{ trans('Send emails to :integration', {'integration': activeIntegration.title}) }}</p>

                    <div v-if="hasLists" class="form-group m-t-2" v-bind:class="{'has-error': errors.has('list')}">
                        <label>{{ activeIntegration.config.list_label }}</label>
                        <select name="list" v-model="list" v-bind:disabled="$parent.isLoading == true" class="input__field">
                            <option value="">---</option>
                            <option v-for="list in activeLists" v-bind:value="list.id" v-bind:key="list.id">{{ list.name }}</option>
                        </select>
                    </div>
                    <div v-if="errors.has('list')" class="form-help">{{ errors.get('list') }}</div>

                    <integration-tags
                        v-if="hasTags"
                        v-bind:integration="activeIntegration"
                        v-bind:selected-tags="membershipTags"
                        v-bind:available-tags="activeTags"
                        v-bind:errors="errors"
                        v-on:change="updateMembershipTags"
                    ></integration-tags>
                </div>
                <div class="slat-aside text-right m-r-4 m-t-2">
                    <a class="btn btn-secondary" data-toggle="serviceClose" v-on:click="removeActiveIntegration">{{ trans('Remove :integration', {'integration': activeIntegration.title}) }}</a>
                </div>
            </div>
        </div>
        <input type="hidden" name="integration" v-bind:value="integration.uid" />
    </div>
</template>
<script>
import { EventBus } from '../../../event-bus.js';
import IntegrationTags from './IntegrationTags.vue';

export default {
    components: {
        IntegrationTags,
    },
    props: {
        activeIntegration: {
            type: Object
        },
        membership: {
            type: Object
        },
        activeLists: {
            type: Array
        },
        activeListId: {
            type: String
        },
        activeMembershipTags: {
            type: Array
        },
        activeTags: {
            type: [Array, Object]
        },
        errors: {
            type: [Array, Object]
        },
    },
    data() {
        return {
            list: this.activeListId,
            membershipTags: this.activeMembershipTags,
            integration: this.activeIntegration,
        };
    },
    computed: {
        hasTags() {
            return this.$parent.hasTags;
        },
        hasLists() {
            return this.activeIntegration.provider !== 'infusionsoft'
                && this.activeIntegration.provider !== 'keap'
        },
    },
    watch: {
        list(list) {
            EventBus.$emit('form:element:changed', 'list', list);
        },
    },
    methods: {
        updateMembershipTags(tags) {
            this.membershipTags = tags;
        },
        removeActiveIntegration() {
            EventBus.$emit('removeActiveIntegration');
        }
    },
    created() {
        EventBus.$emit('form:element:changed', 'integration_uid', this.integration.uid);
    }
}
</script>

<style>
    .row-integration-options {
        position: relative;
    }

    .integration-preloader-container {
        position: absolute;
        top: 0;
        left: 1.5em;
        background: rgba(255,255,255,.85);
        width: 74px;
        height: 74px;
    }

    .integration-preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12px;
        margin-top: -12px;
    }
</style>