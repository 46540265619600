<script>
    export default {
        data() {
            return {
                datePickLabels: {
                    nextMonthCaption: this.trans('Next month'),
                    prevMonthCaption: this.trans('Previous month'),
                    setTimeCaption: this.trans('Set time:'),
                    weekdays: [
                        this.trans('Mon'), this.trans('Tue'), this.trans('Wed'), 
                        this.trans('Thu'), this.trans('Fri'), this.trans('Sat'), 
                        this.trans('Sun')
                    ],
                    months: [
                        this.trans('January'), this.trans('February'), this.trans('March'), 
                        this.trans('April'), this.trans('May'), this.trans('June'), 
                        this.trans('July'), this.trans('August'), this.trans('September'), 
                        this.trans('October'), this.trans('November'), this.trans('December')
                    ]
                }
            }                                
        }
    }
</script>