import { EventBus } from './event-bus.js';

export default class DirtyForm {
    /**
     * Initialize element values and event handlers.
     * @param  {HTMLForm} form
     * @param  {Array} ignoredFields
     * @return {void}
     */
    constructor(form, ignoredFields = []) {
        this.form = form;
        this.ignoredFields = ignoredFields;
        this.initialState = this.getCurrentFormState();

        this.form.addEventListener('input', this.handleChangeEvent.bind(this));
        this.form.addEventListener('change', this.handleChangeEvent.bind(this));
        this.changedState = new Map(this.initialState);

        EventBus.$on('form:element:initialize', this.setElementValue.bind(this));
        EventBus.$on('form:element:changed', this.handleManualChange.bind(this));
        EventBus.$on('form:check', this.resetChangedStateAndCheck.bind(this));
    }

    /**
     * Get state from current form field values.
     *
     * @return  {Map}
     */
    getCurrentFormState() {
        let state = new Map();

        for (let element of this.form.elements) {
            if (element.name && this.elementValue(element) !== undefined && ! this.shouldBeIgnored(element.name)) {
                state.set(element.name, this.elementValue(element));
            }
        }

        return state;
    }

    /**
     * Return form element value based on its type.
     * @param  {HTMLFormElement} element
     * @return {String}
     */
    elementValue(element) {
        if ('radio' === element.type) {
            if ( ! element.checked) {
                return undefined;
            }

            if (element.value) {
                return element.value;
            }

            return +element.checked;
        }

        if ('checkbox' === element.type) {
            if (element.indeterminate) {
                return 2;
            }
            return +element.checked;
        }

        if ('select' === element.type || 'select-multiple' === element.type) {
            var indexes = [];
            for (var i = 0, ii = element.length; i < ii; i++) {
                // Workaround due to IE11's missing support for select.selectedOptions
                if (element.options[i].selected) {
                    indexes.push(i);
                }
            }
            return indexes.join('-');
        }

        return element.value;
    }

    /**
     * Handle input and change event. Set new value for given element and check if form is dirty.
     * @param  {Event} event
     * @return {void}
     */
    handleChangeEvent(event) {
        let element = event.target;
        if (element.name && ! this.shouldBeIgnored(element.name)) {
            this.changedState.set(element.name, this.elementValue(element));
        }

        this.checkForChanges();
    }

    /**
     * Handle manual element value change from global event bus. Set new value and check if form is dirty.
     * @param  {String} name
     * @param  {mixed} value
     * @return {void}
     */
    handleManualChange(name, value) {
        if (this.shouldBeIgnored(name)) {
            return;
        }

        this.changedState.set(name, value);

        this.checkForChanges();
    }

    /**
     * Set element initial value so it could be checked agains later on.
     * @param  {String} name
     * @param  {mixed} value
     * @return {void}
     */
    setElementValue(name, value) {
        this.initialState.set(name, value);
        this.changedState.set(name, value);
    }

    /**
     * Re-init changed state with current form state.
     *
     * @return  {Void}
     */
    resetChangedStateAndCheck() {
        this.changedState = new Map([...this.changedState, ...this.getCurrentFormState()]);
        this.checkForChanges();
    }

    /**
     * Check for dirty data. Trigger event on global event bus. Toggle "beforeunload" event.
     * @return {void}
     */
    checkForChanges() {

        if (this.compareStates(this.initialState, this.changedState)) {
            EventBus.$emit('form:dirty');
        } else {
            EventBus.$emit('form:clean');
        }
    }

    /**
     * Compare states.
     *
     * @param   {Map}  firstState
     * @param   {Map}  secondState
     *
     * @return  {Boolean}
     */
    compareStates(firstState, secondState) {
        if (firstState.size !== secondState.size) {
            return true;
        }

        for (let [key, value] of firstState) {
            if (value !== secondState.get(key)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the field should be ignored.
     *
     * In order for this to work for nested fields, the field name should be in the format of 'parent[0][child]'.
     *
     * @param   {String}  field
     *
     * @return  {Boolean}
     */
    shouldBeIgnored(field) {
        return this.ignoredFields.indexOf(this.getStringUpToBracket(field)) !== -1;
    }

    /**
     * Get part of the string up to the first occurrence of '['.
     *
     * @param   {String}  str  The input string.
     *
     * @return  {String}       The substring up to '['.
     */
    getStringUpToBracket(str) {
        const index = str.indexOf('[');
        if (index === -1) {
            return str; // Return the whole string if '[' is not found
        }
        return str.slice(0, index);
    }
}