import Lang from 'lang.js';

window.Lang = new Lang({
    messages: {
        [window.Kourses.language]: window.i18n
    },
    locale: window.Kourses.language,
    fallback: 'en'
});

Vue.prototype.trans = (...args) => {
    return window.Lang.get(...args);
}