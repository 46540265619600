<template>
    <div class="column p-0 large-6">
        <div class="progress m-b-2">
            <div class="progress-bar c-accent-bg" v-bind:aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" v-bind:style="'width: ' + progress + '%;'">
                <div class="sr-only" v-html="progressNumber"></div>
            </div>
        </div>
        <div class="row row-middle row-collapse">
            <div class="column">
                <div class="f-s-2 c-medium-tint" v-html="progressLabel"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            progress: {
                type: Number,
                default: 0
            }
        },

        computed: {
            progressNumber() {
                return this.progress + '%';
            },
            progressLabel() {
                return this.trans(':progress complete', {'progress': this.progressNumber});
            }
        }
    }
</script>