<template>
    <div class="tag-group form-group p-b-3">
        <a
            v-for="(tag, index) in tags"
            v-bind:key="index"
            v-on:click.prevent="remove"
            class="btn btn-small btn-secondary btn-icon btn-tag btn-icon--right rounded m-r-3"
        >
            {{ tag }}
            <span class="icon i-container i-filled-dark">
                <!-- /icons/remove.svg -->
                <svg class="icon i-size-small" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
            </span>
        </a>
        <input
            type="text"
            placeholder="..."
            class="inline input__field width-auto new-tag"
            v-model="newTag"
            v-on:keydown.delete="removeLastTag"
            v-on:keydown.enter.188.tab.prevent="addNew(newTag)">
        <input v-if="inputAsArray === false" type="hidden" v-bind:name="fieldName" v-bind:value="tagList" />
        <input v-else v-for="tag in tags"  type="hidden" v-bind:name="fieldName + '[]'" v-bind:value="tag" v-bind:key="tag" />
    </div>
</template>
<script>
import { EventBus } from '../../event-bus';

export default {
    props: {
        items: {
            type: Array
        },
        fieldName: {
            type: String,
            default: 'tags'
        },
        inputAsArray: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            tags: _.get(this, 'items', []),
            newTag: ''
        }
    },
    computed: {
        tagList() {
            return this.tags.join(',');
        }
    },
    methods: {
        validate(value) {
            return new RegExp(/^[a-zA-Z0-9\s\-]+$/).test(value);
        },
        addNew(tag) {
            if (tag && this.tags.indexOf(tag) === -1 && this.validate(tag)) {
                this.tags.push(tag);

                this.$emit('change', this.tags);
                EventBus.$emit('form:element:changed', this.fieldName, this.tags.join('-'));
            }
            this.newTag = '';
        },
        remove(index) {
            this.tags.splice(index, 1);

            this.$emit('change', this.tags);
            EventBus.$emit('form:element:changed', this.fieldName, this.tags.join('-'));
        },
        removeLastTag() {
            if (this.newTag) {
                return;
            }
            this.tags.pop();

            this.$emit('change', this.tags);
            EventBus.$emit('form:element:changed', this.fieldName, this.tags.join('-'));
        },
        focusNewTag() {
            this.$el.querySelector('.new-tag').focus();
        },
    },
    mounted() {
        // I don't know why this needs to go in the next tick but it doesn't work otherwise
        Vue.nextTick(() => {
            EventBus.$emit('form:element:initialize', this.fieldName, this.items.join('-'));
        });
    }
}
</script>