<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Select the service you want to integrate with:') }}</h3>
        </div>

        <div v-for="(integration, index) in integrations" v-bind:class="{'h-bg-accent--light' : selectedIntegration !== integration.provider}" v-bind:key="index" class="slat h-anim h-anim-select m-b-0">
            <div class="row row-compress-indent row-compress p-3 row-middle">
                <div class="slat-figure--medium column small-12">
                    <img v-bind:src="integration.icon" class="br br-c--light integration-icon">
                </div>
                <div class="slat-content column">
                    <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                    <p class="f-s-3 c-muted m-b-0">{{ integration.description }}</p>
                </div>

                <div class="slat-aside text-right m-r-4 m-t-2">
                    <button type="button" v-on:click="toggleIntegration(integration)" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                </div>
            </div>

            <div v-if="selectedIntegration === integration.provider" class="row m-b-5">
                <div class="small-12 columns">
                    <div v-if="integration.type === 'webhook'" class="form-group">
                        <label>
                            {{ trans('Webhook URL') }}
                            <clipboard v-bind:data="integration.webhook" inline-template>
                                <div class="form-group-addon copy-append">
                                    <input class="input__field" type="text" readonly v-bind:value="integration.webhook">
                                    <a class="btn btn-text form-append c-accent text-right btn-copy c-bright-bg br-no-radius" v-clipboard="copyData" v-on:success="handleSuccess">{{ trans('Copy Url') }}</a>
                                </div>
                            </clipboard>
                        </label>
                    </div>

                    <div v-for="(field, key) in integration.fields" v-bind:key="key">
                        <div class="form-group">
                            <label>{{ field.label }}</label>
                            <input type="text" class="input__field" v-model="integration.fields[key].value">
                        </div>
                        <div class="form-help">{{ field.help }}</div>
                    </div>

                    <div class="text-right">
                        <button v-on:click="connectIntegration(integration)" v-bind:class="{'btn-loading': loading}" class="btn btn-primary">
                            {{ trans('Save and connect') }}
                            <div v-if="loading" class="loader">
                                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                    <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer"></div>
    </modal>
</template>
<script>
    import Modal from '../../modals/Modal.vue';
    import { EventBus } from '../../../event-bus.js';

    export default {
        components: {
            Modal
        },
        props: {
            integrations: {
                type: Object
            }
        },
        data() {
            return {
                show: false,
                selectedIntegration: null,
                loading: false
            }
        },
        methods: {
            toggleIntegration(integration) {
                if (this.selectedIntegration === integration.provider) {
                    this.selectedIntegration = null;
                } else {
                    this.selectedIntegration = integration.provider;
                }
            },
            connectIntegration(integration) {
                this.loading = true;

                let data = {};

                for (var key in integration.fields) {
                    data[key] = integration.fields[key].value;
                }

                window.axios.put(Kourses.apiBaseUrl + '/integrations/carts/' + integration.provider, {
                    'data': data,
                }).then((response) => {
                    this.show = false;
                    this.loading = false;
                    this.selectedIntegration = null;

                    EventBus.$emit('cart:integrations:update', integration);
                }).catch(({response}) => {
                    console.log(response);
                });
            }
        },
        created() {
            let $vm = this;

            EventBus.$on('modal:new-cart-integration', () => {
                $vm.show = true;
            });
        },
    }
</script>