<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Add New Page') }}</h3>
        </div>
        <div class="form-group" v-bind:class="{'has-error': errors.has('title')}">
            <label>
                {{ trans('Page Title') }}
                <input class="input__field" type="text" v-model="page.title">
            </label>
        </div>
        <div v-if="errors.has('title')" class="form-help">
            {{ errors.get('title') }}
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="addPage" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Add Page') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from './Modal.vue';
    import Errors from '../../errors.js';
    import { EventBus } from '../../event-bus.js';

    export default {
        components: {
            Modal
        },
        data() {
            return {
                page: {
                    title: ''
                },
                show: false,
                loading: false,
                errors: new Errors()
            }
        },
        methods: {
            addPage() {
                this.loading = true;
                window.axios
                    .post(Kourses.activeBaseUrl + '/website/pages', this.page)
                    .then((response) => {
                        window.location.href = response.data.links.edit;

                        this.loading = false;
                        this.show = false;
                        this.reset();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.errors.record(error.response.data);
                    });
            },
            reset() {
                this.page = {
                    title: ''
                };

                this.loading = false;
                this.errors.clear();
            }
        },
        created() {
            let $vm = this;

            EventBus.$on('modal:new-page', () => {
                $vm.show = true;
            });
        },
    }
</script>