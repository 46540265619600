<template>
    <div>
        <div v-if="items.length === 0" class="row-center row m-b-4 row-middle">
            <div class="column p-l-0">
                <h1 class="f-s-7 m-b-0">
                    {{ trans('Ready For Take Off?') }}
                    <br />
                    {{ trans("Let's Get Your Course Started") }}
                </h1>
                <p class="c-muted m-t-3 f-s-5">
                    {{ trans("You've mapped out your content and you're ready to start getting it online. Don't waste any more time, click the button below to start adding your videos, files and training content today!") }}
                </p>
                <a v-bind:href="addLessonUrl" class="btn btn-primary m-t-2">{{ trans('Add Your First Lesson') }}</a>
            </div>
            <div class="medium-6">
                <img class="p-l-8 p-r-8" src="/img/empty-course.png" />
            </div>
        </div>
        <div v-else v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
            <module
                 v-for="(module, index) in items"
                 v-bind:key="'module-' + module.id"
                 v-bind:item="module"
                 v-bind:index="index"
                 v-bind:add-lesson-url="addLessonUrl"
                 v-bind:selected.sync="selected"
                 v-on:show-modal-edit="showModalEdit"
                 v-bind:data-encoded-id="module.encoded_id"
            ></module>
        </div>
        <new-module-modal
            v-on:close="modalNew.show = false"
            v-on:add-module="addModule"
            ref="new-module-modal">
        </new-module-modal>
        <edit-module-modal
            v-on:close="modalEdit.show = false"
            v-on:save-module="saveModule"
            v-on:delete-module="deleteModule"
            ref="edit-module-modal">
        </edit-module-modal>
    </div>
</template>
<script>
import Orderable from '../mixins/Orderable.js';
import Module from './Module.vue';
import { lessonCreateLink, moduleStoreLink, moduleUpdateLink, moduleDeleteLink } from '../routes.js';

export default {
    mixins: [Orderable],
    components: {
        Module
    },
    props: {
        hub: {
            type: Object,
            required: true,
        },
        modules: {
            type: Array,
        },
    },
    data() {
        return {
            items: this.modules,
            model: 'Kourses\\Module',
            checked: [],
            selected: null,
            addModuleUrl: moduleStoreLink(this.hub),
            addLessonUrl: lessonCreateLink(this.hub),
        }
    },
    methods: {
        showModalNew() {
            this.modalNew.show = true;
        },
        addModule(module, createLesson) {
            window.axios
                .post(this.addModuleUrl, module)
                .then((response) => {
                    // If add and add lesson is clicked
                    if (createLesson) {
                        window.location.href = this.addLessonUrl + '?module_id=' + response.data.encoded_id;
                    } else {
                        this.items.push(response.data);
                        this.modalNew.show = false;
                        this.modalNew.reset();

                        this.$emit('modules:count', this.items.length);
                    }
                })
                .catch((error) => {
                    this.modalNew.errors.record(error.response.data);
                });
        },
        showModalEdit(module, index) {
            this.modalEdit.module = module;
            this.modalEdit.index = index;
            this.modalEdit.show = true;
        },
        saveModule(module, index) {
            window.axios
                .put(moduleUpdateLink(module), module)
                .then(() => {
                    this.items.splice(index, 1, module);
                    this.modalEdit.show = false;
                    this.modalEdit.reset();
                })
                .catch((error) => {
                    this.modalEdit.errors.record(error.response.data);
                });
        },
        deleteModule(module, index) {
            this.$dialog.confirm(this.trans("By deleting module you'll also delete its lessons!"), {loader: true})
                .then((dialog) => {
                    window.axios
                        .delete(moduleDeleteLink(module))
                        .then(() => {
                            this.items.splice(index, 1);
                            this.modalEdit.show = false;
                            this.modalEdit.reset();
                            dialog.close();

                            this.$emit('modules:count', this.items.length);
                        });
                });
        },
        isSelected(moduleId) {
            return this.selected === moduleId;
        },
        toggle(moduleId) {
            this.selected = this.selected !== moduleId ? moduleId : null;
        }
    },
    mounted() {
        this.modalNew = this.$refs['new-module-modal'];
        this.modalEdit = this.$refs['edit-module-modal'];
    }
}
</script>