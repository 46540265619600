<template>
    <modal v-bind:show.sync="show" ref="modal" v-on:update:show="cancel">
        <cropper
            ref="cropper"
            v-if="(image !== null)"
            v-bind:src="image.url"
            v-bind:stencil-props="{
                aspectRatio: aspectRatio
            }"
            v-bind:default-size="stencilSize"
            v-on:change="change"
            v-on:ready="(loading = false)"
            v-on:error="handleError"
        />
        <div v-if="loading" class="card column uploads uploads--min-height-250 uploads-r-1_5 br c-lightest-bg text-center p-l-4 p-r-4">
            <div class="upload-content row row-middle">
                <div class="row row-middle row-tall">
                    <div class="column text-center">
                        <div class="card-figure m-b-2">
                            <div class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                                <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                        <div class="card-content">
                            <p class="f-s-4 c-muted">{{ trans('Loading image...') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button v-on:click="cropImage" v-bind:class="{ 'btn-loading': saving, 'btn-disabled': saving || loading }" v-bind:disabled="saving || loading" type="button" class="btn btn-primary">
                        {{ trans('Crop Image') }}
                        <div class="loader" v-if="saving">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Modal,
        Cropper,
    },
    props: {
        aspectRatio: {
            type: Number,
            default: 1,
        },
        resize: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            image: null,
            show: false,
            saving: false,
            loading: true,
            coordinates: null,
            loadingErrorCount: 0,
        }
    },
    methods: {
        change({ coordinates }) {
            this.coordinates = coordinates;
        },
        handleError() {
            // There can be some delay between uploading the image and moving
            // it to the CDN. This will catch it and do 4 attempts with a delay
            if (this.loadingErrorCount < 4) {
                const $vm = this;
                setTimeout(() => {
                    // Copy the image URL
                    const imageUrl = $vm.image.url;

                    // Reset it in order to force re-render
                    $vm.image.url = null;

                    // Add the image URL back so the render will happen
                    $vm.$nextTick(() => {
                        $vm.image.url = imageUrl;
                    });

                    $vm.loadingErrorCount++;
                }, 500);
            } else {
                this.loading = false;
                this.$toast.danger(this.trans('Error loading image. Image could not be read from the CDN.'));
            }
        },
        cropImage() {
            this.saving = true;

            window.axios.post(window.Kourses.activeBaseUrl + '/upload/crop', {
                'file': this.image.path,
                'width': Math.round(this.coordinates.width),
                'height': Math.round(this.coordinates.height),
                'x': Math.round(this.coordinates.left),
                'y': Math.round(this.coordinates.top),
                'size': this.resize,
            })
            .then((result) => {
                this.$emit('crop:success', result.data);
                this.close();
            })
            .catch(({response}) => {
                if (response.data && response.data.message) {
                    this.$toast.danger(response.data.message);
                }

                this.saving = false;
            });
        },
        cancel() {
            this.$emit('crop:cancelled');
            this.show = false;
            this.reset();
        },
        reset() {
            this.image = null;
            this.saving = false;
            this.loading = true;
            this.coordinates = null;
            this.loadingErrorCount = 0;
        },
        close() {
            this.show = false;
            this.reset();
        },
        /**
         * Set default cropper stencil size.
         *
         * Stretch it either full widht or height (depending on image
         * orientation).
         *
         * @see     {@link https://advanced-cropper.github.io/vue-advanced-cropper/components/cropper.html#props} for further information about params and return value.
         *
         * @param   {Object}  visibleArea
         * @param   {Object}  imageSize
         * @param   {Object}  stencilRatio
         * @param   {Object}  sizeRestrictions
         *
         * @return  {Object}
         */
        stencilSize({visibleArea, imageSize, stencilRatio, sizeRestrictions}) {
            let width = imageSize.width;
            let height = imageSize.height / this.aspectRatio;

            if (height > imageSize.height) {
                height = imageSize.height;
                width = imageSize.height * this.aspectRatio;
            }

            return {
                width: width,
                height: height
            }
        },
    },
}
</script>