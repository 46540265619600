<template>
    <div class="row m-t-5 row-center">
        <div class="column large-8 medium-10 small-11 xsmall-12">
            <h3 v-if="heading" class="f-s-7 c-primary f-w-bold m-b-5">{{ heading }}</h3>
            <div v-if="items.length > 0" v-for="(item, index) in items" v-bind:key="index" class="faq m-b-4">
                <h4 class="f-s-5 c-primary m-b-2">{{ item.title }}</h4>
                <p class="f-s-4 c-medium">{{ item.text }}</p>
            </div>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                heading: _.get(this.block.data, 'heading'),
                items: _.get(this.block.data, 'items', [])
            }
        }
    }
</script>