<template>
    <div>
        <div
            class="form-group form-group-prepend"
            v-bind:class="{'has-error': errors.has('slug') && ! valid, 'has-success': valid && dirty}">
            <label>
                {{ trans('URL') }}
                <div class="inline-prepend row row-collapse">
                    <div class="form-prepend c-muted f-s-4">{{ baseUrl }}</div>
                    <input
                        type="text"
                        name="slug"
                        class="input__field column"
                        v-model="slug"
                        v-on:keyup="validateSlug"
                    >
                </div>
            </label>
        </div>
        <div v-if="errors.has('slug')" v-html="errors.get('slug')" class="form-help"></div>
    </div>
</template>
<script>
    import Errors from '../../errors.js';
    import { EventBus } from '../../event-bus.js';

    export default {
        props: {
            id: {
                type: String
            },
            table: {
                type: String,
                required: true
            },
            baseUrl: {
                type: String
            },
            value: {
                type: String,
                default: ''
            },
            validationErrors: {
                type: [Array, Object]
            },
            isValid: {
                type: Boolean
            },
            excludeSoftDeleted: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                slug: this.value,
                dirty: false,
                valid: _.get(this, 'isValid', true),
                errors: new Errors(_.get(this, 'validationErrors', {})),
            };
        },
        methods: {
            validateSlug: _.debounce(function(event) {
                this.dirty = true;
                window.axios
                    .post(Kourses.apiBaseUrl + '/slug/check', {
                        'id': this.id,
                        'slug': this.slug,
                        'table': this.table,
                        'exclude_deleted': this.excludeSoftDeleted,
                    }).then(() => {
                        this.valid = true;
                        this.errors.clear();
                        this.$emit('slug-customized', this.slug);
                        EventBus.$emit('form-valid');
                    }).catch((error) => {
                        this.valid = false;
                        this.errors.record(error.response.data);
                        EventBus.$emit('form-invalid');
                    });
            }, 500)
        },
        created() {
            const $vm = this;

            EventBus.$on('slug-generated', (slug) => {
                $vm.slug = slug;
                $vm.valid = true;
                $vm.errors.clear();
                EventBus.$emit('form-valid');
            });
        }
    }
</script>