window.Vue = require('vue').default;

import Sortable from 'sortablejs';

Vue.directive('sortable', {
    inserted: function (el, binding) {
        new Sortable(el, binding.value || {});
    }
});

import Dialog from './plugins/dialog/js/index.js';
Vue.use(Dialog);

import Toast from './plugins/toast/js/index.js';
Vue.use(Toast);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

Vue.filter('startCase', (value) => {
    return _.startCase(value);
});

Vue.filter('duration', (value) => {
    // Cleanup
    value = parseInt(value, 10);

    // Calculation
    let hours   = Math.floor(value / 3600);
    let minutes = Math.floor((value - (hours * 3600)) / 60);
    let seconds = value - (hours * 3600) - (minutes * 60);

    // Checks
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    // Concatenation
    return hours + ':' + minutes + ':' + seconds;
});

import DirtyForm from './dirty-form.js';

Vue.directive('dirty', {
    inserted(element, binding) {
        new DirtyForm(element, binding.value || []);
    }
});

Vue.directive('scrollToError', {
    bind(element) {
        const elementWithError = element.querySelector('.has-error');

        if (elementWithError === null) {
            return;
        }

        setTimeout(() => {
            elementWithError.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
            });
        }, 500);
    }
})

Vue.directive('hover', {
    bind(element) {
        let mouseOverHandler = (event) => {
            // Clear old active step
            let activeCards = document.querySelectorAll('.card-hover.card-item-active');
            for (let i = 0; i < activeCards.length; i++) {
                activeCards[i].classList.remove('card-item-active');
            }

            element.classList.add('card-item-active');
        }

        element.addEventListener('mouseover', mouseOverHandler);
    }
});

Vue.directive('originalFallback', {
    bind(imageElement) {
        const replaceImgSrcWithOriginal = (event) => {
            event.target.src = event.target.src
                .replace('/max/', '/')
                .replace('/large/', '/')
                .replace('/small/', '/')
                .replace('/thumb/', '/');
        }

        imageElement.addEventListener('error', replaceImgSrcWithOriginal, {once: true});
    }
});

Vue.directive('gistHelpArticle', {
    bind(element, binding) {
        const helpArticleId = binding.value;

        const helpArticleClickHandler = (event) => {
            event.preventDefault();

            if ( ! gist) {
                return;
            }

            gist.chat('open');
            gist.chat('article', helpArticleId);
        }

        element.addEventListener('click', helpArticleClickHandler);
    }
});

Vue.directive('toggle', {
    bind(triggerElement, binding) {
        let triggerHandler = (event) => {
            let dropdownElement = document.getElementById(binding.value);
            let path = event.path || (event.composedPath ? event.composedPath() : undefined);

            if ((dropdownElement.offsetWidth || dropdownElement.offsetHeight)
            && path ? path.indexOf(triggerElement) < 0 : ! triggerElement.contains(event.target)) {
                dropdownElement.classList.remove('dropdown--toggled');
            } else {
                dropdownElement.classList.toggle('dropdown--toggled');
            }
        }

        let clickHandler = (event) => {
            let path = event.path || (event.composedPath ? event.composedPath() : undefined);

            if (path ? path.indexOf(triggerElement) < 0 : ! triggerElement.contains(event.target)) {
                document.getElementById(binding.value).classList.remove('dropdown--toggled');
            }
        }

        let escHandler = (event) => {
            if (event.keyCode === 27) {
                document.getElementById(binding.value).classList.remove('dropdown--toggled');
            }
        }

        let mouseOverHandler = (event) => {
            let dropdownElement = document.getElementById(binding.value);

            dropdownElement.classList.add('dropdown--toggled');
        }

        let mouseOutHandler = (event) => {
            let dropdownElement = document.getElementById(binding.value);

            dropdownElement.classList.remove('dropdown--toggled');
        }

        triggerElement.addEventListener('click', triggerHandler); // Useful for showing on touch devices
        triggerElement.addEventListener('mouseover', mouseOverHandler);
        triggerElement.addEventListener('mouseout', mouseOutHandler);
        document.documentElement.addEventListener('click', clickHandler, false); // This hides other toggled dropdowns
        window.addEventListener('keyup', escHandler, false);
    }
});

// Load components
Vue.component('toaster', require('./components/Toaster.vue').default);
Vue.component('files', require('./components/Files.vue').default);
// Vue.component('media-list', require('./components/MediaList.vue').default);
Vue.component('pricing-plan-list', require('./components/PricingPlanList.vue').default);
Vue.component('publish-button', require('./components/PublishButton.vue').default);
Vue.component('form-wizard', require('./components/FormWizard.vue').default);
Vue.component('wizard-step', require('./components/WizardStep.vue').default);
Vue.component('progress-donut', require('./components/ProgressDonut.vue').default);
Vue.component('upload-progress-donut', require('./components/UploadProgressDonut.vue').default);
Vue.component('tips', require('./components/Tips.vue').default);
Vue.component('modules', require('./components/Modules.vue').default);
Vue.component('lessons', require('./components/Lessons.vue').default);
Vue.component('lessons-page', require('./components/LessonsPage.vue').default);
Vue.component('trigger-button', require('./components/TriggerButton.vue').default);
Vue.component('files', require('./components/Files.vue').default);
Vue.component('lesson-files', require('./components/LessonFiles.vue').default);
Vue.component('page', require('./components/Page.vue').default);
Vue.component('tags', require('./components/Tags.vue').default);
Vue.component('action-toggle', require('./components/ActionToggle.vue').default);
Vue.component('clipboard', require('./components/Clipboard.vue').default);
Vue.component('lesson-publish', require('./components/LessonPublish.vue').default);
Vue.component('members', require('./components/Members.vue').default);
Vue.component('transactions', require('./components/Transactions.vue').default);
Vue.component('members-import-list', require('./components/MembersImportList.vue').default);
Vue.component('members-import-csv-upload', require('./components/MembersImportCsvUpload.vue').default);
Vue.component('save-button', require('./components/SaveButton.vue').default);
Vue.component('toggled', require('./components/Toggled.vue').default);
Vue.component('course-progress', require('./components/CourseProgress.vue').default);
Vue.component('api-keys', require('./components/ApiKeys.vue').default);

// Page builder
// Vue.component('page-builder', () => import('./components/page-builder/PageBuilder.vue').default);
Vue.component('page-builder', require('./components/page-builder/PageBuilder.vue').default);
// Vue.component('page-content', () => import('./components/page-builder/PageContent.vue').default);
// Vue.component('page-content', require('./components/page-builder/PageContent.vue').default);
// Vue.component('sales-page-content', () => import('./components/page-builder/SalesPageContent.vue').default);
Vue.component('sales-page-content', require('./components/page-builder/SalesPageContent.vue').default);

// Load fields
Vue.component('block', require('./components/fields/Block.vue').default);
Vue.component('bullets', require('./components/fields/Bullets.vue').default);
Vue.component('color-picker', require('./components/fields/ColorPicker.vue').default);
Vue.component('dropdown', require('./components/fields/Dropdown.vue').default);
Vue.component('dropdown-enabler-field', require('./components/fields/DropdownEnablerField.vue').default);
Vue.component('redirect-location-dropdown', require('./components/fields/RedirectLocationDropdown.vue').default);
Vue.component('faqs', require('./components/fields/Faqs.vue').default);
Vue.component('file-upload', require('./components/fields/FileUpload.vue').default);
Vue.component('image-checkbox', require('./components/fields/ImageCheckbox.vue').default);
Vue.component('image-upload', require('./components/fields/image/ImageUpload.vue').default);
Vue.component('image-upload-with-cropper', require('./components/fields/image/ImageUploadWithCropper.vue').default);
Vue.component('small-image-upload', require('./components/fields/image/SmallImageUpload.vue').default);
Vue.component('pricing-plan', require('./components/fields/PricingPlan.vue').default);
Vue.component('rich-text-editor', require('./components/fields/RichTextEditor.vue').default);
Vue.component('slug', require('./components/fields/Slug.vue').default);
Vue.component('subdomain-field', require('./components/fields/SubdomainField.vue').default);
Vue.component('testimonials', require('./components/fields/Testimonials.vue').default);
Vue.component('title-and-slug', require('./components/fields/TitleAndSlug.vue').default);
Vue.component('typography', require('./components/fields/Typography.vue').default);
Vue.component('video-upload', require('./components/fields/VideoUpload.vue').default);
Vue.component('video-with-thumbnail-upload', require('./components/fields/VideoWithThumbnailUpload.vue').default);
Vue.component('author', require('./components/fields/Author.vue').default);
Vue.component('toggle', require('./components/fields/Toggle.vue').default);
Vue.component('text-logo-toggle', require('./components/fields/TextLogoToggle.vue').default);
Vue.component('tags-field', require('./components/fields/Tags.vue').default);
Vue.component('number-spinner', require('./components/fields/NumberSpinner.vue').default);
Vue.component('expandable', require('./components/fields/Expandable.vue').default);
Vue.component('assigned-hubs', require('./components/fields/AssignedHubs.vue').default);
Vue.component('toggle-slot', require('./components/fields/ToggleSlot.vue').default);
Vue.component('submit-bar', require('./components/fields/SubmitBar.vue').default);
Vue.component('input-with-vars', require('./components/fields/InputWithVars.vue').default);
Vue.component('autocomplete', require('./components/fields/AutoComplete.vue').default);
Vue.component('default-header', require('./components/fields/DefaultHeader.vue').default);
Vue.component('page-header-image', require('./components/fields/PageHeaderImage.vue').default);
Vue.component('duration', require('./components/fields/Duration.vue').default);
Vue.component('limited-input', require('./components/fields/LimitedInput.vue').default);
Vue.component('limited-textarea', require('./components/fields/LimitedTextarea.vue').default);
Vue.component('domain-verification-input', require('./components/fields/DomainVerificationInput.vue').default);

// Load modals
// Vue.component('media-modal', require('./components/modals/MediaModal.vue').default);
Vue.component('new-module-modal', require('./components/modals/NewModuleModal.vue').default);
Vue.component('edit-module-modal', require('./components/modals/EditModuleModal.vue').default);
Vue.component('new-page-modal', require('./components/modals/NewPageModal.vue').default);
Vue.component('pricing-plan-modal', require('./components/modals/PricingPlanModal.vue').default);
Vue.component('files-upload-modal', require('./components/modals/FilesUploadModal.vue').default);
Vue.component('new-page-modal', require('./components/modals/NewPageModal.vue').default);
Vue.component('video-player-modal', require('./components/modals/VideoPlayerModal.vue').default);
Vue.component('bulk-video-upload-modal', require('./components/modals/BulkVideoUploadModal.vue').default);
Vue.component('new-api-key-modal', require('./components/modals/NewApiKeyModal.vue').default);
Vue.component('preview-modal', require('./components/modals/PreviewModal.vue').default);

// Helpers
Vue.component('show-hide', require('./components/helpers/ShowHide.vue').default);
// Vue.component('show-hide-text-logo', require('./components/helpers/ShowHideTextLogo.vue').default);

// Widgets
Vue.component('activity-widget', require('./components/widgets/ActivityWidget.vue').default);
Vue.component('dashboard-stats-widget', require('./components/widgets/DashboardStatsWidget.vue').default);

// Integrations
Vue.component('integrations-modal', require('./components/integrations/IntegrationsModal.vue').default);
Vue.component('other-integrations', require('./components/integrations/other/Integrations.vue').default);

// Cart integrations
Vue.component('cart-integration-modal', require('./components/integrations/cart/NewIntegrationModal.vue').default);
Vue.component('cart-integrations', require('./components/integrations/cart/Integrations.vue').default);

// Email integrations
Vue.component('email-integrations', require('./components/integrations/email/Integrations.vue').default);

// Coupons
Vue.component('coupons', require('./components/coupons/List.vue').default);
Vue.component('used-coupons', require('./components/coupons/Used.vue').default);

// Comments
Vue.component('all-comments', require('./components/comments/AllComments.vue').default);
Vue.component('post-comments', require('./components/comments/PostComments.vue').default);
Vue.component('member-comments', require('./components/comments/MemberComments.vue').default);
Vue.component('add-comment-modal', require('./components/comments/AddCommentModal.vue').default);
Vue.component('reply-to-comment-modal', require('./components/comments/ReplyToCommentModal.vue').default);

// New MVP components
Vue.component('hubs', require('./components/hubs/Hubs.vue').default);
Vue.component('page-header-tabs', require('./components/PageHeaderTabs.vue').default);
Vue.component('new-hub-modal', require('./components/hubs/NewHubModal.vue').default);
Vue.component('share-hub-modal', require('./components/hubs/ShareHubModal.vue').default);
Vue.component('emoji', require('./components/fields/Emoji.vue').default);
Vue.component('hub-category', require('./components/fields/HubCategory.vue').default);
Vue.component('hub-features', require('./components/fields/HubFeatures.vue').default);
Vue.component('hub-categories', require('./components/hub-categories/HubCategories.vue').default);
Vue.component('hub-drip-schedule', require('./components/drips/HubDripSchedule.vue').default);
Vue.component('memberships', require('./components/memberships/Memberships.vue').default);
Vue.component('new-membership-modal', require('./components/memberships/NewMembershipModal.vue').default);
Vue.component('membership-hubs', require('./components/memberships/MembershipHubs.vue').default);
Vue.component('share-membership-modal', require('./components/memberships/ShareMembershipModal.vue').default);
Vue.component('member-hubs', require('./components/members/MemberHubs.vue').default);
Vue.component('member-memberships', require('./components/members/MemberMemberships.vue').default);
Vue.component('membership-cart-integrations', require('./components/integrations/cart/MembershipIntegrations.vue').default);
Vue.component('membership-email-integrations', require('./components/integrations/email/MembershipIntegrations.vue').default);
Vue.component('promo-offer-location', require('./components/promo-offers/PromoOfferLocation.vue').default);
Vue.component('promo-offers', require('./components/promo-offers/PromoOffers.vue').default);
Vue.component('help-button', require('./components/HelpButton.vue').default);

// Posts
Vue.component('posts', require('./components/posts/Posts.vue').default);
Vue.component('member-posts', require('./components/posts/MemberPosts.vue').default);
Vue.component('post-likes-list', require('./components/posts/PostLikesList.vue').default);