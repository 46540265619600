<template>
    <div class="expandable">
        <div class="expand-header m-b-3">
            <toggle
                v-bind:label="label"
                v-bind:name="fieldName"
                v-bind:toggled.sync="expanded"
            ></toggle>
        </div>
        <transition name="show">
            <div v-if="expanded" class="expand-content form">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            initialValue: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                required: true
            },
            fieldName: {
                type: String,
                default: 'toggle'
            }
        },
        data() {
            return {
                expanded: this.initialValue
            }
        }
    }
</script>