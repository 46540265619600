<template>
    <div class="row m-t-5 row-center">
        <div class="column large-8 medium-10 small-11 xsmall-12">
            <video-player v-bind:url="video"></video-player>
        </div>
    </div>
</template>
<script>
    import VideoPlayer from '../../../VideoPlayer.vue';
    import Block from './Block.vue';

    export default {
        extends: Block,
        components: {
            VideoPlayer
        },
        data() {
            return {
                video: _.get(this.block.data, 'url')
            }
        }
    }
</script>