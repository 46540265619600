<template>
    <div>
        <div v-bind:class="{'table-group': paginator.lastPage > 1}">
            <div class="table-header row row-collapse row-middle br-bottom m-b-5">
                <div class="column text-left">
                    <span v-if="loading" class="c-medium m-r-5 f-s-3">{{ trans('Loading orders...') }}</span>
                    <span v-else class="c-medium m-r-5 f-s-3">{{ trans(':num orders', {'num': items.length}) }}</span>
                </div>
            </div>
            <div class="table-overflow-set">
                <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                    <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
                <table v-if=" ! loading && items.length > 0" class="table">
                    <thead>
                        <tr>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 120px;">{{ trans('Date') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Membership') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Member') }}</th>
                            <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Plan') }}</th>
                            <th class="text-right br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 120px;">{{ trans('Order Total') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" v-bind:key="index">
                            <td v-bind:data-heading="trans('Date')">
                                {{ purchaseDate(item) }}
                            </td>
                            <td v-bind:data-heading="trans('Membership')">
                                {{ item.membership_name }}
                            </td>
                            <td v-bind:data-heading="trans('Member')">
                                {{ item.member_full_name }}
                            </td>
                            <td v-bind:data-heading="trans('Plan')">
                                {{ item.pricing_plan_name }}
                            </td>
                            <td class="text-right" v-bind:data-heading="trans('Order Total')">
                                {{ item.formatted_price_including_tax }}
                                <span class="block f-s-1 c-medium">{{ trans(':discount off', {'discount': coupon.discount}) }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p v-if=" ! loading && items.length === 0" class="c-muted m-t-6 text-center">{{ trans("Coupon hasn't been used!") }}</p>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:full-width="true"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>
    </div>
</template>
<script>
import Paginator from '../Paginator.vue';

export default {
    components: {
        Paginator
    },
    props: {
        coupon: {
            type: Object
        },
        orders: {
            type: Object
        },
    },
    data() {
        return {
            items: _.get(this.orders, 'data', []),
            paginator: {
                to: _.get(this.orders, 'to', 1),
                from: _.get(this.orders, 'from', 1),
                total: _.get(this.orders, 'total', 1),
                lastPage: _.get(this.orders, 'last_page', 1),
                currentPage: _.get(this.orders, 'current_page', 1),
            },
            loading: false,
        }
    },
    methods: {
        changePage(page) {
            this.loading = true;

            this.loadOrders(page);
        },
        loadOrders(page) {
            return window.axios.get(Kourses.apiBaseUrl + '/coupons/' + this.coupon.encoded_id + '/orders?page=' + page).then(this.showOrders);
        },
        showOrders(response) {
            this.paginator = {
                to: response.data.meta.to,
                from: response.data.meta.from,
                total: response.data.meta.total,
                lastPage: response.data.meta.last_page,
                currentPage: response.data.meta.current_page
            };
            this.items = response.data.data;
            this.loading = false;
        },
        purchaseDate(order) {
            return (new Date(order.created_at)).toLocaleDateString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            });
        },
    },
}
</script>