<template>
    <div v-if=" ! removed" class="card br p-4 p-l-4 p-r-4 m-b-4">
        <div class="card-content row row-collapse row-middle">
            <div class="column">
                <a class="i-container i-filled-dark" v-bind:href="page.links.edit">
                    <h4 class="card-title c-primary f-s-4 m-b-0">
                        {{ page.title }}
                        <!-- /icons/edit.svg -->
                        <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                        <span v-if="page.status === 'draft'" class="badge c-muted-bg c-bright f-w-bold f-caps m-r-4 m-l-2">{{ trans('Draft') }}</span>
                    </h4>
                </a>
                <p class="f-s-3 c-muted">{{ page.url }}</p>
            </div>
            <div class="text-right">
                <a class="btn btn-text btn-text--muted width-auto-min" v-bind:href="page.links.preview" target="_blank">{{ trans('Preview') }}</a>
                <publish-button
                    v-bind:publish-url="page.links.publish"
                    v-bind:unpublish-url="page.links.publish"
                    class-name="btn btn-text btn-text--accent"
                    v-bind:published="page.status === 'published' ? true : false"
                    v-on:update:status="toggleStatus"
                    v-bind:publish-label="trans('Publish')"
                    v-bind:unpublish-label="trans('Unpublish')"
                ></publish-button>
                <span class="tooltip animate">
                    <a class="i-container i-filled-dark" href="#delete-file" v-on:click.prevent="removePage()">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                page: this.data,
                csrfToken: window.Laravel.csrfToken,
                removed: false
            }
        },
        methods: {
            toggleStatus(status) {
                this.page.status = status;
            },
            removePage() {
                this.$dialog.confirm(this.trans("If you delete it, it won't be there any more."), {loader: true})
                    .then((dialog) => {
                        window.axios.delete(Kourses.apiBaseUrl + '/pages/' + this.page.encoded_id).then((response) => {
                            this.removed = true;
                            dialog.close();
                            this.$toast.success(this.trans('Page removed!'));
                        });
                    });
            },
        }
    }
</script>