<template>
    <div>
        <component
            v-if="pageHeaderBlock"
            v-bind:block="pageHeaderBlock.block"
            v-bind:index="pageHeaderBlock.index"
            v-bind:is="pageHeaderBlock.type"
            v-bind:key="pageHeaderBlock.index"
        ></component>

        <div v-if="itemsWithNavbarTitles && itemsWithNavbarTitles.length" class="page-header br-bottom m-b-5 cd-secondary-nav">
            <div class="row row-middle cd-secondary-nav-group">
                <div class="column cd-secondary-nav-items">
                    <nav class="nav-tab">
                        <a class="nav-item f-s-4 m-r-3 m-l-3 f-w-bold p-l-0 nav-item--active" href="#jump">{{ trans('Overview') }}</a>
                        <a v-for="block in itemsWithNavbarTitles" class="nav-item f-s-4 m-r-3 m-l-3 f-w-bold" v-bind:href="'#jump-' + block.index" v-scroll-to="'#jump-' + block.index" v-bind:key="block.index">{{ getBlockNavbarTitle(block) }}</a>
                    </nav>
                </div>
            </div>
        </div>

        <div class="row m-t-5">
            <div class="page-content column large-8 medium-7 small-12 xsmall-12">
                <component
                    v-for="block in items"
                    v-bind:block="block.block"
                    v-bind:index="block.index"
                    v-bind:is="block.type"
                    v-bind:key="block.index"
                ></component>
            </div>
            <div class="page-aside column large-4 medium-5 small-12 xsmall-12">
                <div class="course-overview" id="sticky">
                    <div id="stickyOffset">
                        <div class="br dropshadow-medium m-b-6 c-bright-bg">
                            <pricing-plans
                                v-bind:button-label="salesPage.buy_button_label"
                                v-bind:plans="pricingPlans"
                            ></pricing-plans>
                            <div v-if="highlights" class="m-l-5 m-r-5 m-b-5">
                                <div class="br-bottom br-c--lighter m-b-4 p-b-0"></div>
                                <h4 class="f-w-bold f-s-4">{{ highlights.heading }}</h4>
                                <div v-for="(highlight, index) in highlights.items" class="m-b-3" v-bind:key="index">
                                    <span v-if="iconExists(highlight.icon)" class="i-container i-filled-theme i-container-r-1" v-html="getIcon(highlight.icon)"></span>
                                    {{ highlight.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { EventBus } from '../../event-bus.js';
    import PageContent from './PageContent.vue';
    import PricingPlans from './PricingPlans.vue';

    import Faqs from './blocks/renders/sales-page/Faqs.vue';
    import Text from './blocks/renders/sales-page/Text.vue';
    import Image from './blocks/renders/sales-page/Image.vue';
    import Video from './blocks/renders/sales-page/Video.vue';
    import Author from './blocks/renders/sales-page/Author.vue';
    import Bullets from './blocks/renders/sales-page/Bullets.vue';
    import Memberships from './blocks/renders/sales-page/Memberships.vue';
    import PageHeader from './blocks/renders/sales-page/PageHeader.vue';
    import Testimonials from './blocks/renders/sales-page/Testimonials.vue';
    import PricingPlansBlock from './blocks/renders/sales-page/PricingPlans.vue';
    import CourseContent from './blocks/renders/sales-page/CourseContent.vue';
    import PrimaryButton from './blocks/renders/sales-page/PrimaryButton.vue';
    import FeaturedMemberships from './blocks/renders/sales-page/FeaturedMemberships.vue';

    export default {
        extends: PageContent,
        components: {
            'faq': Faqs,
            'textb': Text,
            'author': Author,
            'imageb': Image,
            'videob': Video,
            'bullet': Bullets,
            'pricing': PricingPlansBlock,
            'memberships': Memberships,
            'page_header': PageHeader,
            'testimonial': Testimonials,
            'course_content': CourseContent,
            'primary_button': PrimaryButton,
            'featured_membership': FeaturedMemberships,
            'pricing-plans': PricingPlans
        },
        data() {
            return {
                icons: _.get(this.data, 'icons', null),
                salesPage: _.get(this.data, 'salesPage', null),
                pricingPlans: _.get(this.data, 'plans', null),
                highlights: _.get(this.data, 'highlights', null),
                itemsWithNavbarTitles: this.blocks.filter((block) => {
                    return block.type !== 'page_header' && _.get(block.block.data, 'navbar_title', '') != '';
                })
            }
        },
        methods: {
            iconExists(icon) {
                return _.has(this.icons, icon);
            },
            getIcon(icon) {
                return this.icons[icon];
            },
            getBlockNavbarTitle(block) {
                return _.get(block.block.data, 'navbar_title');
            },
            resetNavbarTitles() {
                this.itemsWithNavbarTitles = this.items.filter((block) => {
                    return _.get(block.block.data, 'navbar_title', '') != '';
                });
            }
        },
        mounted() {
            var $vm = this;

            EventBus.$on('block:add', (block) => {
                $vm.resetNavbarTitles();
            });

            EventBus.$on('block:remove', (index) => {
                $vm.resetNavbarTitles();
            });

            EventBus.$on('block:update:order', (newIndex, oldIndex) => {
                $vm.resetNavbarTitles();
            });

            EventBus.$on('block:update:value', (index, property, value) => {
                if (property === 'navbarTitle') {
                    Vue.set($vm.items[_.findIndex($vm.items, {index: index})].block.data, 'navbar_title', value);
                    $vm.resetNavbarTitles();
                }
            });
        }
    }
</script>