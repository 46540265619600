<template>
    <div class="row row-middle row-full row-collapse">
        <div class="columns">
            <div class="cover-featured cover-container uploads uploads-r-1_3">
                <div class="cover row row-middle row-full upload-content">
                    <div class="cover-image cover-image-overlay">
                        <div class="cover-overlay" v-bind:style="{'backgroundColor': backgroundColor}"></div>
                        <img v-if="image &&  image.url" v-bind:src="resizedImageUrl" v-on:error="e => { resizedImageUrl = image.url }" v-bind:alt="title" />
                    </div>
                    <div class="cover-content column text-center p-t-6 p-b-6 xs-border-bottom sm-border-bottom md-border-bottom-0">
                        <div class="row row-collapse row-center">
                            <div class="columns medium-7">
                                <h2 class="cover-title f-s-10 f-w-bold c-bright m-b-2">{{ title }}</h2>
                                <p v-if="subtitle" class="cover-description f-s-6 c-bright">{{ subtitle }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import PageHeader from '../page/PageHeader.vue';

    export default {
        extends: PageHeader
    }
</script>