<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Edit module') }}</h3>
        </div>
        <div>
            <div class="form-group" v-bind:class="{'has-error': errors.has('name')}">
                <label>
                    {{ trans('Module Name') }}
                    <input class="input__field" type="text" v-model="module.name">
                </label>
            </div>
            <div v-if="errors.has('name')" class="form-help">
                {{ errors.get('name') }}
            </div>
        </div>
        <div>
            <div class="form-group form-group-simple hl-txt" v-bind:class="{'has-error': errors.has('description')}">
                <label>
                    {{ trans('Module Description') }}
                    <textarea v-model="module.description" class="m-t-1 input__field" rows="2"></textarea>
                </label>
            </div>
            <div v-if="errors.has('description')" class="form-help">{{ errors.get('description') }}</div>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveModule" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Save') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-outline btn-outline--alert" v-on:click="deleteModule" v-bind:class="{'btn-loading': loadingDelete}">
                        {{ trans('Delete') }}
                        <div v-if="loadingDelete" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-alert" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from './Modal.vue';
    import Errors from '../../errors.js';

    export default {
        components: {
            Modal
        },
        data() {
            return {
                module: {
                    name: '',
                    description: '',
                },
                index: null,
                show: false,
                loading: false,
                loadingDelete: false,
                errors: new Errors()
            }
        },
        methods: {
            saveModule() {
                this.loading = true;
                this.$emit('save-module', this.module, this.index);
            },
            deleteModule() {
                this.loadingDelete = true;
                this.$emit('delete-module', this.module, this.index);
            },
            reset() {
                this.module = {
                    name: '',
                    description: '',
                };
                this.index = null;
                this.loading = false;
                this.loadingDelete = false;
                this.errors.clear();
            }
        }
    }
</script>