<template>
    <div class="slat m-b-4">
        <div class="br p-t-3 p-b-3" v-bind:class="{ 'c-accent-bg-light': author.is_admin, 'br-c--theme': isPending }">
            <div class="row row-compress row-middle">
                <!-- <div class="slat-controls m-vertical-none m-l-2">
                    <span class="control-group">
                        <div class="inline">
                            <label class="control control-checkbox block c-muted f-s-3 m-b-0 p-l-1">
                                <input type="checkbox">
                                <div class="control-indicator control-checkbox-indicator"></div>
                            </label>
                        </div>
                    </span>
                </div> -->
                <div v-if=" ! author.is_admin" class="slat-figure column">
                    <a v-bind:href="memberShowLink(author)">
                        <div class="avatar">
                            <img v-if="author.image_url" class="avatar-image avatar-medium" v-bind:src="author.image_url">
                            <avatar-img v-else class="avatar-image avatar-medium" v-bind:src="getGravatarUrl(author.gravatar_hash)"></avatar-img>
                        </div>
                    </a>
                </div>
                <div class="slat-content column">
                    <a v-if=" ! author.is_admin" class="f-s-2 c-primary f-w-bold inline" v-bind:href="memberShowLink(author)">{{ author.full_name }}</a>
                    <div v-else class="f-s-2 c-primary f-w-bold block">{{ author.full_name }} <span class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-l-2">{{ trans('Author') }}</span></div>

                    <div class="f-s-1 m-b-2 c-muted">{{ formatDatetime(comment.created_at) }}</div>

                    <div class="f-s-2 m-b-0 c-primary" v-html="comment.text"></div>
                </div>
                <div v-if=" ! isDeleted" class="slat-aside text-right m-r-4">
                    <a v-if=" ! isPending" v-bind:href="previewUrl(comment.breadcrumbs.commentable, comment)" class="m-r-1" target="_blank">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M15.99 10.042c.002-.073-.012-.142-.025-.212-.009-.044-.002-.088-.017-.132C14.713 5.991 11.519 3.5 8 3.5S1.287 5.991.052 9.698a1 1 0 1 0 1.897.633C2.911 7.441 5.344 5.5 8 5.5c2.541 0 4.863 1.788 5.904 4.473a11.21 11.21 0 0 1-.661.94 1 1 0 1 0 1.598 1.203c.417-.555.807-1.081 1.049-1.648l.007-.015c.011-.024.024-.048.034-.072.018-.045.014-.092.024-.139.016-.066.033-.13.035-.2z"/><path d="M8 5.515c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"/></g></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('See comment') }}</span>
                        </span>
                    </a>
                    <a v-if="isPending" v-on:click.prevent="approve" href="#approve" class="m-r-1">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- /icons/s-guarantee.svg -->
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M14.054 5.965a.998.998 0 0 0-.752-.341h-3.128c.685-1.601.642-3.314.639-3.39 0-1.301-1.137-2.36-2.534-2.36-1.298 0-2.454 1.019-2.69 2.37-.316 1.81-1.5 2.458-2.645 3.085a9.685 9.685 0 0 0-.938.56C.247 7.139-.297 10.279.194 12.594a1.001 1.001 0 0 0 1.957-.416c-.411-1.927.207-4.087 1.012-4.658.207-.147.464-.285.741-.437 1.263-.691 3.171-1.737 3.654-4.495.067-.387.397-.714.72-.714.306 0 .534.19.535.392.02.695-.151 2.698-1.177 3.611-.006.007-.009.017-.016.023-.056.053-.094.118-.137.182-.029.045-.068.085-.09.135-.023.051-.028.11-.042.166-.019.071-.041.14-.043.213-.001.01-.006.018-.006.028 0 .05.021.093.028.142.011.072.017.143.043.212.026.068.068.125.107.185.027.042.04.089.074.127.008.007.017.01.024.017.052.055.116.093.18.135.046.031.087.07.136.092.052.023.109.028.165.042.072.018.141.041.215.043.01 0 .018.005.027.005h3.85c-.179 1.223-.485 3.2-.811 4.712-.2.93-1.002 1.534-2.043 1.538-5.059.02-7.689.017-8.051.005a1 1 0 0 0-.649 1.831c.213.14.275.181 3.261.181 1.239 0 2.981-.007 5.446-.017 1.987-.007 3.591-1.26 3.99-3.118.521-2.424.978-5.856.997-6.001a.99.99 0 0 0-.237-.79z"/></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Approve') }}</span>
                        </span>
                    </a>
                    <a v-if="allowReplies" v-on:click.prevent="reply" href="#reply" class="m-r-1">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- https://www.svgrepo.com/svg/352397/reply -->
                                <svg class="icon i-size-tiny inline" width="16" height="16" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"/></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Reply') }}</span>
                        </span>
                    </a>
                    <a v-on:click.prevent="deleteComment" href="#delete-comment">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- /icons/s-trash.svg -->
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Delete') }}</span>
                        </span>
                    </a>
                </div>
            </div>
            <slot name="footer"></slot>
        </div>
        <child-comments v-bind:parent-comment="comment"></child-comments>
    </div>
</template>
<script>
import { COMMENT_STATUS_DELETED, COMMENT_STATUS_PENDING } from '../../constants';
import { memberShowLink } from '../../routes';
import { getGravatarUrlFromHash } from '../../helpers';
import AvatarImg from '../AvatarImg.vue';

export default {
    components: {
        AvatarImg,
        ChildComments: () => import('./ChildComments.vue'), // Done this way because of the circular reference issue - https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    },
    props: {
        comment: {
            type: Object,
            required: true,
        },
        author: {
            type: Object,
        },
        allowReplies: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        isPending() {
            return this.comment.status === COMMENT_STATUS_PENDING;
        },
        isDeleted() {
            return this.comment.status === COMMENT_STATUS_DELETED;
        }
    },
    methods: {
        getGravatarUrl(hash) {
            return getGravatarUrlFromHash(hash);
        },
        memberShowLink(member) {
            return memberShowLink(member);
        },
        deleteComment() {
            this.$emit('comment:delete');
        },
        reply() {
            this.$emit('comment:reply');
        },
        approve() {
            this.$emit('comment:approve');
        },
        formatDatetime(timestamp) {
            return new Date(timestamp).toLocaleString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
        },
        previewUrl(content, comment) {
            return content.preview_url + encodeURIComponent('?comment=' + comment.encoded_id + '#comments');
        }
    }
}
</script>