<template>
    <button type="button" v-bind:class="className" v-on:click="trigger">
        <slot></slot>
    </button>
</template>
<script>
    import { EventBus } from '../event-bus.js';

    export default {
        props: {
            className: {
                type: String,
                default: 'btn btn-primary'
            },
            eventName: {
                type: String,
                required: true
            }
        },
        methods: {
            trigger() {
                EventBus.$emit(this.eventName);
            }
        }
    }
</script>