<template>
    <div class="card br m-b-4">
        <div class="card-content card-content-item row row-collapse row-middle p-t-3 p-4">
            <div class="column">
                <div class="row row-middle">
                    <h4 class="card-title c-primary f-s-4 small-12 m-b-1">{{ module.name }}</h4>
                    <p v-if=" ! editMode" class="c-medium-tint f-s-3" v-html="dripSettings"></p>
                    <div v-if="editMode">
                        <lesson-release
                            v-for="lesson in module.lessons"
                            v-bind:lesson="lesson"
                            v-bind:key="lesson.encoded_id"
                            v-bind:field-name="`${fieldName}[lessons][${lesson.encoded_id}]`"
                            v-on:update:days="updateDays"
                        ></lesson-release>
                    </div>
                </div>
            </div>
            <div v-if=" ! editMode && module.lessons.length > 0" class="text-right">
                <button type="button" class="btn btn-text btn-text--accent" v-html="days > 0 ? trans('Edit Schedule') : trans('Set Schedule')" v-on:click="editMode = ! editMode"></button>
            </div>
        </div>
        <div v-if="editMode && showEmailSettings" v-bind:class="{'expandable-open': ! emailEnabled}" class="card-content-item expandable">
            <div class="expand-header row row-center p-b-1">
                <div class="columns p-t-1">
                    <div class="br-top"></div>
                </div>
                <div class="p-t-2 p-b-2">
                    <div class="control-group text-center">
                        <div class="form-group-addon inline">
                            <label class="control control-checkbox block c-accent f-s-4 m-b-0" href="#ba" data-toggle="toggle">
                                <span class="m-n-t-micro">{{ trans('Set an Email Notification') }}</span>
                                <span class="i-container i-filled-accent">
                                    <!-- /icons/open-alt.svg -->

                                    <svg class="icon icon-expand i-size-small m-l-1" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.664 7.253L2.336 8.747 12 17.338l9.664-8.59-1.328-1.495L12 14.662z" class="icon"/></svg>
                                </span>
                                <input type="checkbox" v-bind:name="fieldName + '[email_enabled]'" v-model="emailEnabled">
                                <div class="control-indicator control-checkbox-indicator m-t-micro"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <transition name="drip-email-slide">
                <div v-if="emailEnabled" class="expand-content form">
                    <div class="p-b-4 p-l-5 p-r-5">
                        <div class="form-group">
                            <input-with-vars
                                v-bind:label="trans('Email Subject')"
                                v-bind:value.sync="emailSubject"
                                placeholder="Subject"
                                v-bind:name="fieldName + '[email_subject]'"
                                v-bind:required="true"
                                v-bind:variables="mergeVars"
                            ></input-with-vars>
                        </div>
                        <div class="form-group form-group-simple">
                            <label class="m-b-2">{{ trans('Email Body') }}</label>
                            <rich-text-editor
                                id="email_body"
                                v-bind:name="fieldName + '[email_body]'"
                                v-bind:content="emailBody"
                                v-bind:variables="mergeVars"
                            ></rich-text-editor>
                        </div>
                        <div class="form-help p-t-3">{{ trans('E-mails sent at 11 am US Eastern time everyday.') }}</div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import ModuleDripMode from './ModuleDripMode.vue';
import LessonRelease from './LessonRelease.vue';
import Errors from '../../errors.js';

export default {
    extends: ModuleDripMode,
    components: {
        LessonRelease
    },
    data() {
        let defaultBody = `Hi {firstname},

{lessonname} is now available inside {hubtitle}!

Click the link below to view this new lesson!

<a href="{lessonurl}">View {lessonname}</a>

Thanks!​`;

        return {
            days: this.module.lessons.reduce((carry, lesson) => {
                return carry + parseInt(_.get(lesson.schedule, 'days', 0), 10);
            }, 0),
            emailBody: this.module.lessons.length > 0
                ? _.get(this.module.lessons[0].schedule, 'email_body', defaultBody)
                : defaultBody,
            emailEnabled: this.module.lessons.length > 0
                ? (_.get(this.module.lessons[0].schedule, 'email_enabled', '') === 'on' ? true : false)
                : false,
            emailSubject: this.module.lessons.length > 0
                ? _.get(this.module.lessons[0].schedule, 'email_subject', this.trans('{hubtitle} - {lessonname} Now Available'))
                : this.trans('{hubtitle} - {lessonname} Now Available'),
            errors: new Errors(),
            editMode: false,
            mergeVars: {
                firstname: this.trans("Member first name"),
                hubtitle: this.trans("Hub title"),
                huburl: this.trans("Hub URL"),
                lastname: this.trans("Member last name"),
                lessonname: this.trans("Lesson name"),
                lessonurl: this.trans("Lesson URL"),
                sitename: this.trans("Site name"),
                siteurl: this.trans("Site URL"),
            },
        }
    },
    computed: {
        dripSettings() {
            if (this.days > 0) {
                return this.trans("<strong class='bold c-primary'>:num_lessons</strong> published lessons. One or more lessons have altered drip release days", {'num_lessons': this.module.lessons.length, 'days': this.days});
            }

            return this.trans(':num_lessons published lessons', {'num_lessons': this.module.lessons.length});
        },
    },
    methods: {
        updateDays(number) {
            this.days += number;
        },
    },
}
</script>
