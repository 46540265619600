<template>
    <div class="row row-middle row-full row-collapse">
        <div class="columns ps--r">
            <featured-memberships v-bind:items="items"></featured-memberships>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';
    import FeaturedMemberships from '../../../FeaturedMemberships.vue';

    export default {
        extends: Block,
        components: {
            FeaturedMemberships
        },
        data() {
            return {
                items: _.get(this.block.data, 'items', [])
            }
        }
    }
</script>