<template>
    <div>
        <select class="form-control" v-bind:name="name" v-model="selected">
            <option value="">{{ selectItemLabel }}</option>
            <option v-for="item in items" v-bind:value="item.id" v-bind:key="item.id">{{ item.name }}</option>
            <option value="0">{{ newItemLabel }}</option>
        </select>
        <slot v-if="selected == '0'"></slot>
    </div>
</template>
<script>
    export default {
        props: [
            'name',
            'items',
            'selected',
            'newItemLabel',
            'selectItemLabel'
        ]
    }
</script>