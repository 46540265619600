<template>
    <div>
        <p v-if="items.length === 0">{{ trans('There are no authorized integrations yet!') }}</p>
        <div v-else v-for="(integration, index) in items" class="row row-compress-indent row-compress row-top p-b-4" v-bind:key="index">
            <div class="slat-figure--medium column small-12">
                <img v-bind:src="integration.icon" class="br br-c--light integration-icon">
            </div>
            <div class="slat-content column">
                <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                <p class="f-s-3 c-muted m-b-0">{{ integration.integrations }}</p>
            </div>
            <div class="slat-aside text-right m-r-4 m-t-2">
                <button v-on:click="disconnectIntegration(integration, index)" type="button" class="btn btn-secondary">
                    {{ trans('Disconnect') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import { EventBus } from '../../../event-bus.js';

    export default {
        props: {
            integrations: {
                type: [Array, Object]
            }
        },
        data() {
            return {
                items: _.get(this, 'integrations', [])
            }
        },
        methods: {
            disconnectIntegration(integration, index) {
                this.$dialog.confirm(this.trans("If you disconnect it, permissions wouldn't be automatically added on purchase."), {loader: true})
                    .then((dialog) => {
                        window.axios.delete(Kourses.apiBaseUrl + '/integrations/carts/' + integration.provider).then(() => {
                            this.items.splice(index, 1);
                            EventBus.$emit('integration:disconnected', integration, 'cart');
                            dialog.close();
                        });
                    });
            }
        },
        created() {
            let $vm = this;

            EventBus.$on('cart:integrations:update', integration => {
                integration.integrations = $vm.trans('This integration does not have any connected membership');
                $vm.items.push(integration);
            });

            EventBus.$on('cart:integrations:disconnected', integration => {
                const index = this.items.findIndex((item) => item.provider === integration.provider);

                if (index < 0) {
                    return;
                }

                this.items.splice(index, 1);
                EventBus.$emit('integration:disconnected', integration, 'cart');
            });
        },
    }
</script>