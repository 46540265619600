<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="row row-middle row-full row-collapse">
            <div class="columns ps--r">
                <featured-memberships v-bind:items="items"></featured-memberships>
            </div>
        </div>
    </div>
</template>
<script>
    import FeaturedMemberships from '../page/FeaturedMemberships.vue';

    export default {
        extends: FeaturedMemberships
    }
</script>