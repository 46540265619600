<template>
    <div>
        <div v-if="tags.length === 0" class="categories-group column small-12 medium-9">
            <div class="text-center p-t-1">
                <h2 class="card-title f-s-5 f-w-regular m-b-2">{{ trans('No Tags added yet') }}</h2>
            </div>
        </div>
        <div v-else class="categories-group">
            <div v-for="(tag, index) in tags" v-bind:key="tag" class="card br p-4 p-l-5 p-r-5 row row-middle row-collapse m-b-4">
                <div class="column">
                    <a class="i-container i-filled-dark cu-p" v-on:click="showModalEdit(tag, index)">
                        <h4 class="f-s-4 m-b-0 c-primary">
                            #{{ tag }}
                            <!-- /icons/edit.svg -->
                            <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                        </h4>
                    </a>
                </div>
                <div class="text-right">
                    <span class="tooltip animate">
                        <a class="i-container i-filled-dark" href="#delete-tag" v-on:click.prevent="deleteTag(tag, index)">
                            <!-- /icons/s-trash.svg -->
                            <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                        </a>
                        <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                    </span>
                </div>
            </div>
        </div>
        <edit-tag-modal
            v-on:close="modalEdit.show = false"
            v-on:save-tag="editTag"
            v-on:delete-tag="deleteTag"
            ref="edit-tag-modal"
        ></edit-tag-modal>
    </div>
</template>
<script>
import EditTagModal from './modals/EditTagModal.vue';

export default {
    components: {
        EditTagModal
    },
    props: {
        existingTags: {
            type: Array,
        },
    },
    data() {
        return {
            tags: _.get(this, 'existingTags', []),
        }
    },
    methods: {
        showModalEdit(tag, index) {
            this.modalEdit.tag = tag;
            this.modalEdit.index = index;
            this.modalEdit.show = true;
        },
        editTag(tag, index) {
            window.axios
                .put(window.Kourses.apiBaseUrl + '/tags/' + this.tags[index], {tag})
                .then(() => {
                    this.tags.splice(index, 1, tag);
                    this.modalEdit.show = false;
                    this.modalEdit.reset();
                })
                .catch((error) => {
                    this.modalEdit.setErrors(error.response.data);
                });
        },
        deleteTag(tag, index) {
            window.axios
                .delete(window.Kourses.apiBaseUrl + '/tags/' + tag)
                .then(() => {
                    this.tags.splice(index, 1);
                    this.modalEdit.show = false;
                    this.modalEdit.reset();
                });
        },
    },
    mounted() {
        this.modalEdit = this.$refs['edit-tag-modal'];
    },
}
</script>