<template>
    <div v-bind:class="sizeClass" class="chartdonut chartdonut_animation">
        <svg v-bind:width="width" v-bind:height="height"><g><circle cx="50%" cy="50%" v-bind:r="radius" stroke-width="3" stroke="#02DCAA" fill="none" v-bind:stroke-dasharray="dashArray"></circle></g></svg>
    </div>
</template>
<script>
    export default {
        props: {
            percentage: {
                type: Number,
                required: true
            },
            size: {
                type: String,
                default: 'small'
            }
        },
        data() {
            return {
                sizeClass: this.size === 'large' ? 'chartdonut--large m-a' : 'chartdonut--small',
                width: this.size === 'large' ? 53 : 33,
                height: this.size === 'large' ? 53 : 33,
                radius: this.size === 'large' ? 23 : 13
            }
        },
        computed: {
            dashArray() {
                const c = Math.PI * 2 * this.radius;
                const value = c * (this.percentage / (100 * 1));
                return value + ' ' + c;
            }
        }
    }
</script>