<template>
<div>
    <div class="slideshow slideshow-tips">
        <div v-for="(item, index) in items" v-bind:key="index" class="slide m-1 card br c-bright-bg p-4 p-b-6 m-b-4 dropshadow-small" v-show="index === activeIndex">
            <div class="card-figure m-b-3">
                <span class="i-container i-filled-theme">
                    <!-- /icons/bulb.svg -->
                    <svg  class="icon i-size-small" width="18" height="24" viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M-3 0h24v24H-3z"/><path d="M0 9a9 9 0 0 1 18 0 8.994 8.994 0 0 1-5 8.059V20H5v-2.941A8.994 8.994 0 0 1 0 9zm11 6.74l.625-.254A6.996 6.996 0 0 0 16 9 7 7 0 0 0 2 9a6.996 6.996 0 0 0 4.375 6.486L7 15.74V18h4v-2.26z" fill="#09D4BF" fill-rule="nonzero"/><path fill="#09D4BF" fill-rule="nonzero" d="M7 22v-4H5v6h8v-6h-2v4zM6 9a3 3 0 0 1 3-3h1V4H9a5 5 0 0 0-5 5v1h2V9z"/></g></svg>
                </span>
            </div>
            <div class="card-content">
                <h4 class="card-title c-primary f-s-4 m-b-2">{{ item.title }}</h4>
                <p class="c-primary f-s-3 m-b-3">{{ item.description }}</p>
                <a class="btn btn-text btn-text--muted" v-bind:href="item.link" target="_blank">{{ item.action }}</a>
            </div>
        </div>
    </div>
    <div class="slideshow-nav text-center">
        <button class="slideshow-pager slideshow-pager--prev p-0 width-auto-min btn btn-small btn-bright" type="button" v-on:click="prevSlide" v-bind:disabled="prevDisabled" v-bind:class="{'btn-disabled': prevDisabled}">
            <span class="i-container i-filled-dark p-2">
                <!-- /icons/circle-left.svg -->
                <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g fill-rule="nonzero" fill="#000" class="icon"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"/><path d="M14.414 8L13 6.586 7.586 12 13 17.414 14.414 16l-4-4z"/></g></g></svg>
            </span>
        </button>
        <span class="slideshow-counter p-2 c-muted f-s-2">{{ counter }}</span>
        <button class="slideshow-pager slideshow-pager--prev p-0 width-auto-min btn btn-small btn-bright" type="button" v-on:click="nextSlide" v-bind:disabled="nextDisabled" v-bind:class="{'btn-disabled': nextDisabled}">
            <span class="i-container i-filled-dark p-2">
                <!-- /icons/circle-right.svg -->
                <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g fill-rule="nonzero" fill="#000" class="icon"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0-2c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"/><path d="M9.586 16L11 17.414 16.414 12 11 6.586 9.586 8l4 4z"/></g></g></svg>
            </span>
        </button>
    </div>
</div>
</template>
<script>
    export default {
        props: {
            items: {
                type: [Array, Object],
                required: true
            },
            loop: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                activeIndex: 0
            }
        },
        computed: {
            counter() {
                return (this.activeIndex + 1) + '/' + this.items.length;
            },
            prevDisabled() {
                return this.loop === false && this.activeIndex === 0;
            },
            nextDisabled() {
                return this.loop === false && this.activeIndex + 1 === this.items.length;
            }
        },
        methods: {
            prevSlide() {
                if (this.activeIndex === 0) {
                    this.activeIndex = this.items.length - 1;
                } else {
                    this.activeIndex--;
                }
            },
            nextSlide() {
                if (this.activeIndex + 1 === this.items.length) {
                    this.activeIndex = 0;
                } else {
                    this.activeIndex++;
                }
            }
        }
    }
</script>