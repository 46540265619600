<template>
    <div class="page-content">
        <div class="row row-collapse row-center">
            <div class="columns">
                <section class="card-grid section row m-t-5 p-b-5">
                    <div class="row medium-12">
                        <div class="column text-left">
                            <span v-if="(memberships.length === 1)" class="c-medium m-r-5 f-s-3">{{ trans('1 Membership') }}</span>
                            <span v-else class="c-medium m-r-5 f-s-3">{{ trans(':count Memberships', {'count': memberships.length}) }}</span>
                        </div>
                    </div>
                    <div class="column">
                        <div v-for="membership in memberships" v-bind:key="membership.id" class="row row-compress row-middle br p-t-4 p-b-4 m-t-4">
                            <div class="slat-content column">
                                <h4 class="card-title c-primary f-s-5 m-b-0">
                                    <a class="c-primary" v-bind:href="membershipEditLink(membership)">
                                        {{ membership.name }}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon m-b-1 m-l-2 inject-svg i-size-small">
                                            <path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"></path>
                                        </svg>
                                    </a>
                                </h4>
                                <p class="f-s-3 m-t-1 c-medium-tint medium-10" v-html="membershipHubs(membership)"></p>
                            </div>
                            <div class="slat-aside text-right m-r-4">
                                <template v-if="membership.pricing_plans.length > 0">
                                    <div v-for="plan in membership.pricing_plans" v-bind:key="plan.id" class="inline row-collapse m-r-4">
                                        <div class="column text-left c-medium-tint f-s-3">
                                            <span v-if="plan.type === 'one-time' || plan.type === 'free'" class="i-container i-filled-dark m-r-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 5 64 64" class="icon i-size-small"><path d="M32,12A20,20,0,1,0,52,32,20,20,0,0,0,32,12ZM30.67,30h2.67a5.78,5.78,0,0,1,5.77,5.77v1a5.78,5.78,0,0,1-5.17,5.74,2,2,0,0,1-3.87,0,5.78,5.78,0,0,1-5.17-5.74,2,2,0,0,1,4,0,1.77,1.77,0,0,0,1.77,1.77h2.67a1.77,1.77,0,0,0,1.77-1.77v-1A1.77,1.77,0,0,0,33.33,34H30.67a5.78,5.78,0,0,1-5.77-5.77v-1a5.78,5.78,0,0,1,5.17-5.74,2,2,0,0,1,3.87,0,5.78,5.78,0,0,1,5.17,5.74,2,2,0,0,1-4,0,1.77,1.77,0,0,0-1.77-1.77H30.67a1.77,1.77,0,0,0-1.77,1.77v1A1.77,1.77,0,0,0,30.67,30Z"/><path d="M32,4A28,28,0,1,0,60,32,28,28,0,0,0,32,4Zm0,52A24,24,0,1,1,56,32,24,24,0,0,1,32,56Z"/></svg>
                                            </span>
                                            <span v-else class="i-container i-filled-dark m-r-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 5 64 64" class="icon i-size-small"><path d="M12,11a2,2,0,0,0-2,2v5a2,2,0,0,0,2,2h5a2,2,0,0,0,0-4h0a22,22,0,0,1,30.59.47,2,2,0,0,0,2.83-2.83A26,26,0,0,0,14,13.25V13A2,2,0,0,0,12,11Z"/><path d="M52,53a2,2,0,0,0,2-2V46a2,2,0,0,0-2-2H47a2,2,0,0,0,0,4h0a22,22,0,0,1-30.62-.44,2,2,0,0,0-2.83,2.83A26,26,0,0,0,50,50.75V51A2,2,0,0,0,52,53Z"/><path d="M46,32A14,14,0,1,0,32,46,14,14,0,0,0,46,32ZM30.25,40h-1a2,2,0,0,1-2-2.29A2.11,2.11,0,0,1,29.44,36h3.5a.66.66,0,0,0,.66-.66v-.68a.67.67,0,0,0-.66-.66H31.06a2,2,0,0,1-.4,0,4.65,4.65,0,0,1-4.25-4.62v-.68a4.66,4.66,0,0,1,3.8-4.57A2,2,0,0,1,33.75,24h1a2,2,0,0,1,2,2.29A2.11,2.11,0,0,1,34.56,28h-3.5a.66.66,0,0,0-.66.66v.68a.67.67,0,0,0,.66.66h1.88a2,2,0,0,1,.4,0,4.65,4.65,0,0,1,4.25,4.62v.68a4.66,4.66,0,0,1-3.8,4.57,2,2,0,0,1-3.55.09Z"/></svg>
                                            </span>

                                            <span v-if="plan.type === 'free'" class="f-w-bold">
                                                {{ trans('Free') }}
                                            </span>
                                            <span v-else>
                                                <span class="f-w-bold">{{ formatPrice(plan.price, plan.currency) }}</span>
                                                <span v-if="plan.type === 'one-time'">/ {{ trans('one-time') }}</span>
                                                <span v-else-if="plan.type === 'subscription'">/ {{ translateInterval(plan.interval) }}</span>
                                                <span v-else-if="plan.type === 'installment-plan'">/ {{ trans('for :installments months', {'installments': plan.interval_count}) }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </template>
                                <div class="inline row-collapse">
                                    <div class="column text-left c-medium-tint f-s-3">
                                        <span class="i-container i-filled-dark m-r-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 5 64 64" class="icon i-size-small">
                                                <g><path d="M24,32a8,8,0,0,0,3.41,6.55,1.07,1.07,0,0,1-.26,1.89A16.23,16.23,0,0,0,16.44,52.2,3.13,3.13,0,0,0,19.5,56h25a3.13,3.13,0,0,0,3.07-3.8A16.23,16.23,0,0,0,36.84,40.44a1.07,1.07,0,0,1-.25-1.89A8,8,0,0,0,40,31.34,8,8,0,0,0,24,32Z"></path><path d="M15.16,28.44A16.23,16.23,0,0,0,4.44,40.2,3.13,3.13,0,0,0,7.5,44h6.85a3.07,3.07,0,0,0,2.37-1.09,20.38,20.38,0,0,1,3.15-3.06,3.14,3.14,0,0,0,.91-3.62A12,12,0,0,1,28,20.72,7.92,7.92,0,0,0,20.23,12a8,8,0,0,0-4.82,14.54A1.07,1.07,0,0,1,15.16,28.44Z"></path><path d="M48.59,26.55A8,8,0,0,0,52,19.34,8,8,0,0,0,36,20c0,.25,0,.49,0,.73a12,12,0,0,1,7.19,15.52,3.14,3.14,0,0,0,.91,3.61,20.38,20.38,0,0,1,3.13,3A3.07,3.07,0,0,0,49.65,44H56.5a3.13,3.13,0,0,0,3.07-3.8A16.23,16.23,0,0,0,48.84,28.44,1.07,1.07,0,0,1,48.59,26.55Z"></path></g>
                                            </svg>
                                        </span>
                                        <a v-bind:href="membership.members_count > 0 ? membersInMembershipShowLink(membership) : null" class="c-medium-tint">
                                            <span class="f-w-bold">{{ membership.members_count }}</span>
                                            {{ membership.members_count !== 1 ? trans('members') : trans('member') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="more-group inline m-l-4">
                                    <membership-dropdown-menu
                                        v-bind:membership="membership"
                                        v-bind:can-delete-memberships="canDeleteMemberships"
                                        v-bind:payments-enabled="paymentsEnabled"
                                        v-bind:integration-types="integrationTypes"
                                    ></membership-dropdown-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
import TranslateInterval from '../../mixins/TranslateInterval.js';
import { membershipEditLink, hubShowLink, membersInMembershipShowLink } from '../../routes.js';
import MembershipDropdownMenu from './MembershipDropdownMenu.vue';

export default {
    mixins: [
        TranslateInterval
    ],
    components: {
        MembershipDropdownMenu
    },
    props: {
        memberships: {
            type: Array,
            required: true
        },
        canDeleteMemberships: {
            type: Boolean,
            default: false
        },
        paymentsEnabled: {
            type: Boolean,
            default: false
        },
        integrationTypes: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        formatPrice(price, currency) {
            price = price > 0 ? parseInt(price, 10) / 100 : 0;
            currency = currency || window.Kourses.reportingCurrency;

            if (price >= 1000) {
                return new Intl.NumberFormat(window.Kourses.locale, {style: 'currency', currency: currency, maximumFractionDigits: 0, minimumFractionDigits: 0}).format(price);
            } else {
                return new Intl.NumberFormat(window.Kourses.locale, {style: 'currency', currency: currency}).format(price);
            }
        },
        membershipHubs(membership) {
            if (membership.hubs.length === 0) {
                return this.trans('No hubs selected!');
            }

            return membership.hubs.reduce((hubsList, hub) => {
                if (hubsList.length > 0) {
                    hubsList += ', ';
                }

                hubsList +=`<a class="c-medium-tint" href="${hubShowLink(hub)}">${hub.title}</a>`;

                return hubsList;
            }, '');
        },
        membershipEditLink(membership) {
            return membershipEditLink(membership);
        },
        membersInMembershipShowLink(membership) {
            return membersInMembershipShowLink(membership);
        },
    },
}
</script>