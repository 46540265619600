<template>
    <div>
        <template v-for="module in modules" class="card br m-b-4">
            <component
                v-bind:is="dripMode"
                v-bind:module="module"
                v-bind:key="module.encoded_id"
            ></component>
        </template>
    </div>
</template>
<script>
import LessonDripMode from './LessonDripMode.vue';
import ModuleDripMode from './ModuleDripMode.vue';
import { EventBus } from '../../event-bus.js';

export default {
    components: {
        'lesson': LessonDripMode,
        'module': ModuleDripMode,
    },
    props: {
        modules: {
            type: Array,
            required: true
        },
        mode: {
            type: String,
            default: 'module',
        },
    },
    data() {
        return {
            dripMode: this.mode,
        }
    },
    mounted() {
        EventBus.$on('form:element:changed', (name, value) => {
            if (name === 'drip_mode') {
                this.dripMode = value === '1' ? 'lesson' : 'module';
            }
        });
    },
}
</script>
