<template>
    <div>
        <div
            v-for="(module, index) in modules"
            v-if="module.lessons.length > 0"
            v-bind:class="{'expandable-open': isExpanded(module.id)}"
            v-bind:key="module.id"
            class="expandable"
        >
            <div class="row" v-bind:class="{'br-top p-t-1 p-b-1': index > 0}">
                <a class="btn-icon btn-icon--right btn-text btn-text--primary btn btn--block f-s-4 f-w-bold p-l-0 p-r-0 text-left" v-on:click.prevent="toggleExpanded(module.id)" href="#expand">
                    {{ module.name }}
                    <div class="i-container i-filled-theme fl-right m-r-0">
                        <!-- /icons/open-alt.svg -->
                        <svg class="icon icon-expand-toggle icon-expand-toggle-open i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.664 7.253L2.336 8.747 12 17.338l9.664-8.59-1.328-1.495L12 14.662z" class="icon"/></svg>
                        <!-- /icons/close-alt.svg -->
                        <svg class="icon icon-expand-toggle icon-expand-toggle-close i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M19 3.586L3.586 19 5 20.414 20.414 5z"/><path d="M20.414 19L5 3.586 3.586 5 19 20.414z"/></g></svg>
                    </div>
                </a>
            </div>
            <div v-if="isExpanded(module.id)" class="expand-content">
                <div class="row row-collapse-indent">
                    <div class="columns m-b-4">
                        <div class="lesson-count c-medium-tint f-s-3">{{ module.lessons.length }} {{ trans('Lessons') }}</div>
                    </div>
                </div>
                <div class="row p-b-1 row-collapse m-t-1">
                    <div class="columns">
                        <div class="media-group">
                            <div
                                v-for="lesson in module.lessons"
                                v-bind:key="lesson.id"
                                class="media row row-collapse-indent m-b-4 p-b-3"
                            >
                                <a class="media-figure column small-4" v-bind:href="lesson.url">
                                    <div class="cover-container uploads uploads-r-16_9">
                                        <div class="cover row row-middle upload-content">
                                            <div class="cover-image cover-image-overlay">
                                                <div class="cover-overlay"></div>
                                                <img v-bind:src="getLessonThumbnail(lesson)">
                                            </div>
                                            <div v-if="lesson.video" class="cover-content column text-center">
                                                <!-- /icons/play.svg -->
                                                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs><filter x="-26%" y="-21%" width="168%" height="154.8%" filterUnits="objectBoundingBox" id="a"><feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.184313725 0 0 0 0 0.203921569 0 0 0 0 0.235294118 0 0 0 0.3 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="SourceGraphic"/></feMerge></filter></defs><path d="M24.52 14.146l-23-14A1.002 1.002 0 0 0 0 1v28a1.002 1.002 0 0 0 1.52.854l23-14a1 1 0 0 0 0-1.708z" filter="url(#a)" transform="translate(4 1)" fill="#FFF"/></svg>
                                            </div>
                                            <div v-if="lesson.duration" class="media-meta columns text-right c-bright f-s-1">{{ lesson.duration | duration }}</div>
                                        </div>
                                    </div>
                                </a>
                                <div class="media-content column">
                                    <div class="row row-collapse row-tall">
                                        <div class="column">
                                            <div class="row row-collapse">
                                                <div class="column">
                                                    <a v-bind:href="lesson.url">
                                                        <h4 class="m-b-2 f-s-4 c-primary">{{ lesson.name}}</h4>
                                                    </a>
                                                </div>
                                                <div class="text-right">
                                                    <span
                                                        v-if="lesson.preview_enabled"
                                                        class="badge c-theme-bg c-bright f-w-bold f-caps"
                                                    >{{ trans('Preview') }}</span>
                                                    <span v-else class="card-status">
                                                        <span class="i-container i-filled-theme">
                                                            <!-- /icons/lock.svg -->
                                                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 4C9.8 4 8 5.8 8 8v1H6c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-9c0-.6-.4-1-1-1h-2V8c0-2.2-1.8-4-4-4zm1 11.7V17h-2v-1.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7zM14 9h-4V8c0-1.1.9-2 2-2s2 .9 2 2v1z" class="icon"/></svg>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row row-collapse">
                                                <p class="f-s-3 c-medium-tint" v-html="clipHtml(lesson.description, 150)"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="br-top"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import clip from 'text-clipper';

    export default {
        props: {
            modules: {
                type: Array
            }
        },
        data() {
            return {
                expanded: []
            }
        },
        methods: {
            isExpanded(moduleId) {
                return _.includes(this.expanded, moduleId);
            },
            toggleExpanded(moduleId) {
                if (this.isExpanded(moduleId)) {
                    this.expanded.splice(_.indexOf(this.expanded, moduleId), 1);
                } else {
                    this.expanded.push(moduleId);
                }
            },
            getLessonThumbnail(lesson) {
                return lesson.image ? lesson.image_url.thumbnail : '/img/placeholder-image.png';
            },
            clipHtml(string, length) {
                return clip(string, length, {
                    html: true
                });
            }
        },
    }
</script>