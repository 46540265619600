<template>
    <div>
        <div class="form-group">
            <label>
                {{ headingLabel }}
                <input class="input__field" type="text" v-bind:name="fieldName + '[heading]'" v-model="heading">
            </label>
        </div>
        <div v-sortable="{ handle: '.handle' }">
            <div class="slat m-t-4 m-b-3" v-for="(item, index) in items" v-bind:key="item.key">
                <div class="br p-t-3 p-b-3">
                    <div v-bind:class="{'br-bottom': expanded === item.key, 'p-b-3': expanded === item.key}" class="slat-group-header slat-group-header-small row row-middle">
                        <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                            <span class="i-container i-filled-dark">
                                <span class="handle">
                                    <!-- /icons/drag.svg -->
                                    <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                                </span>
                            </span>
                        </div>
                        <div class="slat-content column p-l-2">
                            <a href="#expand" v-on:click.prevent="toggleExpanded(item.key)">
                                <h4 v-if="item.author && item.author.length > 0" class="f-w-bold f-s-3 m-b-0">{{ item.author }}</h4>
                                <h4 v-else class="f-w-bold f-s-3 m-b-0">Testimonial {{ index + 1 }}</h4>
                            </a>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <span class="tooltip animate">
                                <a class="i-container i-filled-dark" href="#delete-faq" v-on:click.prevent="deleteItem(index)">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                </a>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                            </span>
                        </div>
                    </div>
                    <div v-show="expanded === item.key" class="slat-group-content p-l-4 p-r-4 p-t-4 m-t-1">
                        <small-image-upload
                            type="avatar"
                            field-name="image"
                            v-bind:index="index"
                            v-on:image-removed="removeImage"
                            v-on:image-selected="addImage"
                            v-bind:existing-file-url="item.image.url"
                            v-bind:existing-file-path="item.image.path"
                            v-bind:upload-label="trans('Upload Photo')"
                            v-bind:delete-label="trans('Delete Photo')"
                            v-bind:crop="true"
                            v-bind:crop-aspect-ratio="1"
                        ></small-image-upload>
                        <input type="hidden" v-bind:name="fieldName + '[items][' + index + '][image][url]'" v-model="item.image.url" />
                        <input type="hidden" v-bind:name="fieldName + '[items][' + index + '][image][path]'" v-model="item.image.path" />
                        <div class="form-group">
                            <label>
                                {{ trans('Name') }}
                                <input ref="testimonial-name" class="input__field" type="text" v-bind:placeholder="trans('First & last name')" v-bind:name="fieldName + '[items][' + index + '][author]'" v-model="item.author">
                            </label>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ trans('Testimonial') }}
                                <input ref="testimonial-text" class="input__field" type="text" v-bind:placeholder="trans('What customers said')" v-bind:name="fieldName + '[items][' + index + '][text]'" v-model="item.text">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center p-t-1">
                <button type="button" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4" v-on:click="addItem(true)">
                    <span class="icon i-container i-filled-accent">
                        <!-- /icons/add.svg -->
                        <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                    </span>
                    {{ trans('Add Testimonial') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        fieldName: {
            type: String,
            default: 'bullets'
        },
        data: {
            type: Object
        },
        headingLabel: {
            type: String,
            required: true
        },
    },
    data() {
        let key = 0;
        return {
            items: _.get(this.data, 'items', []).map((item) => {
                item.key = ++key;
                return item;
            }),
            heading: _.get(this.data, 'heading'),
            key: key,
            expanded: key > 0 ? null : key
        };
    },
    watch: {
        heading(newValue) {
            this.$emit('update:heading', newValue);
        }
    },
    methods: {
        toggleExpanded(key) {
            if (this.expanded === key) {
                this.expanded = null;
            } else {
                this.expanded = key;
            }
        },
        addItem(triggerFocus) {
            this.items.push({
                key: ++this.key,
                author: '',
                text: '',
                image: {
                    url: null,
                    path: null
                }
            });
            this.expanded = this.key;

            if (triggerFocus) {
                Vue.nextTick(() => {
                    this.$refs['testimonial-name'][this.items.length - 1].focus();
                });
            }
        },
        deleteItem: function(index) {
            this.items.splice(index, 1);
        },
        addImage(image, index) {
            this.items[index].image.url = image.url;
            this.items[index].image.path = image.path;
        },
        removeImage(index) {
            this.items[index].image.url = null;
            this.items[index].image.path = null;
        }
    },
    created() {
        // If there are no items add one empty item
        if (this.items.length === 0) {
            this.addItem(false);
        }

        // To trigger reactivty and data sync in page builder
        this.$emit('update:items', this.items);
    }
}
</script>