<template>
    <div class="testimonial text-center c-accent-bg-light p-5 m-t-4">
        <div class="testimonial-content">
            <div v-if="heading" class="header-label">
                <h4 class="f-w-bold c-primary f-s-4">{{ heading }}</h4>
            </div>
            <testimonials v-bind:items="items"></testimonials>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';
    import TestimonialsForRender from '../../../Testimonials.vue';

    export default {
        extends: Block,
        components: {
            'testimonials': TestimonialsForRender
        },
        data() {
            return {
                heading: _.get(this.block.data, 'heading'),
                items: _.get(this.block.data, 'items', [])
            }
        }
    }
</script>