<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <video-player v-bind:url="video"></video-player>
    </div>
</template>
<script>
    import Video from '../page/Video.vue';

    export default {
        extends: Video
    }
</script>