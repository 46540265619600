<template>
    <div class="slideshow slideshow-testimonials-full">
        <slick ref="slick" :options="slickOptions">
            <div v-for="(testimonial, index) in items" class="slide row" style="width: 100%; display: inline-block;" v-bind:key="index">
                <div class="testimonial text-center">
                    <div class="testimonial-content">
                        <p class="f-s-3 c-primary">"{{ testimonial.text }}"</p>
                    </div>
                    <div class="testimonial-figure">
                        <div v-if="testimonial.image.url && testimonial.image.url.length > 0" class="avatar m-b-2">
                            <img class="avatar-image avatar-medium" v-bind:src="resizedImageUrl(testimonial.image.url, 'small')">
                        </div>
                        <span class="name c-primary f-s-3">{{ testimonial.author }}</span>
                    </div>
                </div>
            </div>
        </slick>
    </div>
</template>
<script>
    import Slick from 'vue-slick';
    import { resizedImageUrl } from '../../helpers.js';

    export default {
        components: {
            Slick
        },
        props: {
            items: {
                type: Array
            }
        },
        data() {
            return {
                slickOptions: {
                    dots: true,
                    arrows: false,
                    fade: true,
                    cssEase: 'linear'
                }
            }
        },
        watch: {
            items(newValue) {
                this.reInitSlick();
            }
        },
        methods: {
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            resizedImageUrl(url, size) {
                return resizedImageUrl(url, size);
            },
            reInitSlick() {
                let currIndex = this.$refs.slick.currentSlide();

                this.$refs.slick.destroy();
                this.$nextTick(() => {
                    this.$refs.slick.create();
                    this.$refs.slick.goTo(currIndex, true);
                });
            }
        },
    }
</script>