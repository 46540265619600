<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Add comment') }}</h3>
        </div>

        <div v-bind:class="{'has-error': errors.has('commentable_id')}" class="form-group">
            <label>
                {{ trans('Which hub/feature to add the comment to') }}
                <hub-and-feature-filter
                    v-bind:hubs="hubs"
                    v-bind:selected="feature"
                    v-bind:default-label="trans('Select a feature')"
                    v-on:update:selected="feature = $event"
                ></hub-and-feature-filter>
            </label>
        </div>
        <div v-if="errors.has('feature')" class="form-help">{{ errors.get('commentable_id') }}</div>

        <div v-if="showLessonDropdown" v-bind:class="{'has-error': errors.has('commentable_id')}" class="form-group">
            <label>
                {{ trans('Which lesson to add the comment to') }}
                <select v-model="lesson" class="input-small">
                    <option v-bind:value="null">{{ trans('Select a lesson') }}</option>
                    <template v-if="hub && hub.modules && hub.modules.length > 0">
                        <optgroup v-for="module in hub.modules" v-bind:key="'module-' + module.encoded_id" v-bind:label="module.name">
                            <template v-if="module.lessons.length > 0">
                                <option v-for="lesson in module.lessons" v-bind:value="lesson.encoded_id" v-bind:key="'lesson-' + lesson.encoded_id">{{ lesson.name }}</option>
                            </template>
                        </optgroup>
                    </template>
                </select>
            </label>
        </div>
        <div v-else-if="showPostDropdown" v-bind:class="{'has-error': errors.has('commentable_id')}" class="form-group">
            <label>
                {{ trans('Which post to add the comment to') }}
                <select v-model="post" class="input-small">
                    <option v-bind:value="null">{{ trans('Select a post') }}</option>
                    <option v-for="post in hub.posts" v-bind:value="post.encoded_id" v-bind:key="'post-' + post.encoded_id">{{ post.title }}</option>
                </select>
            </label>
        </div>
        <div v-if="errors.has('commentable_id')" class="form-help">{{ errors.get('commentable_id') }}</div>

        <div v-bind:class="{'has-error': errors.has('text')}" class="form-group form-group-simple hl-txt">
            <textarea v-model="text" class="m-t-1 input__field" ref="text" rows="2" v-bind:placeholder="trans('Add your comment...')" name="text"></textarea>
        </div>
        <div v-if="errors.has('text')" class="form-help">{{ errors.get('text') }}</div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveComment" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Save') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import CommentModal from './CommentModal.vue';
import { EventBus } from '../../event-bus.js';
import HubAndFeatureFilter from './HubAndFeatureFilter.vue';

export default {
    components: {
        HubAndFeatureFilter
    },
    extends: CommentModal,
    props: {
        hubs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            hub: null,
            post: null,
            lesson: null,
            feature: null,
        }
    },
    watch: {
        feature(selectedFeature) {
            this.post = null;
            this.lesson = null;

            if (selectedFeature === null) {
                this.commentableId = null;
                this.commentableType = null;

                return;
            }

            if (selectedFeature.type === 'course' || selectedFeature.type === 'community') {
                this.loadHub(selectedFeature.encoded_hub_id, selectedFeature.type);
            }

            switch (selectedFeature.type) {
                case 'course':
                    this.commentableId = null;
                    this.commentableType = 'Kourses\\Lesson';
                    break;
                case 'community':
                    this.commentableId = null;
                    this.commentableType = 'Kourses\\Models\\Post';
                    break;
                case 'content-page':
                    this.commentableId = selectedFeature.encoded_id;
                    this.commentableType = 'Kourses\\Models\\HubFeature';
                    break;
            }
        },
        lesson(selectedLesson) {
            this.commentableId = selectedLesson;
        },
        post(selectedPost) {
            this.commentableId = selectedPost;
        },
    },
    computed: {
        showPostDropdown() {
            return this.hub !== null && this.feature !== null && this.feature.type === 'community';
        },
        showLessonDropdown() {
            return this.hub !== null && this.feature !== null && this.feature.type === 'course';
        },
    },
    methods: {
        loadHub(hubId, type) {
            // In case that we already have the hub and its lessons loaded
            // we skip another loading
            if (this.hub !== null && this.hub.encoded_id === hubId && this.feature !== null && this.feature.type === type) {
                return;
            }

            this.loading = true;

            window.axios.get(Kourses.apiBaseUrl + '/hubs/' + hubId + '/' + type)
                .then((response) => {
                    this.loading = false;

                    this.hub = response.data;
                })
                .catch((error) => {
                    console.warn(error);
                    this.loading = false;
                    this.errors.record(error.response.data);
                });
        },
        reset() {
            this.errors.clear();
            this.loading = false;
            this.text = '';
            this.hub = null;
            this.feature = null;
            this.lesson = null;
            this.post = null;
            this.commentableId = null;
            this.commentableType = null;
            this.parentId = null;
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-comment', () => {
            $vm.reset();
            $vm.show = true;
        });
    },
}
</script>