<script>
    import { EventBus } from '../../../event-bus.js';

    export default {
        props: {
            usesNavbarTitle: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                navbarTitle: _.get(this.block, 'data.navbar_title', ''),
            }
        },
        watch: {
            navbarTitle(newValue) {
                EventBus.$emit('block:update:value', this.index, 'navbarTitle', newValue);
            },
        }
    }
</script>