<template>
    <div class="chartdonut m-n-t-micro chartdonut_animation chartdonut--large-tmp">
        <div class="footnote row row-middle text-center" style="height: 56px;">
            <span class="columns f-s-3 c-medium-tint p-0">{{ label }}</span>
        </div>
        <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
            <circle cx="28" cy="28" r="24.45070" fill="transparent" stroke="#E6E9ED" stroke-width="5"></circle>
            <circle r="24.45070" cy="28" cx="28" stroke-width="5" stroke="#02DCAA" fill="none" v-bind:stroke-dasharray="dashArray"></circle>
        </g>
        </svg>
    </div>
</template>
<script>
    export default {
        props: {
            percentage: {
                type: Number,
                required: true
            },
            label: {
                type: String
            }
        },
        computed: {
            dashArray() {
                const c = Math.PI * 2 * 24.45070;
                const value = c * (this.percentage / 100);
                return value + ' ' + c;
            }
        }
    }
</script>