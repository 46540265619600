<template>
    <div>
        <component
            v-bind:field-name="'blocks[' + type + '][data]'"
            v-bind:data="data"
            v-bind="extraProps"
            :is="type"
        ></component>
        <input type="hidden" v-bind:name="'blocks[' + type + '][type]'" v-model="type" />
    </div>
</template>
<script>
    import Faqs from './Faqs.vue';
    import Bullets from './Bullets.vue';
    import Testimonials from './Testimonials.vue';

    export default {
        components: {
            'faq': Faqs,
            'bullet': Bullets,
            'testimonial': Testimonials
        },
        props: ['type', 'block', 'extraProps'],
        data() {
            return {
                data: this.block
            }
        }
    }
</script>