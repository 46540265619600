<template>
    <div>
        <div class="row">
            <div class="column">
                <div v-bind:class="{'has-error': errors.has('text')}" class="form-group form-group-simple hl-txt">
                    <textarea v-model="text" class="m-t-1 input__field" ref="reply" rows="2" v-bind:placeholder="trans('Add your comment...')" name="text"></textarea>
                </div>
                <div v-if="errors.has('text')" class="form-help">{{ errors.get('text') }}</div>
            </div>
            <button type="button" class="btn btn-primary" v-on:click="saveComment" v-bind:class="{'btn-loading': saving}">
                {{ trans('Save') }}
                <div v-if="saving" class="loader">
                    <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                        <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                    </div>
                </div>
            </button>
        </div>
        <div class="row">
            <div class="column">
                <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                    <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
                <template v-else>
                    <div v-if="items.length > 0" ref="comments">
                        <comment
                            v-for="(item, index) in items"
                            v-bind:key="item.encoded_id"
                            v-bind:comment="item"
                            v-bind:author="item.member || author"
                            v-on:comment:reply="replyTo(item)"
                            v-on:comment:delete="deleteComment(item, index)"
                        >
                            <template slot="footer">
                                <div class="row row-middle p-t-3">
                                    <div class="column">
                                        <div v-if="item.breadcrumbs.commentable.type === 'Kourses\\Lesson'" class="br-top f-s-2 c-muted p-t-3">
                                            <span class="c-medium f-w-bold">{{ item.breadcrumbs.hub.title }}</span>
                                            <span class="c-muted m-y-2">/</span>
                                            <a v-bind:href="lessonEditLink(item.breadcrumbs.commentable)" class="h-c-muted">{{ item.breadcrumbs.commentable.name }}</a>
                                        </div>
                                        <div v-else-if="item.breadcrumbs.commentable.type === 'Kourses\\Models\\HubFeature'" class="br-top f-s-2 c-muted p-t-3">
                                            <span class="c-medium f-w-bold">{{ item.breadcrumbs.hub.title }}</span>
                                            <span class="c-muted m-y-2">/</span>
                                            <a v-bind:href="contentPageEditLink(item.breadcrumbs.commentable)" class="h-c-muted">{{ trans('Content Page') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </comment>
                        <paginator
                            v-if=" ! loading"
                            v-bind:to="paginator.to"
                            v-bind:from="paginator.from"
                            v-bind:total="paginator.total"
                            v-bind:last-page="paginator.lastPage"
                            v-bind:current-page="paginator.currentPage"
                            v-on:paginator:switch-page="changePage"
                            v-bind:full-width="true"
                        ></paginator>
                    </div>
                    <div v-else>
                        <p class="c-mutedf-s-4">
                            {{ trans("There are no comments at the moment!") }}
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import Comments from './Comments.vue';
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';

export default {
    extends: Comments,
    props: {
        postId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            text: null,
            saving: false,
            errors: new Errors(),
        }
    },
    methods: {
        changePage(page, showLoadingIndicator = true) {
            // We may don't want to show loading indicator while we fetch new comments due to deleting one
            if (showLoadingIndicator === true) {
                this.loading = true;
            }

            window.axios.get(Kourses.apiBaseUrl + '/comments',{
                params: {
                    'page': page,
                    'per_page': this.perPage,
                    'commentable_id': this.postId,
                    'commentable_type': 'post',
                    'status': this.status || null,
                    'query': this.query || null,
                }
            }).then(this.showComments);
        },
        saveComment() {
            this.saving = true;

            window.axios
                .post(Kourses.apiBaseUrl + '/comments', {
                    'text': this.text,
                    'commentable_id': this.postId,
                    'commentable_type': 'Kourses\\Models\\Post',
                    'parent_id': null
                }).then((response) => {
                    EventBus.$emit('comment:added', response.data.data);
                    this.text = null;
                    this.saving = false;
                }).catch((error) => {
                    console.warn(error);
                    this.saving = false;
                    this.errors.record(error.response.data);
                });
        },
        appendAddedComment(comment) {
            this.paginator.to++;
            this.paginator.total++;
            this.paginator.lastPage =  Math.ceil(this.paginator.total / this.perPage);

            this.items.unshift(comment);
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('comment:added', (comment) => {
            $vm.appendAddedComment(comment);
        });
    },
    mounted() {
        if (window.location.hash === '#comments') {
            this.$scrollTo(this.$refs.comments);
        } else if (window.location.hash === '#reply') {
            this.$scrollTo(this.$refs.reply);
            this.$refs.reply.focus();
        }
    }
};
</script>
