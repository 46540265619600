<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <nav class="nav nav-tab br-bottom">
                <a class="nav-item nav-item--active f-s-4 m-r-3">{{ trans('Upload Files') }}</a>
            </nav>
        </div>
        <div class="form-group">
            <file-upload
                v-bind:files.sync="files"
                v-bind:max-files-num="maxFilesNum"
                v-bind:accepted-file-types="acceptedFileTypes"
            ></file-upload>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary" v-on:click="addFiles" v-bind:class="{'btn-loading': saving}">
                        {{ maxFilesNum === 1 ? trans('Add File') : trans('Add Files') }}
                        <div class="loader" v-if="saving">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from './Modal.vue';

    export default {
        components: {
            Modal
        },
        props: {
            acceptedFileTypes: {
                type: String,
                default: null
            },
            maxFilesNum: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                show: false,
                saving: false,
                files: []
            }
        },
        methods: {
            addFiles() {
                this.saving = true;
                this.$emit('add-files', this.files);
            }
        }
    }
</script>