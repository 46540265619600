<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <nav class="nav nav-tab br-bottom">
                <a v-if="videoImageUrl" v-on:click.prevent="tab = 'thumbnail'" v-bind:class="{ 'nav-item--active' : tab === 'thumbnail' }" class="nav-item f-s-4 m-r-3" href="#thumbnail">{{ trans('Current Thumbnail') }}</a>
                <a v-on:click.prevent="tab = 'upload'" v-bind:class="{ 'nav-item--active' : tab === 'upload' }" class="nav-item f-s-4 m-r-3" href="#upload">{{ trans('Upload Image') }}</a>
            </nav>
        </div>
        <div class="form-group">
            <image-upload
                v-if="tab === 'upload'"
                v-bind:show-form-fields="false"
                v-bind:existing-file-path="image"
                v-bind:existing-file-url="imageUrl"
                v-bind:resize="false"
                v-on:image-selected="addImage"
                v-on:upload:image:started="uploadStarted"
                v-on:image-removed="removeImage"
                ratio="16:9"
            ></image-upload>
            <div v-if="tab === 'thumbnail'" class="uploads-group">
                <div class="card column uploads br c-lightest-bg text-center p-l-4 p-r-4 uploads-r-16_9">
                    <div class="upload-content row row-middle row-collapse cover">
                        <div class="column text-center">
                            <div v-if="videoImageUrl" class="cover-image cover-image-overlay">
                                <img v-bind:src="videoImageUrl" class="image-fit" v-original-fallback />
                            </div>
                            <div v-else class="card-content">
                                <div class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                                    <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                                <h4 class="f-w-bold f-s-3 m-t-4">{{ trans('Processing...') }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button v-on:click="selectImage" v-bind:class="{ 'btn-loading': saving, 'btn-disabled': uploading }" v-bind:disabled="uploading" type="button" class="btn btn-primary">
                        {{ trans('Use Image') }}
                        <div class="loader" v-if="saving">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        existingVideoImage: {
            type: String
        },
    },
    data() {
        return {
            show: false,
            saving: false,
            uploading: false,
            url: null,
            tab: this.existingVideoImage ? 'thumbnail' : 'upload',

            image: null,
            videoImage: this.existingVideoImage,
            imageUrl: null,
            videoImageUrl: this.existingVideoImage ? window.Kourses.assetBaseUrl + this.existingVideoImage : null
        }
    },
    methods: {
        selectImage() {
            if (this.tab === 'upload') {
                this.$emit('video:thumb:select', this.tab, this.image);
                this.$emit('close');
                this.show = false;
            } else {
                this.$emit('video:thumb:select', this.tab, null);
                this.$emit('close');
                this.show = false;
            }
        },
        addImage(image) {
            this.image = image.path;
            this.imageUrl = image.url;
            this.uploading = false;
        },
        uploadStarted() {
            this.uploading = true;
        },
        removeImage() {
            this.image = null;
        }
    }
}
</script>