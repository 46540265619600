<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Reply to comment') }}</h3>
        </div>

        <div v-if="replyToComment" class="m-b-4">
            <div class="f-s-2 c-muted m-b-2" v-html="formattedReplyHeading"></div>
            <div class="f-s-2 c-primary line-clamp-2" v-html="replyToComment.text"></div>
        </div>

        <div v-bind:class="{'has-error': errors.has('text')}" class="form-group form-group-simple hl-txt">
            <textarea v-model="text" class="m-t-1 input__field" ref="text" rows="2" v-bind:placeholder="trans('Add your comment...')" name="text"></textarea>
        </div>
        <div v-if="errors.has('text')" class="form-help">{{ errors.get('text') }}</div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveComment" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Reply') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import CommentModal from './CommentModal.vue';
import { EventBus } from '../../event-bus.js';

export default {
    extends: CommentModal,
    data() {
        return {
            parentId: null,
            replyToComment: null
        }
    },
    computed: {
        formattedReplyHeading() {
            let to = 'Course Author';
            let date = this.formatDatetime(this.replyToComment.created_at);

            return this.trans('Reply to <span class="c-medium f-w-bold">:to</span> who on <span class="c-medium f-w-bold">:date</span> said:', {'to': to, 'date': date});
        }
    },
    methods: {
        saveComment() {
            this.loading = true;

            window.axios
                .post(Kourses.apiBaseUrl + '/comments', {
                    'text': this.text,
                    'commentable_id': this.commentableId,
                    'commentable_type': this.commentableType,
                    'parent_id': this.parentId || null
                }).then((response) => {
                    EventBus.$emit('comment:replied', response.data.data);
                    this.close();
                }).catch((error) => {
                    console.warn(error);
                    this.loading = false;
                    this.errors.record(error.response.data);
                });
        },
        reset() {
            this.errors.clear();
            this.loading = false;
            this.text = '';
            this.commentableId = null;
            this.commentableType = null;
            this.parentId = null;
            this.replyToComment = null;
        },
        formatDatetime(timestamp) {
            return new Date(timestamp).toLocaleString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:reply-to-comment', (replyToComment) => {
            $vm.reset();
            $vm.show = true;

            $vm.commentableId = replyToComment.breadcrumbs.commentable.encoded_id;
            $vm.commentableType = replyToComment.breadcrumbs.commentable.type;
            $vm.parentId = replyToComment.encoded_id;

            $vm.replyToComment = replyToComment;
        });
    }
}
</script>