<template>
    <div v-bind:class="typeClasses" class="alert alert--mini br dropshadow-small p-l-3 p-r-3 c-bright m-b-2">
        <div class="text-center toast-content">
            <p class="m-0 f-s-4" v-html="message"></p>
        </div>
        <button v-if="hasCloseButton" type="button" v-on:click="close" class="toast-close i-filled-mute cu-p m-n-r-3">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon i-size-ittybitty">
                <g class="icon">
                    <path d="M19 3.586L3.586 19 5 20.414 20.414 5z"></path>
                    <path d="M20.414 19L5 3.586 3.586 5 19 20.414z"></path>
                </g>
            </svg>
        </button>
    </div>
</template>
<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'success',
                required: true
            },
            message: {
                type: String,
                required: true
            },
            index: {
                type: Number
            }
        },
        computed: {
            typeClasses() {
                switch (this.type) {
                    case 'error':
                        return 'c-error-bg br-c--warning alert--with-close-button';
                    case 'success':
                        return 'c-success-bg br-c--success inline';
                }
            },
            hasCloseButton() {
                if (this.type === 'error') {
                    return true;
                }

                return false;
            }
        },
        methods: {
            close() {
                this.$emit('close', this.index);
            }
        },
        mounted() {
            if (this.type === 'success') {
                setTimeout(() => {
                    this.close();
                }, 3000);
            }
        }
    }
</script>