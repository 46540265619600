<template>
    <div v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
        <div class="slat m-t-4 m-b-3" v-for="(item, index) in items" v-bind:key="item.type">
            <div class="br p-t-3 p-b-3">
                <div class="slat-group-header slat-group-header-small row row-middle">
                    <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                        <span class="i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                    </div>
                    <div class="slat-content column p-l-2">
                        <h4 class="card-title c-primary f-s-4 m-b-0">
                            {{ item.label }}
                            <a v-if="item.status === 'enabled'" class="i-container i-filled-dark" v-bind:href="hubFeatureEditLink(item)">
                                <!-- /icons/edit.svg -->
                                <svg class="icon m-b-1 m-l-2 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.7 4.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM8.6 18H6v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L13.4 8 15 6.4 17.6 9 16 10.6z" class="icon"/></svg>
                            </a>
                        </h4>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <!-- "class-name" attribute intentionally left empty in order to remove "form-group" class -->
                        <toggle
                            v-bind:name="`enabled[${item.type}]`"
                            class-name=""
                            v-bind:toggled="item.status === 'enabled'"
                            v-on:update:toggled="(status) => updateFeatureStatus(item, status)"
                        ></toggle>
                    </div>
                    <input
                        type="hidden"
                        v-bind:name="`order[${item.type}]`"
                        v-bind:value="index + ''"
                    >
                </div>
            </div>
        </div>
        <input
            type="hidden"
            name="features_order"
            v-bind:value="initialOrderString"
        >
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import { hubFeatureEditLink } from '../../routes.js';

export default {
    props: {
        features: {
            type: Array,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
    },
    data() {
        let items = this.features;

        // We need to "join" all existing hub features plus all possible other
        if (items.length < this.types.length) {
            const featureKeys = items.map(item => item.type);
            const typeKeys = this.types.map(item => item.type);
            const diffKeys = typeKeys.filter((key) => ! featureKeys.includes(key));

            diffKeys.forEach((key) => {
                const missingType = this.types.find((item) => item.type === key);

                items.push({
                    label: missingType.name,
                    type: missingType.type,
                    status: 'disabled',
                });
            });
        }

        return {
            items: items,
            initialOrderString: items.map(i => i.type).join('-'),
        }
    },
    methods: {
        hubFeatureEditLink(hubFeature) {
            return hubFeatureEditLink(hubFeature);
        },
        onUpdate(event) {
            this.items.splice(event.newIndex, 0, this.items.splice(event.oldIndex, 1)[0]);

            EventBus.$emit('form:element:changed', 'features_order', this.items.map(i => i.type).join('-'));
        },
        updateFeatureStatus(feature, status) {
            feature.status = status ? 'enabled' : 'disabled';

            EventBus.$emit(
                'field:features:enabled',
                this.items
                    .filter(item => item.status === 'enabled')
                    .length > 1 ? 'multi' : 'single'
            );
        },
    },
}
</script>
