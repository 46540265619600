<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <h3 v-if="heading" class="f-s-7 c-primary f-w-bold m-b-5">{{ heading }}</h3>
        <ul v-if="items.length > 0">
            <li v-if="item.text && item.text.length > 0" v-for="(item, index) in items" v-bind:key="index">{{ item.text }}</li>
        </ul>
    </div>
</template>
<script>
    import Bullets from '../page/Bullets.vue';

    export default {
        extends: Bullets
    }
</script>