<template>
    <button v-on:click="loading = true" v-bind:class="{'btn-loading': loading}" type="submit" class="btn btn-primary">
        {{ label }}
        <div v-if="loading" class="loader">
            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
            </div>
        </div>
    </button>
</template>
<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false
            }
        }
    }
</script>