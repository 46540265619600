<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Edit tag') }}</h3>
            <p class="f-s-4 c-muted">{{ trans('Customize the name of this tag or delete it from your website.') }}</p>
        </div>
        <div class="form-group" v-bind:class="{'has-error': errors.has('tag')}">
            <label>
                {{ trans('Tag') }}
                <input class="input__field" type="text" v-model="tag">
            </label>
        </div>
        <div v-if="errors.has('tag')" class="form-help">
            {{ errors.get('tag') }}
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="saveTag" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Save') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-outline btn-outline--alert" v-on:click="deleteTag" v-bind:class="{'btn-loading': loadingDelete}">
                        {{ trans('Delete') }}
                        <div v-if="loadingDelete" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-alert" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import Errors from '../../errors.js';

export default {
    components: {
        Modal
    },
    data() {
        return {
            tag: '',
            show: false,
            loading: false,
            loadingDelete: false,
            errors: new Errors(),
            index: null
        }
    },
    methods: {
        saveTag() {
            this.loading = true;
            this.$emit('save-tag', this.tag, this.index);
        },
        deleteTag() {
            this.loadingDelete = true;
            this.$emit('delete-tag', this.tag, this.index);
        },
        reset() {
            this.tag = '';
            this.index = null;
            this.loading = false;
            this.loadingDelete = false;
            this.errors.clear();
        },
        setErrors(errors) {
            this.loading = false;
            this.loadingDelete = false;
            this.errors.record(errors);
        },
    },
}
</script>