<template>
    <div class="tag-group form-group">
        <label>
            <span v-if="label" class="block m-b-2">{{ label }}</span>
            <a
                v-for="(item, index) in selected"
                v-bind:key="index"
                v-on:click.prevent="remove(index)"
                class="btn btn-small btn-secondary btn-icon btn-tag btn-icon--right rounded m-r-3 m-b-3"
            >
                {{ item.value }}
                <span class="icon i-container i-filled-dark">
                    <!-- /icons/remove.svg -->
                    <svg class="icon i-size-small" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                </span>
            </a>
            <input
                v-if="allowMore"
                type="text"
                placeholder="..."
                class="inline input__field width-auto new-tag"
                v-model="query"
                autocomplete="kourses-nope"
                v-on:keydown.delete="removeLastTag"
                v-on:keydown.enter.188.prevent="addSelected"
                v-on:keydown.down="currentSelectionDown"
                v-on:keydown.up="currentSelectionUp"
                v-on:focus="showSuggestions"
                v-on-clickaway="hideSuggestions"
            />
        </label>
        <div v-bind:class="{'block': openDropdown && matches.length > 0}" class="dropdown autocomplete__dropdown-container dropdown--bd dropdown-p--left" ref="autocomplete-container">
            <ul class="dropdown-group text-left m-l-0 m-b-0">
                <li v-for="(item, index) in matches" class="dropdown-item dropdown-item--simple" v-bind:key="item.key">
                    <a v-on:click.prevent="addSuggestion(item.key, item.value)" v-bind:class="{'h-anim-selected': index === current}" class="c-primary p-2 f-s-4 block h-anim h-bg-accent--light" v-bind:href="'#' + item.key">{{ item.value }}</a>
                </li>
            </ul>
        </div>
        <input v-for="item in selected" type="hidden" v-bind:name="name + '[]'" v-bind:value="item.key" v-bind:key="item.key" />
    </div>
</template>
<script>
import Fuse from 'fuse.js';
import { directive as onClickaway } from 'vue-clickaway';
import { EventBus } from '../../event-bus';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        items: {
            type: [Object, Array]
        },
        name: {
            type: String,
            required: true
        },
        suggestions: {
            type: [Object, Array]
        },
        label: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: this.items.map((key) => {
                return {key, value: this.suggestions[key]};
            }),
            query: '',

            openDropdown: false,
            data: _.map(this.suggestions, (value, key) => {
                return {
                    key,
                    value
                };
            }),
            current: 0
        }
    },
    computed: {
        matches() {
            if (this.query.length > 0) {
                this.showSuggestions();
                var fuse = new Fuse(this.data, {
                    shouldSort: true,
                        threshold: 0.6,
                        location: 0,
                        distance: 100,
                        maxPatternLength: 10,
                        minMatchCharLength: 1,
                        keys: ['key', 'value']
                });
                return fuse.search(this.query);
            } else {
                return this.data;
            }
        },
        allowMore() {
            return this.multiple || this.selected.length < 1;
        }
    },
    watch: {
        items(newValue) {
            if (newValue.length > 0) {
                this.selected = newValue.map((key) => {
                    return {
                        key,
                        value: this.suggestions[key]
                    };
                });
            } else {
                this.selected = [];
            }
        },
    },
    methods: {
        remove(index) {
            this.selected.splice(index, 1);

            this.emitChanges();
        },
        removeLastTag() {
            if (this.query) {
                return;
            }
            this.selected.pop();

            this.emitChanges();
        },
        showSuggestions() {
            if (this.allowMore) {
                this.openDropdown = true;
            }
        },
        hideSuggestions() {
            this.openDropdown = false;
            this.query = '';
            this.current = 0;
        },
        addSuggestion(key, value) {
            if ((this.query.length > 0 || this.openDropdown) && this.allowMore && _.findIndex(this.selected, {'key': key}) === -1) {
                this.selected.push({key, value});
                this.emitChanges();
            }
            this.hideSuggestions();
        },
        addSelected() {
            let match = this.matches[this.current];
            this.addSuggestion(match.key, match.value);
        },
        currentSelectionUp() {
            if (this.current > 0) {
                this.current--;
                this.$refs['autocomplete-container'].scrollTop -= 50;
            }
        },
        currentSelectionDown() {
            if (this.current < this.matches.length - 1) {
                this.current++;
                if (this.current > 4) {
                    this.$refs['autocomplete-container'].scrollTop += 50;
                }
            }
        },
        emitChanges() {
            this.$emit('change', this.selected.map(item => item.key));
            EventBus.$emit('form:element:changed', this.name, this.selected.map(item => item.key).join('-'));
        }
    },
    created() {
        var $vm = this;

        // Closing the suggestion menu with ESC key
        window.addEventListener('keyup', event => {
            if (this.openDropdown && event.keyCode === 27) {
                this.hideSuggestions();
            }
        });
    },
}
</script>