<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Select Hub') }}</h3>
        </div>
        <div class="br-bottom">
            <div class="row row-collapse">
                <div class="column"></div>
                <div class="form-group form-group-addon form-group-simple m-b-0 search-bar search-bar-expand fl-right text-right" v-bind:class="{'search-bar-open': searchVisible}">
                    <label class="m-t-1 p-t-2">
                        <input class="input__field" type="text" v-bind:placeholder="trans('Search')" v-model="query" v-on:keydown.enter.prevent ref="search-field">
                    </label>
                    <div class="form-append p-b-micro">
                        <button class="i-container i-filled-dark c-bright-bg btn btn-text width-auto-min" type="button" v-on:click="toggleSearch">
                            <!-- /icons/search.svg -->
                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hubs.length > 0" class="m-t-3">
            <div class="slat m-b-4 h-anim h-anim-select h-bg-accent--light" v-for="(hub, index) in filteredHubs" v-bind:key="index">
                <a v-on:click.prevent="selectHub(hub)" v-bind:href="hub.status === 'published' ? '#select-hub' : null" class="row row-middle row-compress-indent row-compress p-3">
                    <div class="slat-figure--medium column small-12">
                        <img v-bind:src="hub.image ? hub.image_url.small : hub.placeholder_image_url" v-bind:alt="hub.title" />
                    </div>
                    <div class="slat-content column">
                        <div class="row row-collapse row-middle">
                            <h4 class="card-title c-primary f-s-4 m-b-0">{{ hub.title }}</h4>
                            <div class="m-l-2">
                                <span v-if="hub.status === 'draft'" class="badge f-w-bold f-caps c-bright c-muted-bg">{{ trans('Draft') }}</span>
                            </div>
                        </div>
                        <div class="card-type f-s-2 c-muted m-t-1">{{ hubFeatures(hub) }}</div>
                    </div>
                    <div v-if="hub.status === 'published'" class="slat-aside text-right m-r-4">
                        <div class="btn btn-text btn-text--accent h-anim-select-item">{{ trans('Select Hub') }}</div>
                    </div>
                </a>
            </div>
        </div>
        <div class="m-t-6" v-else>
            <p class="text-center f-w-bold c-dark m-b-1">{{ trans('You currently have no published hubs.') }}</p>
            <p class="text-center c-muted" v-html="onlyPublishedHubs"></p>
        </div>
        <div slot="footer"></div>
    </modal>
</template>
<script>
import Modal from '../modals/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        hubs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            query: '',
            searchVisible: false
        }
    },
    computed: {
        filteredHubs() {
            let filteredHubs = this.hubs;

            if (this.query !== null && this.query.length > 0) {
                filteredHubs = filteredHubs.filter(item => _.startsWith(item.title.toLowerCase(), this.query));
            }

            return filteredHubs;
        },
        onlyPublishedHubs() {
            if (this.$parent.$options._componentTag === 'membership-hubs') {
                return this.trans('Only published hubs can be added to a membership. Please visit the <a href=":link">hubs</a> screen and publish at least one hub to add it to this membership.', {'link': window.Kourses.activeBaseUrl + '/hubs'});
            } else if (this.$parent.$options._componentTag === 'member-hubs') {
                return this.trans('Only published hubs can be added to a member. Please visit the <a href=":link">hubs</a> screen and publish at least one hub to add it to this member.', {'link': window.Kourses.activeBaseUrl + '/hubs'});
            }

            return this.trans('Only published hubs can be added. Please visit the <a href=":link">hubs</a> screen and publish at least one hub.', {'link': window.Kourses.activeBaseUrl + '/hubs'});
        }
    },
    methods: {
        toggleSearch() {
            this.searchVisible = ! this.searchVisible;

            if (this.searchVisible) {
                this.$refs['search-field'].focus();
            }
        },
        selectHub(hub) {
            if (hub.status !== 'published') {
                return;
            }

            this.$emit('select-hub', hub);
        },
        hubFeatures(hub) {
            if (typeof hub.enabled_hub_features == 'undefined') {
                return '';
            }

            return hub.enabled_hub_features.reduce((featuresList, feature) => {
                if (featuresList.length > 0) {
                    featuresList += ', ';
                }

                featuresList += feature.label;

                return featuresList;
            }, '');
        },
    }
}
</script>