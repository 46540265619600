<template>
    <toggle
        v-bind:name="name"
        class-name="form-group form-group-simple m-b-0"
        v-bind:toggled="status"
        v-on:update:toggled="toggleAction"
    ></toggle>
</template>
<script>
export default {
    props: {
        name: {
            type: String
        },
        enabled: {
            type: Boolean,
            default: false
        },
        actionUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            status: this.enabled
        }
    },
    methods: {
        toggleAction(status) {
            if (status) {
                this.enable();
            } else {
                this.disable();
            }
        },
        enable() {
            window.axios
                .put(this.actionUrl)
                .then((response) => {
                    this.status = response.data.enabled ? true : false;
                    this.$emit('update:status', this.status);
                }, (response) => {
                    console.log(response.data);
                });
        },
        disable() {
            window.axios
                .delete(this.actionUrl)
                .then((response) => {
                    this.status = response.data.enabled ? true : false;
                    this.$emit('update:status', this.status);
                }, (error) => {
                    this.$toast.danger(error.response.data.message);

                    // We need to do this "dance" in order to trigger child
                    // component prop change
                    this.status = false;
                    Vue.nextTick(() => {
                        this.status = true;
                    });
                });
        }
    }
}
</script>