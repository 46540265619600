<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <h3 v-if="heading" class="f-s-7 c-primary f-w-bold m-b-5">{{ heading }}</h3>
        <div v-if="items.length > 0" v-for="(item, index) in items" v-bind:key="index" class="faq m-b-4">
            <h4 class="f-s-5 c-primary m-b-2">{{ item.title }}</h4>
            <p class="f-s-4 c-medium">{{ item.text }}</p>
        </div>
    </div>
</template>
<script>
    import Faqs from '../page/Faqs.vue';

    export default {
        extends: Faqs
    }
</script>