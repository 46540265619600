<template>
    <div class="slat-group m-t-5 p-t-3 br p-b-3 m-b-5">
        <div class="slat-group-header row row-middle" v-bind:class="{'br-bottom p-b-3': isExpanded}">
            <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                <span class="i-container i-filled-dark">
                    <span class="handle">
                        <!-- /icons/drag.svg -->
                        <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                    </span>
                </span>
            </div>
            <div class="slat-content column p-l-2">
                <h2 class="f-w-bold f-s-4 m-b-0">
                    <a v-on:click.prevent="toggleBlock" href="#toggle" class="c-primary">{{ trans('Memberships') }}</a>
                </h2>
            </div>
            <div class="slat-aside text-right m-r-4">
                <span class="tooltip animate">
                    <a class="i-container i-filled-dark" href="#delete-memberships" v-on:click.prevent="removeBlock">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                </span>
            </div>
        </div>
        <div v-show="isExpanded" class="slat-group-content p-t-4 m-t-1">
            <div class="row row-center">
                <div class="columns p-l-3 p-r-3">
                    <div class="form-group">
                        <label>
                            {{ trans('Section Heading') }}
                            <input class="input__field" type="text" v-bind:name="name + '[data][heading]'" v-model="heading">
                        </label>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ trans('Section Subtitle') }}
                            <input class="input__field" type="text" v-bind:name="name + '[data][subtitle]'" v-model="subtitle">
                        </label>
                    </div>

                    <div class="row row-collapse row-collapse-indent">
                        <div class="column medium-4">
                            <toggle
                                v-bind:label="trans('Show all memberships')"
                                v-bind:name="name + '[data][show_all_memberships]'"
                                v-bind:toggled.sync="showAllMemberships"
                            ></toggle>
                        </div>
                        <div class="column">
                            <div class="form-group">
                                <label>
                                    {{ trans('Number of Memberships Visible Initialy') }}
                                    <input class="input__field" type="text" v-bind:name="name + '[data][number_of_memberships]'" v-model="numberOfMemberships">
                                </label>
                            </div>
                        </div>
                    </div>

                    <div v-if=" ! showAllMemberships" v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
                        <div class="slat m-t-4 m-b-3" v-for="(membership, index) in selectedMemberships" v-bind:key="membership.id">
                            <div class="br p-t-3 p-b-3">
                                <div v-bind:class="{'br-bottom': expandedMembership === membership.id, 'p-b-3': expandedMembership === membership.id}" class="slat-group-header slat-group-header-small row row-middle">
                                    <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                                        <span class="i-container i-filled-dark">
                                            <span class="handle">
                                                <!-- /icons/drag.svg -->
                                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="slat-content column p-l-2">
                                        <a href="#expand" v-on:click.prevent="toggleExpanded(membership.id)" class="c-primary">
                                            <h4 class="f-w-bold f-s-3 m-b-0">{{ membership.name }}</h4>
                                        </a>
                                    </div>
                                    <div class="slat-aside text-right m-r-4">
                                        <span class="tooltip animate">
                                            <a class="i-container i-filled-dark" href="#delete-membership" v-on:click.prevent="deleteItem(index)">
                                                <!-- /icons/s-trash.svg -->
                                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                            </a>
                                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="expandedMembership === membership.id" class="slat-group-content p-l-4 p-r-4 p-t-4 m-t-1">
                                    <div class="slat m-b-2">
                                        <div class="row">
                                             <a class="c-primary block" v-bind:href="membershipEditLink(membership)">
                                                <img v-bind:src="membership.image ? membership.image_url.small : membership.image_type_url" v-bind:alt="membership.name" />
                                            </a>
                                            <div class="m-t-3">
                                                <div class="card-type f-s-2 c-muted f-w-bold m-b-2">{{ membership.type | startCase }}</div>
                                                <a class="c-primary" v-bind:href="membershipEditLink(membership)">
                                                    <h4 class="card-title c-primary f-s-4 m-b-0">{{ membership.name }}</h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" v-bind:name="name + '[data][items][' + index + ']'" v-bind:value="membership.id" />
                        </div>
                        <div class="text-center p-t-1">
                            <button type="button" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4" v-on:click="addItem">
                                <span class="icon i-container i-filled-accent">
                                    <!-- /icons/add.svg -->
                                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                                </span>
                                {{ trans('Add Membership') }}
                            </button>
                        </div>
                    </div>

                    <div v-if="usesNavbarTitle" class="form-group">
                        <label>
                            {{ trans('Navbar Link Title') }}
                            <input class="input__field" type="text" v-bind:name="name + '[data][navbar_title]'" v-model="navbarTitle">
                        </label>
                    </div>

                    <membership-picker-modal
                        v-bind:memberships="filteredMemberships"
                        v-on:close="closeModal"
                        v-on:select-membership="selectMembership"
                        ref="membership-picker-modal"
                    ></membership-picker-modal>

                    <input v-if="block.id" type="hidden" v-bind:name="name + '[id]'" v-bind:value="block.id" />
                    <input type="hidden" v-bind:name="name + '[type]'" value="memberships" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '../../../event-bus.js';
import Block from './Block.vue';
import NavbarTitleMixin from './NavbarTitleMixin.vue';
import MembershipPickerModal from '../../memberships/MembershipPickerModal.vue';
import { membershipEditLink } from '../../../routes.js';

export default {
    extends: Block,
    components: {
        MembershipPickerModal,
    },
    mixins: [
        NavbarTitleMixin
    ],
    props: {
        name: {
            type: String
        },
        block: {
            type: Object
        },
        index: {
            type: Number
        }
    },
    data() {
        return {
            heading: _.get(this.block, 'data.heading', ''),
            subtitle: _.get(this.block, 'data.subtitle', ''),
            memberships: _.get(this.$parent, 'data.memberships', []),
            selectedMemberships: [],
            selectedMembershipIds: _.get(this.block, 'data.items', []),
            expandedMembership: null,
            showAllMemberships: _.get(this.block, 'data.show_all_memberships', '1') === '1' ? true : false,
            numberOfMemberships: _.get(this.block, 'data.number_of_memberships', 6)
        }
    },
    watch: {
        heading(newValue) {
            EventBus.$emit('block:update:value', this.index, 'heading', newValue);
        },
        subtitle(newValue) {
            EventBus.$emit('block:update:value', this.index, 'subtitle', newValue);
        },
        numberOfMemberships(newValue) {
            EventBus.$emit('block:update:value', this.index, 'numberOfMemberships', parseInt(newValue, 10));
        },
        showAllMemberships(newValue) {
            EventBus.$emit('block:update:value', this.index, 'showAllMemberships', newValue);
        },
        selectedMembershipIds(newValue) {
            EventBus.$emit('block:update:value', this.index, 'selectedMembershipIds', newValue);
        }
    },
    computed: {
        filteredMemberships() {
            return this.memberships.filter((membership) => {
                return _.indexOf(this.selectedMembershipIds, membership.id) < 0 && _.indexOf(this.selectedMembershipIds, membership.id + '') < 0;
            });
        }
    },
    methods: {
        toggleExpanded(id) {
            if (this.expandedMembership === id) {
                this.expandedMembership = null;
            } else {
                this.expandedMembership = id;
            }
        },
        addItem() {
            this.$refs['membership-picker-modal'].show = true;
        },
        selectMembership(membership) {
            this.$refs['membership-picker-modal'].show = false;
            this.selectedMemberships.push(membership);
            this.selectedMembershipIds.push(membership.id);
            this.expandedMembership = membership.id;
        },
        deleteItem(index) {
            this.selectedMemberships.splice(index, 1);
            this.selectedMembershipIds.splice(index, 1);
        },
        onUpdate(event) {
            this.selectedMemberships.splice(event.newIndex, 0, this.selectedMemberships.splice(event.oldIndex, 1)[0]);
            this.selectedMembershipIds.splice(event.newIndex, 0, this.selectedMembershipIds.splice(event.oldIndex, 1)[0]);
        },
        updateAllMemberships(toggled) {
            this.showAllMemberships = toggled;
        },
        closeModal() {
            this.$refs['membership-picker-modal'].show = false
        },
        membershipEditLink(membership) {
            return membershipEditLink(membership);
        },
    },
    created() {
        if (this.selectedMembershipIds.length && this.memberships.length) {
            this.selectedMembershipIds.forEach((id) => {
                this.selectedMemberships.push(this.memberships.find((item) => {
                    return id == item.id;
                }));
            });
        }
    }
}
</script>