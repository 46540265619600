<template>
    <img v-bind:src="src" v-on:error="replaceWithFallback">
</template>
<script>
export default {
    props: {
        src: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            fallbackImageUrl: window.Kourses.assetBaseUrl + 'img/avatar.png',
        }
    },
    methods: {
        replaceWithFallback(event) {
            // Prevent "infinite" loading if our placeholder URL is missing
            if (event.target.src === this.fallbackImageUrl) {
                return;
            }

            event.target.src = this.fallbackImageUrl;
        }
    }
}
</script>