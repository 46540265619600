<template>
    <div class="form-group-addon">
        <label>
            {{ label }}
            <input type="text" v-bind:name="name" class="input__field" v-bind:placeholder="placeholder" v-bind:value="value" v-on:input="updateValue" ref="input" />
        </label>
        <dropdown-menu class="form-append dropdown--z-above-redactor">
            <span class="text-right f-s-2" slot="trigger">{x}</span>
            <div v-for="(title, variable) in variables" v-bind:key="variable">
                <a v-on:click.prevent="insert(variable)" href="#insert" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-4 block h-anim h-bg-accent--light">{{ title }}</a>
            </div>
        </dropdown-menu>
    </div>
</template>
<script>
    import DropdownMenu from '../DropdownMenu.vue';

    export default {
        components: {
            DropdownMenu
        },
        props: {
            variables: {
                type: Object,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            label: {
                type: String
            },
            value: {
                type: String
            },
            placeholder: {
                type: String
            }
        },
        methods: {
            insert(variable) {
                var input = this.$refs.input;
                var position = input.selectionStart;

                input.value = input.value.substr(0, position) + '{' + variable + '}' + input.value.substr(position);

                this.$emit('update:value', input.value);
            },
            updateValue(event) {
                this.$emit('update:value', event.target.value);
            }
        }
    }
</script>