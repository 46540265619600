<template>
    <div>
        <div v-if="allowNewPlanToBeAdded" class="card br m-b-4">
            <div class="card-content-item expandable" v-bind:class="{'expandable-open': expanded}">
                <div class="expand-header row row-center p-t-1 p-b-1">
                    <a class="btn-icon btn-icon--right c-accent btn-text btn" href="#expanded" v-on:click.prevent="expanded = ! expanded">
                        {{ trans('Add New Pricing Plan') }}
                        <span class="i-container i-filled-accent">
                            <!-- /icons/open-alt.svg -->
                            <svg class="icon icon-expand i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.664 7.253L2.336 8.747 12 17.338l9.664-8.59-1.328-1.495L12 14.662z" class="icon"/></svg>
                        </span>
                    </a>
                </div>
                <div class="expand-content" v-show="expanded">
                    <div class="card-content">
                        <div class="p-b-4 p-l-5 p-r-5">
                            <pricing-plan
                                ref="new-pricing-plan"
                                v-bind:errors="errors"
                                v-bind:selected-type="selectedPlanType"
                                v-bind:allowed-types="allowedTypes"
                                v-bind:has-payment-integrations="hasPaymentIntegrations"
                            ></pricing-plan>
                        </div>
                        <div class="card-footer p-l-5 p-r-5 p-b-6">
                            <button type="button" class="btn btn-primary m-r-3" v-on:click="addPlan">{{ trans('Add Pricing') }}</button>
                            <button type="button" class="btn btn-text btn-text--muted width-auto-min" v-on:click="cancelPlan">{{ trans('Cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="plans.length > 0" v-sortable="{ handle: '.handle', onUpdate }">
            <div v-for="(plan, index) in plans" v-bind:key="getKey(plan)" class="product-card card br p-t-3 p-b-3 m-b-4" v-bind:data-encoded-id="plan.encoded_id">
                <div class="card-content row row-middle row-compress">
                    <div v-if="plans.length > 1" class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                        <span class="i-container i-filled-dark">
                            <span class="handle">
                                <!-- /icons/drag.svg -->
                                <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                            </span>
                        </span>
                    </div>
                    <div class="slat-figure--medium column small-12">
                        <img v-bind:src="assetUrl + '/img/placeholder-image.png'">
                    </div>
                    <div class="slat-content column">
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ plan.name }}</h4>
                        <p class="c-medium-tint f-s-3">{{ describePlan(plan) }}</p>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <a class="btn btn-text btn-text--accent open-modal" data-modal="modal-plan" v-on:click="loadPlan(plan, index)">{{ trans('Edit Plan') }}</a>
                    </div>
                </div>
                <input type="hidden" v-bind:name="'plans[' + index + '][id]'" v-bind:value="plan.id" />
                <input type="hidden" v-bind:name="'plans[' + index + '][name]'" v-bind:value="plan.name" />
                <input type="hidden" v-bind:name="'plans[' + index + '][description]'" v-bind:value="plan.description" />
                <input type="hidden" v-bind:name="'plans[' + index + '][type]'" v-bind:value="plan.type" />
                <input type="hidden" v-bind:name="'plans[' + index + '][price]'" v-bind:value="plan.price" />
                <input type="hidden" v-bind:name="'plans[' + index + '][setup_price]'" v-bind:value="plan.setup_price" />
                <input type="hidden" v-bind:name="'plans[' + index + '][currency]'" v-bind:value="plan.currency" />
                <input type="hidden" v-bind:name="'plans[' + index + '][interval]'" v-bind:value="plan.interval" />
                <input type="hidden" v-bind:name="'plans[' + index + '][interval_count]'" v-bind:value="plan.interval_count" />
                <input type="hidden" v-bind:name="'plans[' + index + '][trial_period_days]'" v-bind:value="plan.trial_period_days" />
            </div>
        </div>
        <pricing-plan-modal
            v-on:save="savePlan"
            v-on:close="modal.show = false"
            v-on:delete="deletePlan"
            ref="pricing-plan-modal"
            v-bind:has-payment-integrations="hasPaymentIntegrations"
        ></pricing-plan-modal>
        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:value="id" v-bind:key="id" />
    </div>
</template>
<script>
import { PLAN_TYPE_FREE, PLAN_TYPE_INSTALLMENT_PLAN, PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION } from '../constants.js';
import { EventBus } from '../event-bus.js';
import Errors from '../errors.js';
import Orderable from '../mixins/Orderable.js';
import TranslateInterval from '../mixins/TranslateInterval.js';
import md5 from 'js-md5';

export default {
    mixins: [
        TranslateInterval,
        Orderable
    ],
    props: [
        'existingPlans', 'hasPaymentIntegrations'
    ],
    data() {
        return {
            plans: this.existingPlans.map((plan) => {
                plan.price /= 100;
                plan.setup_price /= 100;
                return plan;
            }),
            modal: null,
            expanded: this.existingPlans.length === 0 ? true : false,
            forRemoval: [],
            errors: new Errors(),
            model: 'Kourses\\PricingPlan'
        }
    },
    computed: {
        assetUrl() {
            return Kourses.baseUrl;
        },
        allowNewPlanToBeAdded() {
            if (this.plans.length === 0) {
                return true;
            }

            if ([PLAN_TYPE_FREE].includes(this.plans[0].type)) {
                return false;
            }

            return this.plans.length < 2;
        },
        selectedPlanType() {
            if (this.plans.length === 0) {
                return PLAN_TYPE_FREE;
            }

            return this.allowedTypes[0];
        },
        allowedTypes() {
            if (this.plans.length > 0) {
                if (this.plans[0].type === PLAN_TYPE_ONE_TIME) {
                    return [PLAN_TYPE_SUBSCRIPTION, PLAN_TYPE_INSTALLMENT_PLAN];
                }

                return [PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION, PLAN_TYPE_INSTALLMENT_PLAN];
            }

            return [PLAN_TYPE_FREE, PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION, PLAN_TYPE_INSTALLMENT_PLAN];
        },
    },
    watch: {
        plans() {
            Vue.nextTick(() => {
                console.log(this.getPlansHash());
                EventBus.$emit('form:element:changed', 'pricing_plans', this.getPlansHash());
            });
        },
    },
    methods: {
        describePlan(plan) {
            let description = '';

            if (plan.description) {
                description = plan.description + ' - ';
            }

            return description + this.formattedPrice(plan);
        },
        formattedPrice(plan) {
            if (plan.type === PLAN_TYPE_FREE) {
                return this.trans('Free');
            }

            const formattedPrice = new Intl.NumberFormat(window.Kourses.locale, {style: 'currency', currency: plan.currency}).format(plan.price);
            const formattedSetupPrice = new Intl.NumberFormat(window.Kourses.locale, {style: 'currency', currency: plan.currency}).format(plan.setup_price);

            if (plan.type === PLAN_TYPE_ONE_TIME || (plan.type === PLAN_TYPE_INSTALLMENT_PLAN && plan.interval_count === 1)) {
                return formattedPrice;
            }

            const withSetupPrice = plan.setup_price !== null && plan.setup_price > 0
                ? this.trans(' with :price setup fee', {'price': formattedSetupPrice})
                : '';

            if (plan.type === PLAN_TYPE_INSTALLMENT_PLAN) {
                return this.trans(':price for :installments :interval:setup', {'price': formattedPrice, 'installments': plan.interval_count, 'interval': this.translateInterval(plan.interval, 'plural'), 'setup': withSetupPrice});
            }

            if (parseInt(plan.trial_period_days, 10)) {
                return this.trans(':price per :interval after :trial-day free trial:setup', {'price': formattedPrice, 'interval': this.translateInterval(plan.interval), 'trial': plan.trial_period_days, 'setup': withSetupPrice});
            }

            return this.trans(':price per :interval:setup', {'price': formattedPrice, 'interval': this.translateInterval(plan.interval), 'setup': withSetupPrice});
        },
        loadPlan(plan, index) {
            this.modal.plan = plan;
            this.modal.index = index;
            this.modal.show = true;
        },
        addPlan() {
            this.errors.clear();

            this.$refs['new-pricing-plan'].generateName();

            let plan = _.clone(this.$refs['new-pricing-plan'].$data);

            plan.interval_count = plan.intervalCount;
            plan.trial_period_days = plan.trialPeriodDays;
            plan.setup_price = plan.setupPrice;

            if (plan.type === PLAN_TYPE_INSTALLMENT_PLAN && (plan.interval_count === '' || parseInt(plan.interval_count) < 2)) {
                this.errors.set('installments', this.trans('Number of monthly payments needs to be at least 2'));

                return false;
            }

            if ((plan.type === PLAN_TYPE_INSTALLMENT_PLAN || plan.type === PLAN_TYPE_SUBSCRIPTION)
            && plan.trial_period_days !== '' && parseInt(plan.trial_period_days) > 730) {
                this.errors.set('trial_period_days', this.trans('Trial period cannot exceed 2 years (730 days)'));

                return false;
            }

            this.plans.push(plan);
            this.cancelPlan();

            this.$refs['new-pricing-plan'].resetPlan();
        },
        savePlan(plan, index) {
            this.plans.splice(index, 1, plan);
            this.modal.show = false;
            this.modal.reset();
        },
        deletePlan(plan, index) {
            this.$dialog.confirm(this.trans("If you delete your pricing plan, it will no longer be available on your checkout page. Checkout pages with no pricing plans will no longer be available for your customers.<br/><br/>We recommend ensuring you have at least one pricing plan available at all times.<br /><br/>Are you sure you wish to delete this pricing plan?"))
                .then(() => {
                    // If plan was saved earlier we need to set it for removal
                    if (_.has(plan, 'id')) {
                        this.forRemoval.push(plan.id);
                    }

                    // Remove plan from plan list (only UI)
                    this.plans.splice(index, 1);

                    // Close modal
                    this.modal.show = false;
                    this.modal.reset();
                });
        },
        cancelPlan() {
            this.expanded = false;
        },
        getKey(plan) {
            return Symbol(plan);
        },
        getPlansHash() {
            return md5(JSON.stringify(this.plans));
        },
    },
    mounted() {
        this.modal = this.$refs['pricing-plan-modal'];

        // I don't know why this needs to go in the next tick but it doesn't work otherwise
        Vue.nextTick(() => {
            EventBus.$emit('form:element:initialize', 'pricing_plans', this.getPlansHash());
        });
    }
}
</script>