<template>
    <div class="alert p-t-2 p-b-2">
        <div class="row row-middle">
            <div v-if="icon === 'signup'" class="c-bright-bg i-container i-filled-theme inline m-a p-1 rounded-xlarge m-t-0">
                <!-- /icons/s-ra-4.svg -->
                <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M10.846 4.999c0-2.121-1.726-3.846-3.846-3.846S3.154 2.878 3.154 4.999c0 1.003.396 1.91 1.026 2.596-.11.057-.225.106-.333.169C1.813 8.956.5 11.285.5 13.696V15a1 1 0 1 0 2 0v-1.304c0-1.716.926-3.367 2.358-4.207a4.748 4.748 0 0 1 2.186-.646c.009 0 .016-.005.023-.006a3.845 3.845 0 0 0 3.779-3.838zM7 3.153c1.018 0 1.846.828 1.846 1.846S8.018 6.845 7 6.845s-1.846-.828-1.846-1.846S5.982 3.153 7 3.153zM14.5 11h-2V9a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2z" fill="#6F6FFF" class="icon"/></svg>
            </div>
            <div v-else-if="icon === 'purchase'" class="c-bright-bg i-container i-filled-theme inline m-a p-1 rounded-xlarge m-t-0">
                <!-- /icons/s-ra-2.svg -->
                <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M15.496 9.257a.999.999 0 0 0-1.364.372l-1.938 3.391-1.094-.82a1 1 0 1 0-1.199 1.6l2 1.5a.997.997 0 0 0 1.468-.304l2.5-4.375a1 1 0 0 0-.373-1.364z"/><path d="M15 3H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h7.5v-2H2V8.5h14V4a1 1 0 0 0-1-1zm-1 3.5H2V5h12v1.5z"/><path d="M4 9.35a.9.9 0 0 0 0 1.8h2.896a.9.9 0 0 0 0-1.8H4z"/></g></svg>
            </div>
            <div v-else-if="icon === 'failed_payment'" class="c-bright-bg i-container i-filled-stopped inline m-a p-1 rounded-xlarge m-t-0">
                <!-- /icons/s-ra-1.svg -->
                <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M4 9.35a.9.9 0 0 0 0 1.8h2.896a.9.9 0 0 0 0-1.8H4zm11.707-.057a.999.999 0 0 0-1.414 0L12.5 11.086l-1.793-1.793a.999.999 0 1 0-1.414 1.414l1.793 1.793-1.793 1.793a.999.999 0 1 0 1.414 1.414l1.793-1.793 1.793 1.793a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L13.914 12.5l1.793-1.793a.999.999 0 0 0 0-1.414z"/><path d="M15 3H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h7.5v-2H2V8.5h14V4a1 1 0 0 0-1-1zm-1 3.5H2V5h12v1.5z"/></g></svg>
            </div>
            <div v-else-if="icon === 'cancellation'" class="c-bright-bg i-container i-filled-error inline m-a p-1 rounded-xlarge m-t-0">
                <!-- /icons/s-ra-3.svg -->
                <svg class="icon i-size-tiny" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M3.1 10.25a.9.9 0 0 0 .9.9h2.777l.491-1.8H4a.9.9 0 0 0-.9.9z"/><path d="M2 12V8.5h5.5l.546-2H2V5h6.454L9 3H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h5l.546-2H2zm13-9h-2.377l.341-1.234a1 1 0 0 0-1.927-.532L10.549 3l-2.295 8.322-.188.678-.552 2-.479 1.734a1 1 0 1 0 1.929.532L9.589 14H15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 9h-3.859l.966-3.5H14V12zm0-5.5h-2.342l.414-1.5H14v1.5z"/></g></svg>
            </div>
            <div class="column">
                <p class="m-0 f-s-2 c-darker" v-html="message"></p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            icon: {
                type: String,
                default: 'purchase'
            },
            message: {
                type: String
            }
        }
    }
</script>