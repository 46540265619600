<template>
    <div v-on:click="toggleMenu" v-on-clickaway="closeMenu" class="dropdown-container btn btn-icon--right btn-text width-auto-min p-l-0 p-r-0 p-b-0 p-t-0 i-container">
        <slot name="trigger">{{ trans('Menu') }}</slot>
        <span v-if="showCaretIcon" class="text-right p-l-1 i-filled-dark">
            <!-- /icons/caret-down.svg -->
            <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z"/><path fill="#000" class="icon" d="M7 10l5 6 5-6z"/></g></svg>
        </span>
        <transition name="show">
            <div v-if="show" v-bind:class="`dropdown dropdown--width-${width} dropdown--bd dropdown-p--right block`">
                <div class="dropdown-group text-left">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        showCaretIcon: {
            type: Boolean,
            default: true,
        },
        width: {
            type: String,
            default: 'default',
        },
        closeOnClick: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        openMenu() {
            this.show = true;
            this.$emit('open');
        },
        toggleMenu() {
            if (this.closeOnClick === false && this.show === true) {
                return;
            }

            this.show = ! this.show;
            this.$emit(this.show ? 'open' : 'close');
        },
        closeMenu() {
            this.show = false;
            this.$emit('close');
        },
    },
    created() {
        window.addEventListener('keyup', event => {
            if (this.show && event.keyCode === 27) {
                this.closeMenu();
            }
        });
    },
}
</script>