<template>
    <div>
        <div v-bind:class="{'has-error': errors.has('subdomain') && subdomainDirty, 'has-success': subdomainValid && subdomainChecked}" class="form-group">
            <div class="form-group-addon">
                <input
                    class="input__field"
                    type="text"
                    name="subdomain"
                    v-model="subdomain"
                    v-on:keyup="validateSubdomain"
                    required>
                <span class="form-append f-s-4">.{{ websiteDomain }}</span>
            </div>
        </div>
         <div v-if="errors.has('subdomain')" class="form-help">
            {{ errors.get('subdomain') }}
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../../event-bus.js';
    import Errors from '../../errors.js';

    export default {
        data: function() {
            return {
                checkingSubdomain: false,
                subdomainChecked: false,
                subdomainValid: false,
                errors: this.dataErrors ? new Errors(this.dataErrors) : new Errors(),
                subdomain: this.dataSubdomain,
                oldSubdomain: this.dataOldSubdomain
            }
        },

        props: [
            'id',
            'dataErrors',
            'dataSubdomain',
            'dataOldSubdomain',
            'websiteDomain',
            'websiteProtocol'
        ],

        computed: {
            subdomainDirty() {
                return ! this.checkingSubdomain && ! this.subdomainValid;
            }
        },

        methods: {
            validateSubdomain: _.debounce(function(event) {
                if (this.subdomain === this.oldSubdomain) {
                    EventBus.$emit('form-valid');
                    this.subdomainValid = true;
                    this.subdomainChecked = false;
                    this.errors.clear();
                } else if (this.subdomain.length > 2) {
                    this.checkingSubdomain = true;
                    this.errors.record({'subdomain': [this.trans('Checking subdomain availability...')]});
                    window.axios
                        .post(Kourses.apiBaseUrl + '/websites/check-subdomain', {'subdomain': this.subdomain, 'id': this.id})
                        .then((response) => {
                            this.checkingSubdomain = false;
                            this.subdomainChecked = true;
                            this.subdomainValid = true;
                            this.errors.clear();
                            EventBus.$emit('form-valid');
                        })
                        .catch((error) => {
                            this.checkingSubdomain = false;
                            this.subdomainChecked = true;
                            this.subdomainValid = false;
                            this.errors.record(error.response.data);
                            EventBus.$emit('form-invalid');
                        });
                } else if (this.subdomain.length < 3 && this.subdomainChecked) {
                    this.subdomainValid = false;
                    this.errors.clear();
                    EventBus.$emit('form-invalid');
                }
            }, 500),
        },

        created() {
            this.oldSubdomain = this.subdomain;

            if (this.subdomain.length > 0 && typeof this.errors.has('subdomain') === false) {
                this.subdomainValid = true;
                EventBus.$emit('form-valid');
            }
        }
    }
</script>
