<template>
    <dropdown-menu
        style="height: 25px;"
        v-bind:show-caret-icon="false">
        <span class="text-right f-s-2" slot="trigger">&bull;&bull;&bull;</span>

        <div>
            <a v-bind:href="memberShowLink()" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('View Profile') }}</a>
        </div>
        <div>
            <a v-bind:href="memberEditLink()" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Edit Member') }}</a>
        </div>
        <div v-if="member.activated_at === null">
            <a v-on:click.prevent="sendActivationEmail" href="#send-activation-email" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Send Activation Email') }}</a>
        </div>
        <div>
            <a v-on:click.prevent="sendResetPasswordEmail" href="#send-password-reset" class="c-primary p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 block h-anim h-bg-accent--light">{{ trans('Send Password Reset') }}</a>
        </div>

        <div v-if="canDeleteMembers" class="br-top"></div>
        <div v-if="canDeleteMembers" >
            <form method="POST" v-bind:action="memberDeleteLink()">
                <input type="hidden" name="_token" v-bind:value="csrfToken" />
                <input type="hidden" name="_method" value="DELETE" />
                <button v-confirm="{'loader': true, 'message': trans('This will delete all member content including its content and lesson progress.'), 'anotherMessage': trans('Confirm you would still like to delete this member?')}" type="submit" class="c-error p-t-2 p-b-2 p-l-4 p-r-4 f-s-3 btn-block btn-block--left h-anim h-bg-accent--light cu-p">{{ trans('Delete Member') }}</button>
            </form>
        </div>
    </dropdown-menu>
</template>
<script>
import { memberDeleteLink, memberEditLink, memberShowLink, memberSendResetPasswordLink, memberSendActivationLinkLink } from '../../routes';
import DropdownMenu from '../DropdownMenu.vue';

export default {
    components: {
        DropdownMenu
    },
    props: {
        member: {
            type: Object
        },
        canDeleteMembers: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            csrfToken: window.Laravel.csrfToken,
        }
    },
    methods: {
        memberEditLink() {
            return memberEditLink(this.member);
        },
        memberDeleteLink() {
            return memberDeleteLink(this.member);
        },
        memberShowLink() {
            return memberShowLink(this.member);
        },
        sendResetPasswordEmail() {
            window.axios.post(memberSendResetPasswordLink(this.member)).then((response) => {
                this.$toast.success(response.data.message);
            }).catch((error) => {
                console.warn(error);
                this.$toast.danger(this.trans('Unknown error occured. Reset password email not sent!'));
            });
        },
        sendActivationEmail() {
            window.axios.post(memberSendActivationLinkLink(this.member)).then((response) => {
                this.$toast.success(response.data.message);
            }).catch((error) => {
                console.warn(error);
                this.$toast.danger(this.trans('Unknown error occured. Activation email not sent!'));
            });
        },
    },
}
</script>