<template>
    <svg v-bind:viewBox="viewBox">
        <polygon
            v-bind:fill="fillColor"
            stroke="none"
            v-bind:points="areaPoints"
        ></polygon>
        <polyline
            fill="none"
            v-bind:stroke="strokeColor"
            stroke-width="4"
            v-bind:points="linePoints"
        ></polyline>
    </svg>
</template>
<script>
    export default {
        props: {
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 215
            },
            heightCompensation: {
                type: Number,
                default: 100
            },
            strokeColor: {
                type: String,
                default: '#28d6b1'
            },
            fillColor: {
                type: String,
                default: '#f4fdfb'
            },
            dataset: {
                type: Array
            }
        },
        data() {
            return {
                linePoints: this.convertedDataset()
            }
        },
        watch: {
            dataset(value) {
                this.linePoints = this.convertedDataset();
            }
        },
        computed: {
            viewBox() {
                return '0 0 ' + this.width + ' ' + this.height;
            },
            areaPoints() {
                return this.linePoints + ' ' + this.width + ',' + this.height + ' 0,' + this.height;
            }
        },
        methods: {
            convertedDataset() {
                var count = this.dataset.length;
                var increment = parseInt(this.width / count, 10);
                var maxHeight = this.height - this.heightCompensation;

                // Get min and max to limit the chart data
                var limits = this.dataset.reduce((limits, value) => {
                    if (value < limits.min) {
                        limits.min = value;
                    }
                    if (value > limits.max) {
                        limits.max = value;
                    }

                    return limits;
                }, {min: maxHeight, max: 0});

                // Special case when there is no data at all (everything is a zero)
                if (limits.min === 0 && limits.max === 0) {
                    return `0,${maxHeight} ${this.width},${maxHeight}`;
                }

                var coef = maxHeight / (limits.max - limits.min);

                return this.dataset.reduce((points, value, index) => {
                    var x = index * increment;
                    // var y = maxHeight - value;
                    var y = maxHeight - parseInt((value - limits.min) * coef, 10);

                    if (index === 0) {
                        x = 0;
                    } else if (index === count -1) {
                        x = this.width;
                    }

                    return points + x + ',' + y + ' ';
                }, '');
            }
        }
    }
</script>