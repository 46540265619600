export default {
    data() {
        return {
            orderableParentKey: null,
        }
    },
    methods: {
        getParentIdFromEvent(event) {
            if (this.orderableParentKey === null) {
                return null;
            }

            return event.to.getAttribute('data-' + this.orderableParentKey.replaceAll('_', '-'));
        },
        getEncodedIdFromEvent(event) {
            return event.item.getAttribute('data-encoded-id');
        },
        onUpdate(event) {
            this.updateOrder(
                this.getEncodedIdFromEvent(event),
                event.newIndex + 1,
                this.getParentIdFromEvent(event)
            );
        },
        updateOrder(encodedId, newOrder, parentId = null) {
            if ( ! encodedId) {
                return;
            }

            window.axios
                .post(Kourses.apiBaseUrl + '/order/change', {
                    'id': encodedId,
                    'parent_id': parentId,
                    'parent_key': this.orderableParentKey,
                    'order': newOrder,
                    'model': this.model,
                });
        },
    }
}
