<template>
    <div>
        <div class="form-group">
            <label class="control-label">{{ trans('Location') }}</label>
            <select name="location" class="form-control" v-model="selectedLocation">
                <option v-for="item in locations" v-bind:key="item.id" v-bind:value="item.id">{{ item.name }}</option>
            </select>
        </div>
        <div v-if="selectedLocation === 'course'" class="form-group" v-bind:class="{ 'has-error' : error !== '' }">
            <label class="control-label">{{ trans('Hubs') }}</label>
            <select name="location_id" class="form-control" v-model="selectedLocationId">
                <option value="">{{ trans('Select a hub') }}</option>
                <option v-for="item in hubs" v-bind:key="item.encoded_id" v-bind:value="item.encoded_id">{{ item.title }}</option>
            </select>
        </div>
        <div v-if="error !== ''" class="form-help">{{ error }}</div>
    </div>
</template>
<script>
export default {
    props: {
        location: {
            type: String,
            required: true,
        },
        locationId: {
            type: String,
            require: false,
        },
        hubs: {
            type: Array,
            required: true,
        },
        locations: {
            type: Array,
            required: true,
        },
        error: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            selectedLocation: this.location,
            selectedLocationId: this.locationId,
        }
    }
}
</script>