<template>
    <div class="slat-group m-t-5 p-t-3 br p-b-3 m-b-5">
        <div class="slat-group-header row row-middle" v-bind:class="{'br-bottom p-b-3': isExpanded}">
            <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                <span class="i-container i-filled-dark">
                    <span class="handle">
                        <!-- /icons/drag.svg -->
                        <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                    </span>
                </span>
            </div>
            <div class="slat-content column p-l-2">
                <h2 class="f-w-bold f-s-4 m-b-0">
                    <a v-on:click.prevent="toggleBlock" href="#toggle" class="c-primary">{{ trans('Featured Membership') }}</a>
                </h2>
            </div>
            <div class="slat-aside text-right m-r-4">
                <span class="tooltip animate">
                    <a class="i-container i-filled-dark" href="#delete-memberships" v-on:click.prevent="removeBlock">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                </span>
            </div>
        </div>
        <div v-show="isExpanded" class="slat-group-content p-t-4 m-t-1">
            <div class="row row-center">
                <div class="columns p-l-3 p-r-3">
                    <div v-sortable="{ onUpdate: onUpdate, handle: '.handle' }">
                        <featured-membership-block-item
                            v-for="(item, index) in selectedMemberships"
                            v-bind:key="item.key"
                            v-bind:item="item"
                            v-bind:field-name="name"
                            v-bind:index="index"
                            v-bind:expanded="expandedMembership === item.key"
                            v-on:delete="deleteItem"
                            v-on:update:expanded="toggleExpanded"
                        ></featured-membership-block-item>
                        <div class="text-center p-t-1">
                            <button type="button" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4" v-on:click="addItem">
                                <span class="icon i-container i-filled-accent">
                                    <!-- /icons/add.svg -->
                                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                                </span>
                                {{ trans('Add Membership') }}
                            </button>
                        </div>
                    </div>

                    <membership-picker-modal
                        v-bind:memberships="filteredMemberships"
                        v-on:close="modal.show = false"
                        v-on:select-membership="selectMembership"
                        ref="membership-picker-modal"
                    ></membership-picker-modal>

                    <input v-if="block.id" type="hidden" v-bind:name="name + '[id]'" v-bind:value="block.id" />
                    <input type="hidden" v-bind:name="name + '[type]'" value="featured_membership" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '../../../event-bus.js';
import Block from './Block.vue';
import FeaturedMembershipBlockItem from './FeaturedMembershipBlockItem.vue';
import MembershipPickerModal from '../../memberships/MembershipPickerModal.vue';

export default {
    extends: Block,
    components: {
        FeaturedMembershipBlockItem,
        MembershipPickerModal
    },
    data() {
        let key = 0;
        return {
            memberships: _.get(this.$parent, 'data.memberships', []),
            selectedMemberships: _.get(this.block, 'data.items', []).map((item) => {
                item.key = ++key;
                return item;
            }),
            selectedMembershipIds: [],
            key: key,
            expandedMembership: null
        }
    },
    computed: {
        filteredMemberships() {
            return this.memberships.filter((membership) => {
                return _.indexOf(this.selectedMembershipIds, membership.id) < 0 && _.indexOf(this.selectedMembershipIds, membership.id + '') < 0;
            });
        }
    },
    watch: {
        selectedMemberships(items) {
            EventBus.$emit('block:update:value', this.index, 'items', items);
        }
    },
    methods: {
        toggleExpanded(key) {
            if (this.expandedMembership === key) {
                this.expandedMembership = null;
            } else {
                this.expandedMembership = key;
            }
        },
        addItem() {
            this.modal.show = true;
        },
        selectMembership(membership) {
            this.modal.show = false;
            this.selectedMemberships.push({
                key: ++this.key,
                name: membership.name,
                description: membership.description,
                image: {
                    path: membership.image,
                    url: _.get(membership, 'image_url.full', '')
                },
                call_to_action: 'Learn More',
                membership_id: membership.id,
                color: '#000000',
                opacity: 50
            });
            this.selectedMembershipIds.push(membership.id);
            this.expandedMembership = this.key;
        },
        deleteItem(index) {
            this.selectedMemberships.splice(index, 1);
            this.selectedMembershipIds.splice(index, 1);
        },
        onUpdate: function (event) {
            this.selectedMemberships.splice(event.newIndex, 0, this.selectedMemberships.splice(event.oldIndex, 1)[0]);
        }
    },
    mounted() {
        this.modal = this.$refs['membership-picker-modal'];
    },
    created() {
        if (this.selectedMemberships.length && this.memberships.length) {
            this.selectedMembershipIds = this.selectedMemberships.map(item => parseInt(item.membership_id));

            // To trigger reactivty and data sync in page builder
            this.$emit('update:items', this.selectedMemberships);
        }
    }
}
</script>