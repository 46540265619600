<template>
    <modal v-bind:show.sync="show" ref="modal" class="text-left">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Preview') }}</h3>
        </div>
        <div v-if="thankYouPageUrl" class="dropdown-item">
            <a class="row row-collapse row-middle p-4" v-bind:href="thankYouPageUrl" target="_blank">
                <img class="icon i-size-medium" v-bind:src="assetUrl('img/icons/lg-2.png')">
                <div class="column c-primary f-size-small m-l-3 m-r-3">{{ trans('Thank You Page') }}</div>
                <div class="text-right">
                    <span class="i-container i-filled-dark">
                        <!-- /icons/out-link-small.svg -->
                        <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M18.125 5H12v1.75h4.025l-5.513 5.513 1.225 1.224 5.513-5.512V12H19V5.875C19 5.35 18.65 5 18.125 5z"/><path d="M17.25 19H5.875C5.35 19 5 18.65 5 18.125V6.75c0-.525.35-.875.875-.875h3.5v1.75H6.75v9.625h9.625v-2.625h1.75v3.5c0 .525-.35.875-.875.875z"/></g></svg>
                    </span>
                </div>
            </a>
        </div>
        <div slot="footer"></div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import { assetUrl } from '../../helpers.js';
import Modal from './Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        thankYouPageUrl: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        assetUrl(path) {
            return assetUrl(path);
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:preview', () => {
            $vm.show = true;
        });
    }
}
</script>