<template>
    <div class="alert-box alert-box--right alert-box--top">
        <toast
            v-for="(toast, index) in toasts"
            v-bind:key="index"
            v-bind:type="toast.type"
            v-bind:index="index"
            v-bind:message="toast.message"
            v-on:close="destroy"
        ></toast>
    </div>
</template>
<script>
    import Toast from './Toast.vue';

    export default {
        components: {
            Toast
        },
        data() {
            return {
                toasts: []
            }
        },
        methods: {
            commit(data) {
                this.toasts.push(data);
            },
            destroy(index) {
                this.$delete(this.toasts, index);
            }
        }
    }
</script>