<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div v-if="heading || subtitle" class="text-center m-b-5">
            <h4 v-if="heading" class="c-primary f-s-8 f-w-bold m-b-3">{{ heading }}</h4>
            <p v-if="subtitle" class="f-s-3 c-medium-tint">{{ subtitle }}</p>
        </div>
        <div class="card-group m-b-5">
            <div class="row row-collapse-indent row-center">
                <div v-for="plan in plans" class="column large-5 medium-6 small-6 xsmall-12 m-b-5" v-bind:key="plan.id">
                    <div class="card card-item--stretch column br p-4 p-t-5">
                        <div class="card-content">
                            <div class="text-center">
                                <div class="c-muted f-s-6 m-b-1 m-t-1">{{ plan.name }}</div>
                                <div class="price f-s-11 f-w-bold custom-heading-font">{{ plan.formatted_price_including_tax }}</div>
                                <div class="f-w-bold custom-heading-font c-primary m-n-t-1 m-b-4" v-html="plan.formatted_type"></div>
                                <div v-if="plan.trial_period_days > 0" class="c-medium-tint f-s-3 m-b-4 m-n-t-3" v-html="plan.formatted_trial"></div>
                                <div class="c-medium-tint f-s-3 m-b-5">{{ plan.description }}</div>
                                <a class="btn btn-primary btn-theme f-w-bold" href="#url">{{ label }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Block from '../page/Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                plans: _.get(this.$parent, 'data.plans', null),
                heading: _.get(this.block.data, 'heading'),
                subtitle: _.get(this.block.data, 'subtitle'),
                label: _.get(this.$parent, 'data.salesPage.buy_button_label', 'Add to Cart'),
            }
        }
    }
</script>