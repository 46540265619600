<template>
    <select class="form-control" v-bind:name="name" v-model="selectedOption">
        <option v-if="showLabel" value="">{{ selectItemLabel }}</option>
        <option v-for="item in items" v-bind:value="item.id" v-bind:key="item.id">{{ item.name }}</option>
    </select>
</template>
<script>
import { EventBus } from '../../event-bus';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        items: {
            type: [Array, Object],
            required: true
        },
        selected: {
            type: String,
            default: '',
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        selectItemLabel: {
            type: String,
            default: '',
        },
        selected: {
            type: String,
            default: function() {
                if (this.$parent.hasOwnProperty('selected')) {
                    return this.$parent.selected;
                }
            }
        },
    },
    data() {
        return {
            selectedOption: this.selected,
        }
    },
    watch: {
        selectedOption: function(selected) {
            this.$parent.$emit('item-selected', selected);
            EventBus.$emit('field:' + this.name + ':selected', selected);
        }
    }
}
</script>