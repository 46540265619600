<template>
    <div class="form-group" v-bind:class="error ? 'has-error': ''">
        <label>
            {{ trans('Select a category') }}
            <select class="input__field" v-model="hubCategoryId" name="hub_category_id">
                <option v-for="category in hubCategories" v-bind:value="category.encoded_id" v-bind:key="category.id">{{ category.title }}</option>
                <option disabled>---</option>
                <option value="NEW">{{ trans('Create new category') }}</option>
            </select>
        </label>
        <div v-if="hubCategoryId && hubCategoryId === 'NEW'" v-bind:class="{'has-error': error}" class="form-group">
            <input v-model="hubCategoryTitle" class="input__field" type="text" ref="hub_category_title" name="hub_category_title" placeholder="Hub category title" maxlength="255">
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            hubCategories: {
                type: Array,
            },
            selected: {
                type: String,
            },
            error: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                hubCategoryId: this.selected,
                hubCategoryTitle: null,
            }
        },
    }
</script>