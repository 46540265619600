<template>
    <select class="input-small input__field" v-model="item">
        <option v-bind:value="null">{{ defaultLabel }}</option>
        <optgroup v-for="hub in items" v-bind:key="'hub-' + hub.encoded_id" v-bind:label="hub.title">
            <option v-for="feature in hub.features" v-bind:value="feature" v-bind:key="'feature-' + feature.encoded_id">{{ feature.title }}</option>
        </optgroup>
    </select>
</template>
<script>
export default {
    props: {
        hubs: {
            type: Array,
            required: true,
        },
        selected: {
            type: Object,
            default: null,
        },
        defaultLabel: {
            type: String,
            default: 'All Hubs',
        },
    },
    data() {
        return {
            item: this.selected,
            items: this.hubs.map(hub => {
                return {
                    title: hub.title,
                    encoded_id: hub.encoded_id,
                    features: hub.hub_features.map(feature => {
                        return {
                            type: feature.type,
                            title: feature.label,
                            encoded_id: feature.encoded_id,
                            encoded_hub_id: feature.encoded_hub_id,
                        }
                    })
                }
            })
        }
    },
    watch: {
        selected(selected) {
            this.item = selected;
        },
        item(selected) {
            this.$emit('update:selected', selected);
        },
    },
}
</script>
