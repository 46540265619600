<template>
    <div class="slat-group m-t-5 p-t-3 br p-b-3 m-b-5">
        <div class="slat-group-header row row-middle" v-bind:class="{'br-bottom p-b-3': isExpanded}">
            <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                <span class="i-container i-filled-dark">
                    <!-- /icons/lock.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 4C9.8 4 8 5.8 8 8v1H6c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-9c0-.6-.4-1-1-1h-2V8c0-2.2-1.8-4-4-4zm1 11.7V17h-2v-1.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7zM14 9h-4V8c0-1.1.9-2 2-2s2 .9 2 2v1z" class="icon"/></svg>
                </span>
            </div>
            <div class="slat-content column p-l-2">
                <h2 class="f-w-bold f-s-4 m-b-0">
                    <a v-on:click.prevent="toggleBlock" href="#toggle" class="c-primary">{{ trans('Page Header') }}</a>
                </h2>
            </div>
            <div class="slat-aside text-right m-r-4">
                <span class="tooltip animate">
                    <a class="i-container i-filled-dark" href="#delete-faq" v-on:click.prevent="removeBlock">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </a>
                    <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                </span>
            </div>
        </div>
        <div v-show="isExpanded" class="slat-group-content p-t-4 m-t-1">
            <div class="row row-center">
                <div class="columns p-l-3 p-r-3">
                    <div class="form-group-addon m-b-2">
                        <label class="control control-checkbox block c-muted m-b-0">
                            {{ trans('Use Default Header Image') }}
                            <input v-bind:name="name + '[data][default_image]'" v-model="useDefaultImage" value="1" type="checkbox">
                            <div class="control-indicator control-checkbox-indicator"></div>
                        </label>
                    </div>
                    <div class="cover-container">
                        <image-upload
                            field-name="image"
                            ratio="3:1"
                            v-bind:overlay-color="overlayColor"
                            v-bind:existing-file-url="dataUrl"
                            v-bind:existing-file-path="dataPath"
                            v-on:image-selected="addImage"
                            v-on:image-removed="removeImage"
                        ></image-upload>
                        <input type="hidden" v-bind:name="name + '[data][image][url]'" v-model="dataUrl" >
                        <input type="hidden" v-bind:name="name + '[data][image][path]'" v-model="dataPath" >

                        <div class="c-muted f-s-2 m-t-2">{{ trans('Make this file: 640 -1280px wide; Smaller than 1.5GB') }}</div>
                        <color-picker
                            v-bind:color-label="trans('Colour Overlay')"
                            v-bind:opacity-label="trans('Opacity')"
                            v-bind:value="{ color, opacity }"
                            v-bind:use-opacity="true"
                            v-bind:field-name-prefix="name + '[data]'"
                            v-on:update:color="color = $event"
                            v-on:update:opacity="opacity = $event"
                            v-bind:in-sidebar="true"
                        ></color-picker>

                        <div v-if="useDefaultImage" class="cover-overlay cover-overlay--bright"></div>
                    </div>

                    <div class="form-group-addon m-b-2">
                        <label class="control control-checkbox block c-muted m-b-0">
                            {{ trans('Use Default Header Content') }}
                            <input v-bind:name="name + '[data][default_content]'" v-model="useDefaultContent" value="1" type="checkbox">
                            <div class="control-indicator control-checkbox-indicator"></div>
                        </label>
                    </div>
                    <div class="cover-container">
                        <div class="form-group">
                            <label>
                                {{ trans('Headline') }}
                                <input class="input__field" type="text" v-bind:name="name + '[data][title]'" v-model="title" >
                            </label>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ trans('Sub-headline') }}
                                <input class="input__field" type="text" v-bind:name="name + '[data][subtitle]'" v-model="subtitle">
                            </label>
                        </div>

                        <div v-if="useDefaultContent" class="cover-overlay cover-overlay--bright"></div>
                    </div>

                    <input v-if="block.id" type="hidden" v-bind:name="name + '[id]'" v-bind:value="block.id" />
                    <input type="hidden" v-bind:name="name + '[type]'" value="page_header" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { EventBus } from '../../../event-bus.js';
    import Block from './Block.vue';

    export default {
        extends: Block,
        data() {
            let useDefaultImage = _.get(this.block, 'data.default_image', '0') === '1' ? true : false;
            let useDefaultContent = _.get(this.block, 'data.default_content', '0') === '1' ? true : false;

            return {
                // fieldName: this.name + '[data][image][path]',
                dataUrl: useDefaultImage ? _.get(this.$parent, 'data.defaults.default_header.image.url', '')
                                         : _.get(this.block, 'data.image.url', ''),
                dataPath: useDefaultImage ? _.get(this.$parent, 'data.defaults.default_header.image.path', '')
                                          : _.get(this.block, 'data.image.path', ''),
                title: useDefaultContent ? _.get(this.$parent, 'data.defaults.default_header.title', '')
                                         : _.get(this.block, 'data.title', ''),
                subtitle: useDefaultContent ? _.get(this.$parent, 'data.defaults.default_header.subtitle', '')
                                            : _.get(this.block, 'data.subtitle', ''),
                color: useDefaultImage ? _.get(this.$parent, 'data.defaults.default_header.color', '#000000')
                                       : _.get(this.block, 'data.color', '#000000'),
                opacity: useDefaultImage ? _.get(this.$parent, 'data.defaults.default_header.opacity', 50)
                                         : _.get(this.block, 'data.opacity', 50),

                useDefaultImage: useDefaultImage,
                useDefaultContent: useDefaultContent
            };
        },
        watch: {
            useDefaultImage(newValue) {
                if (newValue) {
                    this.dataUrl = _.get(this.$parent, 'data.defaults.default_header.image.url', '');
                    this.dataPath = _.get(this.$parent, 'data.defaults.default_header.image.path', '');
                    this.color = _.get(this.$parent, 'data.defaults.default_header.color', '#000000');
                    this.opacity = _.get(this.$parent, 'data.defaults.default_header.opacity', 50);
                } else {
                    this.dataUrl = _.get(this.block, 'data.image.url', '');
                    this.dataPath = _.get(this.block, 'data.image.path', '');
                    this.color = _.get(this.block, 'data.color', '#000000');
                    this.opacity = _.get(this.block, 'data.opacity', 50);
                }
            },
            useDefaultContent(newValue) {
                if (newValue) {
                    this.title = _.get(this.$parent, 'data.defaults.default_header.title', '');
                    this.subtitle = _.get(this.$parent, 'data.defaults.default_header.subtitle', '');
                } else {
                    this.title = _.get(this.block, 'data.title', '');
                    this.subtitle = _.get(this.block, 'data.subtitle', '');
                }
            },

            title(newValue) {
                EventBus.$emit('block:update:value', this.index, 'title', newValue);
            },
            subtitle(newValue) {
                EventBus.$emit('block:update:value', this.index, 'subtitle', newValue);
            },
            opacity(newValue) {
                EventBus.$emit('block:update:value', this.index, 'opacity', newValue);
            },
            color(newValue) {
                EventBus.$emit('block:update:value', this.index, 'color', newValue);
            },
            dataUrl(newValue) {
                EventBus.$emit('block:update:value', this.index, 'image', {'url': newValue});
            }
        },
        computed: {
            overlayColor() {
                return `background-color: ${this.color}; opacity: ${this.opacity / 100}`;
            }
        },
        methods: {
            addImage(image) {
                this.dataUrl = image.url;
                this.dataPath = image.path;

                EventBus.$emit('block:update:value', this.index, 'image', image);
            },
            removeImage() {
                this.dataUrl = this.dataPath = '';
            }
        },
    }
</script>