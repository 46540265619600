<template>
    <nav class="nav-tab">
        <a v-for="(title, key) in tabs"
            v-on:click.prevent="switchActive(key)"
            v-bind:href="'#' + key"
            v-bind:class="{'nav-item--active': active === key }"
            v-bind:key="key"
            class="nav-item f-s-4 m-r-3 m-l-3"
        >{{ title }}</a>
    </nav>
</template>
<script>
    import { EventBus } from '../event-bus.js';

    export default {
        props: {
            selected: {
                type: String,
                required: true
            },
            tabs: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                active: this.selected
            }
        },
        methods: {
            switchActive(active) {
                this.active = active;

                EventBus.$emit('page-header-tabs:switch-active', active);
            }
        }
    }
</script>