<template>
    <modal v-bind:show.sync="show" ref="modal" size="medium">
        <div slot="header"></div>

        <form-wizard
            v-cloak
            v-bind:validation="validators"
            v-bind:action="actionUrl"
        >
            <wizard-step
                v-bind:next-label="trans('Next: Title')"
            >
                <div class="row">
                    <div class="column">
                        <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('Which features do you want to activate in your hub?') }}</h4>
                        <p class="f-s-4 c-medium-tint m-b-6">{{ trans('Select the category for this hub, or create a new one. Categories are used to organise your hubs on the sidebar of your site, as well as on the hubs screen (you can change this later).') }}</p>
                    </div>
                </div>
                <div class="row p-b-4">
                    <div class="column">
                        <div class="slat m-t-4 m-b-3" v-for="(feature) in hubFeatureTypes" v-bind:key="feature.type">
                            <div class="br p-t-3 p-b-3">
                                <div class="slat-group-header slat-group-header-small row row-middle">
                                    <div class="slat-content column p-l-2">
                                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ feature.name }}</h4>
                                        <p class="c-muted f-s-2">{{ feature.description }}</p>
                                    </div>
                                    <div class="slat-aside text-right m-r-4">
                                        <!-- "class-name" attribute intentionally left empty in order to remove "form-group" class -->
                                        <toggle
                                            v-bind:name="`enabled[${feature.type}]`"
                                            class-name=""
                                            v-bind:toggled="isEnabled(feature.type)"
                                            v-on:update:toggled="(checked) => toggleFeature(feature.type, checked)"
                                        ></toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="errors.has('features')" class="form-help m-t-5 c-error">{{ errors.get('features') }}</div>
                    </div>
                </div>
            </wizard-step>
            <wizard-step
                v-bind:prev-label="trans('Back to Features')"
                v-bind:next-label="trans('Select Category')"
                v-on:enter="elementFocus('title')"
            >
                <div class="row">
                    <div class="column">
                        <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('Give your hub a name') }}</h4>
                        <p class="f-s-4 c-medium-tint m-b-6">{{ trans("Your hub name will be used throughout your membership site to allow easy navigation to this content sidebar. It will also be shown on your course and content pages.") }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="small-2 columns">
                        <emoji
                            value="➡️"
                            name="icon"
                            v-bind:show-clear-button="false"
                        ></emoji>
                    </div>
                    <div class="small-10 columns">
                        <div v-bind:class="{'has-error': errors.has('title')}" class="form-group">
                            <input v-model="title" class="input__field" type="text" ref="title" name="title" v-bind:placeholder="trans('Hub name')" maxlength="80">
                        </div>
                        <div v-if="errors.has('title')" class="form-help">{{ errors.get('title') }}</div>
                    </div>
                </div>
            </wizard-step>
            <wizard-step
                v-bind:prev-label="trans('Back to Hub Title')"
                v-bind:next-label="trans('Create Hub')"
                v-on:enter="elementFocus('category_id')"
            >
                <div class="row">
                    <div class="column">
                        <h4 class="add-product-heading f-s-7 m-b-2">{{ trans('Select a category') }}</h4>
                        <p class="f-s-4 c-medium-tint m-b-6">{{ trans("Select the category for this hub, or create a new one. Categories are used to organise your hubs on the sidebar of your site, as well as on the hubs screen (you can change this later).") }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="small-12 columns">
                        <div v-bind:class="{'has-error': errors.has('category_id')}" class="form-group hl-txt">
                            <select class="input__field" ref="category_id" name="hub_category_id" v-model="hubCategoryId">
                                <option v-bind:value="null">{{ trans('Select your category') }}</option>
                                <option disabled>---</option>
                                <option value="NEW">{{ trans('Create new category') }}</option>
                                <option v-if="hubCategories.length > 0" disabled>---</option>
                                <option v-for="category in hubCategories" v-bind:key="category.encoded_id" v-bind:value="category.encoded_id">{{ category.title }}</option>
                            </select>
                        </div>
                        <div v-if="errors.has('category_id')" class="form-help">{{ errors.get('category_id') }}</div>
                        <div v-if="hubCategoryId && hubCategoryId === 'NEW'" v-bind:class="{'has-error': errors.has('category_title')}" class="form-group">
                            <input v-model="hubCategoryTitle" class="input__field" type="text" ref="hub_category_title" name="hub_category_title" placeholder="Hub category title" maxlength="255" required>
                        </div>
                        <div v-if="errors.has('category_title')" class="form-help">{{ errors.get('category_title') }}</div>
                    </div>
                </div>
            </wizard-step>
        </form-wizard>

        <div slot="footer"></div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Errors from '../../errors.js';
import Modal from '../modals/Modal.vue';

export default {
    components: {
        Modal,
    },
    props: {
        hubCategories: {
            type: Array,
        },
        hubFeatureTypes: {
            type: Array,
        },
    },
    data() {
        return {
            show: false,
            errors: new Errors(),
            validators: {
                1: this.validateFeatures,
                2: this.validateTitle,
                3: this.validateCategory,
            },
            title: null,
            hubCategoryId: null,
            hubCategoryTitle: null,
            actionUrl: window.Kourses.activeBaseUrl + '/hubs',
            enabledFeatures: [],
        }
    },
    watch: {
        hubCategoryId() {
            this.errors.clear();
        },
    },
    methods: {
        isEnabled(type) {
            return this.enabledFeatures.indexOf(type) >= 0;
        },
        toggleFeature(type, checked) {
            if (checked === true) {
                this.enabledFeatures.push(type);
            } else {
                this.enabledFeatures = this.enabledFeatures.filter(item => item !== type);
            }
        },
        validateFeatures() {
            this.errors.clear();

            if (this.enabledFeatures.length > 0) {
                return true;
            }

            this.errors.set('features', this.trans('Please select at least one feature to proceed with creating your hub'));

            return false;
        },
        validateTitle() {
            this.errors.clear();

            if ( ! this.title || this.title.trim().length === 0) {
                this.errors.set('title', this.trans('Please enter a title for your hub'));

                return false;
            }

            return true;
        },
        validateCategory() {
            this.errors.clear();

            if ( ! this.hubCategoryId) {
                this.errors.set('category_id', this.trans('Please select existing category or create a new one'));

                return false;
            }

            if (this.hubCategoryId === 'NEW' && (! this.hubCategoryTitle || this.hubCategoryTitle.trim().length === 0)) {
                this.errors.set('category_title', this.trans('Please enter a title for your new category'));

                return false;
            }

            return true;
        },
        elementFocus(element) {
            this.$nextTick(() => this.$refs[element].focus());
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-hub', () => {
            $vm.show = true;
        });
    },
    mounted() {
        // Auto-open (show) modal window if hash is what we want
        if (window.location.hash && window.location.hash.substring(1) === 'new-hub') {
            this.show = true;
        }
    },
}
</script>