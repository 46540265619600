import * as tus from 'tus-js-client';
import { sha256 } from 'js-sha256';

class BunnyUploader {
    constructor(config) {
        this.config = {...{
            bunnyApiKey: null,
            bunnyApiUrl: null,
            bunnyLibraryId: null,
            bunnyPullZoneUrl: null,
            bunnyCollectionId: null,
            koursesApiUrl: null,
            koursesWebsiteId: null,
        }, ...config};
        this.fileUrl = null;
        this.currentUpload = null;
    }

    upload(file, options) {
        this.createCollectionIfNeeded()
            .then((collectionId) => {
                this.createVideo(file.name, collectionId)
                    .then((videoId) => {
                        const expirationTime = Math.floor(Date.now() / 1000) + 36000;
                        this.currentUpload = new tus.Upload(file, {
                            endpoint: 'https://video.bunnycdn.com/tusupload',
                            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                            headers: {
                                AuthorizationSignature: this.generateSignature(videoId, expirationTime),
                                AuthorizationExpire: expirationTime,
                                VideoId: videoId,
                                LibraryId: this.config.bunnyLibraryId,
                            },
                            metadata: {
                                filetype: file.type,
                                title: file.name,
                                collection: collectionId,
                            },
                            onError: (data) => {
                                options.onError(JSON.parse(data).error);
                            },
                            onProgress: (bytesUploaded, bytesTotal) => {
                                options.onProgress(bytesUploaded, bytesTotal);
                            },
                            onSuccess: () => {
                                if (this.currentUpload) {
                                    this.loopUntilTranscoded(this.fileUrl, options.onTranscoded);

                                    return options.onSuccess(this.fileUrl);
                                }
                            }
                        });

                        this.currentUpload.start();
                    });
            });
    }

    loopUntilTranscoded(fileUrl, transcodedCallback) {
        let interval = setInterval(() => {
            this.getVideoStatus(fileUrl)
                .then(({status, thumbnail, length}) => {
                    if (status === 'transcoded') {
                        clearInterval(interval);

                        transcodedCallback(thumbnail, length);
                    }
                });
            }, 5000);
    }

    generateSignature(videoId, expirationTime) {
        return sha256(this.config.bunnyLibraryId + this.config.bunnyApiKey + expirationTime + videoId);
    }

    createCollection(collectionName) {
        return window.axios.post(this.config.bunnyApiUrl + '/library/' + this.config.bunnyLibraryId + '/collections', {
            'name': collectionName,
        },{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/*+json',
                'AccessKey': this.config.bunnyApiKey,
            }
        }).then((response) => {
            let collection = response.data.guid;

            // Save to DB
            return window.axios.post(this.config.koursesApiUrl + '/bunny/settings', {
                collection: collection
            }).then(() => {
                return collection;
            });
        });
    }

    createCollectionIfNeeded() {
        if (this.config.bunnyCollectionId) {
            return Promise.resolve(this.config.bunnyCollectionId);
        }

        return this.createCollection('Website #' + this.config.koursesWebsiteId).then((collectionId) => {
            window.Bunny.collection = collectionId;
            this.config.bunnyCollectionId = collectionId;

            return collectionId;
        });
    }

    createVideo(fileName, collectionId) {
        return window.axios.post(this.config.bunnyApiUrl + '/library/' + this.config.bunnyLibraryId + '/videos', {
            'title': fileName,
            'collectionId': collectionId
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/*+json',
                'AccessKey': this.config.bunnyApiKey,
            }
        }).then((response) => {
            this.fileUrl = response.data.guid;

            return response.data.guid;
        }).catch(({response}) => {
            throw new Error(response.data.error);
        });
    }

    getVideoStatus(video) {
        return window.axios.get(this.config.bunnyApiUrl + '/library/' + this.config.bunnyLibraryId + '/videos/' + video, {
            headers: {
                'Accept': 'application/json',
                'AccessKey': this.config.bunnyApiKey,
            }
        }).then((response) => {
            if (response.data.encodeProgress === 100) {
                return this.signUrl('https://' + this.config.bunnyPullZoneUrl + '/' + response.data.guid + '/' + response.data.thumbnailFileName)
                    .then((signedUrl) => {
                        return {
                            status: 'transcoded',
                            thumbnail: signedUrl,
                            length: response.data.length,
                        };
                    });
            }

            return {
                status: 'processing',
                thumbnail: null,
                length: response.data.length,
            };
        }).catch(() => {
            return {
                status: 'missing',
                thumbnail: null,
                length: null,
            };
        });
    }

    signUrl(url) {
        return window.axios.post(this.config.koursesApiUrl + '/bunny/sign-url', {
            url: url
        }).then((response) => {
            return response.data.signed_url;
        });
    }

    terminate() {
        // TODO: Where did the code for terminating upload go? I've implemented this for Vimeo and for Bunny I think
        this.currentUpload = null;
    }
}

class VimeoUploader {
    constructor(config) {
        this.config = {...{
            vimeoToken: null,
            vimeoApiUrl: null,
            vimeoFolder: null,
            koursesApiUrl: null,
            koursesDomains: [],
            koursesWebsiteId: null,
        }, ...config};
        this.fileUrl = null;
        this.currentUpload = null;
    }

    upload(file, options) {
        this.createFolderIfNeeded()
            .then((folderId) => {
                this.createUploadUrl(file.name, file.size, folderId)
                    .then((uploadUrl) => {
                        this.currentUpload = new tus.Upload(file, {
                            uploadUrl: uploadUrl,
                            onError: (data) => {
                                options.onError(JSON.parse(data).error);
                            },
                            onProgress: (bytesUploaded, bytesTotal) => {
                                options.onProgress(bytesUploaded, bytesTotal);
                            },
                            onSuccess: () => {
                                if (this.currentUpload) {
                                    this.loopUntilTranscoded(this.fileUrl, options.onTranscoded);

                                    return options.onSuccess(this.fileUrl);
                                }
                            }
                        });

                        this.currentUpload.start();
                    })
                    .catch(({response}) => {
                        throw new Error(response.data.error);
                    });
            });
    }

    loopUntilTranscoded(fileUrl, transcodedCallback) {
        let interval = setInterval(() => {
            this.getVideoStatus(fileUrl)
                .then(({status, thumbnail, length}) => {
                    if (status === 'transcoded') {
                        clearInterval(interval);

                        transcodedCallback(thumbnail, length);
                    }
                });
            }, 5000);
    }

    createUploadUrl(fileName, fileSize, folderId) {
        return window.axios.post(this.config.vimeoApiUrl + '/me/videos', {
            'name': fileName,
            'upload': {
                'approach': 'tus',
                'size': fileSize,
            },
            'privacy': {
                'view': 'unlisted',
                'embed': 'whitelist',
            },
            'folder_uri': '/folders/' + folderId,
            'embed_domains': this.config.koursesDomains,
        }, {
            headers: {
                'Authorization': 'Bearer ' + this.config.vimeoToken,
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.vimeo.*+json;version=3.4',
            }
        }).then((response) => {
            this.fileUrl = response.data.link;

            return response.data.upload.upload_link;
        }).catch(({response}) => {
            throw new Error(response.data.error);
        });
    }

    createFolder(folderName) {
        return window.axios.post(this.config.vimeoApiUrl + '/me/projects', {
            'name': folderName,
        },{
            headers: {
                'Authorization': 'Bearer ' + this.config.vimeoToken,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            let folder = response.data.uri.split('/')[4];

            // Save to DB
            return window.axios.post(this.config.koursesApiUrl + '/vimeo/settings', {
                folder: folder
            }).then(() => {
                return folder;
            });
        });
    }

    createFolderIfNeeded() {
        if (this.config.vimeoFolder) {
            return Promise.resolve(this.config.vimeoFolder);
        }

        return this.createFolder('Website #' + this.config.koursesWebsiteId).then((folderId) => {
            window.Vimeo.folder = folderId;
            this.config.vimeoFolder = folderId;

            return folderId;
        });
    }

    getVideoStatus(video) {
        return window.axios.get(this.config.vimeoApiUrl + '/videos/' + this.getVideoIdFromUrl(video) + '?fields=status,duration,pictures', {
            headers: {
                'Authorization': 'Bearer ' + this.config.vimeoToken,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.data.status === 'available') {
                return {
                    status: 'transcoded',
                    thumbnail: response.data.pictures.sizes.pop().link,
                    length: response.data.duration,
                };
            }

            return {
                status: 'processing',
                thumbnail: null,
                length: response.data.duration,
            };
        }).catch(() => {
            return {
                status: 'missing',
                thumbnail: null,
                length: null,
            };
        });
    }

    terminate() {
        tus.Upload.terminate(this.currentUpload.url);
        this.currentUpload = null;
    }

    getVideoIdFromUrl(url) {
        const parsedUrl = url.split('/');

        if (parsedUrl.length !== 5) {
            return null;
        }

        return parsedUrl[3];
    }
}

export {
    BunnyUploader,
    VimeoUploader
}