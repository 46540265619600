export default {
    data() {
        return {
            fileUrl: null,
            filePath: null,
        }
    },
    methods: {
        /**
         * When user cancels the crop we remove the uploaded image.
         *
         * @return {Void}
         */
        cropCancelled() {
            this.deleteFileFromStorage(this.filePath);
            this.removeFile();
        },
        /**
         * Swap uploaded image with the cropped on.
         *
         * @param   {Object}  image
         *
         * @return  {Void}
         */
        cropSuccessful(image) {
            // In order to "reload" the cropped image (as it uses the same
            // filename) we are setting the file URL to null and then in the
            // next tick we are setting the correct URL which triggers
            // re-render
            this.fileUrl = null;
            this.resizedImageUrl = null;

            this.$nextTick(() => {
                this.fileUrl = this.resizedImageUrl = image.url;
                this.filePath = image.path;
            });
        },
        /**
         * Initialize cropper modal.
         *
         * Show modal when image is uploaded.
         *
         * @param  {String} ref
         *
         * @return  {Void}
         */
        initCropper(ref) {
            const cropper = this.$refs[ref];

            this.$on('image-selected', (image) => {
                cropper.show = true;
                cropper.image = image;
            });
        },
    }
}