<template>
    <div class="page-content">
        <div class="row row-collapse row-center">
            <div class="columns">
                <section class="card-grid section row m-t-5 p-b-5">
                    <div v-for="hub in filteredHubs" v-bind:key="hub.id" class="card column medium-4 small-6 m-b-5">
                        <div class="card-item card-item--no-hover card-item--stretch">
                            <div class="card-figure m-b-4 card-figure-animate animate">
                                <a v-bind:href="hubShowLink(hub)" class="card-link">
                                    <img v-bind:src="hub.image ? hub.image_url.small : hub.placeholder_image_url" v-bind:alt="hub.title" />
                                </a>
                            </div>
                            <div class="card-content">
                                <div class="card-content-meta m-b-2">
                                    <span class="card-type f-s-2 c-muted f-w-bold m-r-3">{{ hubFeatures(hub) }}</span>
                                    <span class="card-status badge f-w-bold f-caps c-bright" v-bind:class="{'c-theme-bg': hub.status === 'published', 'c-muted-bg': hub.status === 'draft'}">{{ hub.status === 'draft' ? trans('Draft') : trans('Published') }}</span>
                                </div>
                                <div class="row row-collapse">
                                    <div class="column text-left">
                                        <h2 class="f-s-5 f-w-bold">
                                            <a class="card-title card-link" v-bind:href="hubShowLink(hub)">{{ hub.title }}</a>
                                        </h2>
                                        <p v-if="hub.author" class="c-medium-tint f-s-2 m-n-t-3">{{ hub.author.full_name }}</p>
                                    </div>
                                    <hub-dropdown-menu
                                        v-bind:hub="hub"
                                        v-bind:can-delete-hubs="canDeleteHubs"
                                    ></hub-dropdown-menu>
                                </div>
                            </div>
                            <div class="card-footer card-footer--bottom">
                                <div class="row row-collapse row-tall">
                                    <div class="column text-left c-medium-tint f-s-3">
                                        <span class="i-container i-filled-dark m-r-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 5 64 64" class="icon i-size-small"><g><path d="M24,32a8,8,0,0,0,3.41,6.55,1.07,1.07,0,0,1-.26,1.89A16.23,16.23,0,0,0,16.44,52.2,3.13,3.13,0,0,0,19.5,56h25a3.13,3.13,0,0,0,3.07-3.8A16.23,16.23,0,0,0,36.84,40.44a1.07,1.07,0,0,1-.25-1.89A8,8,0,0,0,40,31.34,8,8,0,0,0,24,32Z"/><path d="M15.16,28.44A16.23,16.23,0,0,0,4.44,40.2,3.13,3.13,0,0,0,7.5,44h6.85a3.07,3.07,0,0,0,2.37-1.09,20.38,20.38,0,0,1,3.15-3.06,3.14,3.14,0,0,0,.91-3.62A12,12,0,0,1,28,20.72,7.92,7.92,0,0,0,20.23,12a8,8,0,0,0-4.82,14.54A1.07,1.07,0,0,1,15.16,28.44Z"/><path d="M48.59,26.55A8,8,0,0,0,52,19.34,8,8,0,0,0,36,20c0,.25,0,.49,0,.73a12,12,0,0,1,7.19,15.52,3.14,3.14,0,0,0,.91,3.61,20.38,20.38,0,0,1,3.13,3A3.07,3.07,0,0,0,49.65,44H56.5a3.13,3.13,0,0,0,3.07-3.8A16.23,16.23,0,0,0,48.84,28.44,1.07,1.07,0,0,1,48.59,26.55Z"/></g></svg>
                                        </span>
                                        <a v-bind:href="hub.members_count > 0 ? membersInHubShowLink(hub) : null" class="c-medium-tint">
                                            <span class="f-w-bold">{{ hub.members_count }}</span>
                                            {{ hub.members_count !== 1 ? trans('members') : trans('member') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="br-top m-t-3"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import { hubShowLink, membersInHubShowLink } from '../../routes.js';
import HubDropdownMenu from './HubDropdownMenu.vue';

export default {
    components: {
        HubDropdownMenu
    },
    props: {
        hubs: {
            type: Array,
            required: true
        },
        selected: {
            type: String,
            default: 'all'
        },
        canDeleteHubs: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            active: this.selected
        }
    },
    computed: {
        filteredHubs() {
            if (this.active === 'all') {
                return this.hubs;
            } else {
                return this.hubs.filter((hub) => {
                    if (hub.enabled_hub_features.length === 0) {
                        return false;
                    }

                    return hub.enabled_hub_features.some(feature => feature.type === this.active);
                });
            }
        }
    },
    methods: {
        hubFeatures(hub) {
            if (typeof hub.enabled_hub_features == 'undefined') {
                return '';
            }

            return hub.enabled_hub_features.reduce((featuresList, feature) => {
                if (featuresList.length > 0) {
                    featuresList += ', ';
                }

                featuresList += feature.label;

                return featuresList;
            }, '');
        },
        hubShowLink(hub) {
            return hubShowLink(hub);
        },
        membersInHubShowLink(hub) {
            return membersInHubShowLink(hub);
        },
    },
    created() {
        EventBus.$on('page-header-tabs:switch-active', (active) => {
            this.active = active;
        });
    }
}
</script>