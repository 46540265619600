<template>
    <div class="row row-collapse">
        <div class="column m-r-2">
            <div v-bind:class="{ 'has-error': hasError }" class="form-group">
                <label for="minutes">
                    <span>{{ trans('Minutes') }}</span>
                    <input id="minutes" class="input__field" type="number" v-model="minutes" min="0" max="240" step="1">
                </label>
            </div>
        </div>
        <div class="column m-l-2">
            <div v-bind:class="{ 'has-error': hasError }"  class="form-group">
                <label for="seconds">
                    <span>{{ trans('Seconds') }}</span>
                    <input id="seconds" class="input__field" type="number" v-model="seconds" min="0" max="60" step="1">
                </label>
            </div>
        </div>
        <input type="hidden" v-bind:name="name" v-bind:value="duration">
    </div>
</template>
<script>
import { EventBus } from '../../event-bus.js';

export default {
    props: {
        label: {
            type: String,
            default: 'Duration',
        },
        value: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: 'duration',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            minutes: 0,
            seconds: 0,
        }
    },
    computed: {
        duration() {
            return this.toSeconds(this.minutes, this.seconds);
        }
    },
    methods: {
        toSeconds(min, sec) {
            var duration = 0;

            if (parseInt(min, 10) > 0) {
                duration = parseInt(min, 10) * 60 + parseInt(sec, 10);
            } else {
                duration = parseInt(sec, 10);
            }

            EventBus.$emit('form:element:changed', this.name, duration);

            return duration;
        },
        fromSeconds(sec) {
            this.minutes = parseInt(Math.floor(parseInt(sec, 10) / 60), 10);
            this.seconds = parseInt(sec % 60, 10);
        },
    },
    created() {
        this.fromSeconds(this.value);
    },
}
</script>
