<template>
    <div ref="likes">
        <ul v-if="likes.length > 0">
            <li v-for="like in likes" v-bind:key="like.encoded_id" class="m-b-1">
                <span class="f-s-2 c-primary f-w-bold inline">{{ like.member.full_name }}</span>
                <span v-if="isFromAdminUser(like)" class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-l-2">{{ trans('Admin') }}</span>
                <span class="f-s-1 c-muted m-l-2">{{ like.created_at_dfh }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        likes: {
            type: [Object, Array],
            required: true,
        },
    },
    methods: {
        isFromAdminUser(like) {
            return like.member.is_admin;
        },
    },
    mounted() {
        if (window.location.hash === '#likes') {
            this.$scrollTo(this.$refs.likes);
        }
    }
}
</script>