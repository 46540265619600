<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="m-t-4">
            <h2 v-if="heading" class="f-s-8 c-primary m-t-6">{{ heading }}</h2>
             <div v-html="text"></div>
        </div>
    </div>
</template>
<script>
    import Text from '../page/Text.vue';

    export default {
        extends: Text
    }
</script>