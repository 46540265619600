<template>
    <div v-if="image" class="row m-t-5 row-center">
        <div class="column large-8 medium-10 small-11 xsmall-12">
            <figure class="m-0">
                <img v-bind:src="resizedImageUrl" v-bind:alt="alt" v-on:error="e => { resizedImageUrl = image.url; }">
                <figcaption v-if="caption" class="p-t-2 f-s-2 c-muted">{{ caption }}</figcaption>
            </figure>
        </div>
    </div>
</template>
<script>
    import Block from './Block.vue';
    import { resizedImageUrl } from '../../../../../helpers.js';

    export default {
        extends: Block,
        data() {
            return {
                image: _.get(this.block, 'data'),
                resizedImageUrl: resizedImageUrl(_.get(this.block, 'data.url', ''), 'large'),
                alt: _.get(this.block.data, 'alt'),
                caption: _.get(this.block.data, 'caption'),
            }
        },
        watch: {
            image(newImage) {
                this.resizedImageUrl = resizedImageUrl(newImage.url, 'large');
            }
        }
    }
</script>