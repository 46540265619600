<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <h4 v-if="heading" class="c-primary f-s-7 f-w-bold m-b-5 text-left">{{ heading }}</h4>
        <div class="row row-collapse">
            <div v-if="author.image" class="p-r-5 m-b-4">
                <span class="avatar">
                    <img class="avatar-image avatar-xlarge" v-bind:src="author.image_url.large">
                </span>
            </div>
            <div class="column xs-clear">
                <h4 class="f-s-5 m-b-2">{{ author.full_name }}</h4>
                <div v-if="author.title" class="meta c-muted f-s-4 m-b-4">{{ author.title }}</div>
                <p v-if="author.bio" class="f-s-4" v-html="author.bio"></p>
            </div>
        </div>
    </div>
</template>
<script>
    import Block from '../page/Block.vue';

    export default {
        extends: Block,
        data() {
            return {
                heading: _.get(this.block.data, 'heading'),
                author: _.get(this.$parent, 'data.author', null),
            }
        }
    }
</script>