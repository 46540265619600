<template>
    <div ref="player">
        <div v-if="transcoded === false && url" class="text-center">{{ trans('Video is still being transcoded. Please try again in a few minutes.') }}</div>
    </div>
</template>
<script>
    import Player from '@vimeo/player';

    export default {
        props: {
            url: {
                type: String
            }
        },
        data() {
            return {
                transcoded: true,
                player: null
            }
        },
        watch: {
            url(url) {
                this.loadVideo(url);
            }
        },
        methods: {
            loadVideo(url) {
                if ( ! url) {
                    return;
                }

                if (this.player) {
                    this.player.loadVideo(url);
                } else {
                    this.player = new Player(this.$refs['player'], {url: url, responsive: 1});
                    this.player.ready().catch((e) => {
                        this.transcoded = false;
                    });
                }
            }
        },
        mounted() {
            this.loadVideo(this.url);
        }
    }
</script>