<template>
    <div>
        <div v-bind:class="{'has-error': errors.has('tags') }" class="form-group m-b-0 m-t-2">
            <autocomplete
                v-if="useAutocomplete"
                v-bind:items="selectedTags"
                v-bind:suggestions="availableTags"
                v-bind:multiple="allowMultiple"
                v-on:change="updateTags"
                name="tags"
                v-bind:label="trans('Tag')"
            ></autocomplete>
            <div v-else class="form-group">
                <label class="m-b-2">{{ trans('Tag') }}</label>
                <tags-field
                    v-bind:items="selectedTags"
                    v-on:change="updateTags"
                    v-bind:input-as-array="true"
                ></tags-field>
            </div>
        </div>
        <div v-if="errors.has('tags')" class="form-help">{{ errors.get('tags') }}</div>
    </div>
</template>
<script>
export default {
    props: {
        integration: {
            type: Object
        },
        selectedTags: {
            type: [
                Array,
                Object
            ]
        },
        availableTags: {
            type: [
                Array,
                Object
            ]
        },
        errors: {
            type: [Array, Object]
        },
    },
    computed: {
        useAutocomplete() {
            if ( ! this.integration.config.hasOwnProperty('new_tags')) {
                return true;
            }

            return ! this.integration.config.new_tags;
        },
        allowMultiple() {
            if ( ! this.integration.config.hasOwnProperty('multi_tags')) {
                return false;
            }

            return this.integration.config.multi_tags;
        }
    },
    methods: {
        updateTags(tags) {
            this.$emit('change', tags);
        }
    }
}
</script>