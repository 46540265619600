<template>
    <div v-cloak>
        <div v-if="newItems.length > 0" class="slat-group m-b-5">
            <div class="slat-group-content">
                <div class="slat m-b-3 c-warning-bg" v-for="(token, index) in newItems" v-bind:key="token.id" v-bind:class="{'m-t-4': index > 0}">
                    <div class="row row-compress row-middle br p-t-3 p-b-3">
                        <div class="slat-content column">
                            <div class="row row-compress row-middle">
                                <h4 class="f-w-bold f-s-3 m-b-0">
                                    {{ token.plainTextToken }}
                                </h4>
                                <clipboard v-bind:data="token.plainTextToken" inline-template>
                                    <button type="button" class="m-l-2 btn btn-tiny c-error-bg h-bg-error" v-clipboard="copyData" v-on:success="handleSuccess">{{ trans('Copy API Key') }}</button>
                                </clipboard>
                            </div>
                            <p class="f-s-3 m-t-1 c-muted">
                                {{ trans("Make sure to copy your new personal access token now. You won’t be able to see it again!") }}
                            </p>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <span class="tooltip animate">
                                <button type="button" class="cu-p i-container i-filled-dark" v-on:click.prevent="removeNewToken(index)">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                </button>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="items.length > 0" class="slat-group m-b-5">
            <div class="slat-group-content">
                <div class="slat m-b-3" v-for="(token, index) in items" v-bind:key="token.id" v-bind:class="{'m-t-4': index > 0}">
                    <div class="row row-compress row-middle br p-t-3 p-b-3">
                        <div class="slat-content column">
                            <h4 class="f-w-bold f-s-3 m-b-0">
                                {{ token.name }} <span class="f-w-regular f-s-2 c-medium-tint">for {{ token.website.name }}</span>
                            </h4>
                            <p class="f-s-2 m-t-1 c-muted-dim">
                                <span>{{ trans('Created at: :date', {date: token.created_at.substr(0, 10)}) }}</span>
                                <span>&nbsp;&bull;&nbsp;</span>
                                <span>{{ trans('Last used at: :date', {date: token.last_used_at !== null ? token.last_used_at.substr(0.10) : trans('Not yet used')}) }}</span>
                            </p>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <span class="tooltip animate">
                                <button type="button" class="cu-p i-container i-filled-dark" v-on:click.prevent="removeToken(index)">
                                    <!-- /icons/s-trash.svg -->
                                    <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                                </button>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="items.length === 0 && newItems.length === 0" class="row-center row m-b-4 row-middle">
            <div class="columns small-6">
                <img v-bind:src="emptyImageUrl" />
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '../event-bus.js';

export default {
    props: {
        tokens: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            items: this.tokens,
            newItems: [],
            emptyImageUrl: Kourses.baseUrl + '/img/members-placeholder.png',
        };
    },
    methods: {
        removeToken(index) {
            this.$dialog
                .confirm(this.trans("If you delete it, all apps that were using it will stop working."), {loader: true})
                .then((dialog) => {
                    window.axios.delete(Kourses.apiBaseUrl + '/settings/api-keys/' + this.items[index].encoded_id).then(() => {
                        this.items.splice(index, 1);
                        dialog.close();
                    }).catch((error) => {
                        console.warn(error);
                        this.$toast.danger(this.trans('Unknown error occured. API key was not deleted!'));
                        dialog.close();
                    });
                });
        },
        removeNewToken(index) {
            this.$dialog
                .confirm(this.trans("If you delete it, all apps that were using it will stop working."), {loader: true})
                .then((dialog) => {
                    window.axios.delete(Kourses.apiBaseUrl + '/settings/api-keys/' + this.newItems[index].accessToken.encoded_id).then(() => {
                        this.newItems.splice(index, 1);
                        dialog.close();
                    }).catch((error) => {
                        console.warn(error);
                        this.$toast.danger(this.trans('Unknown error occured. API key was not deleted!'));
                        dialog.close();
                    });
                });
        },
        newToken(token) {
            this.newItems.push(token);
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('api-key:added', (token) => {
            console.log(token);
            $vm.newToken(token);
        });
    }
}
</script>
