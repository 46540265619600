<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Create API Key') }}</h3>
        </div>

        <div class="row row-collapse row-collapse-indent">
            <div class="columns">
                <div class="form-group" v-bind:class="{'has-error': errors.has('name')}">
                    <label>
                        {{ trans('Name') }}
                        <input type="text" v-model="token.name" required>
                    </label>
                </div>
                <div class="form-help">{{ trans("What's this token for?")}}</div>
                <div v-if="errors.has('name')" class="form-help">
                    {{ errors.get('name') }}
                </div>
            </div>
        </div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="addApiKey" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Add API Key') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';

export default {
    components: {
        Modal,
    },
    data() {
        return {
            token: {
                id: 0,
                name: '',
            },
            show: false,
            loading: false,
            errors: new Errors()
        }
    },
    methods: {
        addApiKey() {
            this.loading = true;

            window.axios.post(Kourses.apiBaseUrl + '/settings/api-keys', this.token).then((response) => {
                this.show = false;
                EventBus.$emit('api-key:added', response.data);
                this.reset();
            }).catch((error) => {
                console.warn(error);
                this.loading = false;
                this.errors.record(error.response.data);
            });
        },
        reset() {
            this.token = {
                id: 0,
                name: '',
            };

            this.loading = false;
            this.errors.clear();
        },
        close() {
            this.reset();
            this.show = false;
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:new-api-key', () => {
            $vm.reset();
            $vm.show = true;
        });

        $vm.$on('close', () => {
            $vm.close();
        });
    },
}
</script>