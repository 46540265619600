<template>
    <modal v-bind:show.sync="show" ref="modal" class="text-center">
        <div slot="header">
            <h3 class="panel-title f-s-7 m-b-2" v-html="options.headline"></h3>
        </div>
        <p class="f-s-5 c-medium-tint m-b-5" v-html="options.message"></p>
        <p v-if="options.anotherMessage" class="f-s-5 c-medium-tint" v-html="options.anotherMessage"></p>
        <div slot="footer">
            <div v-if="options.window === 'confirm'" class="row">
                <div class="columns m-b-3">
                    <button type="button" v-on:click="proceed" class="btn modal-close-external btn-primary--warning" v-bind:class="{'btn-loading': loading}">
                        {{ options.okText }}
                        <div v-if="loading && loaderEnabled" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
                <div class="columns">
                    <button type="button" v-on:click="cancel" class="btn btn-text btn-text--muted">{{ options.cancelText }}</button>
                </div>
            </div>
            <div v-if="options.window === 'alert'" class="row">
                <div class="columns m-b-3">
                    <button type="button" v-on:click="close" class="btn modal-close-external btn-primary">
                        {{ options.okText }}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {DIALOG_TYPES, ANIMATION_TYPES, CONFIRM_TYPES} from '../js/constants';
    import Modal from '../../../components/modals/Modal.vue';

    export default {
        data: function () {
            return {
                input: '',
                show: true,
                loading: false,
                closed: false,
                endedAnimations: []
            }
        },
        props: {
            options: {
                type: Object,
                required: true
            },
            escapeKeyClose: {
                type: Boolean,
                "default": false
            }
        },
        watch: {
            "escapeKeyClose": function(val){
                if(val === true){
                    this.cancel()
                }
            }
        },
        computed: {
            animation(){
                let a = this.options.animation.toUpperCase()
                return ANIMATION_TYPES.hasOwnProperty(a)
                    ? ANIMATION_TYPES[a]
                    : ANIMATION_TYPES.ZOOM
            },
            loaderEnabled(){
                return !!this.options.loader
            }
        },
        methods: {
            proceed(){
                if (this.loaderEnabled) {
                    this.switchLoadingState(true)
                    this.options.promiseResolver({
                        close: this.close,
                        loading: this.switchLoadingState
                    })
                } else {
                    this.options.promiseResolver(true)
                    this.close()
                }
            },
            cancel(){
                if (this.loading === true)
                    return
                this.close()
            },
            switchLoadingState(loading = null){
                if(loading === null){
                    loading = !this.loading
                }

                this.loading = !!loading
            },
            close(){
                this.show = false
                this.closed = true
            },
            animationEnded(type){
                this.endedAnimations.push(type)

                if(this.endedAnimations.indexOf('backdrop') !== -1
                    && this.endedAnimations.indexOf('content') !== -1
                ){
                    this.options.promiseRejecter(false)
                    this.$emit('close', this.options.id)
                }

            }
        },
        beforeDestroy() {
            if (this.closed === false) {
                this.cancel();
            }
        },
        components: {Modal}
    }
</script>
