<template>
    <div>
        <p v-if="showTitle" class="f-s-5 f-w-bold m-t-5 text-center">{{ trans('Give imported members access to hubs') }}</p>
        <div v-for="(hub, index) in selectedHubs" v-bind:key="hub.id" class="card br row m-b-6">
            <div class="column">
                <div class="card-content row row-collapse-indent row-middle m-t-4 m-b-4">
                    <div class="slat-figure--large column small-12">
                        <img v-bind:src="hub.image ? hub.image_url.thumbnail : hub.placeholder_image_url" v-bind:alt="hub.title" />
                    </div>
                    <div class="slat-content column p-l-0">
                        <div class="card-type f-s-2 c-muted f-w-bold m-b-2">{{ hubFeatures(hub) }}</div>
                        <h4 class="card-title c-primary f-s-4 m-b-0">{{ hub.title }}</h4>
                    </div>
                    <div class="slat-aside text-right m-r-4">
                        <a v-on:click.prevent="deleteItem(index)" class="i-container i-filled-dark btn btn-text width-auto-min p-0 m-r-0" href="#remove-hub">
                            <!-- /icons/remove.svg -->
                            <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"/><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"/></g></svg>
                        </a>
                    </div>
                </div>
            </div>
            <input type="hidden" v-bind:name="'hubs[' + index + ']'" v-model="hub.id">
            <div class="columns p-b-1">
                <div class="br-top p-t-1"></div>
                <div class="row row-collapse row-middle">
                    <div v-if="hub.type !== 'download'" class="column large-5">
                        <div class="control-group text-left p-b-micro p-t-micro">
                            <div class="form-group-addon inline">
                                <label class="control control-checkbox block c-muted m-b-0">
                                    {{ trans('Skip original drip schedule') }}
                                    <input type="checkbox" v-bind:name="'skip_drip_schedule[' + hub.id + ']'" value="1">
                                    <div class="control-indicator control-checkbox-indicator"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="c-muted f-s-2 p-r-4 text-right">{{ trans('Set Hub Expiry') }}</div>
                    </div>
                    <div class="column">
                        <div class="form-group m-b-0">
                            <date-pick
                                v-model="hub.ends_at"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date'), 'name': 'ends_at[' + hub.id + ']'}"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="selectedHubs.length > 0" class="m-t-4 m-b-6">
            <div class="control-group text-center p-b-micro p-t-micro">
                <div class="form-group-addon inline">
                    <label class="control control-checkbox block c-muted m-b-0">
                        {{ trans('Send notification email') }}
                        <input type="checkbox" name="send_notification" value="1" v-model="shouldSendNotification">
                        <div class="control-indicator control-checkbox-indicator"></div>
                    </label>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button v-on:click="addHub" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/add.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                </span>
                {{ trans('Add Hub') }}
            </button>
        </div>
        <div v-if="errors.length > 0" class="form-help c-error">
            {{ errors }}
        </div>

        <hub-picker-modal
            v-bind:hubs="filteredHubs"
            v-on:close="modal.show = false"
            v-on:select-hub="selectHub"
            ref="hub-picker-modal"
        ></hub-picker-modal>
    </div>
</template>
<script>
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';
import HubPickerModal from '../memberships/HubPickerModal.vue';
import { EventBus } from '../../event-bus';

export default {
    components: {
        DatePick,
        HubPickerModal,
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    props: {
        hubs: {
            type: Array,
        },
        selected: {
            type: Array,
        },
        errors: {
            type: String,
            default: '',
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        sendNotification: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedHubs: [],
            selectedHubIds: _.get(this, 'selected', []),
            modal: null,
            shouldSendNotification: this.sendNotification,
        }
    },
    computed: {
        filteredHubs() {
            return this.hubs.filter((hub) => {
                return _.indexOf(this.selectedHubIds, hub.id) < 0 && _.indexOf(this.selectedHubIds, hub.id + '') < 0;
            });
        },
    },
    methods: {
        addHub() {
            this.modal.show = true;
        },
        selectHub(hub) {
            this.modal.show = false;
            this.selectedHubs.push(hub);
            this.selectedHubIds.push(hub.id);
            EventBus.$emit('form:element:changed', 'hubs', this.selectedHubIds.join('-'));
        },
        deleteItem(index) {
            this.selectedHubs.splice(index, 1);
            this.selectedHubIds.splice(index, 1);
            EventBus.$emit('form:element:changed', 'hubs', this.selectedHubIds.join('-'));
        },
        hubFeatures(hub) {
            if (typeof hub.enabled_hub_features == 'undefined') {
                return '';
            }

            return hub.enabled_hub_features.reduce((featuresList, feature) => {
                if (featuresList.length > 0) {
                    featuresList += ', ';
                }

                featuresList += feature.label;

                return featuresList;
            }, '');
        },
    },
    mounted() {
        this.modal = this.$refs['hub-picker-modal'];

        this.$nextTick(() => {
            EventBus.$emit('form:element:initialize', 'hubs', this.selectedHubIds.join('-'));
        });
    },
    created() {
        if (this.selectedHubIds.length && this.hubs.length) {
            this.selectedHubs = this.selectedHubIds.map(id => _.find(this.hubs, {'id': id}) || _.find(this.hubs, {'id': parseInt(id)}));
        }
    }
}
</script>