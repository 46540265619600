<template>
    <modal-centered v-bind:show.sync="show" ref="modal" class="text-left" size="large">
        <div v-bind:id="id" ref="player" v-bind:class="{'dropshadow-large': transcoded}">
            <div v-if="transcoded === false" class="text-center">{{ trans('Video is still being transcoded. Please try again in a few minutes.') }}</div>
        </div>
    </modal-centered>
</template>
<script>
    import { EventBus } from '../../event-bus.js';
    import Player from '@vimeo/player';
    import ModalCentered from './ModalCentered.vue';

    export default {
        components: {
            ModalCentered
        },
        props: {
            id: {
                type: String
            },
            url: {
                type: String
            },
            title: {
                type: String
            }
        },
        data() {
            return {
                show: false,
                player: null,
                transcoded: true
            }
        },
        watch: {
            show(value) {
                if (value === true) {
                    if (this.player === null) {
                        this.player = new Player(this.$refs['player'], {url: this.url, responsive: 1, autoplay: true});
                        this.player.ready().catch((e) => {
                            this.transcoded = false;
                        });
                    } else {
                        this.player.play();
                    }
                } else {
                    this.player.pause();
                }
            }
        },
        created() {
            let $vm = this;

            EventBus.$on('modal:video-player', () => {
                $vm.show = true;
            });
        }
    }
</script>