<template>
    <div class="m-t-2">
        <div class="row row-collapse br-bottom">
            <div class="column c-medium-tint column f-s-3 p-b-2 f-w-bold">{{ trans('First name') }}</div>
            <div class="column c-medium-tint column f-s-3 p-b-2 f-w-bold">{{ trans('Last name') }}</div>
            <div class="column c-medium-tint column f-s-3 p-b-2 f-w-bold">{{ trans('Email') }}</div>
        </div>
        <div v-for="(member, index) in members" class="row row-collapse p-t-3" v-bind:key="index">
            <div class="column">
                <div class="row row-collapse-indent">
                    <div class="column">
                        <div class="form-group m-b-0">
                            <input class="input__field input-small" type="text" v-bind:name="'members[' + index + '][first_name]'" v-model="member.firstName" placeholder="First name">
                        </div>
                    </div>
                    <div class="column">
                        <div class="form-group m-b-0">
                            <input class="input__field input-small" type="text" v-bind:name="'members[' + index + '][last_name]'" v-model="member.lastName" placeholder="Last name">
                        </div>
                    </div>
                    <div class="column">
                        <div class="form-group m-b-0">
                            <input class="input__field input-small" type="email" v-bind:name="'members[' + index + '][email]'" v-model="member.email" placeholder="Email">
                        </div>
                    </div>
                </div>
            </div>
            <a v-on:click.prevent="removeMember(member, index)" class="btn btn-text form-append width-auto-min">
                <span class="tooltip animate">
                    <span class="i-container i-filled-dark">
                        <!-- /icons/s-trash.svg -->
                        <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                    </span>
                    <span class="tooltip-content tooltip-item tooltip--right">{{ trans('Remove') }}</span>
                </span>
            </a>
        </div>
        <div class="text-center p-t-3">
            <button v-on:click="addMember" v-bind:disabled="members.length === 50" v-bind:class="{'btn-disabled': members.length === 50}" type="button" class="btn btn-text btn-text--accent btn-icon m-b-4">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/add.svg -->
                    <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                </span>
                {{ trans('Add Another Member') }}
            </button>
        </div>
        <div v-if="errors.length > 0" class="form-help c-error">
            {{ errors }}
        </div>
    </div>
</template>
<script>
import { EventBus } from '../event-bus';

export default {
    props: {
        entered: {
            type: Array
        },
        errors: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            members: _.get(this, 'entered', []).map((member) => {
                return {
                    'email': member.email,
                    'firstName': member.first_name,
                    'lastName': member.last_name,
                };
            })
        }
    },
    methods: {
        removeMember(member, index) {
            this.members.splice(index, 1);

            if (this.members.length === 0) {
                this.addMember();

                EventBus.$emit('form:element:changed', 'members[0][first_name]', '');
                EventBus.$emit('form:element:changed', 'members[0][last_name]', '');
                EventBus.$emit('form:element:changed', 'members[0][email]', '');
            }
        },
        addMember() {
            this.members.push({
                firstName: '',
                lastName: '',
                email: ''
            });
        },
    },
    mounted() {
        if (this.members.length === 0) {
            this.addMember();

            this.$nextTick(() => {
                EventBus.$emit('form:element:initialize', 'members[0][first_name]', '');
                EventBus.$emit('form:element:initialize', 'members[0][last_name]', '');
                EventBus.$emit('form:element:initialize', 'members[0][email]', '');
            });
        }
    }
}
</script>