<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="testimonial text-center c-accent-bg-light p-5 m-t-4">
            <div class="testimonial-content">
                <div v-if="heading" class="header-label">
                    <h4 class="f-w-bold c-primary f-s-4">{{ heading }}</h4>
                </div>
                <testimonials v-bind:items="items"></testimonials>
            </div>
        </div>
    </div>
</template>
<script>
    import TestimonialsRender from '../page/Testimonials.vue';

    export default {
        extends: TestimonialsRender
    }
</script>