<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Add new module') }}</h3>
        </div>
        <div>
            <div class="form-group" v-bind:class="{'has-error': errors.has('name')}">
                <label>
                    {{ trans('Module Name') }}
                    <input class="input__field" type="text" v-model="module.name">
                </label>
            </div>
            <div v-if="errors.has('name')" class="form-help">{{ errors.get('name') }}</div>
        </div>
        <div>
            <div class="form-group form-group-simple hl-txt" v-bind:class="{'has-error': errors.has('description')}">
                <label>
                    {{ trans('Module Description') }}
                    <textarea v-model="module.description" class="m-t-1 input__field" rows="2"></textarea>
                </label>
            </div>
            <div v-if="errors.has('description')" class="form-help">{{ errors.get('description') }}</div>
        </div>
        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="addModule(false)" v-bind:class="{'btn-loading': loading}">
                        {{ trans('Add Module') }}
                        <div v-if="loading" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                    <button type="button" class="btn btn-outline btn-outline--bright m-r-3" v-on:click="addModule(true)" v-bind:class="{'btn-loading': loadingPlusLesson}">
                        {{ trans('Add & Add Lesson') }}
                        <div v-if="loadingPlusLesson" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-accent" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import { EventBus } from '../../event-bus.js';
    import Modal from './Modal.vue';
    import Errors from '../../errors.js';

    export default {
        components: {
            Modal
        },
        data() {
            return {
                module: {
                    name: '',
                    description: '',
                },
                show: false,
                loading: false, // for "Add module" button
                loadingPlusLesson: false, // for "Add module and lesson" button
                errors: new Errors()
            }
        },
        methods: {
            addModule(createLesson) {
                if (createLesson) {
                    this.loadingPlusLesson = true;
                } else {
                    this.loading = true;
                }

                this.$emit('add-module', this.module, createLesson);
            },
            reset() {
                this.module = {
                    name: '',
                    description: '',
                };

                this.loading = false;
                this.loadingPlusLesson = false;
                this.errors.clear();
            }
        },
        created() {
            let $vm = this;

            EventBus.$on('modal:new-module', () => {
                $vm.show = true;
                $vm.loading = false;
                $vm.loadingPlusLesson = false;
            });
        }
    }
</script>