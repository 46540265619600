<template>
    <div>
        <div v-if=" ! infoMessage && ! filePath" class="m-t-2 text-center">
            <button v-on:click="showModal" type="button" class="btn btn-outline btn-outline--bright btn-icon m-b-4">
                <span class="icon i-container i-filled-accent">
                    <!-- /icons/upload.svg -->
                    <svg class="i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 7.4V16h2V7.4l4 4 1.4-1.4-5.7-5.7c-.4-.4-1-.4-1.4 0L5.6 10 7 11.4l4-4zM5 18h14v2H5z" class="icon"/></svg>
                </span>
                {{ trans('Upload CSV File') }}
            </button>
            <p class="c-muted f-s-2">{{ trans('Format your CSV file by First Name, Last Name, Email Address and Password (4 columns)') }}</p>
            <div v-if="errorMessage" class="alert p-3 c-muted alert c-warning-bg m-b-2">
                <div class="row-collapse row row-middle">
                    <span class="i-container i-filled-error">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon inject-svg i-size-small m-r-2"><path d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6v1h-2v-1c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C9.9 7.6 10.9 7 12 7c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" class="icon"></path></svg>
                    </span>
                    <div class="column">
                        <p class="m-0 f-s-2 c-medium text-left">{{  errorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="m-t-2 text-center">
            <span class="i-container i-filled-accent m-r-2">
                <!-- /icons/file.svg -->
                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M31 0H1C.4 0 0 .4 0 1v30c0 .6.4 1 1 1h30c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 30H2V2h28v28z"/><path d="M8 17h16c.6 0 1-.4 1-1s-.4-1-1-1H8c-.6 0-1 .4-1 1s.4 1 1 1zm0-7h16c.6 0 1-.4 1-1s-.4-1-1-1H8c-.6 0-1 .4-1 1s.4 1 1 1zm0 14h16c.6 0 1-.4 1-1s-.4-1-1-1H8c-.6 0-1 .4-1 1s.4 1 1 1z"/></g></svg>
            </span>
            <div class="c-muted f-s-4 inline m-r-4">
                {{ infoMessage }}
            </div>
            <button v-on:click="removeFile" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Remove') }}</button>
        </div>

        <files-upload-modal
            accepted-file-types=".csv"
            v-bind:max-files-num="1"
            v-on:close="modal.show = false"
            v-on:add-files="addFiles"
            ref="files-upload-modal"
        ></files-upload-modal>

        <input type="hidden" v-bind:value="filePath" name="file" />
    </div>
</template>
<script>
import { EventBus } from '../event-bus.js';

export default {
    data() {
        return {
            modal: null,
            filePath: '',
            infoMessage: null,
            errorMessage: null,
        }
    },
    watch: {
        filePath(path) {
            EventBus.$emit('form:element:changed', 'file', path);
        },
    },
    methods: {
        addFiles(files) {
            this.filePath = _.head(files).path;
            window.axios.put(Kourses.apiBaseUrl + '/members/import-csv', {
                'file_path': this.filePath
            }).then((response) => {
                this.modal.show = false;
                this.modal.saving = false;
                this.modal.files = [];
                this.infoMessage = _.get(response.data, 'records', 0) + this.trans(' member records found in given CSV file.');
                EventBus.$emit('removeAllFiles');
            }).catch(({response}) => {
                this.modal.show = false;
                this.modal.saving = false;
                this.modal.files = [];
                this.filePath = '';
                this.infoMessage = null;
                this.errorMessage = response.data.message;
                EventBus.$emit('removeAllFiles');
            });
        },
        showModal() {
            this.modal.show = true;
        },
        removeFile() {
            this.filePath = '';
            this.infoMessage = null;
            this.errorMessage = null;
        }
    },
    mounted() {
        this.modal = this.$refs['files-upload-modal'];
    }
}
</script>