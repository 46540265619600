<template>
    <slick ref="slick" :options="slickOptions" class="slideshow slideshow-featured-full">
        <div v-for="(membership, index) in items" class="slide" v-bind:key="index">
            <div class="cover-featured cover-container uploads uploads-r-1_3">
                <div class="cover row row-middle row-full upload-content row-center">
                    <div class="cover-image cover-image-overlay">
                        <div class="cover-overlay" v-bind:style="{'background-color': membership.color, 'opacity': membership.opacity > 0 ? membership.opacity / 100 : 0}"></div>
                        <img
                            v-if="membership.image.url && membership.image.url.length > 0"
                            v-bind:src="resizedImageUrl(membership.image.url, 'large')"
                            v-bind:alt="membership.name"
                        />
                    </div>
                    <div class="cover-content column medium-8 p-t-6 p-b-6">
                        <h2 class="cover-title f-s-10 f-w-bold c-bright m-b-2">{{ membership.name }}</h2>
                        <p v-if="membership.description" class="cover-description f-s-6 c-bright" v-html="membership.description"></p>
                        <a class="btn btn-theme btn-primary" href="#membership">{{ membership.call_to_action }}</a>
                    </div>
                </div>
            </div>
        </div>
    </slick>
</template>
<script>
import Slick from 'vue-slick';
import { resizedImageUrl } from '../../helpers.js';

export default {
    components: {
        Slick
    },
    props: {
        items: {
            type: Array
        }
    },
    data() {
        return {
            slickOptions: {
                dots: false,
                arrows: true,
                fade: true,
                cssEase: 'linear',
                prevArrow: '<div class="slick-prev slick-prev-large" ref="slick-prev"><span class="i-container i-filled-dark"><!-- /icons/slide-prev.svg --><svg class="icon" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32zM36.828 17.586l-1.414-1.414L20 31.586 35.414 47l1.414-1.414-14-14 14-14z" fill="#94A0B0" class="icon"/></svg></span></div>',
                nextArrow:'<div class="slick-next slick-next-large" ref="slick-next"><span class="i-container i-filled-dark"><!-- /icons/slide-next.svg --><svg class="icon" width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32zm27.172 13.414l1.414 1.414L44 31.414 28.586 16l-1.414 1.414 14 14-14 14z" fill="#94A0B0" class="icon"/></svg></span></div>'
            }
        }
    },
    watch: {
        items(newValue) {
            this.reInitSlick();
        }
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },
        resizedImageUrl(url, size) {
            return resizedImageUrl(url, size);
        },
        reInitSlick() {
            let currIndex = this.$refs.slick.currentSlide();

            this.$refs.slick.destroy();
            this.$nextTick(() => {
                this.$refs.slick.create();
                this.$refs.slick.goTo(currIndex, true);
            });
        }
    }
}
</script>