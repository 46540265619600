<script>
    import VueClipboards from 'vue-clipboards';

    Vue.use(VueClipboards);

    export default {
        props: {
            data: {
                type: String
            }
        },
        data() {
            return {
                copyData: this.data
            }
        },
        methods: {
            handleSuccess() {
                this.$toast.success(this.trans('URL copied!'));
            }
        }
    }
</script>