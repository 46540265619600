<template>
    <div v-show="active">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: ['nextLabel', 'prevLabel'],
        data() {
            return {
                active: false
            }
        },
        watch: {
            active(status) {
                if (status) {
                    this.$emit('enter');
                }
            }
        },
        created() {
            this.$parent.addStep(this);
        }
    }
</script>