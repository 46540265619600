<script>
import Modal from '../modals/Modal.vue';
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';

export default {
    components: {
        Modal
    },
    data() {
        return {
            show: false,
            loading: false,
            errors: new Errors(),
            text: '',
            commentableId: null,
            commentableType: 'Kourses\\Lesson',
            parentId: null,
        }
    },
    methods: {
        saveComment() {
            this.loading = true;

            window.axios
                .post(Kourses.apiBaseUrl + '/comments', {
                    'text': this.text,
                    'commentable_id': this.commentableId,
                    'commentable_type': this.commentableType,
                    'parent_id': this.parentId
                }).then((response) => {
                    EventBus.$emit('comment:added', response.data.data);
                    this.close();
                }).catch((error) => {
                    console.warn(error);
                    this.loading = false;
                    this.errors.record(error.response.data);
                });
        },
        reset() {
            this.errors.clear();
            this.loading = false;
            this.text = '';
            this.commentableId = null;
            this.commentableType = 'Kourses\\Lesson';
            this.parentId = null;
        },
        close() {
            this.reset();
            this.show = false;
        },
    },
    created() {
        let $vm = this;

        $vm.$on('close', () => {
            $vm.close();
        })
    },
}
</script>