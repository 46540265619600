<template>
    <div class="slat m-b-4">
        <div class="br p-t-3 p-b-3" v-bind:class="{ 'c-accent-bg-light': author.is_admin }">
            <div class="row row-compress row-middle">
                <div v-if=" ! author.is_admin" class="slat-figure column">
                    <a v-bind:href="memberShowLink(author)">
                        <div class="avatar">
                            <img v-if="author.image_url" class="avatar-image avatar-medium" v-bind:src="author.image_url">
                            <avatar-img v-else class="avatar-image avatar-medium" v-bind:src="getGravatarUrl(author.gravatar_hash)"></avatar-img>
                        </div>
                    </a>
                </div>
                <div class="slat-content column">
                    <a v-if=" ! author.is_admin" class="f-s-2 c-primary f-w-bold inline" v-bind:href="memberShowLink(author)">{{ author.full_name }}</a>
                    <div v-else class="f-s-2 c-primary f-w-bold block">{{ author.full_name }} <span class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-l-2">{{ trans('Author') }}</span></div>

                    <div class="f-s-1 m-b-2 c-muted">{{ formatDatetime(post.created_at) }}</div>

                    <h3 class="f-s-3 m-b-1">
                        <a class="c-primary" v-bind:href="postShowLink(post)" target="website">{{ post.title }}</a>
                    </h3>
                    <p class="f-s-2 m-b-0 c-primary line-clamp-2" v-html="post.content"></p>
                </div>
                <div class="slat-aside text-right m-r-4">
                    <a v-bind:href="postEditLink(post)" class="m-r-1">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- /icons/s-edit.svg -->
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#6F6FFF" class="icon" d="M15.626 4.334a1 1 0 0 0-.444-.623L9.244.002a1 1 0 0 0-1.378.317L2.833 8.375c-.019.029-.021.063-.035.095a.887.887 0 0 0-.101.385c-.002.036-.017.067-.015.103l.319 6.095c.004.074.033.14.053.209.012.043.009.087.026.129l.01.016c.014.03.034.054.051.082a.945.945 0 0 0 .145.201c.026.027.055.049.084.073a.96.96 0 0 0 .197.127c.032.016.062.032.098.045a.984.984 0 0 0 .332.064l.002.001H4l.053-.001c.066-.004.123-.032.186-.048.051-.013.103-.01.152-.031l5.616-2.386c.011-.005.017-.014.027-.019.105-.049.202-.113.286-.196.015-.015.025-.031.039-.047.036-.041.075-.08.104-.128l5.035-8.056a.99.99 0 0 0 .128-.754zm-6.027 6.423A8.046 8.046 0 0 0 8.517 9.66l2.16-3.459a1.001 1.001 0 0 0-.319-1.378 1.003 1.003 0 0 0-1.379.32L6.818 8.602c-.049-.022-.09-.052-.141-.074a1.001 1.001 0 1 0-.782 1.843c.271.114.524.245.754.39l.001.001.001.001a5.72 5.72 0 0 1 1.497 1.391l-3.225 1.369-.227-4.356 4.336-6.939 4.241 2.649-3.674 5.88z"/></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Edit') }}</span>
                        </span>
                    </a>
                    <template v-if="allowLikes === true">
                        <button v-if="liked" v-on:click="dislikePost" type="button" class="m-r-1">
                            <span class="tooltip animate">
                                <span class="i-container i-filled-theme">
                                    <!-- https://www.svgrepo.com/svg/507332/heart -->
                                    <svg width="16" height="16" class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M5.361 3.47A5.127 5.127 0 0 1 7.5 3c.737 0 1.464.16 2.139.47.674.309 1.28.759 1.785 1.318a.777.777 0 0 0 1.152 0C13.598 3.658 15.006 3 16.5 3c1.493 0 2.902.657 3.924 1.788C21.443 5.915 22 7.424 22 8.979c0 1.555-.557 3.064-1.576 4.192l-6.198 6.858a3 3 0 0 1-4.452 0L3.576 13.17a6.043 6.043 0 0 1-1.17-1.936A6.443 6.443 0 0 1 2 8.98c0-.772.137-1.538.406-2.256a6.044 6.044 0 0 1 1.17-1.935A5.5 5.5 0 0 1 5.361 3.47Z" clip-rule="evenodd"/></svg>
                                </span>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Dislike') }}</span>
                            </span>
                        </button>
                        <button v-else v-on:click="likePost" type="button" class="m-r-1">
                            <span class="tooltip animate">
                                <span class="i-container i-stroke-theme">
                                    <!-- https://www.svgrepo.com/svg/507729/heart -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="icon i-size-tiny inline" viewBox="0 0 24 24"><path fill="none" stroke-linejoin="round" stroke-width="3" d="m4.882 12.956 5.62 6.351a2 2 0 0 0 2.996 0l5.62-6.351c1.673-1.891 2.542-4.269 1.285-6.536-1.452-2.62-4.113-3.156-6.426-1.396a13.084 13.084 0 0 0-1.766 1.654.284.284 0 0 1-.422 0 13.084 13.084 0 0 0-1.766-1.654C7.71 3.264 5.049 3.8 3.597 6.42c-1.257 2.267-.388 4.645 1.285 6.536Z"/></svg>
                                </span>
                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Like') }}</span>
                            </span>
                        </button>
                    </template>
                    <a v-if="allowComments" v-on:click="reply" v-bind:href="postReplyLink(post)" class="m-r-1">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- https://www.svgrepo.com/svg/352397/reply -->
                                <svg class="icon i-size-tiny inline" width="16" height="16" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"/></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Reply') }}</span>
                        </span>
                    </a>
                    <a v-on:click.prevent="deletePost" href="#delete-post">
                        <span class="tooltip animate">
                            <span class="i-container i-filled-theme">
                                <!-- /icons/s-trash.svg -->
                                <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                            </span>
                            <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Delete') }}</span>
                        </span>
                    </a>
                </div>
            </div>
            <div class="row row-middle p-t-3">
                <div class="column">
                    <div class="row row-middle row-collapse br-top p-t-3">
                        <div class="column">
                            <span class="f-s-2 c-medium f-w-bold">{{ post.hub.title }}</span>
                        </div>
                        <div class="fl-right">
                            <span v-if="allowLikes" class="m-r-2">
                                <a v-if="post.likes_count > 0" v-bind:href="postLikesLink(post)" class="f-s-2 c-theme">{{ likesCount }}</a>
                                <span v-else class="f-s-2 c-muted">{{  trans('0 likes') }}</span>
                            </span>
                            <span v-if="allowComments">
                                <a v-if="post.comments_count > 0" v-bind:href="postCommentsLink(post)" class="f-s-2 c-theme">{{ commentsCount }}</a>
                                <span v-else class="f-s-2 c-muted">{{  trans('0 comments') }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { memberShowLink, postShowLink, postEditLink } from '../../routes';
import { getGravatarUrlFromHash } from '../../helpers';
import AvatarImg from '../AvatarImg.vue';
import { HUB_FEATURE_TYPE_COMMUNITY } from '../../constants';

export default {
    components: {
        AvatarImg,
    },
    props: {
        post: {
            type: Object,
            required: true
        },
        author: {
            type: Object,
            required: true
        },
        liked: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        allowLikes() {
            if (this.post.disable_likes === true) {
                return false;
            }

            const communityFeature = this.post.hub.features.find((feature) => feature.type === HUB_FEATURE_TYPE_COMMUNITY);

            if ( ! communityFeature) {
                return false;
            }

            if (typeof communityFeature.options.likes == 'undefined') {
                return true;
            }

            return parseInt(communityFeature.options.likes) === 1;
        },
        allowComments() {
            const communityFeature = this.post.hub.features.find((feature) => feature.type === HUB_FEATURE_TYPE_COMMUNITY);

            if ( ! communityFeature) {
                return false;
            }

            return parseInt(communityFeature.options.comments) === 1;
        },
        commentsCount() {
            if (this.post.comments_count === 1) {
                return this.trans('1 comment');
            }

            return this.trans(':count comments', {'count': this.post.comments_count});
        },
        likesCount() {
            if (this.post.likes_count === 1) {
                return this.trans('1 like');
            }

            return this.trans(':count likes', {'count': this.post.likes_count});
        },
    },
    methods: {
        getGravatarUrl(hash) {
            return getGravatarUrlFromHash(hash);
        },
        memberShowLink(member) {
            return memberShowLink(member);
        },
        postShowLink(post) {
            return postShowLink(post);
        },
        postEditLink(post) {
            return postEditLink(post);
        },
        postCommentsLink(post) {
            return postShowLink(post) + '#comments';
        },
        postLikesLink(post) {
            return postShowLink(post) + '#likes';
        },
        postReplyLink(post) {
            return postShowLink(post) + '#reply';
        },
        formatDatetime(timestamp) {
            return new Date(timestamp).toLocaleString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
        },
        deletePost() {
            this.$emit('post:delete');
        },
        reply() {
            this.$emit('post:reply');
        },
        likePost() {
            this.$emit('post:like');
        },
        dislikePost() {
            this.$emit('post:dislike');
        },
    }
}
</script>