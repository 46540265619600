<script>
    import { EventBus } from '../../event-bus.js';

    export default {
        props: {
            id: {
                type: String
            },
            table: {
                type: String,
                required: true
            },
            excludeSoftDeleted: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                dirty: false,
                title: '',
            }
        },
        methods: {
            generateSlug: function() {
                if ( ! this.dirty) {
                    window.axios
                        .post(Kourses.apiBaseUrl + '/slug/generate', {
                            'id': this.id,
                            'title': this.title,
                            'table': this.table,
                            'exclude_deleted': this.excludeSoftDeleted,
                        }).then((response) => {
                            EventBus.$emit('slug-generated', response.data.slug);
                        });
                }
            }
        }
    }
</script>
