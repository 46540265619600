<template>
    <div class="expandable">
        <div class="expand-header m-b-3">
            <toggle
                label="Show Author"
                name="show_author"
                v-bind:toggled.sync="expanded"
            ></toggle>
        </div>
        <transition name="show">
            <div v-if="expanded" class="expand-content form">
                <div v-if="authors.length > 0" class="form-group">
                    <label>
                        {{ trans('Select an author') }}
                        <select class="input__field" v-model="authorId" name="author_id">
                            <option v-for="author in authors" v-bind:value="author.id" v-bind:key="author.id">{{ author.full_name }}</option>
                        </select>
                    </label>
                </div>
                <div class="text-center m-n-t-1">
                    <a v-bind:href="newAuthorUrl" class="btn btn-text btn-text--accent btn-icon ul-none m-b-4">
                        <span class="icon i-container i-filled-accent">
                            <!-- /icons/add.svg -->
                            <svg class="icon i-size-small inline" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g class="icon"><path d="M11 5v14h2V5z"/><path d="M19 11H5v2h14z"/></g></svg>
                        </span>
                        {{ trans('Add Author') }}
                    </a>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        props: {
            existingAuthors: {
                type: Array
            },
            selected: {
                type: String
            },
            newAuthorUrl: {
                type: String
            }
        },
        data() {
            return {
                authorId: this.selected,
                authors: this.existingAuthors,
                expanded: this.selected !== '' ? true : false
            }
        }
    }
</script>