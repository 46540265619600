<template>
    <div>
        <div class="form-group">
            <label>{{ label }}</label>
                <select class="form-control" v-model="selected">
                    <option value="external">{{ trans('External URL') }}</option>
                    <option value="dashboard">{{ trans('Dashboard') }}</option>
                    <option value="feed">{{ trans('Feed (if available)') }}</option>
                    <option value="hubs">{{ trans('Hubs') }}</option>
                    <optgroup v-for="hub in hubs" v-bind:key="'hub-' + hub.encoded_id" v-bind:label="hub.title">
                        <option v-for="feature in hub.enabled_hub_features" v-bind:key="'feature-' + feature.id" v-bind:value="'hub:' + hub.encoded_id + ':' + feature.type">{{ feature.tab_title }}</option>
                    </optgroup>
                </select>
        </div>
        <input type="hidden" v-bind:name="name" v-bind:value="redirectLocation" />
        <div v-bind:class="{'has-error': validationErrors.has('external_url')}" class="form-group" v-if="selected === 'external'">
            <label>
                {{ trans('External URL') }}
                <input type="text" class="input__field" v-model="external" name="external_url" />
            </label>
        </div>
        <div v-if="validationErrors.has('external_url')" class="form-help">{{ validationErrors.get('external_url') }}</div>
    </div>
</template>
<script>
import Errors from '../../errors.js';
import { EventBus } from '../../event-bus.js';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        hubs: {
            type: Array,
            required: true
        },
        errors: {
            type: [Array, Object]
        },
    },
    data() {
        return {
            selected: this.value.indexOf('external:') === 0 ? 'external' : this.value,
            external: this.value.indexOf('external:') === 0 ? this.value.substring(9) : '',
            validationErrors: new Errors(_.get(this, 'errors', {})),
        }
    },
    computed: {
        redirectLocation() {
            if (this.selected === 'external') {
                return 'external:' + this.external;
            }

            return this.selected;
        }
    },
    watch: {
        redirectLocation() {
            EventBus.$emit('form:element:changed', this.name, this.redirectLocation);
        }
    }
}
</script>