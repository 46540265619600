<template>
    <form v-bind:action="action" method="POST" v-on:submit="checkIfLastStep">
        <input type="hidden" name="_token" v-bind:value="token" />
        <div class="row">
            <div class="column">
                <div class="progress-group m-b-7">
                    <div class="progress progress--compressed">
                        <div class="progress-bar" v-bind:aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100" v-bind:style="'width: ' + progressPercentage + '%;'"></div>
                    </div>
                    <div class="c-muted">{{ currentStep }}/{{ totalSteps }}</div>
                </div>
            </div>
        </div>
        <slot></slot>
        <div class="row m-t-6 m-b-7">
            <div class="small-12 columns">
                <div class="row row-collapse">
                    <div class="column">
                        <button v-if="prevLabel" v-on:click="prevStep" type="button" class="btn btn-text btn-text--muted i-filled-accent p-l-0">
                            <!-- /icons/prev.svg -->
                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M10.414 12l4-4L13 6.586 7.586 12 13 17.414 14.414 16z" fill-rule="nonzero" fill="#AAB2BD"/></g></svg>
                            {{ prevLabel }}
                        </button>
                    </div>
                    <div v-if="nextLabel && currentStep !== totalSteps" class="text-right">
                        <button class="btn btn-primary" v-bind:class="{'btn-disabled': nextDisabled }" v-bind:disabled="nextDisabled" type="button" v-on:click.prevent="nextStep">{{ nextLabel }}</button>
                    </div>
                    <div v-if="currentStep === totalSteps" class="text-right">
                        <button v-on:click="submitForm" class="btn btn-primary" v-bind:class="{'btn-disabled': nextDisabled, 'btn-loading': submitted}" v-bind:disabled="nextDisabled" type="submit">
                            {{ nextLabel }}
                            <div v-if="submitted" class="loader">
                                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                    <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
    import { EventBus } from '../event-bus.js';

    export default {
        props: {
            action: {
                type: String
            },
            validation: {
                type: Object
            }
        },
        data() {
            return {
                currentStep: 1,
                totalSteps: 1,
                token: window.Laravel.csrfToken,
                steps: [],
                nextLabel: false,
                prevLabel: false,
                nextDisabled: false,
                submitted: false
            }
        },
        computed: {
            progressPercentage() {
                return 100 / this.totalSteps * this.currentStep;
            },
            currentStepIndex() {
                return this.currentStep - 1;
            }
        },
        methods: {
            addStep(step) {
                this.steps.push(step);
            },
            submitForm(event) {
                if (_.has(this.validation, this.currentStep) && false === this.validation[this.currentStep]()) {
                    event.stopPropagation();
                    event.preventDefault();
                    return false;
                }

                this.submitted = true;
            },
            nextStep(event) {
                if (_.has(this.validation, this.currentStep) && false === this.validation[this.currentStep]()) {
                    return false;
                }

                event.target.blur();
                this.$children[this.currentStepIndex].active = false;
                this.currentStep += 1;
                this.$children[this.currentStepIndex].active = true;
                this.nextLabel = this.steps[this.currentStepIndex].nextLabel;
                this.prevLabel = this.steps[this.currentStepIndex].prevLabel;
            },
            prevStep(event) {
                event.target.blur();
                this.$children[this.currentStepIndex].active = false;
                this.currentStep -= 1;
                this.$children[this.currentStepIndex].active = true;
                this.nextLabel = this.steps[this.currentStepIndex].nextLabel;
                this.prevLabel = this.steps[this.currentStepIndex].prevLabel;
            },
            checkIfLastStep(event) {
                if (this.currentStep === this.totalSteps) {
                    return true;
                }

                event.preventDefault();
                event.stopPropagation();
            }
        },
        created() {
            var $vm = this;

            EventBus.$on('form-valid', () => {
                $vm.nextDisabled = false;
            });
            EventBus.$on('form-invalid', () => {
                $vm.nextDisabled = true;
            });
        },
        mounted() {
            this.totalSteps = this.$children.length;
            this.$children[0].active = true;
            this.nextLabel = this.steps[0].nextLabel;
            this.prevLabel = this.steps[0].prevLabel;
        }
    }
</script>