<template>
    <div class="slat m-t-4 m-b-3" v-bind:key="item.key">
        <div class="br p-t-3 p-b-3">
            <div v-bind:class="{'br-bottom': expanded, 'p-b-3': expanded}" class="slat-group-header slat-group-header-small row row-middle">
                <div class="slat-controls slat-controls--mini m-vertical-none m-l-2">
                    <span class="i-container i-filled-dark">
                        <span class="handle">
                            <!-- /icons/drag.svg -->
                            <svg class="icon i-size-small inline icon-drag" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(8 3)" fill-rule="evenodd" class="icon"><rect width="3" height="3" rx="1.5"/><rect x="5" width="3" height="3" rx="1.5"/><rect y="5" width="3" height="3" rx="1.5"/><rect x="5" y="5" width="3" height="3" rx="1.5"/><rect y="10" width="3" height="3" rx="1.5"/><rect x="5" y="10" width="3" height="3" rx="1.5"/><rect y="15" width="3" height="3" rx="1.5"/><rect x="5" y="15" width="3" height="3" rx="1.5"/></g></svg>
                        </span>
                    </span>
                </div>
                <div class="slat-content column p-l-2">
                    <a href="#expand" v-on:click.prevent="toggleExpanded">
                        <h4 class="f-w-bold f-s-3 m-b-0">{{ name }}</h4>
                    </a>
                </div>
                <div class="slat-aside text-right m-r-4">
                    <span class="tooltip animate">
                        <a class="i-container i-filled-dark" href="#delete-membership" v-on:click.prevent="deleteItem">
                            <!-- /icons/s-trash.svg -->
                            <svg class="icon i-size-tiny inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"/><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"/></g></svg>
                        </a>
                        <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Remove') }}</span>
                    </span>
                </div>
            </div>
            <div v-show="expanded" class="slat-group-content p-l-4 p-r-4 p-t-4 m-t-1">
                <image-upload
                    ratio="3:1"
                    v-bind:overlay-color="overlayColor"
                    v-bind:field-name="fieldName + '[data][items][' + index + '][image][path]'"
                    v-bind:field-url-name="fieldName + '[data][items][' + index + '][image][url]'"
                    v-bind:existing-file-url="image.url"
                    v-bind:existing-file-path="image.path"
                ></image-upload>
                <div class="c-muted f-s-2 m-t-2">{{ trans('Make this file: MP4, M4V, AVI; 640 -1280px wide; Smaller than 1.5GB') }}</div>

                <color-picker
                    v-bind:color-label="trans('Colour Overlay')"
                    v-bind:opacity-label="trans('Opacity')"
                    v-bind:value="{ color, opacity }"
                    v-bind:use-opacity="true"
                    v-bind:field-name-prefix="fieldName + '[data][items][' + index + ']'"
                    v-on:update:color="color = $event"
                    v-on:update:opacity="opacity = $event"
                    v-bind:in-sidebar="true"
                ></color-picker>

                <div class="form-group">
                    <label>
                        {{ trans('Name') }}
                        <input class="input__field" type="text" v-bind:name="fieldName + '[data][items][' + index + '][name]'" v-model="name" >
                    </label>
                </div>
                <div class="form-group">
                    <label>
                        {{ trans('Description') }}
                        <input class="input__field" type="text" v-bind:name="fieldName + '[data][items][' + index + '][description]'" v-model="description">
                    </label>
                </div>
                <div class="form-group">
                    <label>
                        {{ trans('Call to Action') }}
                        <input class="input__field" type="text" v-bind:name="fieldName + '[data][items][' + index + '][call_to_action]'" v-model="callToAction">
                    </label>
                </div>
            </div>
        </div>
        <input type="hidden" v-bind:name="fieldName + '[data][items][' + index + '][membership_id]'" v-bind:value="item.membership_id" />
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object
        },
        fieldName: {
            type: String
        },
        index: {
            type: Number
        },
        expanded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            name: _.get(this.item, 'name', ''),
            description: _.get(this.item, 'description', ''),
            callToAction: _.get(this.item, 'call_to_action', ''),

            image: _.get(this.item, 'image'),

            color: _.get(this.item, 'color', '#000000'),
            opacity: _.get(this.item, 'opacity', 50),
        }
    },
    watch: {
        name(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'name': newValue}));
        },
        description(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'description': newValue}));
        },
        callToAction(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'call_to_action': newValue}));
        },
        image(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'image': newValue}));
        },
        color(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'color': newValue}));
        },
        opacity(newValue) {
            this.$emit('update:item', Object.assign(this.item, {'opacity': newValue}));
        }
    },
    computed: {
        overlayColor() {
            return `background-color: ${this.color}; opacity: ${this.opacity / 100}`;
        }
    },
    methods: {
        toggleExpanded() {
            this.$emit('update:expanded', this.item.key);
        },
        deleteItem() {
            this.$emit('delete', this.index);
        }
    }
}
</script>