<script>
    export default {
        render() {
            return;
        },
        props: {
            messages: {
                type: Array
            }
        },
        methods: {
            alert(message, type) {
                if (type === 'error') {
                    this.$toast.danger(message);
                } else {
                    this.$toast.success(message);
                }
            }
        },
        created() {
            for (let item of this.messages) {
                this.alert(item.message, item.type);
            }
        }
    }
</script>