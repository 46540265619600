<template>
    <button v-bind:class="cssClasses" type="button" v-on:click="toggleStatus()">
        {{ status === 'published' ? unpublishLabel : publishLabel }}
        <div v-if="loading" class="loader">
            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                <svg v-bind:class="spinnerClassName" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
            </div>
        </div>
    </button>
</template>
<script>
import { EventBus } from '../event-bus.js';

export default {
    props: {
        publishUrl: {
            type: String,
            required: true
        },
        unpublishUrl: {
            type: String,
            required: true
        },
        published: {
            type: Boolean,
            default: true
        },
        publishLabel: {
            type: String,
            required: true
        },
        unpublishLabel: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: 'btn'
        },
        spinnerClassName: {
            type: String,
            default: 'i-stroke-accent'
        }
    },
    data() {
        return {
            status: this.published ? 'published' : 'draft',
            loading: false
        }
    },
    computed: {
        cssClasses() {
            if (this.loading) {
                return this.className + ' btn-loading';
            }

            return this.className;
        }
    },
    methods: {
        toggleStatus() {
            if (this.status === 'published') {
                this.unpublish();
            } else {
                this.publish();
            }
        },
        publish() {
            this.loading = true;
            window.axios
                .put(this.publishUrl)
                .then((response) => {
                    this.loading = false;
                    this.status = response.data.status;
                    this.$emit('update:status', response.data.status);
                    this.$toast.success(this.trans('Published!'));

                    // Used for toggling "Share" button
                    EventBus.$emit('hub:published', true);
                })
                .catch(({response}) => {
                    this.loading = false;
                    this.$toast.danger(response.data.message);
                });
        },
        unpublish() {
            this.loading = true;
            window.axios
                .delete(this.unpublishUrl)
                .then((response) => {
                    this.loading = false;
                    this.status = response.data.status;
                    this.$emit('update:status', response.data.status);
                    this.$toast.success(this.trans('Unpublished!'));

                    // Used for toggling "Share" button
                    EventBus.$emit('hub:published', false);
                }, (response) => {
                    console.log(response.data);
                });
        }
    }
}
</script>