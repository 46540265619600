<template>
    <modal v-bind:show.sync="show" ref="modal">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Select the service you want to integrate with:') }}</h3>
        </div>

        <div v-for="(integration, index) in integrations" v-bind:class="{'h-bg-accent--light' : selectedIntegration !== integration.provider}" v-bind:key="index" class="slat h-anim h-anim-select m-b-0">
            <div class="row row-compress-indent row-compress p-3 row-middle">
                <div class="slat-figure--medium column small-12">
                    <img class="br br-c--light integration-icon" v-bind:src="integration.icon">
                </div>
                <div class="slat-content column">
                    <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                    <p class="f-s-3 c-muted m-b-0">{{ integration.description }}</p>
                </div>

                <div v-if="integration.type == 'oauth'" class="slat-aside text-right m-r-4 m-t-2">
                    <button v-if="integration.authorized" v-on:click="connectOAuthIntegration(integration)" type="button" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                    <a v-else v-bind:href="integration.connect_url" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external" data-toggle="service">
                        {{ trans('Connect') }}
                    </a>
                </div>

                <div v-else class="slat-aside text-right m-r-4 m-t-2">
                    <button v-on:click="selectIntegration(integration)" type="button" class="btn btn-text btn-text--accent h-anim-select-item modal-close-external">
                        {{ trans('Choose service') }}
                    </button>
                </div>
            </div>

            <div v-if="integration.type == 'default' && selectedIntegration == integration.provider" class="row m-b-5">
                <div class="small-12 columns">
                    <div v-for="(field, fieldKey) in integration.config.connection.fields" v-bind:key="'label-' + fieldKey">
                        <div v-bind:class="{'has-error': errors.has(fieldKey)}" class="form-group">
                            <label>{{ field.label }}</label>
                            <input type="text" value="" class="input__field" v-model="integration.connection[fieldKey]">
                        </div>
                        <div v-if="errors.has(fieldKey)" v-bind:key="'error-' + fieldKey" class="form-help">{{ errors.get(fieldKey) }}</div>
                    </div>
                    <div class="text-right">
                        <button v-on:click="connectIntegration(integration)" v-bind:class="{'btn-loading': saving}" class="btn btn-primary" type="button">
                            {{ trans('Save and connect') }}
                            <div v-if="saving" class="loader">
                                <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                    <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer"></div>
    </modal>
</template>

<script>
    import Modal from '../../modals/Modal.vue';
    import { EventBus } from '../../../event-bus.js';
    import Errors from '../../../errors.js';

    export default {
        components: {
            Modal
        },
        props: {
            activeIntegration: { type: Object },
            integrations:      { type: Array },
        },
        data() {
            return {
                show: false,
                saving: false,
                selectedIntegration: '',
                errors: new Errors()
            }
        },
        computed: {
            availableIntegrations() {
                return this.integrations;
            }
        },
        methods: {
            selectIntegration(integration) {
                this.errors.clear();
                this.selectedIntegration = integration.provider;
            },
            connectIntegration(integration) {
                this.saving = true;

                if (this.validateIntegration(integration)) {
                    EventBus.$emit('connectIntegration', integration);
                }

                this.saving = false;
            },
            connectOAuthIntegration(integration) {
                this.saving = true;

                EventBus.$emit('connectOAuthIntegration', integration);
            },
            reset() {
                this.selectedIntegration = '';
                this.saving = false;
            },
            validateIntegration(integration) {
                this.errors.clear();

                let valid = true;

                for (var key in integration.config.connection.fields) {
                    if (integration.connection[key] === '') {
                        valid = false;
                        this.errors.set(key, this.trans(':field field is required.', {'field': integration.config.connection.fields[key].label}));
                    }
                }

                return valid;
            }
        }
    }
</script>
