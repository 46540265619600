<template>
    <div class="preview-group">
        <div v-for="(combination, index) in combinations" class="f-group m-b-5" v-bind:key="index">
            <div v-bind:class="{'card-item-active': isActive(combination) }" class="card card-mini br">
                <a v-on:click.prevent="select(combination)" class="card-item card-link" href="#fonts" v-bind:title="combinationName(combination)">
                    <div class="card-content row row-collapse row-middle p-4 p-l-5 p-r-5">
                        <div class="column">
                            <img v-bind:src="combination.image" v-bind:alt="combinationName(combination)" />
                        </div>
                        <div class="card-status">
                            <div class="i-container dropshadow-medium inline rounded-xlarge c-accent-bg i-filled-accent">
                                <!-- /icons/done-fill.svg -->
                                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><circle class="icon" cx="15" cy="15" r="15"/><path d="M8.707 15.293a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10a1 1 0 1 0-1.414-1.414L12 18.586l-3.293-3.293z" fill="#FFF" fill-rule="nonzero"/></g></svg>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <input type="hidden" v-bind:name="fieldNamePrefix + '[heading-font-family]'" v-model="heading" />
        <input type="hidden" v-bind:name="fieldNamePrefix + '[body-font-family]'" v-model="body" />
    </div>
</template>
<script>
    export default {
        props: {
            combinations: {
                type: Array,
                required: true
            },
            selectedHeading: {
                type: String,
                required: true
            },
            selectedBody: {
                type: String,
                required: true
            },
            fieldNamePrefix: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                heading: this.selectedHeading,
                body: this.selectedBody
            }
        },
        methods: {
            isActive(combination) {
                return combination.heading === this.heading && combination.body === this.body;
            },
            combinationName(combination) {
                return combination.heading === combination.body ? combination.body : combination.heading + ' + ' + combination.body;
            },
            select(combination) {
                this.body = combination.body;
                this.heading = combination.heading;
            }
        }
    }
</script>