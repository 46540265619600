<template>
    <div v-bind:id="'jump-' + index" class="m-b-6">
        <div class="row m-t-5">
            <div class="columns">
                <div class="row row-collapse-indent row-middle m-b-2">
                    <div class="column">
                        <h4 class="f-s-5 m-b-2">{{ heading }}</h4>
                    </div>
                    <div class="text-right p-r-4">
                        <a class="h-anim h-c-theme" href="#memberships">
                            {{ trans('See All') }}
                            <span class="i-container i-filled-theme">
                                <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" class="icon"/></svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <memberships
                v-bind:items="filteredMemberships"
                v-bind:narrow="isNarrow"
                v-bind:show-toolbar="false"
                v-bind:visible-items-num="numberOfMemberships"
            ></memberships>
        </div>
    </div>
</template>
<script>
    import Memberships from '../page/Memberships.vue';

    export default {
        extends: Memberships
    }
</script>