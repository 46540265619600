<template>
    <div v-cloak>
        <div v-if="items.length > 0">
            <div v-for="(integration, index) in items" v-bind:key="index" class="slat m-b-4">
                <div class="row row-compress-indent row-compress row-top row-integration-options">
                    <div class="slat-figure--medium column small-12">
                        <img v-bind:src="integration.icon" class="br br-c--light integration-icon">
                    </div>
                    <div class="slat-content column">
                        <h2 class="card-title c-primary f-s-4 m-b-1">{{ integration.title }}</h2>
                        <p class="f-s-3 c-muted">{{ integration.description }}</p>

                        <div v-bind:class="{'has-error': errors.has('integrations.' + integration.provider + '.external_product_id')}" class="form-group">
                            <label>
                                {{ trans('Product ID from the platform') }}
                                <input class="input__field" type="text" v-bind:name="'integrations[' + integration.provider + '][external_product_id]'" v-model="integration.external_product_id" required>
                            </label>
                        </div>
                        <div v-if="errors.has('integrations.' + integration.provider + '.external_product_id')" v-html="errors.get('integrations.' + integration.provider + '.external_product_id')" class="form-help"></div>
                    </div>

                    <div class="slat-aside text-right m-r-4 m-t-2">
                        <button v-on:click="removeIntegration(integration, index)" type="button" class="btn btn-secondary">{{ trans('Remove') }} {{ integration.title }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <slot name="empty"></slot>
        </div>
        <input type="hidden" v-bind:name="'for_removal[' + index + ']'" v-for="(id, index) in forRemoval" v-bind:value="id" v-bind:key="id" />
    </div>
</template>
<script>
import Errors from '../../../errors.js';
import { EventBus } from '../../../event-bus.js';
import md5 from 'js-md5';

export default {
    props: {
        integrations: {
            type: Array
        },
        validationErrors: {
            type: [Array, Object]
        }
    },
    data() {
        return {
            items: _.get(this, 'integrations', []),
            errors: new Errors(_.get(this, 'validationErrors', {})),
            forRemoval: [],
        }
    },
    methods: {
        removeIntegration(integration, index) {
            this.items.splice(index, 1);

            if (_.has(integration, 'id')) {
                this.forRemoval.push(integration.id);
            }

            EventBus.$emit('form:element:changed', 'integrations', this.getItemsHash());
        },
        getItemsHash() {
            return md5(JSON.stringify(this.items));
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('cart:integrations:update', integration => {
            $vm.items.push(integration);

            EventBus.$emit('form:element:changed', 'integrations', $vm.getItemsHash());
        });
    },
    mounted() {
        // I don't know why this needs to go in the next tick but it doesn't work otherwise
        Vue.nextTick(() => {
            EventBus.$emit('form:element:initialize', 'integrations', this.getItemsHash());
        });
    }
}
</script>