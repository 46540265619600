(function($R)
{
    $R.add('plugin', 'custom-variables', {
        translations: {
            en: {
                "custom-variables": "Variables"
            }
        },
        init: function(app)
        {
            // define app
            this.app = app;

            // define services
            this.lang = app.lang;
            this.opts = app.opts;
            this.toolbar = app.toolbar;
            this.insertion = app.insertion;
            this.selection = app.selection;
        },
        start: function()
        {
            if (this.opts.variables.length === 0) {
                return;
            }

            // create dropdown object
            var dropdownData = _.mapValues(this.opts.variables, (title, variable) => {
                return {
                    title: title,
                    api: 'plugin.custom-variables.itemSelected',
                    args: {
                        variable: variable,
                        title: title,
                    }
                }
            });

            // create the button data
            var buttonData = {
                title: this.lang.get('custom-variables')
            };

            // create the button
            var $button = this.toolbar.addButton('custom-variables', buttonData);
            $button.setIcon('<i class="re-icon-variable"></i>');

            // set dropdown
            var dropdown = $button.setDropdown(dropdownData);
        },
        itemSelected: function(args, index) {
            this.insertion.insertRaw('{' + args.variable + '}');
            this.app.broadcast('keyup', {});
        }
    });
})(Redactor);