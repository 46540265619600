export default class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor(errors) {
        if (errors) {
            this.record(errors);
        } else {
            this.errors = {};
        }
    }


    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
        return this.errors.hasOwnProperty(field);
    }


    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }


    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    /**
     * Set error message for a field.
     * @param {string} field
     * @param {string} error
     */
    set(field, error) {
        if ( ! this.errors.hasOwnProperty(field)) {
            this.errors[field] = [];
        }

        this.errors[field].push(error);
    }

    /**
     * Retrieve the error message for a field (duplicate message only to be compatible with Laravel error system).
     *
     * @param {string} field
     */
    first(field) {
        return this.get(field);
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record(errors) {
        // L5.5 changed JSOn error response structure
        this.errors = errors.hasOwnProperty('errors') ? errors.errors : errors;
    }


    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field) {
        if (field) {
            delete this.errors[field];

            return;
        }

        this.errors = {};
    }
}