<template>
    <modal v-bind:show.sync="show" ref="modal" class="text-left">
        <div slot="header">
            <div class="nav nav-tab">
                <a v-if="checkoutUrl" class="nav-item f-s-4 m-r-3 m-l-3" v-bind:class="{'nav-item--active': active === 'checkout'}" href="#checkout" v-on:click.prevent="active = 'checkout'">{{ trans('Checkout') }}</a>
            </div>
        </div>
        <div v-if="active === 'checkout'">
            <h4 class="f-s-8 m-b-2">{{ trans('Your checkout sharing URL') }}</h4>
            <p class="c-muted f-s-4">{{ trans('Copy and paste the links below into emails, chats or browsers.') }}</p>
            <div class="form-group form-group-addon">
                <label>
                    <span class="sc-reader">{{ trans('URL') }}</span>
                    <input class="input__field" type="text" disabled v-bind:value="checkoutUrl">
                </label>
            </div>
        </div>
        <div slot="footer">
            <button v-clipboard="copyUrl" v-on:success="showSuccessMessage" class="btn btn-primary m-r-3" type="button">{{ copyButtonLabel }}</button>
            <a v-if="testCheckoutUrl && active === 'checkout'" v-bind:href="testCheckoutUrl" target="website" class="btn btn-text btn-text--muted">{{  trans('Test Checkout Page') }}</a>
        </div>
    </modal>
</template>
<script>
import { EventBus } from '../../event-bus.js';
import Modal from '../modals/Modal.vue';
import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

export default {
    components: {
        Modal
    },
    props: {
        checkoutUrl: {
            type: String
        },
        testCheckoutUrl: {
            type: String,
            default: null
        },
        activeTab: {
            type: String,
            default: 'checkout'
        }
    },
    data() {
        return {
            show: false,
            active: this.activeTab
        }
    },
    computed: {
        copyButtonLabel() {
            switch (this.active) {
                default:
                    return this.trans('Copy Checkout URL');
            }
        }
    },
    methods: {
        copyUrl() {
            switch (this.active) {
                default:
                    return this.checkoutUrl;
            }
        },
        showSuccessMessage() {
            this.$toast.success(this.trans('URL copied!'));
        }
    },
    created() {
        let $vm = this;

        EventBus.$on('modal:share', () => {
            $vm.show = true;
        });
    }
}
</script>