<template>
    <div v-bind:class="{'row-middle': hasRowMiddleClass}" class="upload-content row row-collapse">
        <div class="column p-4 overflow-y--auto">
            <div v-if="screenKey">
                <h3>{{ trans('Enter Wistia API key') }}</h3>
                <div class="row row-bottom">
                    <div class="column p-l-0 wistia-key-container">
                        <div class="form-group m-b-0">
                            <label>
                                {{ trans('API Key') }}
                                <input type="text" v-model="wistiaKey" maxlength="100" class="input__field p-l-2">
                            </label>
                        </div>
                    </div>
                    <button v-bind:class="{'btn-loading': isSavingKey}" v-on:click="saveApiKey" type="button" class="btn btn-primary wistia-save-key-button">
                        {{ trans('Save API Key') }}
                        <div v-if="isSavingKey" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div v-else-if="screenSelectVideo" class="wistia-video-container">
                <h3>Select a video</h3>
                <div v-for="(video, index) in videos" class="slat m-b-4 br c-bright-bg" v-bind:key="index">
                    <div class="row row-compress-indent row-compress row-middle p-3">
                        <div class="slat-figure--medium column small-12">
                            <img class="br br-c--light wistia-video--image" v-bind:src="video.thumbnail">
                        </div>
                        <div class="slat-content column">
                            <h2 class="card-title c-primary f-s-4 m-b-1 wistia-video--title">{{ video.name }}</h2>
                            <p class="f-s-3 c-muted m-b-0">{{ video.duration | duration }}</p>
                        </div>
                        <div class="slat-aside text-right m-r-4">
                            <button v-on:click="useVideo(video)" type="button" class="btn btn-primary wistia-video--use-button">{{ trans('Use video') }}</button>
                        </div>
                    </div>
                </div>
                <div v-if="showLoadMore" class="text-center">
                    <button v-bind:class="{'btn-loading': isLoadingMoreVideos}" v-on:click="loadMoreVideos" type="button" class="btn btn-primary">
                        {{ trans('Load more videos') }}
                        <div v-if="isLoadingMoreVideos" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div v-else-if="screenLoadingFirstVideos" class="text-center">
                <div class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                    <svg class="i-stroke-theme" width="53" height="53"><g><circle class="icon" cx="50%" cy="50%" r="23" stroke-width="3" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
                <div class="card-content m-t-4">
                    <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Fetching your videos from Wistia') }}</h4>
                </div>
            </div>
            <div v-else-if="screenUploading" class="text-center">
                <div class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                    <svg class="i-stroke-theme" width="53" height="53"><g><circle class="icon" cx="50%" cy="50%" r="23" stroke-width="3" fill="none" stroke-dashoffset="5"></circle></g></svg>
                </div>
                <div class="card-content m-t-4">
                    <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading :fileName', {'fileName': video.name}) }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const SAVING_KEY = 'saving_key';
    const LOADING_MORE_VIDEOS = 'loading_more_videos';
    const LOADING_FIRST_VIDEOS = 'loading_first_videos';
    const UPLOADING = 'uploading';

    const SCREEN_KEY = 'screen_key';
    const SCREEN_UPLOADING = 'screen_uploading';
    const SCREEN_SELECT_VIDEO = 'screen_select_video';
    const SCREEN_LOADING_FIRST_VIDEOS = 'screen_loading_first_videos';

    export default {
        props: {
            apiKey: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                keyAdded: false,
                wistiaKey: this.apiKey,
                page: 1,
                videos: [],
                showLoadMore: false,
                video: null,

                loading: false,
                loadingMore: false,
                uploading: false,
                screen: SCREEN_LOADING_FIRST_VIDEOS,
            }
        },
        computed: {
            hasApiKey() {
                return (this.apiKey !== null && this.apiKey.length > 0) || this.keyAdded;
            },
            hasRowMiddleClass() {
                switch (this.screen) {
                    case SCREEN_KEY:
                    case SCREEN_LOADING_FIRST_VIDEOS:
                    case SCREEN_UPLOADING:
                        return true;
                    default:
                        return false;
                }
            },
            screenKey() {
                return this.screen === SCREEN_KEY;
            },
            screenSelectVideo() {
                return this.screen === SCREEN_SELECT_VIDEO;
            },
            screenLoadingFirstVideos() {
                return this.screen === SCREEN_LOADING_FIRST_VIDEOS;
            },
            screenUploading() {
                return this.screen === SCREEN_UPLOADING;
            },
            isLoadingMoreVideos() {
                return this.loading === LOADING_MORE_VIDEOS;
            },
            isSavingKey() {
                return this.loading === SAVING_KEY;
            }
        },
        methods: {
            saveApiKey() {
                this.loading = SAVING_KEY;

                window.axios
                    .post(window.Kourses.apiBaseUrl + '/wistia/key', {
                        key: this.wistiaKey
                    })
                    .then((response) => {
                        this.keyAdded = true;
                        this.loading = false;
                        this.screen = SCREEN_LOADING_FIRST_VIDEOS;
                        this.fetchVideos(1);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.$toast.danger(this.trans('Error while saving Wistia key!'));
                    });
            },
            fetchVideos(page) {
                if (page === 1) {
                    this.loading = true;
                }

                window.axios
                    .get(window.Kourses.apiBaseUrl + '/wistia/videos?page=' + this.page)
                    .then((response) => {
                        this.loading = this.loadingMore = false;
                        this.videos = this.videos.concat(response.data);
                        this.screen = SCREEN_SELECT_VIDEO;

                        if (response.data.length < 100) {
                            this.showLoadMore = false;
                        } else {
                            this.showLoadMore = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.screen = SCREEN_SELECT_VIDEO;
                        this.$toast.danger(this.trans('Error while fetching videos from Wistia!'));
                    });
            },
            loadMoreVideos() {
                this.loadingMore = true;
                this.loading = LOADING_MORE_VIDEOS;
                this.page += 1;

                this.fetchVideos(this.page);
            },
            useVideo(video) {
                // Show uploading screen
                this.uploading = true;
                this.loading = UPLOADING;
                this.video = video;
                this.screen = SCREEN_UPLOADING;

                // Now send the video URL through API to upload it to Vimeo
                this.uploadVideo(video);
            },
            uploadVideo(video) {
                window.axios
                    .post(window.Kourses.apiBaseUrl + '/wistia/videos', {
                        'url': video.video,
                        'name': video.name
                    })
                    .then((response) => {
                        this.$emit('video:uploaded', response.data);
                        this.uploading = false;
                        this.loading = false;
                        this.screen = SCREEN_SELECT_VIDEO;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.$toast.danger(this.trans('Error while uploading videos to Vimeo!'));
                    });
            }
        },
        mounted() {
            if ( ! this.apiKey || this.apiKey.length === 0) {
                this.screen = SCREEN_KEY;
            }

            if (this.hasApiKey && this.videos.length === 0) {
                this.fetchVideos(1);
            }
        }
    }
</script>