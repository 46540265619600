<template>
    <div v-if="lastPage > 1" class="footer footer-fixed">
        <div class="row">
            <aside v-if=" ! fullWidth" class="columns small-12 medium-3 large-3 p-r-6"></aside>
            <div class="column small-12 c-bright-bg" v-bind:class="{'medium-9 large-9': ! fullWidth}">
                <div class="row row-collapse row-middle br-top">
                    <div class="column text-right">
                        <span class="c-medium-tint f-s-2 m-r-3">{{ trans(':from - :to of :total', {'from': from, 'to': to, 'total': total}) }}</span>
                        <span class="c-medium-tint f-s-2">
                            <a v-if="currentPage > 1" v-on:click.prevent="prevPage()" href="#prev" class="i-container i-filled-dark btn btn-text width-auto-min">
                                <!-- /icons/back.svg -->
                                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FFF" width="32" height="32" rx="2"/><path fill="#798492" d="M19 21l-6-5 6-5z"/></g></svg>
                            </a>
                            <span class="form-group form-inline form-group-simple inline m-b-0">
                                <input class="input__field p-r-0 text-center f-s-2 p-b-0" type="text" size="4" maxlength="3" v-bind:value="currentPage" v-on:keyup.enter="jumpToPage">
                            </span>
                            <a v-if="currentPage < lastPage" v-on:click.prevent="nextPage()" href="#next" class="i-container i-filled-dark btn btn-text width-auto-min">
                                <!-- /icons/next.svg -->
                                <svg class="icon i-size-medium" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FFF" width="32" height="32" rx="2"/><path fill="#798492" d="M13 21l6-5-6-5z"/></g></svg>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            currentPage: {
                type: Number
            },
            from: {
                type: Number
            },
            to: {
                type: Number
            },
            lastPage: {
                type: Number
            },
            total: {
                type: Number
            },
            fullWidth: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            jumpToPage(event) {
                console.log(event);
                this.$emit('paginator:switch-page', event.target.value);
            },
            nextPage() {
                this.$emit('paginator:switch-page', this.currentPage + 1);
            },
            prevPage() {
                this.$emit('paginator:switch-page', (this.currentPage > 1 ? this.currentPage - 1 : 1));
            }
        }
    }
</script>