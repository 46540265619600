import ToastContainer from '../components/ToastContainer.vue';

const ToastPlugin = {
    mounted: false,
    $container: {},

    install(Vue, options) {
        Vue.prototype.success = function(message) {
            this.mountIfNotMounted();

            this.$container.commit({
                'type': 'success',
                'message': message
            });
        }

        Vue.prototype.danger = function(message) {
            this.mountIfNotMounted();

            this.$container.commit({
                'type': 'error',
                'message': message
            });
        }

        Vue.prototype.mountIfNotMounted = function() {
            if (this.mounted) {
                return;
            }

            this.$container = (() => {
                let ToastConstructor = Vue.extend(ToastContainer);
                let node = document.createElement('div');
                document.querySelector('body').appendChild(node);

                return (new ToastConstructor()).$mount(node);
            })();

            this.mounted = true;
        }

        Object.defineProperties(Vue.prototype, {
            $toast: {
                get() {
                    return this;
                }
            }
        });
    }
};
export default ToastPlugin;